<script>
import Layout from "../../layouts/main";
import { FormWizard, TabContent } from "vue3-form-wizard";
import 'vue3-form-wizard/dist/style.css';
import firstStep from '../sub-components/first_step.vue';
import personalInformation from '../sub-components/personal_information_step.vue';
import businessAndSource from '../sub-components/businessAndSource_step.vue';
import InformationAbout from '../sub-components/Information_about.vue';
import declarations from '../sub-components/declarations.vue';
import Additional_information from '../sub-components/Additional_information.vue';
import Required_Step from '../sub-components/Required_Step.vue';
import Assistance_Form from '../sub-components/Assistance_Form.vue';
import Undertakings_From from '../sub-components/Undertakings_From.vue';


import CryptoJS from 'crypto-js';
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';

export default {

  components: {
    Layout,
    firstStep,
    FormWizard,
    TabContent,
    personalInformation,
    businessAndSource,
    InformationAbout,
    declarations,
    Additional_information,
    Required_Step,
    Assistance_Form,
    Undertakings_From
    // EasyDataTable,
    // ReportStatus 
  },
  data() {
    return {
      app_URL: process.env.VUE_APP_URL,
      arrItem: [],
      addNotice: false,
      notice: "",
      image: "",
      documents: [],
      undertaking: "",
      currentStep: -1,
      currentStepC: 0,
    }
  },
  mounted() {
    this.getAllFiles();
    if (this.$store.state.applicantCstep != null) {
      this.currentStepC = parseInt(this.$store.state.applicantCstep)
    }
    this.dissabledField();

  },
  created() {
    this.getAllFiles();
    if (this.$store.state.applicantCstep != null) {
      this.currentStepC = parseInt(this.$store.state.applicantCstep)
    }
    this.dissabledField();


  },
  methods: {
    onComplete() {
      this.$router.push({ path: '/applicants' });
    },
    resetAdd() {
      this.notice = "";
    },
    dissabledField() {
      const tags = ["input", "textarea", "select"];
      tags.forEach(tagName => {
        const nodes = document.getElementsByTagName(tagName);
        for (let i = 0; i < nodes.length; i++) {
          if (tagName === "textarea" && nodes[i].id === "myTextarea") {
            continue;
          }
          nodes[i].disabled = true;
        }
      });
    },
    addToNotice() {
      axios.post(this.app_URL + "api/addNotice", {
        notice: this.notice,
        user_id: atob(decodeURIComponent(JSON.parse(localStorage.getItem("authenticatedUserId")))),
        form_id: this.decode(this.$route.params.id),
        step_id: this.currentStep + 1
      }).then(() => {
        this.$store.state.isAccepted = true
        this.$store.state.notificationData = 'Notice Added Successfully'
        setTimeout(() => {
          this.$store.state.isAccepted = false
          this.$store.state.notificationData = null
        }, 2500);
        this.addNotice = false;
      })
    },
    getAllFiles() {
      axios.post(this.app_URL + "api/getAllFiles", { disclosure_id: this.decode(this.$route.params.id) }).then((res) => {
        this.image = res.data.image;
        if (res.data.document) {
          this.documents = res.data.document;
        }
        if (res.data.undertaking) {
          this.undertaking = res.data.undertaking.image;
        }
      })
    },
    download(img) {
      axios.post(this.app_URL + "api/downloadImage", { responseType: 'blob', image: img }).then((response) => {
        const link = document.createElement('a');
        link.href = 'data:image/*;base64,' + response.data.base64Image;
        link.setAttribute('download', response.data.imagePath);
        document.body.appendChild(link);
        link.click();
        this.$store.state.isAccepted = true
        this.$store.state.notificationData = 'Image Downloaded Successfully'
        setTimeout(() => {
          this.$store.state.isAccepted = false
          this.$store.state.notificationData = null
        }, 2500)
      })
    },
    handleStepChange(step) {
      this.currentStep = step
    },
    getFileType(fileName) {
      const extension = fileName.split('.').pop().toLowerCase();

      switch (extension) {
        case 'pdf':
          return 'pdf';
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'image';
        default:
          return null; // or handle other file types accordingly
      }
    },

    downloadForm(fileName) {
      const fileType = this.getFileType(fileName);

      if (!fileType) {
        console.error('Invalid file type.');
        return;
      }

      axios.post(this.app_URL + 'api/downloadForm', { image: fileName }, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: this.getMimeType(fileType) });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$store.state.isAccepted = true
          this.$store.state.notificationData = 'File Downloaded Successfully'
          setTimeout(() => {
            this.$store.state.isAccepted = false
            this.$store.state.notificationData = null
          }, 2500)
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    },

    getMimeType(fileType) {
      switch (fileType) {
        case 'pdf':
          return 'application/pdf';
        case 'image':
          return 'image/jpeg'; // or adjust for other image types
        default:
          return null; // or handle other file types accordingly
      }
    },
    decode(id) {
      return decodeURIComponent(CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(CryptoJS.enc.Utf8))
    }
  }

};

</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col-6">
          <h2 class="font-size-22 text-theme-dark">Table Data</h2>
        </div>
        <!-- <div class="col-6">
          <div class="d-flex justify-content-end ms-3">
            <a href="" class="font-size-15 btn-sm btn btn-theme">
              <i class="mdi mdi-add font-size-15 align-middle me-1"></i> Add Notice </a></div>
        </div> -->
        <div class="col-lg-6 text-end">
          <a href="javascript:void(0);" class="btn btn-theme-dark" @click="addNotice = true, resetAdd()"> <i
              class="mdi mdi-add font-size-15 align-middle me-1"></i> Add Notice </a>
          <b-modal title="Add Notice" v-model="addNotice" hide-footer centered>
            <form>
              <div class="row">
                <div class="col-md-12">
                  <textarea class="form-control mb-3" rows="6" id="myTextarea" v-model="notice"></textarea>
                </div>
                <div class="col-12">
                  <div class="d-block text-end">
                    <button type="button" @click="addToNotice" class="btn btn-theme btn-custom-wdth">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </b-modal>
        </div>
      </div>
      <div class="row gx-3 mb-5 tablwdataScroll">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="image">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Profile Image</label>
                      <div class="fw-bold text-theme"><a @click="download(image)"
                          style="color:#ff1e27; cursor:pointer;">{{ image }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="undertaking">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Signature</label>
                      <div class="fw-bold text-theme"><a @click="download(undertaking)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            undertaking }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6"
              v-for="(file, index) in (documents.imagesForm12 ? documents.imagesForm12.split(',') : [])" :key="index">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Form 12</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(file)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            file }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.bankStatementCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Bank Statement</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.bankStatementCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.bankStatementCp
                          }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span v-if="documents.photos"> -->
            <div class="col-lg-3 col-md-4 col-sm-6"
              v-for="( file, index ) in  (documents.photos ? documents.photos.split(',') : []) " :key="index">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Photos {{ index + 1 }}</label>
                      <div class="fw-bold text-theme"><a @click="download(file)" style="color:#ff1e27; cursor:pointer;">{{
                        file }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </span> -->
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.birthCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Birth Certificate</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.birthCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.birthCp }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.emplyLetterCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Employement Certificate</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.emplyLetterCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.emplyLetterCp }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.fileFormD2">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Form D2</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.fileFormD2)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.fileFormD2 }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.fileFormD3">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Form D3</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.fileFormD3)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.fileFormD3 }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.fileFormD4">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Form D4</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.fileFormD4)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.fileFormD4 }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.nationalIdCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">National ID</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.nationalIdCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.nationalIdCp }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.passportCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Passport</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.passportCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.passportCp }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6"
              v-for="( file, index ) in  (documents.professionalRefCp ? documents.professionalRefCp.split(',') : []) "
              :key="index">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Professional Ref</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(file)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            file
                          }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.militaryDocCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Military Certificate</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.militaryDocCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.militaryDocCp
                          }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.diplomaCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Diploma Certificate</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.diplomaCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.diplomaCp
                          }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.policRecCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Diploma Certificate</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.policRecCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.policRecCp
                          }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" v-if="documents.residentPrfCp">
              <div class="card">
                <div class="card-body p-3">
                  <div class="">
                    <div class="bg-lightprimary">
                      <label class="font-size-16" for="profile">Diploma Certificate</label>
                      <div class="fw-bold text-theme"><a @click="downloadForm(documents.residentPrfCp)"
                          style="color:#ff1e27; cursor:pointer;">{{
                            documents.residentPrfCp
                          }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">

        <div class="card memberStep">
          <div class="card-body">
            <!-- <div class="pb-4 text-center">
                <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="60" />
              </div>
              <h2 class="mainTitleSize">Application for Dominica Citizenship</h2> -->

            <form-wizard @on-complete="onComplete" step-size="xs" color="#0c3361" :start-index="currentStepC"
              @on-change="handleStepChange">
              <tab-content title="">
                <firstStep ref="firstStepRef" />
              </tab-content>
              <tab-content title="">
                <personalInformation ref="personalInformationRef" />
              </tab-content>
              <tab-content title="">
                <businessAndSource ref="businessAndSourceStepRef" />
              </tab-content>
              <tab-content title="">
                <InformationAbout ref="informationAboutRef" />
              </tab-content>
              <tab-content title="">
                <declarations ref="declarationsRef" />
              </tab-content>
              <tab-content title="">
                <Additional_information ref="additionalInformationRef" />
              </tab-content>
              <tab-content title="">
                <Required_Step ref="requiredStepRef" />
              </tab-content>
              <tab-content title="">
                <Assistance_Form ref="assistanceFormRef" />
              </tab-content>
              <tab-content title="">
                <Undertakings_From ref="undertakingFormRef" />
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>

    </main>
  </Layout>
</template>