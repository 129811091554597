const states = [
    {
        "name": "Badakhshan",
        "country_name": "Afghanistan"},
    {
        "name": "Badghis",
        "country_name": "Afghanistan"},
    {
        "name": "Baghlan",
        "country_name": "Afghanistan"},
    {
        "name": "Balkh",
        "country_name": "Afghanistan"},
    {
        "name": "Bamyan",
        "country_name": "Afghanistan"},
    {
        "name": "Daykundi",
        "country_name": "Afghanistan"},
    {
        "name": "Farah",
        "country_name": "Afghanistan"},
    {
        "name": "Faryab",
        "country_name": "Afghanistan"},
    {
        "name": "Ghazni",
        "country_name": "Afghanistan"},
    {
        "name": "Ghōr",
        "country_name": "Afghanistan"},
    {
        "name": "Helmand",
        "country_name": "Afghanistan"},
    {
        "name": "Herat",
        "country_name": "Afghanistan"},
    {
        "name": "Jowzjan",
        "country_name": "Afghanistan"},
    {
        "name": "Kabul",
        "country_name": "Afghanistan"},
    {
        "name": "Kandahar",
        "country_name": "Afghanistan"},
    {
        "name": "Kapisa",
        "country_name": "Afghanistan"},
    {
        "name": "Khost",
        "country_name": "Afghanistan"},
    {
        "name": "Kunar",
        "country_name": "Afghanistan"},
    {
        "name": "Kunduz Province",
        "country_name": "Afghanistan"},
    {
        "name": "Laghman",
        "country_name": "Afghanistan"},
    {
        "name": "Logar",
        "country_name": "Afghanistan"},
    {
        "name": "Nangarhar",
        "country_name": "Afghanistan"},
    {
        "name": "Nimruz",
        "country_name": "Afghanistan"},
    {
        "name": "Nuristan",
        "country_name": "Afghanistan"},
    {
        "name": "Paktia",
        "country_name": "Afghanistan"},
    {
        "name": "Paktika",
        "country_name": "Afghanistan"},
    {
        "name": "Panjshir",
        "country_name": "Afghanistan"},
    {
        "name": "Parwan",
        "country_name": "Afghanistan"},
    {
        "name": "Samangan",
        "country_name": "Afghanistan"},
    {
        "name": "Sar-e Pol",
        "country_name": "Afghanistan"},
    {
        "name": "Takhar",
        "country_name": "Afghanistan"},
    {
        "name": "Urozgan",
        "country_name": "Afghanistan"},
    {
        "name": "Zabul",
        "country_name": "Afghanistan"},
    {
        "name": "Berat County",
        "country_name": "Albania"},
    {
        "name": "Berat District",
        "country_name": "Albania"},
    {
        "name": "Bulqizë District",
        "country_name": "Albania"},
    {
        "name": "Delvinë District",
        "country_name": "Albania"},
    {
        "name": "Devoll District",
        "country_name": "Albania"},
    {
        "name": "Dibër County",
        "country_name": "Albania"},
    {
        "name": "Dibër District",
        "country_name": "Albania"},
    {
        "name": "Durrës County",
        "country_name": "Albania"},
    {
        "name": "Durrës District",
        "country_name": "Albania"},
    {
        "name": "Elbasan County",
        "country_name": "Albania"},
    {
        "name": "Fier County",
        "country_name": "Albania"},
    {
        "name": "Fier District",
        "country_name": "Albania"},
    {
        "name": "Gjirokastër County",
        "country_name": "Albania"},
    {
        "name": "Gjirokastër District",
        "country_name": "Albania"},
    {
        "name": "Gramsh District",
        "country_name": "Albania"},
    {
        "name": "Has District",
        "country_name": "Albania"},
    {
        "name": "Kavajë District",
        "country_name": "Albania"},
    {
        "name": "Kolonjë District",
        "country_name": "Albania"},
    {
        "name": "Korçë County",
        "country_name": "Albania"},
    {
        "name": "Korçë District",
        "country_name": "Albania"},
    {
        "name": "Krujë District",
        "country_name": "Albania"},
    {
        "name": "Kuçovë District",
        "country_name": "Albania"},
    {
        "name": "Kukës County",
        "country_name": "Albania"},
    {
        "name": "Kukës District",
        "country_name": "Albania"},
    {
        "name": "Kurbin District",
        "country_name": "Albania"},
    {
        "name": "Lezhë County",
        "country_name": "Albania"},
    {
        "name": "Lezhë District",
        "country_name": "Albania"},
    {
        "name": "Librazhd District",
        "country_name": "Albania"},
    {
        "name": "Lushnjë District",
        "country_name": "Albania"},
    {
        "name": "Malësi e Madhe District",
        "country_name": "Albania"},
    {
        "name": "Mallakastër District",
        "country_name": "Albania"},
    {
        "name": "Mat District",
        "country_name": "Albania"},
    {
        "name": "Mirditë District",
        "country_name": "Albania"},
    {
        "name": "Peqin District",
        "country_name": "Albania"},
    {
        "name": "Përmet District",
        "country_name": "Albania"},
    {
        "name": "Pogradec District",
        "country_name": "Albania"},
    {
        "name": "Pukë District",
        "country_name": "Albania"},
    {
        "name": "Sarandë District",
        "country_name": "Albania"},
    {
        "name": "Shkodër County",
        "country_name": "Albania"},
    {
        "name": "Shkodër District",
        "country_name": "Albania"},
    {
        "name": "Skrapar District",
        "country_name": "Albania"},
    {
        "name": "Tepelenë District",
        "country_name": "Albania"},
    {
        "name": "Tirana County",
        "country_name": "Albania"},
    {
        "name": "Tirana District",
        "country_name": "Albania"},
    {
        "name": "Tropojë District",
        "country_name": "Albania"},
    {
        "name": "Vlorë County",
        "country_name": "Albania"},
    {
        "name": "Vlorë District",
        "country_name": "Albania"},
    {
        "name": "Adrar",
        "country_name": "Algeria"},
    {
        "name": "Aïn Defla",
        "country_name": "Algeria"},
    {
        "name": "Aïn Témouchent",
        "country_name": "Algeria"},
    {
        "name": "Algiers",
        "country_name": "Algeria"},
    {
        "name": "Annaba",
        "country_name": "Algeria"},
    {
        "name": "Batna",
        "country_name": "Algeria"},
    {
        "name": "Béchar",
        "country_name": "Algeria"},
    {
        "name": "Béjaïa",
        "country_name": "Algeria"},
    {
        "name": "Béni Abbès",
        "country_name": "Algeria"},
    {
        "name": "Biskra",
        "country_name": "Algeria"},
    {
        "name": "Blida",
        "country_name": "Algeria"},
    {
        "name": "Bordj Baji Mokhtar",
        "country_name": "Algeria"},
    {
        "name": "Bordj Bou Arréridj",
        "country_name": "Algeria"},
    {
        "name": "Bouïra",
        "country_name": "Algeria"},
    {
        "name": "Boumerdès",
        "country_name": "Algeria"},
    {
        "name": "Chlef",
        "country_name": "Algeria"},
    {
        "name": "Constantine",
        "country_name": "Algeria"},
    {
        "name": "Djanet",
        "country_name": "Algeria"},
    {
        "name": "Djelfa",
        "country_name": "Algeria"},
    {
        "name": "El Bayadh",
        "country_name": "Algeria"},
    {
        "name": "El M'ghair",
        "country_name": "Algeria"},
    {
        "name": "El Menia",
        "country_name": "Algeria"},
    {
        "name": "El Oued",
        "country_name": "Algeria"},
    {
        "name": "El Tarf",
        "country_name": "Algeria"},
    {
        "name": "Ghardaïa",
        "country_name": "Algeria"},
    {
        "name": "Guelma",
        "country_name": "Algeria"},
    {
        "name": "Illizi",
        "country_name": "Algeria"},
    {
        "name": "In Guezzam",
        "country_name": "Algeria"},
    {
        "name": "In Salah",
        "country_name": "Algeria"},
    {
        "name": "Jijel",
        "country_name": "Algeria"},
    {
        "name": "Khenchela",
        "country_name": "Algeria"},
    {
        "name": "Laghouat",
        "country_name": "Algeria"},
    {
        "name": "M'Sila",
        "country_name": "Algeria"},
    {
        "name": "Mascara",
        "country_name": "Algeria"},
    {
        "name": "Médéa",
        "country_name": "Algeria"},
    {
        "name": "Mila",
        "country_name": "Algeria"},
    {
        "name": "Mostaganem",
        "country_name": "Algeria"},
    {
        "name": "Naama",
        "country_name": "Algeria"},
    {
        "name": "Oran",
        "country_name": "Algeria"},
    {
        "name": "Ouargla",
        "country_name": "Algeria"},
    {
        "name": "Ouled Djellal",
        "country_name": "Algeria"},
    {
        "name": "Oum El Bouaghi",
        "country_name": "Algeria"},
    {
        "name": "Relizane",
        "country_name": "Algeria"},
    {
        "name": "Saïda",
        "country_name": "Algeria"},
    {
        "name": "Sétif",
        "country_name": "Algeria"},
    {
        "name": "Sidi Bel Abbès",
        "country_name": "Algeria"},
    {
        "name": "Skikda",
        "country_name": "Algeria"},
    {
        "name": "Souk Ahras",
        "country_name": "Algeria"},
    {
        "name": "Tamanghasset",
        "country_name": "Algeria"},
    {
        "name": "Tébessa",
        "country_name": "Algeria"},
    {
        "name": "Tiaret",
        "country_name": "Algeria"},
    {
        "name": "Timimoun",
        "country_name": "Algeria"},
    {
        "name": "Tindouf",
        "country_name": "Algeria"},
    {
        "name": "Tipasa",
        "country_name": "Algeria"},
    {
        "name": "Tissemsilt",
        "country_name": "Algeria"},
    {
        "name": "Tizi Ouzou",
        "country_name": "Algeria"},
    {
        "name": "Tlemcen",
        "country_name": "Algeria"},
    {
        "name": "Touggourt",
        "country_name": "Algeria"},
    {
        "name": "Andorra la Vella",
        "country_name": "Andorra"},
    {
        "name": "Canillo",
        "country_name": "Andorra"},
    {
        "name": "Encamp",
        "country_name": "Andorra"},
    {
        "name": "Escaldes-Engordany",
        "country_name": "Andorra"},
    {
        "name": "La Massana",
        "country_name": "Andorra"},
    {
        "name": "Ordino",
        "country_name": "Andorra"},
    {
        "name": "Sant Julià de Lòria",
        "country_name": "Andorra"},
    {
        "name": "Bengo Province",
        "country_name": "Angola"},
    {
        "name": "Benguela Province",
        "country_name": "Angola"},
    {
        "name": "Bié Province",
        "country_name": "Angola"},
    {
        "name": "Cabinda Province",
        "country_name": "Angola"},
    {
        "name": "Cuando Cubango Province",
        "country_name": "Angola"},
    {
        "name": "Cuanza Norte Province",
        "country_name": "Angola"},
    {
        "name": "Cuanza Sul",
        "country_name": "Angola"},
    {
        "name": "Cunene Province",
        "country_name": "Angola"},
    {
        "name": "Huambo Province",
        "country_name": "Angola"},
    {
        "name": "Huíla Province",
        "country_name": "Angola"},
    {
        "name": "Luanda Province",
        "country_name": "Angola"},
    {
        "name": "Lunda Norte Province",
        "country_name": "Angola"},
    {
        "name": "Lunda Sul Province",
        "country_name": "Angola"},
    {
        "name": "Malanje Province",
        "country_name": "Angola"},
    {
        "name": "Moxico Province",
        "country_name": "Angola"},
    {
        "name": "Uíge Province",
        "country_name": "Angola"},
    {
        "name": "Zaire Province",
        "country_name": "Angola"},
    {
        "name": "Barbuda",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Redonda",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Saint George Parish",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Saint John Parish",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Saint Mary Parish",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Saint Paul Parish",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Saint Peter Parish",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Saint Philip Parish",
        "country_name": "Antigua And Barbuda"},
    {
        "name": "Buenos Aires",
        "country_name": "Argentina"},
    {
        "name": "Catamarca",
        "country_name": "Argentina"},
    {
        "name": "Chaco",
        "country_name": "Argentina"},
    {
        "name": "Chubut",
        "country_name": "Argentina"},
    {
        "name": "Ciudad Autónoma de Buenos Aires",
        "country_name": "Argentina"},
    {
        "name": "Córdoba",
        "country_name": "Argentina"},
    {
        "name": "Corrientes",
        "country_name": "Argentina"},
    {
        "name": "Entre Ríos",
        "country_name": "Argentina"},
    {
        "name": "Formosa",
        "country_name": "Argentina"},
    {
        "name": "Jujuy",
        "country_name": "Argentina"},
    {
        "name": "La Pampa",
        "country_name": "Argentina"},
    {
        "name": "La Rioja",
        "country_name": "Argentina"},
    {
        "name": "Mendoza",
        "country_name": "Argentina"},
    {
        "name": "Misiones",
        "country_name": "Argentina"},
    {
        "name": "Neuquén",
        "country_name": "Argentina"},
    {
        "name": "Río Negro",
        "country_name": "Argentina"},
    {
        "name": "Salta",
        "country_name": "Argentina"},
    {
        "name": "San Juan",
        "country_name": "Argentina"},
    {
        "name": "San Luis",
        "country_name": "Argentina"},
    {
        "name": "Santa Cruz",
        "country_name": "Argentina"},
    {
        "name": "Santa Fe",
        "country_name": "Argentina"},
    {
        "name": "Santiago del Estero",
        "country_name": "Argentina"},
    {
        "name": "Tierra del Fuego",
        "country_name": "Argentina"},
    {
        "name": "Tucumán",
        "country_name": "Argentina"},
    {
        "name": "Aragatsotn Region",
        "country_name": "Armenia"},
    {
        "name": "Ararat Province",
        "country_name": "Armenia"},
    {
        "name": "Armavir Region",
        "country_name": "Armenia"},
    {
        "name": "Gegharkunik Province",
        "country_name": "Armenia"},
    {
        "name": "Kotayk Region",
        "country_name": "Armenia"},
    {
        "name": "Lori Region",
        "country_name": "Armenia"},
    {
        "name": "Shirak Region",
        "country_name": "Armenia"},
    {
        "name": "Syunik Province",
        "country_name": "Armenia"},
    {
        "name": "Tavush Region",
        "country_name": "Armenia"},
    {
        "name": "Vayots Dzor Region",
        "country_name": "Armenia"},
    {
        "name": "Yerevan",
        "country_name": "Armenia"},
    {
        "name": "Australian Capital Territory",
        "country_name": "Australia"},
    {
        "name": "New South Wales",
        "country_name": "Australia"},
    {
        "name": "Northern Territory",
        "country_name": "Australia"},
    {
        "name": "Queensland",
        "country_name": "Australia"},
    {
        "name": "South Australia",
        "country_name": "Australia"},
    {
        "name": "Tasmania",
        "country_name": "Australia"},
    {
        "name": "Victoria",
        "country_name": "Australia"},
    {
        "name": "Western Australia",
        "country_name": "Australia"},
    {
        "name": "Burgenland",
        "country_name": "Austria"},
    {
        "name": "Carinthia",
        "country_name": "Austria"},
    {
        "name": "Lower Austria",
        "country_name": "Austria"},
    {
        "name": "Salzburg",
        "country_name": "Austria"},
    {
        "name": "Styria",
        "country_name": "Austria"},
    {
        "name": "Tyrol",
        "country_name": "Austria"},
    {
        "name": "Upper Austria",
        "country_name": "Austria"},
    {
        "name": "Vienna",
        "country_name": "Austria"},
    {
        "name": "Vorarlberg",
        "country_name": "Austria"},
    {
        "name": "Absheron District",
        "country_name": "Azerbaijan"},
    {
        "name": "Agdam District",
        "country_name": "Azerbaijan"},
    {
        "name": "Agdash District",
        "country_name": "Azerbaijan"},
    {
        "name": "Aghjabadi District",
        "country_name": "Azerbaijan"},
    {
        "name": "Agstafa District",
        "country_name": "Azerbaijan"},
    {
        "name": "Agsu District",
        "country_name": "Azerbaijan"},
    {
        "name": "Astara District",
        "country_name": "Azerbaijan"},
    {
        "name": "Babek District",
        "country_name": "Azerbaijan"},
    {
        "name": "Baku",
        "country_name": "Azerbaijan"},
    {
        "name": "Balakan District",
        "country_name": "Azerbaijan"},
    {
        "name": "Barda District",
        "country_name": "Azerbaijan"},
    {
        "name": "Beylagan District",
        "country_name": "Azerbaijan"},
    {
        "name": "Bilasuvar District",
        "country_name": "Azerbaijan"},
    {
        "name": "Dashkasan District",
        "country_name": "Azerbaijan"},
    {
        "name": "Fizuli District",
        "country_name": "Azerbaijan"},
    {
        "name": "Ganja",
        "country_name": "Azerbaijan"},
    {
        "name": "Gədəbəy",
        "country_name": "Azerbaijan"},
    {
        "name": "Gobustan District",
        "country_name": "Azerbaijan"},
    {
        "name": "Goranboy District",
        "country_name": "Azerbaijan"},
    {
        "name": "Goychay",
        "country_name": "Azerbaijan"},
    {
        "name": "Goygol District",
        "country_name": "Azerbaijan"},
    {
        "name": "Hajigabul District",
        "country_name": "Azerbaijan"},
    {
        "name": "Imishli District",
        "country_name": "Azerbaijan"},
    {
        "name": "Ismailli District",
        "country_name": "Azerbaijan"},
    {
        "name": "Jabrayil District",
        "country_name": "Azerbaijan"},
    {
        "name": "Jalilabad District",
        "country_name": "Azerbaijan"},
    {
        "name": "Julfa District",
        "country_name": "Azerbaijan"},
    {
        "name": "Kalbajar District",
        "country_name": "Azerbaijan"},
    {
        "name": "Kangarli District",
        "country_name": "Azerbaijan"},
    {
        "name": "Khachmaz District",
        "country_name": "Azerbaijan"},
    {
        "name": "Khizi District",
        "country_name": "Azerbaijan"},
    {
        "name": "Khojali District",
        "country_name": "Azerbaijan"},
    {
        "name": "Kurdamir District",
        "country_name": "Azerbaijan"},
    {
        "name": "Lachin District",
        "country_name": "Azerbaijan"},
    {
        "name": "Lankaran",
        "country_name": "Azerbaijan"},
    {
        "name": "Lankaran District",
        "country_name": "Azerbaijan"},
    {
        "name": "Lerik District",
        "country_name": "Azerbaijan"},
    {
        "name": "Martuni",
        "country_name": "Azerbaijan"},
    {
        "name": "Masally District",
        "country_name": "Azerbaijan"},
    {
        "name": "Mingachevir",
        "country_name": "Azerbaijan"},
    {
        "name": "Nakhchivan Autonomous Republic",
        "country_name": "Azerbaijan"},
    {
        "name": "Neftchala District",
        "country_name": "Azerbaijan"},
    {
        "name": "Oghuz District",
        "country_name": "Azerbaijan"},
    {
        "name": "Ordubad District",
        "country_name": "Azerbaijan"},
    {
        "name": "Qabala District",
        "country_name": "Azerbaijan"},
    {
        "name": "Qakh District",
        "country_name": "Azerbaijan"},
    {
        "name": "Qazakh District",
        "country_name": "Azerbaijan"},
    {
        "name": "Quba District",
        "country_name": "Azerbaijan"},
    {
        "name": "Qubadli District",
        "country_name": "Azerbaijan"},
    {
        "name": "Qusar District",
        "country_name": "Azerbaijan"},
    {
        "name": "Saatly District",
        "country_name": "Azerbaijan"},
    {
        "name": "Sabirabad District",
        "country_name": "Azerbaijan"},
    {
        "name": "Sadarak District",
        "country_name": "Azerbaijan"},
    {
        "name": "Salyan District",
        "country_name": "Azerbaijan"},
    {
        "name": "Samukh District",
        "country_name": "Azerbaijan"},
    {
        "name": "Shabran District",
        "country_name": "Azerbaijan"},
    {
        "name": "Shahbuz District",
        "country_name": "Azerbaijan"},
    {
        "name": "Shaki",
        "country_name": "Azerbaijan"},
    {
        "name": "Shaki District",
        "country_name": "Azerbaijan"},
    {
        "name": "Shamakhi District",
        "country_name": "Azerbaijan"},
    {
        "name": "Shamkir District",
        "country_name": "Azerbaijan"},
    {
        "name": "Sharur District",
        "country_name": "Azerbaijan"},
    {
        "name": "Shirvan",
        "country_name": "Azerbaijan"},
    {
        "name": "Shusha District",
        "country_name": "Azerbaijan"},
    {
        "name": "Siazan District",
        "country_name": "Azerbaijan"},
    {
        "name": "Sumqayit",
        "country_name": "Azerbaijan"},
    {
        "name": "Tartar District",
        "country_name": "Azerbaijan"},
    {
        "name": "Tovuz District",
        "country_name": "Azerbaijan"},
    {
        "name": "Ujar District",
        "country_name": "Azerbaijan"},
    {
        "name": "Yardymli District",
        "country_name": "Azerbaijan"},
    {
        "name": "Yevlakh",
        "country_name": "Azerbaijan"},
    {
        "name": "Yevlakh District",
        "country_name": "Azerbaijan"},
    {
        "name": "Zangilan District",
        "country_name": "Azerbaijan"},
    {
        "name": "Zaqatala District",
        "country_name": "Azerbaijan"},
    {
        "name": "Zardab District",
        "country_name": "Azerbaijan"},
    {
        "name": "Capital",
        "country_name": "Bahrain"},
    {
        "name": "Central",
        "country_name": "Bahrain"},
    {
        "name": "Muharraq",
        "country_name": "Bahrain"},
    {
        "name": "Northern",
        "country_name": "Bahrain"},
    {
        "name": "Southern",
        "country_name": "Bahrain"},
    {
        "name": "Bagerhat District",
        "country_name": "Bangladesh"},
    {
        "name": "Bahadia",
        "country_name": "Bangladesh"},
    {
        "name": "Bandarban District",
        "country_name": "Bangladesh"},
    {
        "name": "Barguna District",
        "country_name": "Bangladesh"},
    {
        "name": "Barisal District",
        "country_name": "Bangladesh"},
    {
        "name": "Barisal Division",
        "country_name": "Bangladesh"},
    {
        "name": "Bhola District",
        "country_name": "Bangladesh"},
    {
        "name": "Bogra District",
        "country_name": "Bangladesh"},
    {
        "name": "Brahmanbaria District",
        "country_name": "Bangladesh"},
    {
        "name": "Chandpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Chapai Nawabganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Chittagong District",
        "country_name": "Bangladesh"},
    {
        "name": "Chittagong Division",
        "country_name": "Bangladesh"},
    {
        "name": "Chuadanga District",
        "country_name": "Bangladesh"},
    {
        "name": "Comilla District",
        "country_name": "Bangladesh"},
    {
        "name": "Cox's Bazar District",
        "country_name": "Bangladesh"},
    {
        "name": "Dhaka District",
        "country_name": "Bangladesh"},
    {
        "name": "Dhaka Division",
        "country_name": "Bangladesh"},
    {
        "name": "Dinajpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Faridpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Feni District",
        "country_name": "Bangladesh"},
    {
        "name": "Gaibandha District",
        "country_name": "Bangladesh"},
    {
        "name": "Gazipur District",
        "country_name": "Bangladesh"},
    {
        "name": "Gopalganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Habiganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Jamalpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Jessore District",
        "country_name": "Bangladesh"},
    {
        "name": "Jhalokati District",
        "country_name": "Bangladesh"},
    {
        "name": "Jhenaidah District",
        "country_name": "Bangladesh"},
    {
        "name": "Joypurhat District",
        "country_name": "Bangladesh"},
    {
        "name": "Khagrachari District",
        "country_name": "Bangladesh"},
    {
        "name": "Khulna District",
        "country_name": "Bangladesh"},
    {
        "name": "Khulna Division",
        "country_name": "Bangladesh"},
    {
        "name": "Kishoreganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Kurigram District",
        "country_name": "Bangladesh"},
    {
        "name": "Kushtia District",
        "country_name": "Bangladesh"},
    {
        "name": "Lakshmipur District",
        "country_name": "Bangladesh"},
    {
        "name": "Lalmonirhat District",
        "country_name": "Bangladesh"},
    {
        "name": "Madaripur District",
        "country_name": "Bangladesh"},
    {
        "name": "Meherpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Moulvibazar District",
        "country_name": "Bangladesh"},
    {
        "name": "Munshiganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Mymensingh District",
        "country_name": "Bangladesh"},
    {
        "name": "Mymensingh Division",
        "country_name": "Bangladesh"},
    {
        "name": "Naogaon District",
        "country_name": "Bangladesh"},
    {
        "name": "Narail District",
        "country_name": "Bangladesh"},
    {
        "name": "Narayanganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Natore District",
        "country_name": "Bangladesh"},
    {
        "name": "Netrokona District",
        "country_name": "Bangladesh"},
    {
        "name": "Nilphamari District",
        "country_name": "Bangladesh"},
    {
        "name": "Noakhali District",
        "country_name": "Bangladesh"},
    {
        "name": "Pabna District",
        "country_name": "Bangladesh"},
    {
        "name": "Panchagarh District",
        "country_name": "Bangladesh"},
    {
        "name": "Patuakhali District",
        "country_name": "Bangladesh"},
    {
        "name": "Pirojpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Rajbari District",
        "country_name": "Bangladesh"},
    {
        "name": "Rajshahi District",
        "country_name": "Bangladesh"},
    {
        "name": "Rajshahi Division",
        "country_name": "Bangladesh"},
    {
        "name": "Rangamati Hill District",
        "country_name": "Bangladesh"},
    {
        "name": "Rangpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Rangpur Division",
        "country_name": "Bangladesh"},
    {
        "name": "Satkhira District",
        "country_name": "Bangladesh"},
    {
        "name": "Shariatpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Sherpur District",
        "country_name": "Bangladesh"},
    {
        "name": "Sirajganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Sunamganj District",
        "country_name": "Bangladesh"},
    {
        "name": "Sylhet District",
        "country_name": "Bangladesh"},
    {
        "name": "Sylhet Division",
        "country_name": "Bangladesh"},
    {
        "name": "Tangail District",
        "country_name": "Bangladesh"},
    {
        "name": "Thakurgaon District",
        "country_name": "Bangladesh"},
    {
        "name": "Christ Church",
        "country_name": "Barbados"},
    {
        "name": "Saint Andrew",
        "country_name": "Barbados"},
    {
        "name": "Saint George",
        "country_name": "Barbados"},
    {
        "name": "Saint James",
        "country_name": "Barbados"},
    {
        "name": "Saint John",
        "country_name": "Barbados"},
    {
        "name": "Saint Joseph",
        "country_name": "Barbados"},
    {
        "name": "Saint Lucy",
        "country_name": "Barbados"},
    {
        "name": "Saint Michael",
        "country_name": "Barbados"},
    {
        "name": "Saint Peter",
        "country_name": "Barbados"},
    {
        "name": "Saint Philip",
        "country_name": "Barbados"},
    {
        "name": "Saint Thomas",
        "country_name": "Barbados"},
    {
        "name": "Brest Region",
        "country_name": "Belarus"},
    {
        "name": "Gomel Region",
        "country_name": "Belarus"},
    {
        "name": "Grodno Region",
        "country_name": "Belarus"},
    {
        "name": "Minsk",
        "country_name": "Belarus"},
    {
        "name": "Minsk Region",
        "country_name": "Belarus"},
    {
        "name": "Mogilev Region",
        "country_name": "Belarus"},
    {
        "name": "Vitebsk Region",
        "country_name": "Belarus"},
    {
        "name": "Antwerp",
        "country_name": "Belgium"},
    {
        "name": "Brussels-Capital Region",
        "country_name": "Belgium"},
    {
        "name": "East Flanders",
        "country_name": "Belgium"},
    {
        "name": "Flanders",
        "country_name": "Belgium"},
    {
        "name": "Flemish Brabant",
        "country_name": "Belgium"},
    {
        "name": "Hainaut",
        "country_name": "Belgium"},
    {
        "name": "Liège",
        "country_name": "Belgium"},
    {
        "name": "Limburg",
        "country_name": "Belgium"},
    {
        "name": "Luxembourg",
        "country_name": "Belgium"},
    {
        "name": "Namur",
        "country_name": "Belgium"},
    {
        "name": "Wallonia",
        "country_name": "Belgium"},
    {
        "name": "Walloon Brabant",
        "country_name": "Belgium"},
    {
        "name": "West Flanders",
        "country_name": "Belgium"},
    {
        "name": "Belize District",
        "country_name": "Belize"},
    {
        "name": "Cayo District",
        "country_name": "Belize"},
    {
        "name": "Corozal District",
        "country_name": "Belize"},
    {
        "name": "Orange Walk District",
        "country_name": "Belize"},
    {
        "name": "Stann Creek District",
        "country_name": "Belize"},
    {
        "name": "Toledo District",
        "country_name": "Belize"},
    {
        "name": "Alibori Department",
        "country_name": "Benin"},
    {
        "name": "Atakora Department",
        "country_name": "Benin"},
    {
        "name": "Atlantique Department",
        "country_name": "Benin"},
    {
        "name": "Borgou Department",
        "country_name": "Benin"},
    {
        "name": "Collines Department",
        "country_name": "Benin"},
    {
        "name": "Donga Department",
        "country_name": "Benin"},
    {
        "name": "Kouffo Department",
        "country_name": "Benin"},
    {
        "name": "Littoral Department",
        "country_name": "Benin"},
    {
        "name": "Mono Department",
        "country_name": "Benin"},
    {
        "name": "Ouémé Department",
        "country_name": "Benin"},
    {
        "name": "Plateau Department",
        "country_name": "Benin"},
    {
        "name": "Zou Department",
        "country_name": "Benin"},
    {
        "name": "Devonshire",
        "country_name": "Bermuda"},
    {
        "name": "Hamilton",
        "country_name": "Bermuda"},
    {
        "name": "Paget",
        "country_name": "Bermuda"},
    {
        "name": "Pembroke",
        "country_name": "Bermuda"},
    {
        "name": "Saint George's",
        "country_name": "Bermuda"},
    {
        "name": "Sandys",
        "country_name": "Bermuda"},
    {
        "name": "Smith's",
        "country_name": "Bermuda"},
    {
        "name": "Southampton",
        "country_name": "Bermuda"},
    {
        "name": "Warwick",
        "country_name": "Bermuda"},
    {
        "name": "Bumthang District",
        "country_name": "Bhutan"},
    {
        "name": "Chukha District",
        "country_name": "Bhutan"},
    {
        "name": "Dagana District",
        "country_name": "Bhutan"},
    {
        "name": "Gasa District",
        "country_name": "Bhutan"},
    {
        "name": "Haa District",
        "country_name": "Bhutan"},
    {
        "name": "Lhuntse District",
        "country_name": "Bhutan"},
    {
        "name": "Mongar District",
        "country_name": "Bhutan"},
    {
        "name": "Paro District",
        "country_name": "Bhutan"},
    {
        "name": "Pemagatshel District",
        "country_name": "Bhutan"},
    {
        "name": "Punakha District",
        "country_name": "Bhutan"},
    {
        "name": "Samdrup Jongkhar District",
        "country_name": "Bhutan"},
    {
        "name": "Samtse District",
        "country_name": "Bhutan"},
    {
        "name": "Sarpang District",
        "country_name": "Bhutan"},
    {
        "name": "Thimphu District",
        "country_name": "Bhutan"},
    {
        "name": "Trashigang District",
        "country_name": "Bhutan"},
    {
        "name": "Trongsa District",
        "country_name": "Bhutan"},
    {
        "name": "Tsirang District",
        "country_name": "Bhutan"},
    {
        "name": "Wangdue Phodrang District",
        "country_name": "Bhutan"},
    {
        "name": "Zhemgang District",
        "country_name": "Bhutan"},
    {
        "name": "Beni Department",
        "country_name": "Bolivia"},
    {
        "name": "Chuquisaca Department",
        "country_name": "Bolivia"},
    {
        "name": "Cochabamba Department",
        "country_name": "Bolivia"},
    {
        "name": "La Paz Department",
        "country_name": "Bolivia"},
    {
        "name": "Oruro Department",
        "country_name": "Bolivia"},
    {
        "name": "Pando Department",
        "country_name": "Bolivia"},
    {
        "name": "Potosí Department",
        "country_name": "Bolivia"},
    {
        "name": "Santa Cruz Department",
        "country_name": "Bolivia"},
    {
        "name": "Tarija Department",
        "country_name": "Bolivia"},
    {
        "name": "Bonaire",
        "country_name": "Bonaire, Sint Eustatius and Saba"},
    {
        "name": "Saba",
        "country_name": "Bonaire, Sint Eustatius and Saba"},
    {
        "name": "Sint Eustatius",
        "country_name": "Bonaire, Sint Eustatius and Saba"},
    {
        "name": "Bosnian Podrinje Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Brčko District",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Canton 10",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Central Bosnia Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Federation of Bosnia and Herzegovina",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Herzegovina-Neretva Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Posavina Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Republika Srpska",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Sarajevo Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Tuzla Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Una-Sana Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "West Herzegovina Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Zenica-Doboj Canton",
        "country_name": "Bosnia and Herzegovina"},
    {
        "name": "Central District",
        "country_name": "Botswana"},
    {
        "name": "Ghanzi District",
        "country_name": "Botswana"},
    {
        "name": "Kgalagadi District",
        "country_name": "Botswana"},
    {
        "name": "Kgatleng District",
        "country_name": "Botswana"},
    {
        "name": "Kweneng District",
        "country_name": "Botswana"},
    {
        "name": "Ngamiland",
        "country_name": "Botswana"},
    {
        "name": "North-East District",
        "country_name": "Botswana"},
    {
        "name": "North-West District",
        "country_name": "Botswana"},
    {
        "name": "South-East District",
        "country_name": "Botswana"},
    {
        "name": "Southern District",
        "country_name": "Botswana"},
    {
        "name": "Acre",
        "country_name": "Brazil"},
    {
        "name": "Alagoas",
        "country_name": "Brazil"},
    {
        "name": "Amapá",
        "country_name": "Brazil"},
    {
        "name": "Amazonas",
        "country_name": "Brazil"},
    {
        "name": "Bahia",
        "country_name": "Brazil"},
    {
        "name": "Ceará",
        "country_name": "Brazil"},
    {
        "name": "Distrito Federal",
        "country_name": "Brazil"},
    {
        "name": "Espírito Santo",
        "country_name": "Brazil"},
    {
        "name": "Goiás",
        "country_name": "Brazil"},
    {
        "name": "Maranhão",
        "country_name": "Brazil"},
    {
        "name": "Mato Grosso",
        "country_name": "Brazil"},
    {
        "name": "Mato Grosso do Sul",
        "country_name": "Brazil"},
    {
        "name": "Minas Gerais",
        "country_name": "Brazil"},
    {
        "name": "Pará",
        "country_name": "Brazil"},
    {
        "name": "Paraíba",
        "country_name": "Brazil"},
    {
        "name": "Paraná",
        "country_name": "Brazil"},
    {
        "name": "Pernambuco",
        "country_name": "Brazil"},
    {
        "name": "Piauí",
        "country_name": "Brazil"},
    {
        "name": "Rio de Janeiro",
        "country_name": "Brazil"},
    {
        "name": "Rio Grande do Norte",
        "country_name": "Brazil"},
    {
        "name": "Rio Grande do Sul",
        "country_name": "Brazil"},
    {
        "name": "Rondônia",
        "country_name": "Brazil"},
    {
        "name": "Roraima",
        "country_name": "Brazil"},
    {
        "name": "Santa Catarina",
        "country_name": "Brazil"},
    {
        "name": "São Paulo",
        "country_name": "Brazil"},
    {
        "name": "Sergipe",
        "country_name": "Brazil"},
    {
        "name": "Tocantins",
        "country_name": "Brazil"},
    {
        "name": "Belait District",
        "country_name": "Brunei"},
    {
        "name": "Brunei-Muara District",
        "country_name": "Brunei"},
    {
        "name": "Temburong District",
        "country_name": "Brunei"},
    {
        "name": "Tutong District",
        "country_name": "Brunei"},
    {
        "name": "Blagoevgrad Province",
        "country_name": "Bulgaria"},
    {
        "name": "Burgas Province",
        "country_name": "Bulgaria"},
    {
        "name": "Dobrich Province",
        "country_name": "Bulgaria"},
    {
        "name": "Gabrovo Province",
        "country_name": "Bulgaria"},
    {
        "name": "Haskovo Province",
        "country_name": "Bulgaria"},
    {
        "name": "Kardzhali Province",
        "country_name": "Bulgaria"},
    {
        "name": "Kyustendil Province",
        "country_name": "Bulgaria"},
    {
        "name": "Lovech Province",
        "country_name": "Bulgaria"},
    {
        "name": "Montana Province",
        "country_name": "Bulgaria"},
    {
        "name": "Pazardzhik Province",
        "country_name": "Bulgaria"},
    {
        "name": "Pernik Province",
        "country_name": "Bulgaria"},
    {
        "name": "Pleven Province",
        "country_name": "Bulgaria"},
    {
        "name": "Plovdiv Province",
        "country_name": "Bulgaria"},
    {
        "name": "Razgrad Province",
        "country_name": "Bulgaria"},
    {
        "name": "Ruse Province",
        "country_name": "Bulgaria"},
    {
        "name": "Shumen",
        "country_name": "Bulgaria"},
    {
        "name": "Silistra Province",
        "country_name": "Bulgaria"},
    {
        "name": "Sliven Province",
        "country_name": "Bulgaria"},
    {
        "name": "Smolyan Province",
        "country_name": "Bulgaria"},
    {
        "name": "Sofia City Province",
        "country_name": "Bulgaria"},
    {
        "name": "Sofia Province",
        "country_name": "Bulgaria"},
    {
        "name": "Stara Zagora Province",
        "country_name": "Bulgaria"},
    {
        "name": "Targovishte Province",
        "country_name": "Bulgaria"},
    {
        "name": "Varna Province",
        "country_name": "Bulgaria"},
    {
        "name": "Veliko Tarnovo Province",
        "country_name": "Bulgaria"},
    {
        "name": "Vidin Province",
        "country_name": "Bulgaria"},
    {
        "name": "Vratsa Province",
        "country_name": "Bulgaria"},
    {
        "name": "Yambol Province",
        "country_name": "Bulgaria"},
    {
        "name": "Balé Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Bam Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Banwa Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Bazèga Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Boucle du Mouhoun Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Bougouriba Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Boulgou",
        "country_name": "Burkina Faso"},
    {
        "name": "Cascades Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Centre",
        "country_name": "Burkina Faso"},
    {
        "name": "Centre-Est Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Centre-Nord Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Centre-Ouest Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Centre-Sud Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Comoé Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Est Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Ganzourgou Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Gnagna Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Gourma Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Hauts-Bassins Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Houet Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Ioba Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Kadiogo Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Kénédougou Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Komondjari Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Kompienga Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Kossi Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Koulpélogo Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Kouritenga Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Kourwéogo Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Léraba Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Loroum Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Mouhoun",
        "country_name": "Burkina Faso"},
    {
        "name": "Nahouri Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Namentenga Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Nayala Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Nord Region, Burkina Faso",
        "country_name": "Burkina Faso"},
    {
        "name": "Noumbiel Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Oubritenga Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Oudalan Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Passoré Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Plateau-Central Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Poni Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Sahel Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Sanguié Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Sanmatenga Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Séno Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Sissili Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Soum Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Sourou Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Sud-Ouest Region",
        "country_name": "Burkina Faso"},
    {
        "name": "Tapoa Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Tuy Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Yagha Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Yatenga Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Ziro Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Zondoma Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Zoundwéogo Province",
        "country_name": "Burkina Faso"},
    {
        "name": "Bubanza Province",
        "country_name": "Burundi"},
    {
        "name": "Bujumbura Mairie Province",
        "country_name": "Burundi"},
    {
        "name": "Bujumbura Rural Province",
        "country_name": "Burundi"},
    {
        "name": "Bururi Province",
        "country_name": "Burundi"},
    {
        "name": "Cankuzo Province",
        "country_name": "Burundi"},
    {
        "name": "Cibitoke Province",
        "country_name": "Burundi"},
    {
        "name": "Gitega Province",
        "country_name": "Burundi"},
    {
        "name": "Karuzi Province",
        "country_name": "Burundi"},
    {
        "name": "Kayanza Province",
        "country_name": "Burundi"},
    {
        "name": "Kirundo Province",
        "country_name": "Burundi"},
    {
        "name": "Makamba Province",
        "country_name": "Burundi"},
    {
        "name": "Muramvya Province",
        "country_name": "Burundi"},
    {
        "name": "Muyinga Province",
        "country_name": "Burundi"},
    {
        "name": "Mwaro Province",
        "country_name": "Burundi"},
    {
        "name": "Ngozi Province",
        "country_name": "Burundi"},
    {
        "name": "Rumonge Province",
        "country_name": "Burundi"},
    {
        "name": "Rutana Province",
        "country_name": "Burundi"},
    {
        "name": "Ruyigi Province",
        "country_name": "Burundi"},
    {
        "name": "Banteay Meanchey",
        "country_name": "Cambodia"},
    {
        "name": "Battambang",
        "country_name": "Cambodia"},
    {
        "name": "Kampong Cham",
        "country_name": "Cambodia"},
    {
        "name": "Kampong Chhnang",
        "country_name": "Cambodia"},
    {
        "name": "Kampong Speu",
        "country_name": "Cambodia"},
    {
        "name": "Kampong Thom",
        "country_name": "Cambodia"},
    {
        "name": "Kampot",
        "country_name": "Cambodia"},
    {
        "name": "Kandal",
        "country_name": "Cambodia"},
    {
        "name": "Kep",
        "country_name": "Cambodia"},
    {
        "name": "Koh Kong",
        "country_name": "Cambodia"},
    {
        "name": "Kratie",
        "country_name": "Cambodia"},
    {
        "name": "Mondulkiri",
        "country_name": "Cambodia"},
    {
        "name": "Oddar Meanchey",
        "country_name": "Cambodia"},
    {
        "name": "Pailin",
        "country_name": "Cambodia"},
    {
        "name": "Phnom Penh",
        "country_name": "Cambodia"},
    {
        "name": "Preah Vihear",
        "country_name": "Cambodia"},
    {
        "name": "Prey Veng",
        "country_name": "Cambodia"},
    {
        "name": "Pursat",
        "country_name": "Cambodia"},
    {
        "name": "Ratanakiri",
        "country_name": "Cambodia"},
    {
        "name": "Siem Reap",
        "country_name": "Cambodia"},
    {
        "name": "Sihanoukville",
        "country_name": "Cambodia"},
    {
        "name": "Stung Treng",
        "country_name": "Cambodia"},
    {
        "name": "Svay Rieng",
        "country_name": "Cambodia"},
    {
        "name": "Takeo",
        "country_name": "Cambodia"},
    {
        "name": "Adamawa",
        "country_name": "Cameroon"},
    {
        "name": "Centre",
        "country_name": "Cameroon"},
    {
        "name": "East",
        "country_name": "Cameroon"},
    {
        "name": "Far North",
        "country_name": "Cameroon"},
    {
        "name": "Littoral",
        "country_name": "Cameroon"},
    {
        "name": "North",
        "country_name": "Cameroon"},
    {
        "name": "Northwest",
        "country_name": "Cameroon"},
    {
        "name": "South",
        "country_name": "Cameroon"},
    {
        "name": "Southwest",
        "country_name": "Cameroon"},
    {
        "name": "West",
        "country_name": "Cameroon"},
    {
        "name": "Alberta",
        "country_name": "Canada"},
    {
        "name": "British Columbia",
        "country_name": "Canada"},
    {
        "name": "Manitoba",
        "country_name": "Canada"},
    {
        "name": "New Brunswick",
        "country_name": "Canada"},
    {
        "name": "Newfoundland and Labrador",
        "country_name": "Canada"},
    {
        "name": "Northwest Territories",
        "country_name": "Canada"},
    {
        "name": "Nova Scotia",
        "country_name": "Canada"},
    {
        "name": "Nunavut",
        "country_name": "Canada"},
    {
        "name": "Ontario",
        "country_name": "Canada"},
    {
        "name": "Prince Edward Island",
        "country_name": "Canada"},
    {
        "name": "Quebec",
        "country_name": "Canada"},
    {
        "name": "Saskatchewan",
        "country_name": "Canada"},
    {
        "name": "Yukon",
        "country_name": "Canada"},
    {
        "name": "Barlavento Islands",
        "country_name": "Cape Verde"},
    {
        "name": "Boa Vista",
        "country_name": "Cape Verde"},
    {
        "name": "Brava",
        "country_name": "Cape Verde"},
    {
        "name": "Maio Municipality",
        "country_name": "Cape Verde"},
    {
        "name": "Mosteiros",
        "country_name": "Cape Verde"},
    {
        "name": "Paul",
        "country_name": "Cape Verde"},
    {
        "name": "Porto Novo",
        "country_name": "Cape Verde"},
    {
        "name": "Praia",
        "country_name": "Cape Verde"},
    {
        "name": "Ribeira Brava Municipality",
        "country_name": "Cape Verde"},
    {
        "name": "Ribeira Grande",
        "country_name": "Cape Verde"},
    {
        "name": "Ribeira Grande de Santiago",
        "country_name": "Cape Verde"},
    {
        "name": "Sal",
        "country_name": "Cape Verde"},
    {
        "name": "Santa Catarina",
        "country_name": "Cape Verde"},
    {
        "name": "Santa Catarina do Fogo",
        "country_name": "Cape Verde"},
    {
        "name": "Santa Cruz",
        "country_name": "Cape Verde"},
    {
        "name": "São Domingos",
        "country_name": "Cape Verde"},
    {
        "name": "São Filipe",
        "country_name": "Cape Verde"},
    {
        "name": "São Lourenço dos Órgãos",
        "country_name": "Cape Verde"},
    {
        "name": "São Miguel",
        "country_name": "Cape Verde"},
    {
        "name": "São Vicente",
        "country_name": "Cape Verde"},
    {
        "name": "Sotavento Islands",
        "country_name": "Cape Verde"},
    {
        "name": "Tarrafal",
        "country_name": "Cape Verde"},
    {
        "name": "Tarrafal de São Nicolau",
        "country_name": "Cape Verde"},
    {
        "name": "Bamingui-Bangoran Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Bangui",
        "country_name": "Central African Republic"},
    {
        "name": "Basse-Kotto Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Haut-Mbomou Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Haute-Kotto Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Kémo Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Lobaye Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Mambéré-Kadéï",
        "country_name": "Central African Republic"},
    {
        "name": "Mbomou Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Nana-Grébizi Economic Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Nana-Mambéré Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Ombella-M'Poko Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Ouaka Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Ouham Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Ouham-Pendé Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Sangha-Mbaéré",
        "country_name": "Central African Republic"},
    {
        "name": "Vakaga Prefecture",
        "country_name": "Central African Republic"},
    {
        "name": "Bahr el Gazel",
        "country_name": "Chad"},
    {
        "name": "Batha",
        "country_name": "Chad"},
    {
        "name": "Borkou",
        "country_name": "Chad"},
    {
        "name": "Chari-Baguirmi",
        "country_name": "Chad"},
    {
        "name": "Ennedi-Est",
        "country_name": "Chad"},
    {
        "name": "Ennedi-Ouest",
        "country_name": "Chad"},
    {
        "name": "Guéra",
        "country_name": "Chad"},
    {
        "name": "Hadjer-Lamis",
        "country_name": "Chad"},
    {
        "name": "Kanem",
        "country_name": "Chad"},
    {
        "name": "Lac",
        "country_name": "Chad"},
    {
        "name": "Logone Occidental",
        "country_name": "Chad"},
    {
        "name": "Logone Oriental",
        "country_name": "Chad"},
    {
        "name": "Mandoul",
        "country_name": "Chad"},
    {
        "name": "Mayo-Kebbi Est",
        "country_name": "Chad"},
    {
        "name": "Mayo-Kebbi Ouest",
        "country_name": "Chad"},
    {
        "name": "Moyen-Chari",
        "country_name": "Chad"},
    {
        "name": "N'Djamena",
        "country_name": "Chad"},
    {
        "name": "Ouaddaï",
        "country_name": "Chad"},
    {
        "name": "Salamat",
        "country_name": "Chad"},
    {
        "name": "Sila",
        "country_name": "Chad"},
    {
        "name": "Tandjilé",
        "country_name": "Chad"},
    {
        "name": "Tibesti",
        "country_name": "Chad"},
    {
        "name": "Wadi Fira",
        "country_name": "Chad"},
    {
        "name": "Aisén del General Carlos Ibañez del Campo",
        "country_name": "Chile"},
    {
        "name": "Antofagasta",
        "country_name": "Chile"},
    {
        "name": "Arica y Parinacota",
        "country_name": "Chile"},
    {
        "name": "Atacama",
        "country_name": "Chile"},
    {
        "name": "Biobío",
        "country_name": "Chile"},
    {
        "name": "Coquimbo",
        "country_name": "Chile"},
    {
        "name": "La Araucanía",
        "country_name": "Chile"},
    {
        "name": "Libertador General Bernardo O'Higgins",
        "country_name": "Chile"},
    {
        "name": "Los Lagos",
        "country_name": "Chile"},
    {
        "name": "Los Ríos",
        "country_name": "Chile"},
    {
        "name": "Magallanes y de la Antártica Chilena",
        "country_name": "Chile"},
    {
        "name": "Maule",
        "country_name": "Chile"},
    {
        "name": "Ñuble",
        "country_name": "Chile"},
    {
        "name": "Región Metropolitana de Santiago",
        "country_name": "Chile"},
    {
        "name": "Tarapacá",
        "country_name": "Chile"},
    {
        "name": "Valparaíso",
        "country_name": "Chile"},
    {
        "name": "Anhui",
        "country_name": "China"},
    {
        "name": "Beijing",
        "country_name": "China"},
    {
        "name": "Chongqing",
        "country_name": "China"},
    {
        "name": "Fujian",
        "country_name": "China"},
    {
        "name": "Gansu",
        "country_name": "China"},
    {
        "name": "Guangdong",
        "country_name": "China"},
    {
        "name": "Guangxi Zhuang",
        "country_name": "China"},
    {
        "name": "Guizhou",
        "country_name": "China"},
    {
        "name": "Hainan",
        "country_name": "China"},
    {
        "name": "Hebei",
        "country_name": "China"},
    {
        "name": "Heilongjiang",
        "country_name": "China"},
    {
        "name": "Henan",
        "country_name": "China"},
    {
        "name": "Hong Kong SAR",
        "country_name": "China"},
    {
        "name": "Hubei",
        "country_name": "China"},
    {
        "name": "Hunan",
        "country_name": "China"},
    {
        "name": "Inner Mongolia",
        "country_name": "China"},
    {
        "name": "Jiangsu",
        "country_name": "China"},
    {
        "name": "Jiangxi",
        "country_name": "China"},
    {
        "name": "Jilin",
        "country_name": "China"},
    {
        "name": "Liaoning",
        "country_name": "China"},
    {
        "name": "Macau SAR",
        "country_name": "China"},
    {
        "name": "Ningxia Huizu",
        "country_name": "China"},
    {
        "name": "Qinghai",
        "country_name": "China"},
    {
        "name": "Shaanxi",
        "country_name": "China"},
    {
        "name": "Shandong",
        "country_name": "China"},
    {
        "name": "Shanghai",
        "country_name": "China"},
    {
        "name": "Shanxi",
        "country_name": "China"},
    {
        "name": "Sichuan",
        "country_name": "China"},
    {
        "name": "Taiwan",
        "country_name": "China"},
    {
        "name": "Tianjin",
        "country_name": "China"},
    {
        "name": "Xinjiang",
        "country_name": "China"},
    {
        "name": "Xizang",
        "country_name": "China"},
    {
        "name": "Yunnan",
        "country_name": "China"},
    {
        "name": "Zhejiang",
        "country_name": "China"},
    {
        "name": "Amazonas",
        "country_name": "Colombia"},
    {
        "name": "Antioquia",
        "country_name": "Colombia"},
    {
        "name": "Arauca",
        "country_name": "Colombia"},
    {
        "name": "Archipiélago de San Andrés, Providencia y Santa Catalina",
        "country_name": "Colombia"},
    {
        "name": "Atlántico",
        "country_name": "Colombia"},
    {
        "name": "Bogotá D.C.",
        "country_name": "Colombia"},
    {
        "name": "Bolívar",
        "country_name": "Colombia"},
    {
        "name": "Boyacá",
        "country_name": "Colombia"},
    {
        "name": "Caldas",
        "country_name": "Colombia"},
    {
        "name": "Caquetá",
        "country_name": "Colombia"},
    {
        "name": "Casanare",
        "country_name": "Colombia"},
    {
        "name": "Cauca",
        "country_name": "Colombia"},
    {
        "name": "Cesar",
        "country_name": "Colombia"},
    {
        "name": "Chocó",
        "country_name": "Colombia"},
    {
        "name": "Córdoba",
        "country_name": "Colombia"},
    {
        "name": "Cundinamarca",
        "country_name": "Colombia"},
    {
        "name": "Guainía",
        "country_name": "Colombia"},
    {
        "name": "Guaviare",
        "country_name": "Colombia"},
    {
        "name": "Huila",
        "country_name": "Colombia"},
    {
        "name": "La Guajira",
        "country_name": "Colombia"},
    {
        "name": "Magdalena",
        "country_name": "Colombia"},
    {
        "name": "Meta",
        "country_name": "Colombia"},
    {
        "name": "Nariño",
        "country_name": "Colombia"},
    {
        "name": "Norte de Santander",
        "country_name": "Colombia"},
    {
        "name": "Putumayo",
        "country_name": "Colombia"},
    {
        "name": "Quindío",
        "country_name": "Colombia"},
    {
        "name": "Risaralda",
        "country_name": "Colombia"},
    {
        "name": "Santander",
        "country_name": "Colombia"},
    {
        "name": "Sucre",
        "country_name": "Colombia"},
    {
        "name": "Tolima",
        "country_name": "Colombia"},
    {
        "name": "Valle del Cauca",
        "country_name": "Colombia"},
    {
        "name": "Vaupés",
        "country_name": "Colombia"},
    {
        "name": "Vichada",
        "country_name": "Colombia"},
    {
        "name": "Anjouan",
        "country_name": "Comoros"},
    {
        "name": "Grande Comore",
        "country_name": "Comoros"},
    {
        "name": "Mohéli",
        "country_name": "Comoros"},
    {
        "name": "Bouenza Department",
        "country_name": "Congo"},
    {
        "name": "Brazzaville",
        "country_name": "Congo"},
    {
        "name": "Cuvette Department",
        "country_name": "Congo"},
    {
        "name": "Cuvette-Ouest Department",
        "country_name": "Congo"},
    {
        "name": "Kouilou Department",
        "country_name": "Congo"},
    {
        "name": "Lékoumou Department",
        "country_name": "Congo"},
    {
        "name": "Likouala Department",
        "country_name": "Congo"},
    {
        "name": "Niari Department",
        "country_name": "Congo"},
    {
        "name": "Plateaux Department",
        "country_name": "Congo"},
    {
        "name": "Pointe-Noire",
        "country_name": "Congo"},
    {
        "name": "Pool Department",
        "country_name": "Congo"},
    {
        "name": "Sangha Department",
        "country_name": "Congo"},
    {
        "name": "Alajuela Province",
        "country_name": "Costa Rica"},
    {
        "name": "Guanacaste Province",
        "country_name": "Costa Rica"},
    {
        "name": "Heredia Province",
        "country_name": "Costa Rica"},
    {
        "name": "Limón Province",
        "country_name": "Costa Rica"},
    {
        "name": "Provincia de Cartago",
        "country_name": "Costa Rica"},
    {
        "name": "Puntarenas Province",
        "country_name": "Costa Rica"},
    {
        "name": "San José Province",
        "country_name": "Costa Rica"},
    {
        "name": "Abidjan",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Agnéby",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Bafing Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Bas-Sassandra District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Bas-Sassandra Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Comoé District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Denguélé District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Denguélé Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Dix-Huit Montagnes",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Fromager",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Gôh-Djiboua District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Haut-Sassandra",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Lacs District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Lacs Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Lagunes District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Lagunes region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Marahoué Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Montagnes District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Moyen-Cavally",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Moyen-Comoé",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "N'zi-Comoé",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Sassandra-Marahoué District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Savanes Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Sud-Bandama",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Sud-Comoé",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Vallée du Bandama District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Vallée du Bandama Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Woroba District",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Worodougou",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Yamoussoukro",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Zanzan Region",
        "country_name": "Cote D'Ivoire (Ivory Coast)"},
    {
        "name": "Bjelovar-Bilogora",
        "country_name": "Croatia"},
    {
        "name": "Brod-Posavina",
        "country_name": "Croatia"},
    {
        "name": "Dubrovnik-Neretva",
        "country_name": "Croatia"},
    {
        "name": "Istria",
        "country_name": "Croatia"},
    {
        "name": "Karlovac",
        "country_name": "Croatia"},
    {
        "name": "Koprivnica-Križevci",
        "country_name": "Croatia"},
    {
        "name": "Krapina-Zagorje",
        "country_name": "Croatia"},
    {
        "name": "Lika-Senj",
        "country_name": "Croatia"},
    {
        "name": "Međimurje",
        "country_name": "Croatia"},
    {
        "name": "Osijek-Baranja",
        "country_name": "Croatia"},
    {
        "name": "Požega-Slavonia",
        "country_name": "Croatia"},
    {
        "name": "Primorje-Gorski Kotar",
        "country_name": "Croatia"},
    {
        "name": "Šibenik-Knin",
        "country_name": "Croatia"},
    {
        "name": "Sisak-Moslavina",
        "country_name": "Croatia"},
    {
        "name": "Split-Dalmatia",
        "country_name": "Croatia"},
    {
        "name": "Varaždin",
        "country_name": "Croatia"},
    {
        "name": "Virovitica-Podravina",
        "country_name": "Croatia"},
    {
        "name": "Vukovar-Syrmia",
        "country_name": "Croatia"},
    {
        "name": "Zadar",
        "country_name": "Croatia"},
    {
        "name": "Zagreb",
        "country_name": "Croatia"},
    {
        "name": "Zagreb",
        "country_name": "Croatia"},
    {
        "name": "Artemisa Province",
        "country_name": "Cuba"},
    {
        "name": "Camagüey Province",
        "country_name": "Cuba"},
    {
        "name": "Ciego de Ávila Province",
        "country_name": "Cuba"},
    {
        "name": "Cienfuegos Province",
        "country_name": "Cuba"},
    {
        "name": "Granma Province",
        "country_name": "Cuba"},
    {
        "name": "Guantánamo Province",
        "country_name": "Cuba"},
    {
        "name": "Havana Province",
        "country_name": "Cuba"},
    {
        "name": "Holguín Province",
        "country_name": "Cuba"},
    {
        "name": "Isla de la Juventud",
        "country_name": "Cuba"},
    {
        "name": "Las Tunas Province",
        "country_name": "Cuba"},
    {
        "name": "Matanzas Province",
        "country_name": "Cuba"},
    {
        "name": "Mayabeque Province",
        "country_name": "Cuba"},
    {
        "name": "Pinar del Río Province",
        "country_name": "Cuba"},
    {
        "name": "Sancti Spíritus Province",
        "country_name": "Cuba"},
    {
        "name": "Santiago de Cuba Province",
        "country_name": "Cuba"},
    {
        "name": "Villa Clara Province",
        "country_name": "Cuba"},
    {
        "name": "Famagusta District (Mağusa)",
        "country_name": "Cyprus"},
    {
        "name": "Kyrenia District (Keryneia)",
        "country_name": "Cyprus"},
    {
        "name": "Larnaca District (Larnaka)",
        "country_name": "Cyprus"},
    {
        "name": "Limassol District (Leymasun)",
        "country_name": "Cyprus"},
    {
        "name": "Nicosia District (Lefkoşa)",
        "country_name": "Cyprus"},
    {
        "name": "Paphos District (Pafos)",
        "country_name": "Cyprus"},
    {
        "name": "Benešov",
        "country_name": "Czech Republic"},
    {
        "name": "Beroun",
        "country_name": "Czech Republic"},
    {
        "name": "Blansko",
        "country_name": "Czech Republic"},
    {
        "name": "Břeclav",
        "country_name": "Czech Republic"},
    {
        "name": "Brno-město",
        "country_name": "Czech Republic"},
    {
        "name": "Brno-venkov",
        "country_name": "Czech Republic"},
    {
        "name": "Bruntál",
        "country_name": "Czech Republic"},
    {
        "name": "Česká Lípa",
        "country_name": "Czech Republic"},
    {
        "name": "České Budějovice",
        "country_name": "Czech Republic"},
    {
        "name": "Český Krumlov",
        "country_name": "Czech Republic"},
    {
        "name": "Cheb",
        "country_name": "Czech Republic"},
    {
        "name": "Chomutov",
        "country_name": "Czech Republic"},
    {
        "name": "Chrudim",
        "country_name": "Czech Republic"},
    {
        "name": "Děčín",
        "country_name": "Czech Republic"},
    {
        "name": "Domažlice",
        "country_name": "Czech Republic"},
    {
        "name": "Frýdek-Místek",
        "country_name": "Czech Republic"},
    {
        "name": "Havlíčkův Brod",
        "country_name": "Czech Republic"},
    {
        "name": "Hodonín",
        "country_name": "Czech Republic"},
    {
        "name": "Hradec Králové",
        "country_name": "Czech Republic"},
    {
        "name": "Jablonec nad Nisou",
        "country_name": "Czech Republic"},
    {
        "name": "Jeseník",
        "country_name": "Czech Republic"},
    {
        "name": "Jičín",
        "country_name": "Czech Republic"},
    {
        "name": "Jihlava",
        "country_name": "Czech Republic"},
    {
        "name": "Jihočeský kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Jihomoravský kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Jindřichův Hradec",
        "country_name": "Czech Republic"},
    {
        "name": "Karlovarský kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Karlovy Vary",
        "country_name": "Czech Republic"},
    {
        "name": "Karviná",
        "country_name": "Czech Republic"},
    {
        "name": "Kladno",
        "country_name": "Czech Republic"},
    {
        "name": "Klatovy",
        "country_name": "Czech Republic"},
    {
        "name": "Kolín",
        "country_name": "Czech Republic"},
    {
        "name": "Kraj Vysočina",
        "country_name": "Czech Republic"},
    {
        "name": "Královéhradecký kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Kroměříž",
        "country_name": "Czech Republic"},
    {
        "name": "Kutná Hora",
        "country_name": "Czech Republic"},
    {
        "name": "Liberec",
        "country_name": "Czech Republic"},
    {
        "name": "Liberecký kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Litoměřice",
        "country_name": "Czech Republic"},
    {
        "name": "Louny",
        "country_name": "Czech Republic"},
    {
        "name": "Mělník",
        "country_name": "Czech Republic"},
    {
        "name": "Mladá Boleslav",
        "country_name": "Czech Republic"},
    {
        "name": "Moravskoslezský kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Most",
        "country_name": "Czech Republic"},
    {
        "name": "Náchod",
        "country_name": "Czech Republic"},
    {
        "name": "Nový Jičín",
        "country_name": "Czech Republic"},
    {
        "name": "Nymburk",
        "country_name": "Czech Republic"},
    {
        "name": "Olomouc",
        "country_name": "Czech Republic"},
    {
        "name": "Olomoucký kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Opava",
        "country_name": "Czech Republic"},
    {
        "name": "Ostrava-město",
        "country_name": "Czech Republic"},
    {
        "name": "Pardubice",
        "country_name": "Czech Republic"},
    {
        "name": "Pardubický kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Pelhřimov",
        "country_name": "Czech Republic"},
    {
        "name": "Písek",
        "country_name": "Czech Republic"},
    {
        "name": "Plzeň-jih",
        "country_name": "Czech Republic"},
    {
        "name": "Plzeň-město",
        "country_name": "Czech Republic"},
    {
        "name": "Plzeň-sever",
        "country_name": "Czech Republic"},
    {
        "name": "Plzeňský kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Prachatice",
        "country_name": "Czech Republic"},
    {
        "name": "Praha-východ",
        "country_name": "Czech Republic"},
    {
        "name": "Praha-západ",
        "country_name": "Czech Republic"},
    {
        "name": "Praha, Hlavní město",
        "country_name": "Czech Republic"},
    {
        "name": "Přerov",
        "country_name": "Czech Republic"},
    {
        "name": "Příbram",
        "country_name": "Czech Republic"},
    {
        "name": "Prostějov",
        "country_name": "Czech Republic"},
    {
        "name": "Rakovník",
        "country_name": "Czech Republic"},
    {
        "name": "Rokycany",
        "country_name": "Czech Republic"},
    {
        "name": "Rychnov nad Kněžnou",
        "country_name": "Czech Republic"},
    {
        "name": "Semily",
        "country_name": "Czech Republic"},
    {
        "name": "Sokolov",
        "country_name": "Czech Republic"},
    {
        "name": "Strakonice",
        "country_name": "Czech Republic"},
    {
        "name": "Středočeský kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Šumperk",
        "country_name": "Czech Republic"},
    {
        "name": "Svitavy",
        "country_name": "Czech Republic"},
    {
        "name": "Tábor",
        "country_name": "Czech Republic"},
    {
        "name": "Tachov",
        "country_name": "Czech Republic"},
    {
        "name": "Teplice",
        "country_name": "Czech Republic"},
    {
        "name": "Třebíč",
        "country_name": "Czech Republic"},
    {
        "name": "Trutnov",
        "country_name": "Czech Republic"},
    {
        "name": "Uherské Hradiště",
        "country_name": "Czech Republic"},
    {
        "name": "Ústecký kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Ústí nad Labem",
        "country_name": "Czech Republic"},
    {
        "name": "Ústí nad Orlicí",
        "country_name": "Czech Republic"},
    {
        "name": "Vsetín",
        "country_name": "Czech Republic"},
    {
        "name": "Vyškov",
        "country_name": "Czech Republic"},
    {
        "name": "Žďár nad Sázavou",
        "country_name": "Czech Republic"},
    {
        "name": "Zlín",
        "country_name": "Czech Republic"},
    {
        "name": "Zlínský kraj",
        "country_name": "Czech Republic"},
    {
        "name": "Znojmo",
        "country_name": "Czech Republic"},
    {
        "name": "Bas-Uélé",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Équateur",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Haut-Katanga",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Haut-Lomami",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Haut-Uélé",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Ituri",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Kasaï",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Kasaï Central",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Kasaï Oriental",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Kinshasa",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Kongo Central",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Kwango",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Kwilu",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Lomami",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Lualaba",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Mai-Ndombe",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Maniema",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Mongala",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Nord-Kivu",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Nord-Ubangi",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Sankuru",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Sud-Kivu",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Sud-Ubangi",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Tanganyika",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Tshopo",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Tshuapa",
        "country_name": "Democratic Republic of the Congo"},
    {
        "name": "Capital Region of Denmark",
        "country_name": "Denmark"},
    {
        "name": "Central Denmark Region",
        "country_name": "Denmark"},
    {
        "name": "North Denmark Region",
        "country_name": "Denmark"},
    {
        "name": "Region of Southern Denmark",
        "country_name": "Denmark"},
    {
        "name": "Region Zealand",
        "country_name": "Denmark"},
    {
        "name": "Ali Sabieh Region",
        "country_name": "Djibouti"},
    {
        "name": "Arta Region",
        "country_name": "Djibouti"},
    {
        "name": "Dikhil Region",
        "country_name": "Djibouti"},
    {
        "name": "Djibouti",
        "country_name": "Djibouti"},
    {
        "name": "Obock Region",
        "country_name": "Djibouti"},
    {
        "name": "Tadjourah Region",
        "country_name": "Djibouti"},
    {
        "name": "Saint Andrew Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint David Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint George Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint John Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint Joseph Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint Luke Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint Mark Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint Patrick Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint Paul Parish",
        "country_name": "Dominica"},
    {
        "name": "Saint Peter Parish",
        "country_name": "Dominica"},
    {
        "name": "Azua Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Baoruco Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Barahona Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Dajabón Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Distrito Nacional",
        "country_name": "Dominican Republic"},
    {
        "name": "Duarte Province",
        "country_name": "Dominican Republic"},
    {
        "name": "El Seibo Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Espaillat Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Hato Mayor Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Hermanas Mirabal Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Independencia",
        "country_name": "Dominican Republic"},
    {
        "name": "La Altagracia Province",
        "country_name": "Dominican Republic"},
    {
        "name": "La Romana Province",
        "country_name": "Dominican Republic"},
    {
        "name": "La Vega Province",
        "country_name": "Dominican Republic"},
    {
        "name": "María Trinidad Sánchez Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Monseñor Nouel Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Monte Cristi Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Monte Plata Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Pedernales Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Peravia Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Puerto Plata Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Samaná Province",
        "country_name": "Dominican Republic"},
    {
        "name": "San Cristóbal Province",
        "country_name": "Dominican Republic"},
    {
        "name": "San José de Ocoa Province",
        "country_name": "Dominican Republic"},
    {
        "name": "San Juan Province",
        "country_name": "Dominican Republic"},
    {
        "name": "San Pedro de Macorís",
        "country_name": "Dominican Republic"},
    {
        "name": "Sánchez Ramírez Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Santiago Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Santiago Rodríguez Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Santo Domingo Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Valverde Province",
        "country_name": "Dominican Republic"},
    {
        "name": "Aileu municipality",
        "country_name": "East Timor"},
    {
        "name": "Ainaro Municipality",
        "country_name": "East Timor"},
    {
        "name": "Baucau Municipality",
        "country_name": "East Timor"},
    {
        "name": "Bobonaro Municipality",
        "country_name": "East Timor"},
    {
        "name": "Cova Lima Municipality",
        "country_name": "East Timor"},
    {
        "name": "Dili municipality",
        "country_name": "East Timor"},
    {
        "name": "Ermera District",
        "country_name": "East Timor"},
    {
        "name": "Lautém Municipality",
        "country_name": "East Timor"},
    {
        "name": "Liquiçá Municipality",
        "country_name": "East Timor"},
    {
        "name": "Manatuto District",
        "country_name": "East Timor"},
    {
        "name": "Manufahi Municipality",
        "country_name": "East Timor"},
    {
        "name": "Viqueque Municipality",
        "country_name": "East Timor"},
    {
        "name": "Azuay",
        "country_name": "Ecuador"},
    {
        "name": "Bolívar",
        "country_name": "Ecuador"},
    {
        "name": "Cañar",
        "country_name": "Ecuador"},
    {
        "name": "Carchi",
        "country_name": "Ecuador"},
    {
        "name": "Chimborazo",
        "country_name": "Ecuador"},
    {
        "name": "Cotopaxi",
        "country_name": "Ecuador"},
    {
        "name": "El Oro",
        "country_name": "Ecuador"},
    {
        "name": "Esmeraldas",
        "country_name": "Ecuador"},
    {
        "name": "Galápagos",
        "country_name": "Ecuador"},
    {
        "name": "Guayas",
        "country_name": "Ecuador"},
    {
        "name": "Imbabura",
        "country_name": "Ecuador"},
    {
        "name": "Loja",
        "country_name": "Ecuador"},
    {
        "name": "Los Ríos",
        "country_name": "Ecuador"},
    {
        "name": "Manabí",
        "country_name": "Ecuador"},
    {
        "name": "Morona-Santiago",
        "country_name": "Ecuador"},
    {
        "name": "Napo",
        "country_name": "Ecuador"},
    {
        "name": "Orellana",
        "country_name": "Ecuador"},
    {
        "name": "Pastaza",
        "country_name": "Ecuador"},
    {
        "name": "Pichincha",
        "country_name": "Ecuador"},
    {
        "name": "Santa Elena",
        "country_name": "Ecuador"},
    {
        "name": "Santo Domingo de los Tsáchilas",
        "country_name": "Ecuador"},
    {
        "name": "Sucumbíos",
        "country_name": "Ecuador"},
    {
        "name": "Tungurahua",
        "country_name": "Ecuador"},
    {
        "name": "Zamora Chinchipe",
        "country_name": "Ecuador"},
    {
        "name": "Alexandria",
        "country_name": "Egypt"},
    {
        "name": "Aswan",
        "country_name": "Egypt"},
    {
        "name": "Asyut",
        "country_name": "Egypt"},
    {
        "name": "Beheira",
        "country_name": "Egypt"},
    {
        "name": "Beni Suef",
        "country_name": "Egypt"},
    {
        "name": "Cairo",
        "country_name": "Egypt"},
    {
        "name": "Dakahlia",
        "country_name": "Egypt"},
    {
        "name": "Damietta",
        "country_name": "Egypt"},
    {
        "name": "Faiyum",
        "country_name": "Egypt"},
    {
        "name": "Gharbia",
        "country_name": "Egypt"},
    {
        "name": "Giza",
        "country_name": "Egypt"},
    {
        "name": "Ismailia",
        "country_name": "Egypt"},
    {
        "name": "Kafr el-Sheikh",
        "country_name": "Egypt"},
    {
        "name": "Luxor",
        "country_name": "Egypt"},
    {
        "name": "Matrouh",
        "country_name": "Egypt"},
    {
        "name": "Minya",
        "country_name": "Egypt"},
    {
        "name": "Monufia",
        "country_name": "Egypt"},
    {
        "name": "New Valley",
        "country_name": "Egypt"},
    {
        "name": "North Sinai",
        "country_name": "Egypt"},
    {
        "name": "Port Said",
        "country_name": "Egypt"},
    {
        "name": "Qalyubia",
        "country_name": "Egypt"},
    {
        "name": "Qena",
        "country_name": "Egypt"},
    {
        "name": "Red Sea",
        "country_name": "Egypt"},
    {
        "name": "Sharqia",
        "country_name": "Egypt"},
    {
        "name": "Sohag",
        "country_name": "Egypt"},
    {
        "name": "South Sinai",
        "country_name": "Egypt"},
    {
        "name": "Suez",
        "country_name": "Egypt"},
    {
        "name": "Ahuachapán Department",
        "country_name": "El Salvador"},
    {
        "name": "Cabañas Department",
        "country_name": "El Salvador"},
    {
        "name": "Chalatenango Department",
        "country_name": "El Salvador"},
    {
        "name": "Cuscatlán Department",
        "country_name": "El Salvador"},
    {
        "name": "La Libertad Department",
        "country_name": "El Salvador"},
    {
        "name": "La Paz Department",
        "country_name": "El Salvador"},
    {
        "name": "La Unión Department",
        "country_name": "El Salvador"},
    {
        "name": "Morazán Department",
        "country_name": "El Salvador"},
    {
        "name": "San Miguel Department",
        "country_name": "El Salvador"},
    {
        "name": "San Salvador Department",
        "country_name": "El Salvador"},
    {
        "name": "San Vicente Department",
        "country_name": "El Salvador"},
    {
        "name": "Santa Ana Department",
        "country_name": "El Salvador"},
    {
        "name": "Sonsonate Department",
        "country_name": "El Salvador"},
    {
        "name": "Usulután Department",
        "country_name": "El Salvador"},
    {
        "name": "Annobón Province",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Bioko Norte Province",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Bioko Sur Province",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Centro Sur Province",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Insular Region",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Kié-Ntem Province",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Litoral Province",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Río Muni",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Wele-Nzas Province",
        "country_name": "Equatorial Guinea"},
    {
        "name": "Anseba Region",
        "country_name": "Eritrea"},
    {
        "name": "Debub Region",
        "country_name": "Eritrea"},
    {
        "name": "Gash-Barka Region",
        "country_name": "Eritrea"},
    {
        "name": "Maekel Region",
        "country_name": "Eritrea"},
    {
        "name": "Northern Red Sea Region",
        "country_name": "Eritrea"},
    {
        "name": "Southern Red Sea Region",
        "country_name": "Eritrea"},
    {
        "name": "Harju County",
        "country_name": "Estonia"},
    {
        "name": "Hiiu County",
        "country_name": "Estonia"},
    {
        "name": "Ida-Viru County",
        "country_name": "Estonia"},
    {
        "name": "Järva County",
        "country_name": "Estonia"},
    {
        "name": "Jõgeva County",
        "country_name": "Estonia"},
    {
        "name": "Lääne County",
        "country_name": "Estonia"},
    {
        "name": "Lääne-Viru County",
        "country_name": "Estonia"},
    {
        "name": "Pärnu County",
        "country_name": "Estonia"},
    {
        "name": "Põlva County",
        "country_name": "Estonia"},
    {
        "name": "Rapla County",
        "country_name": "Estonia"},
    {
        "name": "Saare County",
        "country_name": "Estonia"},
    {
        "name": "Tartu County",
        "country_name": "Estonia"},
    {
        "name": "Valga County",
        "country_name": "Estonia"},
    {
        "name": "Viljandi County",
        "country_name": "Estonia"},
    {
        "name": "Võru County",
        "country_name": "Estonia"},
    {
        "name": "Addis Ababa",
        "country_name": "Ethiopia"},
    {
        "name": "Afar Region",
        "country_name": "Ethiopia"},
    {
        "name": "Amhara Region",
        "country_name": "Ethiopia"},
    {
        "name": "Benishangul-Gumuz Region",
        "country_name": "Ethiopia"},
    {
        "name": "Dire Dawa",
        "country_name": "Ethiopia"},
    {
        "name": "Gambela Region",
        "country_name": "Ethiopia"},
    {
        "name": "Harari Region",
        "country_name": "Ethiopia"},
    {
        "name": "Oromia Region",
        "country_name": "Ethiopia"},
    {
        "name": "Somali Region",
        "country_name": "Ethiopia"},
    {
        "name": "Southern Nations, Nationalities, and Peoples' Region",
        "country_name": "Ethiopia"},
    {
        "name": "Tigray Region",
        "country_name": "Ethiopia"},
    {
        "name": "Ba",
        "country_name": "Fiji Islands"},
    {
        "name": "Bua",
        "country_name": "Fiji Islands"},
    {
        "name": "Cakaudrove",
        "country_name": "Fiji Islands"},
    {
        "name": "Central Division",
        "country_name": "Fiji Islands"},
    {
        "name": "Eastern Division",
        "country_name": "Fiji Islands"},
    {
        "name": "Kadavu",
        "country_name": "Fiji Islands"},
    {
        "name": "Lau",
        "country_name": "Fiji Islands"},
    {
        "name": "Lomaiviti",
        "country_name": "Fiji Islands"},
    {
        "name": "Macuata",
        "country_name": "Fiji Islands"},
    {
        "name": "Nadroga-Navosa",
        "country_name": "Fiji Islands"},
    {
        "name": "Naitasiri",
        "country_name": "Fiji Islands"},
    {
        "name": "Namosi",
        "country_name": "Fiji Islands"},
    {
        "name": "Northern Division",
        "country_name": "Fiji Islands"},
    {
        "name": "Ra",
        "country_name": "Fiji Islands"},
    {
        "name": "Rewa",
        "country_name": "Fiji Islands"},
    {
        "name": "Rotuma",
        "country_name": "Fiji Islands"},
    {
        "name": "Serua",
        "country_name": "Fiji Islands"},
    {
        "name": "Tailevu",
        "country_name": "Fiji Islands"},
    {
        "name": "Western Division",
        "country_name": "Fiji Islands"},
    {
        "name": "Åland Islands",
        "country_name": "Finland"},
    {
        "name": "Central Finland",
        "country_name": "Finland"},
    {
        "name": "Central Ostrobothnia",
        "country_name": "Finland"},
    {
        "name": "Finland Proper",
        "country_name": "Finland"},
    {
        "name": "Kainuu",
        "country_name": "Finland"},
    {
        "name": "Kymenlaakso",
        "country_name": "Finland"},
    {
        "name": "Lapland",
        "country_name": "Finland"},
    {
        "name": "North Karelia",
        "country_name": "Finland"},
    {
        "name": "Northern Ostrobothnia",
        "country_name": "Finland"},
    {
        "name": "Northern Savonia",
        "country_name": "Finland"},
    {
        "name": "Ostrobothnia",
        "country_name": "Finland"},
    {
        "name": "Päijänne Tavastia",
        "country_name": "Finland"},
    {
        "name": "Pirkanmaa",
        "country_name": "Finland"},
    {
        "name": "Satakunta",
        "country_name": "Finland"},
    {
        "name": "South Karelia",
        "country_name": "Finland"},
    {
        "name": "Southern Ostrobothnia",
        "country_name": "Finland"},
    {
        "name": "Southern Savonia",
        "country_name": "Finland"},
    {
        "name": "Tavastia Proper",
        "country_name": "Finland"},
    {
        "name": "Uusimaa",
        "country_name": "Finland"},
    {
        "name": "Ain",
        "country_name": "France"},
    {
        "name": "Aisne",
        "country_name": "France"},
    {
        "name": "Allier",
        "country_name": "France"},
    {
        "name": "Alpes-de-Haute-Provence",
        "country_name": "France"},
    {
        "name": "Alpes-Maritimes",
        "country_name": "France"},
    {
        "name": "Alsace",
        "country_name": "France"},
    {
        "name": "Ardèche",
        "country_name": "France"},
    {
        "name": "Ardennes",
        "country_name": "France"},
    {
        "name": "Ariège",
        "country_name": "France"},
    {
        "name": "Aube",
        "country_name": "France"},
    {
        "name": "Aude",
        "country_name": "France"},
    {
        "name": "Auvergne-Rhône-Alpes",
        "country_name": "France"},
    {
        "name": "Aveyron",
        "country_name": "France"},
    {
        "name": "Bas-Rhin",
        "country_name": "France"},
    {
        "name": "Bouches-du-Rhône",
        "country_name": "France"},
    {
        "name": "Bourgogne-Franche-Comté",
        "country_name": "France"},
    {
        "name": "Bretagne",
        "country_name": "France"},
    {
        "name": "Calvados",
        "country_name": "France"},
    {
        "name": "Cantal",
        "country_name": "France"},
    {
        "name": "Centre-Val de Loire",
        "country_name": "France"},
    {
        "name": "Charente",
        "country_name": "France"},
    {
        "name": "Charente-Maritime",
        "country_name": "France"},
    {
        "name": "Cher",
        "country_name": "France"},
    {
        "name": "Clipperton",
        "country_name": "France"},
    {
        "name": "Corrèze",
        "country_name": "France"},
    {
        "name": "Corse",
        "country_name": "France"},
    {
        "name": "Corse-du-Sud",
        "country_name": "France"},
    {
        "name": "Côte-d'Or",
        "country_name": "France"},
    {
        "name": "Côtes-d'Armor",
        "country_name": "France"},
    {
        "name": "Creuse",
        "country_name": "France"},
    {
        "name": "Deux-Sèvres",
        "country_name": "France"},
    {
        "name": "Dordogne",
        "country_name": "France"},
    {
        "name": "Doubs",
        "country_name": "France"},
    {
        "name": "Drôme",
        "country_name": "France"},
    {
        "name": "Essonne",
        "country_name": "France"},
    {
        "name": "Eure",
        "country_name": "France"},
    {
        "name": "Eure-et-Loir",
        "country_name": "France"},
    {
        "name": "Finistère",
        "country_name": "France"},
    {
        "name": "French Guiana",
        "country_name": "France"},
    {
        "name": "French Polynesia",
        "country_name": "France"},
    {
        "name": "French Southern and Antarctic Lands",
        "country_name": "France"},
    {
        "name": "Gard",
        "country_name": "France"},
    {
        "name": "Gers",
        "country_name": "France"},
    {
        "name": "Gironde",
        "country_name": "France"},
    {
        "name": "Grand-Est",
        "country_name": "France"},
    {
        "name": "Guadeloupe",
        "country_name": "France"},
    {
        "name": "Haut-Rhin",
        "country_name": "France"},
    {
        "name": "Haute-Corse",
        "country_name": "France"},
    {
        "name": "Haute-Garonne",
        "country_name": "France"},
    {
        "name": "Haute-Loire",
        "country_name": "France"},
    {
        "name": "Haute-Marne",
        "country_name": "France"},
    {
        "name": "Haute-Saône",
        "country_name": "France"},
    {
        "name": "Haute-Savoie",
        "country_name": "France"},
    {
        "name": "Haute-Vienne",
        "country_name": "France"},
    {
        "name": "Hautes-Alpes",
        "country_name": "France"},
    {
        "name": "Hautes-Pyrénées",
        "country_name": "France"},
    {
        "name": "Hauts-de-France",
        "country_name": "France"},
    {
        "name": "Hauts-de-Seine",
        "country_name": "France"},
    {
        "name": "Hérault",
        "country_name": "France"},
    {
        "name": "Île-de-France",
        "country_name": "France"},
    {
        "name": "Ille-et-Vilaine",
        "country_name": "France"},
    {
        "name": "Indre",
        "country_name": "France"},
    {
        "name": "Indre-et-Loire",
        "country_name": "France"},
    {
        "name": "Isère",
        "country_name": "France"},
    {
        "name": "Jura",
        "country_name": "France"},
    {
        "name": "La Réunion",
        "country_name": "France"},
    {
        "name": "Landes",
        "country_name": "France"},
    {
        "name": "Loir-et-Cher",
        "country_name": "France"},
    {
        "name": "Loire",
        "country_name": "France"},
    {
        "name": "Loire-Atlantique",
        "country_name": "France"},
    {
        "name": "Loiret",
        "country_name": "France"},
    {
        "name": "Lot",
        "country_name": "France"},
    {
        "name": "Lot-et-Garonne",
        "country_name": "France"},
    {
        "name": "Lozère",
        "country_name": "France"},
    {
        "name": "Maine-et-Loire",
        "country_name": "France"},
    {
        "name": "Manche",
        "country_name": "France"},
    {
        "name": "Marne",
        "country_name": "France"},
    {
        "name": "Martinique",
        "country_name": "France"},
    {
        "name": "Mayenne",
        "country_name": "France"},
    {
        "name": "Mayotte",
        "country_name": "France"},
    {
        "name": "Métropole de Lyon",
        "country_name": "France"},
    {
        "name": "Meurthe-et-Moselle",
        "country_name": "France"},
    {
        "name": "Meuse",
        "country_name": "France"},
    {
        "name": "Morbihan",
        "country_name": "France"},
    {
        "name": "Moselle",
        "country_name": "France"},
    {
        "name": "Nièvre",
        "country_name": "France"},
    {
        "name": "Nord",
        "country_name": "France"},
    {
        "name": "Normandie",
        "country_name": "France"},
    {
        "name": "Nouvelle-Aquitaine",
        "country_name": "France"},
    {
        "name": "Occitanie",
        "country_name": "France"},
    {
        "name": "Oise",
        "country_name": "France"},
    {
        "name": "Orne",
        "country_name": "France"},
    {
        "name": "Paris",
        "country_name": "France"},
    {
        "name": "Pas-de-Calais",
        "country_name": "France"},
    {
        "name": "Pays-de-la-Loire",
        "country_name": "France"},
    {
        "name": "Provence-Alpes-Côte-d’Azur",
        "country_name": "France"},
    {
        "name": "Puy-de-Dôme",
        "country_name": "France"},
    {
        "name": "Pyrénées-Atlantiques",
        "country_name": "France"},
    {
        "name": "Pyrénées-Orientales",
        "country_name": "France"},
    {
        "name": "Rhône",
        "country_name": "France"},
    {
        "name": "Saint Pierre and Miquelon",
        "country_name": "France"},
    {
        "name": "Saint-Barthélemy",
        "country_name": "France"},
    {
        "name": "Saint-Martin",
        "country_name": "France"},
    {
        "name": "Saône-et-Loire",
        "country_name": "France"},
    {
        "name": "Sarthe",
        "country_name": "France"},
    {
        "name": "Savoie",
        "country_name": "France"},
    {
        "name": "Seine-et-Marne",
        "country_name": "France"},
    {
        "name": "Seine-Maritime",
        "country_name": "France"},
    {
        "name": "Seine-Saint-Denis",
        "country_name": "France"},
    {
        "name": "Somme",
        "country_name": "France"},
    {
        "name": "Tarn",
        "country_name": "France"},
    {
        "name": "Tarn-et-Garonne",
        "country_name": "France"},
    {
        "name": "Territoire de Belfort",
        "country_name": "France"},
    {
        "name": "Val-d'Oise",
        "country_name": "France"},
    {
        "name": "Val-de-Marne",
        "country_name": "France"},
    {
        "name": "Var",
        "country_name": "France"},
    {
        "name": "Vaucluse",
        "country_name": "France"},
    {
        "name": "Vendée",
        "country_name": "France"},
    {
        "name": "Vienne",
        "country_name": "France"},
    {
        "name": "Vosges",
        "country_name": "France"},
    {
        "name": "Wallis and Futuna",
        "country_name": "France"},
    {
        "name": "Yonne",
        "country_name": "France"},
    {
        "name": "Yvelines",
        "country_name": "France"},
    {
        "name": "Estuaire Province",
        "country_name": "Gabon"},
    {
        "name": "Haut-Ogooué Province",
        "country_name": "Gabon"},
    {
        "name": "Moyen-Ogooué Province",
        "country_name": "Gabon"},
    {
        "name": "Ngounié Province",
        "country_name": "Gabon"},
    {
        "name": "Nyanga Province",
        "country_name": "Gabon"},
    {
        "name": "Ogooué-Ivindo Province",
        "country_name": "Gabon"},
    {
        "name": "Ogooué-Lolo Province",
        "country_name": "Gabon"},
    {
        "name": "Ogooué-Maritime Province",
        "country_name": "Gabon"},
    {
        "name": "Woleu-Ntem Province",
        "country_name": "Gabon"},
    {
        "name": "Banjul",
        "country_name": "Gambia The"},
    {
        "name": "Central River Division",
        "country_name": "Gambia The"},
    {
        "name": "Lower River Division",
        "country_name": "Gambia The"},
    {
        "name": "North Bank Division",
        "country_name": "Gambia The"},
    {
        "name": "Upper River Division",
        "country_name": "Gambia The"},
    {
        "name": "West Coast Division",
        "country_name": "Gambia The"},
    {
        "name": "Adjara",
        "country_name": "Georgia"},
    {
        "name": "Autonomous Republic of Abkhazia",
        "country_name": "Georgia"},
    {
        "name": "Guria",
        "country_name": "Georgia"},
    {
        "name": "Imereti",
        "country_name": "Georgia"},
    {
        "name": "Kakheti",
        "country_name": "Georgia"},
    {
        "name": "Khelvachauri Municipality",
        "country_name": "Georgia"},
    {
        "name": "Kvemo Kartli",
        "country_name": "Georgia"},
    {
        "name": "Mtskheta-Mtianeti",
        "country_name": "Georgia"},
    {
        "name": "Racha-Lechkhumi and Kvemo Svaneti",
        "country_name": "Georgia"},
    {
        "name": "Samegrelo-Zemo Svaneti",
        "country_name": "Georgia"},
    {
        "name": "Samtskhe-Javakheti",
        "country_name": "Georgia"},
    {
        "name": "Senaki Municipality",
        "country_name": "Georgia"},
    {
        "name": "Shida Kartli",
        "country_name": "Georgia"},
    {
        "name": "Tbilisi",
        "country_name": "Georgia"},
    {
        "name": "Baden-Württemberg",
        "country_name": "Germany"},
    {
        "name": "Bavaria",
        "country_name": "Germany"},
    {
        "name": "Berlin",
        "country_name": "Germany"},
    {
        "name": "Brandenburg",
        "country_name": "Germany"},
    {
        "name": "Bremen",
        "country_name": "Germany"},
    {
        "name": "Hamburg",
        "country_name": "Germany"},
    {
        "name": "Hesse",
        "country_name": "Germany"},
    {
        "name": "Lower Saxony",
        "country_name": "Germany"},
    {
        "name": "Mecklenburg-Vorpommern",
        "country_name": "Germany"},
    {
        "name": "North Rhine-Westphalia",
        "country_name": "Germany"},
    {
        "name": "Rhineland-Palatinate",
        "country_name": "Germany"},
    {
        "name": "Saarland",
        "country_name": "Germany"},
    {
        "name": "Saxony",
        "country_name": "Germany"},
    {
        "name": "Saxony-Anhalt",
        "country_name": "Germany"},
    {
        "name": "Schleswig-Holstein",
        "country_name": "Germany"},
    {
        "name": "Thuringia",
        "country_name": "Germany"},
    {
        "name": "Ahafo",
        "country_name": "Ghana"},
    {
        "name": "Ashanti",
        "country_name": "Ghana"},
    {
        "name": "Bono",
        "country_name": "Ghana"},
    {
        "name": "Bono East",
        "country_name": "Ghana"},
    {
        "name": "Central",
        "country_name": "Ghana"},
    {
        "name": "Eastern",
        "country_name": "Ghana"},
    {
        "name": "Greater Accra",
        "country_name": "Ghana"},
    {
        "name": "North East",
        "country_name": "Ghana"},
    {
        "name": "Northern",
        "country_name": "Ghana"},
    {
        "name": "Oti",
        "country_name": "Ghana"},
    {
        "name": "Savannah",
        "country_name": "Ghana"},
    {
        "name": "Upper East",
        "country_name": "Ghana"},
    {
        "name": "Upper West",
        "country_name": "Ghana"},
    {
        "name": "Volta",
        "country_name": "Ghana"},
    {
        "name": "Western",
        "country_name": "Ghana"},
    {
        "name": "Western North",
        "country_name": "Ghana"},
    {
        "name": "Achaea Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Aetolia-Acarnania Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Arcadia Prefecture",
        "country_name": "Greece"},
    {
        "name": "Argolis Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Attica Region",
        "country_name": "Greece"},
    {
        "name": "Boeotia Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Central Greece Region",
        "country_name": "Greece"},
    {
        "name": "Central Macedonia",
        "country_name": "Greece"},
    {
        "name": "Chania Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Corfu Prefecture",
        "country_name": "Greece"},
    {
        "name": "Corinthia Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Crete Region",
        "country_name": "Greece"},
    {
        "name": "Drama Regional Unit",
        "country_name": "Greece"},
    {
        "name": "East Attica Regional Unit",
        "country_name": "Greece"},
    {
        "name": "East Macedonia and Thrace",
        "country_name": "Greece"},
    {
        "name": "Epirus Region",
        "country_name": "Greece"},
    {
        "name": "Euboea",
        "country_name": "Greece"},
    {
        "name": "Grevena Prefecture",
        "country_name": "Greece"},
    {
        "name": "Imathia Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Ioannina Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Ionian Islands Region",
        "country_name": "Greece"},
    {
        "name": "Karditsa Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Kastoria Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Kefalonia Prefecture",
        "country_name": "Greece"},
    {
        "name": "Kilkis Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Kozani Prefecture",
        "country_name": "Greece"},
    {
        "name": "Laconia",
        "country_name": "Greece"},
    {
        "name": "Larissa Prefecture",
        "country_name": "Greece"},
    {
        "name": "Lefkada Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Pella Regional Unit",
        "country_name": "Greece"},
    {
        "name": "Peloponnese Region",
        "country_name": "Greece"},
    {
        "name": "Phthiotis Prefecture",
        "country_name": "Greece"},
    {
        "name": "Preveza Prefecture",
        "country_name": "Greece"},
    {
        "name": "Serres Prefecture",
        "country_name": "Greece"},
    {
        "name": "South Aegean",
        "country_name": "Greece"},
    {
        "name": "Thessaloniki Regional Unit",
        "country_name": "Greece"},
    {
        "name": "West Greece Region",
        "country_name": "Greece"},
    {
        "name": "West Macedonia Region",
        "country_name": "Greece"},
    {
        "name": "Carriacou and Petite Martinique",
        "country_name": "Grenada"},
    {
        "name": "Saint Andrew Parish",
        "country_name": "Grenada"},
    {
        "name": "Saint David Parish",
        "country_name": "Grenada"},
    {
        "name": "Saint George Parish",
        "country_name": "Grenada"},
    {
        "name": "Saint John Parish",
        "country_name": "Grenada"},
    {
        "name": "Saint Mark Parish",
        "country_name": "Grenada"},
    {
        "name": "Saint Patrick Parish",
        "country_name": "Grenada"},
    {
        "name": "Alta Verapaz Department",
        "country_name": "Guatemala"},
    {
        "name": "Baja Verapaz Department",
        "country_name": "Guatemala"},
    {
        "name": "Chimaltenango Department",
        "country_name": "Guatemala"},
    {
        "name": "Chiquimula Department",
        "country_name": "Guatemala"},
    {
        "name": "El Progreso Department",
        "country_name": "Guatemala"},
    {
        "name": "Escuintla Department",
        "country_name": "Guatemala"},
    {
        "name": "Guatemala Department",
        "country_name": "Guatemala"},
    {
        "name": "Huehuetenango Department",
        "country_name": "Guatemala"},
    {
        "name": "Izabal Department",
        "country_name": "Guatemala"},
    {
        "name": "Jalapa Department",
        "country_name": "Guatemala"},
    {
        "name": "Jutiapa Department",
        "country_name": "Guatemala"},
    {
        "name": "Petén Department",
        "country_name": "Guatemala"},
    {
        "name": "Quetzaltenango Department",
        "country_name": "Guatemala"},
    {
        "name": "Quiché Department",
        "country_name": "Guatemala"},
    {
        "name": "Retalhuleu Department",
        "country_name": "Guatemala"},
    {
        "name": "Sacatepéquez Department",
        "country_name": "Guatemala"},
    {
        "name": "San Marcos Department",
        "country_name": "Guatemala"},
    {
        "name": "Santa Rosa Department",
        "country_name": "Guatemala"},
    {
        "name": "Sololá Department",
        "country_name": "Guatemala"},
    {
        "name": "Suchitepéquez Department",
        "country_name": "Guatemala"},
    {
        "name": "Totonicapán Department",
        "country_name": "Guatemala"},
    {
        "name": "Beyla Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Boffa Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Boké Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Boké Region",
        "country_name": "Guinea"},
    {
        "name": "Conakry",
        "country_name": "Guinea"},
    {
        "name": "Coyah Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Dabola Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Dalaba Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Dinguiraye Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Dubréka Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Faranah Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Forécariah Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Fria Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Gaoual Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Guéckédou Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Kankan Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Kankan Region",
        "country_name": "Guinea"},
    {
        "name": "Kérouané Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Kindia Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Kindia Region",
        "country_name": "Guinea"},
    {
        "name": "Kissidougou Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Koubia Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Koundara Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Kouroussa Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Labé Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Labé Region",
        "country_name": "Guinea"},
    {
        "name": "Lélouma Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Lola Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Macenta Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Mali Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Mamou Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Mamou Region",
        "country_name": "Guinea"},
    {
        "name": "Mandiana Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Nzérékoré Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Nzérékoré Region",
        "country_name": "Guinea"},
    {
        "name": "Pita Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Siguiri Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Télimélé Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Tougué Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Yomou Prefecture",
        "country_name": "Guinea"},
    {
        "name": "Bafatá",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Biombo Region",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Bolama Region",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Cacheu Region",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Gabú Region",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Leste Province",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Norte Province",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Oio Region",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Quinara Region",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Sul Province",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Tombali Region",
        "country_name": "Guinea-Bissau"},
    {
        "name": "Barima-Waini",
        "country_name": "Guyana"},
    {
        "name": "Cuyuni-Mazaruni",
        "country_name": "Guyana"},
    {
        "name": "Demerara-Mahaica",
        "country_name": "Guyana"},
    {
        "name": "East Berbice-Corentyne",
        "country_name": "Guyana"},
    {
        "name": "Essequibo Islands-West Demerara",
        "country_name": "Guyana"},
    {
        "name": "Mahaica-Berbice",
        "country_name": "Guyana"},
    {
        "name": "Pomeroon-Supenaam",
        "country_name": "Guyana"},
    {
        "name": "Potaro-Siparuni",
        "country_name": "Guyana"},
    {
        "name": "Upper Demerara-Berbice",
        "country_name": "Guyana"},
    {
        "name": "Upper Takutu-Upper Essequibo",
        "country_name": "Guyana"},
    {
        "name": "Artibonite",
        "country_name": "Haiti"},
    {
        "name": "Centre",
        "country_name": "Haiti"},
    {
        "name": "Grand'Anse",
        "country_name": "Haiti"},
    {
        "name": "Nippes",
        "country_name": "Haiti"},
    {
        "name": "Nord",
        "country_name": "Haiti"},
    {
        "name": "Nord-Est",
        "country_name": "Haiti"},
    {
        "name": "Nord-Ouest",
        "country_name": "Haiti"},
    {
        "name": "Ouest",
        "country_name": "Haiti"},
    {
        "name": "Sud",
        "country_name": "Haiti"},
    {
        "name": "Sud-Est",
        "country_name": "Haiti"},
    {
        "name": "Atlántida Department",
        "country_name": "Honduras"},
    {
        "name": "Bay Islands Department",
        "country_name": "Honduras"},
    {
        "name": "Choluteca Department",
        "country_name": "Honduras"},
    {
        "name": "Colón Department",
        "country_name": "Honduras"},
    {
        "name": "Comayagua Department",
        "country_name": "Honduras"},
    {
        "name": "Copán Department",
        "country_name": "Honduras"},
    {
        "name": "Cortés Department",
        "country_name": "Honduras"},
    {
        "name": "El Paraíso Department",
        "country_name": "Honduras"},
    {
        "name": "Francisco Morazán Department",
        "country_name": "Honduras"},
    {
        "name": "Gracias a Dios Department",
        "country_name": "Honduras"},
    {
        "name": "Intibucá Department",
        "country_name": "Honduras"},
    {
        "name": "La Paz Department",
        "country_name": "Honduras"},
    {
        "name": "Lempira Department",
        "country_name": "Honduras"},
    {
        "name": "Ocotepeque Department",
        "country_name": "Honduras"},
    {
        "name": "Olancho Department",
        "country_name": "Honduras"},
    {
        "name": "Santa Bárbara Department",
        "country_name": "Honduras"},
    {
        "name": "Valle Department",
        "country_name": "Honduras"},
    {
        "name": "Yoro Department",
        "country_name": "Honduras"},
    {
        "name": "Central and Western",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Eastern",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Islands",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Kowloon City",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Kwai Tsing",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Kwun Tong",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "North",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Sai Kung",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Sha Tin",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Sham Shui Po",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Southern",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Tai Po",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Tsuen Wan",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Tuen Mun",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Wan Chai",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Wong Tai Sin",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Yau Tsim Mong",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Yuen Long",
        "country_name": "Hong Kong S.A.R."},
    {
        "name": "Bács-Kiskun",
        "country_name": "Hungary"},
    {
        "name": "Baranya",
        "country_name": "Hungary"},
    {
        "name": "Békés",
        "country_name": "Hungary"},
    {
        "name": "Békéscsaba",
        "country_name": "Hungary"},
    {
        "name": "Borsod-Abaúj-Zemplén",
        "country_name": "Hungary"},
    {
        "name": "Budapest",
        "country_name": "Hungary"},
    {
        "name": "Csongrád County",
        "country_name": "Hungary"},
    {
        "name": "Debrecen",
        "country_name": "Hungary"},
    {
        "name": "Dunaújváros",
        "country_name": "Hungary"},
    {
        "name": "Eger",
        "country_name": "Hungary"},
    {
        "name": "Érd",
        "country_name": "Hungary"},
    {
        "name": "Fejér County",
        "country_name": "Hungary"},
    {
        "name": "Győr",
        "country_name": "Hungary"},
    {
        "name": "Győr-Moson-Sopron County",
        "country_name": "Hungary"},
    {
        "name": "Hajdú-Bihar County",
        "country_name": "Hungary"},
    {
        "name": "Heves County",
        "country_name": "Hungary"},
    {
        "name": "Hódmezővásárhely",
        "country_name": "Hungary"},
    {
        "name": "Jász-Nagykun-Szolnok County",
        "country_name": "Hungary"},
    {
        "name": "Kaposvár",
        "country_name": "Hungary"},
    {
        "name": "Kecskemét",
        "country_name": "Hungary"},
    {
        "name": "Komárom-Esztergom",
        "country_name": "Hungary"},
    {
        "name": "Miskolc",
        "country_name": "Hungary"},
    {
        "name": "Nagykanizsa",
        "country_name": "Hungary"},
    {
        "name": "Nógrád County",
        "country_name": "Hungary"},
    {
        "name": "Nyíregyháza",
        "country_name": "Hungary"},
    {
        "name": "Pécs",
        "country_name": "Hungary"},
    {
        "name": "Pest County",
        "country_name": "Hungary"},
    {
        "name": "Salgótarján",
        "country_name": "Hungary"},
    {
        "name": "Somogy County",
        "country_name": "Hungary"},
    {
        "name": "Sopron",
        "country_name": "Hungary"},
    {
        "name": "Szabolcs-Szatmár-Bereg County",
        "country_name": "Hungary"},
    {
        "name": "Szeged",
        "country_name": "Hungary"},
    {
        "name": "Székesfehérvár",
        "country_name": "Hungary"},
    {
        "name": "Szekszárd",
        "country_name": "Hungary"},
    {
        "name": "Szolnok",
        "country_name": "Hungary"},
    {
        "name": "Szombathely",
        "country_name": "Hungary"},
    {
        "name": "Tatabánya",
        "country_name": "Hungary"},
    {
        "name": "Tolna County",
        "country_name": "Hungary"},
    {
        "name": "Vas County",
        "country_name": "Hungary"},
    {
        "name": "Veszprém",
        "country_name": "Hungary"},
    {
        "name": "Veszprém County",
        "country_name": "Hungary"},
    {
        "name": "Zala County",
        "country_name": "Hungary"},
    {
        "name": "Zalaegerszeg",
        "country_name": "Hungary"},
    {
        "name": "Capital Region",
        "country_name": "Iceland"},
    {
        "name": "Eastern Region",
        "country_name": "Iceland"},
    {
        "name": "Northeastern Region",
        "country_name": "Iceland"},
    {
        "name": "Northwestern Region",
        "country_name": "Iceland"},
    {
        "name": "Southern Peninsula Region",
        "country_name": "Iceland"},
    {
        "name": "Southern Region",
        "country_name": "Iceland"},
    {
        "name": "Western Region",
        "country_name": "Iceland"},
    {
        "name": "Westfjords",
        "country_name": "Iceland"},
    {
        "name": "Andaman and Nicobar Islands",
        "country_name": "India"},
    {
        "name": "Andhra Pradesh",
        "country_name": "India"},
    {
        "name": "Arunachal Pradesh",
        "country_name": "India"},
    {
        "name": "Assam",
        "country_name": "India"},
    {
        "name": "Bihar",
        "country_name": "India"},
    {
        "name": "Chandigarh",
        "country_name": "India"},
    {
        "name": "Chhattisgarh",
        "country_name": "India"},
    {
        "name": "Dadra and Nagar Haveli and Daman and Diu",
        "country_name": "India"},
    {
        "name": "Delhi",
        "country_name": "India"},
    {
        "name": "Goa",
        "country_name": "India"},
    {
        "name": "Gujarat",
        "country_name": "India"},
    {
        "name": "Haryana",
        "country_name": "India"},
    {
        "name": "Himachal Pradesh",
        "country_name": "India"},
    {
        "name": "Jammu and Kashmir",
        "country_name": "India"},
    {
        "name": "Jharkhand",
        "country_name": "India"},
    {
        "name": "Karnataka",
        "country_name": "India"},
    {
        "name": "Kerala",
        "country_name": "India"},
    {
        "name": "Ladakh",
        "country_name": "India"},
    {
        "name": "Lakshadweep",
        "country_name": "India"},
    {
        "name": "Madhya Pradesh",
        "country_name": "India"},
    {
        "name": "Maharashtra",
        "country_name": "India"},
    {
        "name": "Manipur",
        "country_name": "India"},
    {
        "name": "Meghalaya",
        "country_name": "India"},
    {
        "name": "Mizoram",
        "country_name": "India"},
    {
        "name": "Nagaland",
        "country_name": "India"},
    {
        "name": "Odisha",
        "country_name": "India"},
    {
        "name": "Puducherry",
        "country_name": "India"},
    {
        "name": "Punjab",
        "country_name": "India"},
    {
        "name": "Rajasthan",
        "country_name": "India"},
    {
        "name": "Sikkim",
        "country_name": "India"},
    {
        "name": "Tamil Nadu",
        "country_name": "India"},
    {
        "name": "Telangana",
        "country_name": "India"},
    {
        "name": "Tripura",
        "country_name": "India"},
    {
        "name": "Uttar Pradesh",
        "country_name": "India"},
    {
        "name": "Uttarakhand",
        "country_name": "India"},
    {
        "name": "West Bengal",
        "country_name": "India"},
    {
        "name": "Aceh",
        "country_name": "Indonesia"},
    {
        "name": "Bali",
        "country_name": "Indonesia"},
    {
        "name": "Banten",
        "country_name": "Indonesia"},
    {
        "name": "Bengkulu",
        "country_name": "Indonesia"},
    {
        "name": "DI Yogyakarta",
        "country_name": "Indonesia"},
    {
        "name": "DKI Jakarta",
        "country_name": "Indonesia"},
    {
        "name": "Gorontalo",
        "country_name": "Indonesia"},
    {
        "name": "Jambi",
        "country_name": "Indonesia"},
    {
        "name": "Jawa Barat",
        "country_name": "Indonesia"},
    {
        "name": "Jawa Tengah",
        "country_name": "Indonesia"},
    {
        "name": "Jawa Timur",
        "country_name": "Indonesia"},
    {
        "name": "Kalimantan Barat",
        "country_name": "Indonesia"},
    {
        "name": "Kalimantan Selatan",
        "country_name": "Indonesia"},
    {
        "name": "Kalimantan Tengah",
        "country_name": "Indonesia"},
    {
        "name": "Kalimantan Timur",
        "country_name": "Indonesia"},
    {
        "name": "Kalimantan Utara",
        "country_name": "Indonesia"},
    {
        "name": "Kepulauan Bangka Belitung",
        "country_name": "Indonesia"},
    {
        "name": "Kepulauan Riau",
        "country_name": "Indonesia"},
    {
        "name": "Lampung",
        "country_name": "Indonesia"},
    {
        "name": "Maluku",
        "country_name": "Indonesia"},
    {
        "name": "Maluku Utara",
        "country_name": "Indonesia"},
    {
        "name": "Nusa Tenggara Barat",
        "country_name": "Indonesia"},
    {
        "name": "Nusa Tenggara Timur",
        "country_name": "Indonesia"},
    {
        "name": "Papua",
        "country_name": "Indonesia"},
    {
        "name": "Papua Barat",
        "country_name": "Indonesia"},
    {
        "name": "Riau",
        "country_name": "Indonesia"},
    {
        "name": "Sulawesi Barat",
        "country_name": "Indonesia"},
    {
        "name": "Sulawesi Selatan",
        "country_name": "Indonesia"},
    {
        "name": "Sulawesi Tengah",
        "country_name": "Indonesia"},
    {
        "name": "Sulawesi Tenggara",
        "country_name": "Indonesia"},
    {
        "name": "Sulawesi Utara",
        "country_name": "Indonesia"},
    {
        "name": "Sumatera Barat",
        "country_name": "Indonesia"},
    {
        "name": "Sumatera Selatan",
        "country_name": "Indonesia"},
    {
        "name": "Sumatera Utara",
        "country_name": "Indonesia"},
    {
        "name": "Alborz",
        "country_name": "Iran"},
    {
        "name": "Ardabil",
        "country_name": "Iran"},
    {
        "name": "Bushehr",
        "country_name": "Iran"},
    {
        "name": "Chaharmahal and Bakhtiari",
        "country_name": "Iran"},
    {
        "name": "East Azerbaijan",
        "country_name": "Iran"},
    {
        "name": "Fars",
        "country_name": "Iran"},
    {
        "name": "Gilan",
        "country_name": "Iran"},
    {
        "name": "Golestan",
        "country_name": "Iran"},
    {
        "name": "Hamadan",
        "country_name": "Iran"},
    {
        "name": "Hormozgan",
        "country_name": "Iran"},
    {
        "name": "Ilam",
        "country_name": "Iran"},
    {
        "name": "Isfahan",
        "country_name": "Iran"},
    {
        "name": "Kerman",
        "country_name": "Iran"},
    {
        "name": "Kermanshah",
        "country_name": "Iran"},
    {
        "name": "Khuzestan",
        "country_name": "Iran"},
    {
        "name": "Kohgiluyeh and Boyer-Ahmad",
        "country_name": "Iran"},
    {
        "name": "Kurdistan",
        "country_name": "Iran"},
    {
        "name": "Lorestan",
        "country_name": "Iran"},
    {
        "name": "Markazi",
        "country_name": "Iran"},
    {
        "name": "Mazandaran",
        "country_name": "Iran"},
    {
        "name": "North Khorasan",
        "country_name": "Iran"},
    {
        "name": "Qazvin",
        "country_name": "Iran"},
    {
        "name": "Qom",
        "country_name": "Iran"},
    {
        "name": "Razavi Khorasan",
        "country_name": "Iran"},
    {
        "name": "Semnan",
        "country_name": "Iran"},
    {
        "name": "Sistan and Baluchestan",
        "country_name": "Iran"},
    {
        "name": "South Khorasan",
        "country_name": "Iran"},
    {
        "name": "Tehran",
        "country_name": "Iran"},
    {
        "name": "West Azarbaijan",
        "country_name": "Iran"},
    {
        "name": "Yazd",
        "country_name": "Iran"},
    {
        "name": "Zanjan",
        "country_name": "Iran"},
    {
        "name": "Al Anbar",
        "country_name": "Iraq"},
    {
        "name": "Al Muthanna",
        "country_name": "Iraq"},
    {
        "name": "Al-Qādisiyyah",
        "country_name": "Iraq"},
    {
        "name": "Babylon",
        "country_name": "Iraq"},
    {
        "name": "Baghdad",
        "country_name": "Iraq"},
    {
        "name": "Basra",
        "country_name": "Iraq"},
    {
        "name": "Dhi Qar",
        "country_name": "Iraq"},
    {
        "name": "Diyala",
        "country_name": "Iraq"},
    {
        "name": "Dohuk",
        "country_name": "Iraq"},
    {
        "name": "Erbil",
        "country_name": "Iraq"},
    {
        "name": "Karbala",
        "country_name": "Iraq"},
    {
        "name": "Kirkuk",
        "country_name": "Iraq"},
    {
        "name": "Maysan",
        "country_name": "Iraq"},
    {
        "name": "Najaf",
        "country_name": "Iraq"},
    {
        "name": "Nineveh",
        "country_name": "Iraq"},
    {
        "name": "Saladin",
        "country_name": "Iraq"},
    {
        "name": "Sulaymaniyah",
        "country_name": "Iraq"},
    {
        "name": "Wasit",
        "country_name": "Iraq"},
    {
        "name": "Carlow",
        "country_name": "Ireland"},
    {
        "name": "Cavan",
        "country_name": "Ireland"},
    {
        "name": "Clare",
        "country_name": "Ireland"},
    {
        "name": "Connacht",
        "country_name": "Ireland"},
    {
        "name": "Cork",
        "country_name": "Ireland"},
    {
        "name": "Donegal",
        "country_name": "Ireland"},
    {
        "name": "Dublin",
        "country_name": "Ireland"},
    {
        "name": "Galway",
        "country_name": "Ireland"},
    {
        "name": "Kerry",
        "country_name": "Ireland"},
    {
        "name": "Kildare",
        "country_name": "Ireland"},
    {
        "name": "Kilkenny",
        "country_name": "Ireland"},
    {
        "name": "Laois",
        "country_name": "Ireland"},
    {
        "name": "Leinster",
        "country_name": "Ireland"},
    {
        "name": "Limerick",
        "country_name": "Ireland"},
    {
        "name": "Longford",
        "country_name": "Ireland"},
    {
        "name": "Louth",
        "country_name": "Ireland"},
    {
        "name": "Mayo",
        "country_name": "Ireland"},
    {
        "name": "Meath",
        "country_name": "Ireland"},
    {
        "name": "Monaghan",
        "country_name": "Ireland"},
    {
        "name": "Munster",
        "country_name": "Ireland"},
    {
        "name": "Offaly",
        "country_name": "Ireland"},
    {
        "name": "Roscommon",
        "country_name": "Ireland"},
    {
        "name": "Sligo",
        "country_name": "Ireland"},
    {
        "name": "Tipperary",
        "country_name": "Ireland"},
    {
        "name": "Ulster",
        "country_name": "Ireland"},
    {
        "name": "Waterford",
        "country_name": "Ireland"},
    {
        "name": "Westmeath",
        "country_name": "Ireland"},
    {
        "name": "Wexford",
        "country_name": "Ireland"},
    {
        "name": "Wicklow",
        "country_name": "Ireland"},
    {
        "name": "Central District",
        "country_name": "Israel"},
    {
        "name": "Haifa District",
        "country_name": "Israel"},
    {
        "name": "Jerusalem District",
        "country_name": "Israel"},
    {
        "name": "Northern District",
        "country_name": "Israel"},
    {
        "name": "Southern District",
        "country_name": "Israel"},
    {
        "name": "Tel Aviv District",
        "country_name": "Israel"},
    {
        "name": "Abruzzo",
        "country_name": "Italy"},
    {
        "name": "Agrigento",
        "country_name": "Italy"},
    {
        "name": "Alessandria",
        "country_name": "Italy"},
    {
        "name": "Ancona",
        "country_name": "Italy"},
    {
        "name": "Aosta Valley",
        "country_name": "Italy"},
    {
        "name": "Apulia",
        "country_name": "Italy"},
    {
        "name": "Ascoli Piceno",
        "country_name": "Italy"},
    {
        "name": "Asti",
        "country_name": "Italy"},
    {
        "name": "Avellino",
        "country_name": "Italy"},
    {
        "name": "Barletta-Andria-Trani",
        "country_name": "Italy"},
    {
        "name": "Basilicata",
        "country_name": "Italy"},
    {
        "name": "Belluno",
        "country_name": "Italy"},
    {
        "name": "Benevento",
        "country_name": "Italy"},
    {
        "name": "Bergamo",
        "country_name": "Italy"},
    {
        "name": "Biella",
        "country_name": "Italy"},
    {
        "name": "Brescia",
        "country_name": "Italy"},
    {
        "name": "Brindisi",
        "country_name": "Italy"},
    {
        "name": "Calabria",
        "country_name": "Italy"},
    {
        "name": "Caltanissetta",
        "country_name": "Italy"},
    {
        "name": "Campania",
        "country_name": "Italy"},
    {
        "name": "Campobasso",
        "country_name": "Italy"},
    {
        "name": "Caserta",
        "country_name": "Italy"},
    {
        "name": "Catanzaro",
        "country_name": "Italy"},
    {
        "name": "Chieti",
        "country_name": "Italy"},
    {
        "name": "Como",
        "country_name": "Italy"},
    {
        "name": "Cosenza",
        "country_name": "Italy"},
    {
        "name": "Cremona",
        "country_name": "Italy"},
    {
        "name": "Crotone",
        "country_name": "Italy"},
    {
        "name": "Cuneo",
        "country_name": "Italy"},
    {
        "name": "Emilia-Romagna",
        "country_name": "Italy"},
    {
        "name": "Enna",
        "country_name": "Italy"},
    {
        "name": "Fermo",
        "country_name": "Italy"},
    {
        "name": "Ferrara",
        "country_name": "Italy"},
    {
        "name": "Foggia",
        "country_name": "Italy"},
    {
        "name": "Forlì-Cesena",
        "country_name": "Italy"},
    {
        "name": "Friuli–Venezia Giulia",
        "country_name": "Italy"},
    {
        "name": "Frosinone",
        "country_name": "Italy"},
    {
        "name": "Gorizia",
        "country_name": "Italy"},
    {
        "name": "Grosseto",
        "country_name": "Italy"},
    {
        "name": "Imperia",
        "country_name": "Italy"},
    {
        "name": "Isernia",
        "country_name": "Italy"},
    {
        "name": "L'Aquila",
        "country_name": "Italy"},
    {
        "name": "La Spezia",
        "country_name": "Italy"},
    {
        "name": "Latina",
        "country_name": "Italy"},
    {
        "name": "Lazio",
        "country_name": "Italy"},
    {
        "name": "Lecce",
        "country_name": "Italy"},
    {
        "name": "Lecco",
        "country_name": "Italy"},
    {
        "name": "Liguria",
        "country_name": "Italy"},
    {
        "name": "Livorno",
        "country_name": "Italy"},
    {
        "name": "Lodi",
        "country_name": "Italy"},
    {
        "name": "Lombardy",
        "country_name": "Italy"},
    {
        "name": "Lucca",
        "country_name": "Italy"},
    {
        "name": "Macerata",
        "country_name": "Italy"},
    {
        "name": "Mantua",
        "country_name": "Italy"},
    {
        "name": "Marche",
        "country_name": "Italy"},
    {
        "name": "Massa and Carrara",
        "country_name": "Italy"},
    {
        "name": "Matera",
        "country_name": "Italy"},
    {
        "name": "Medio Campidano",
        "country_name": "Italy"},
    {
        "name": "Modena",
        "country_name": "Italy"},
    {
        "name": "Molise",
        "country_name": "Italy"},
    {
        "name": "Monza and Brianza",
        "country_name": "Italy"},
    {
        "name": "Novara",
        "country_name": "Italy"},
    {
        "name": "Nuoro",
        "country_name": "Italy"},
    {
        "name": "Oristano",
        "country_name": "Italy"},
    {
        "name": "Padua",
        "country_name": "Italy"},
    {
        "name": "Palermo",
        "country_name": "Italy"},
    {
        "name": "Parma",
        "country_name": "Italy"},
    {
        "name": "Pavia",
        "country_name": "Italy"},
    {
        "name": "Perugia",
        "country_name": "Italy"},
    {
        "name": "Pesaro and Urbino",
        "country_name": "Italy"},
    {
        "name": "Pescara",
        "country_name": "Italy"},
    {
        "name": "Piacenza",
        "country_name": "Italy"},
    {
        "name": "Piedmont",
        "country_name": "Italy"},
    {
        "name": "Pisa",
        "country_name": "Italy"},
    {
        "name": "Pistoia",
        "country_name": "Italy"},
    {
        "name": "Pordenone",
        "country_name": "Italy"},
    {
        "name": "Potenza",
        "country_name": "Italy"},
    {
        "name": "Prato",
        "country_name": "Italy"},
    {
        "name": "Ragusa",
        "country_name": "Italy"},
    {
        "name": "Ravenna",
        "country_name": "Italy"},
    {
        "name": "Reggio Emilia",
        "country_name": "Italy"},
    {
        "name": "Rieti",
        "country_name": "Italy"},
    {
        "name": "Rimini",
        "country_name": "Italy"},
    {
        "name": "Rovigo",
        "country_name": "Italy"},
    {
        "name": "Salerno",
        "country_name": "Italy"},
    {
        "name": "Sardinia",
        "country_name": "Italy"},
    {
        "name": "Sassari",
        "country_name": "Italy"},
    {
        "name": "Savona",
        "country_name": "Italy"},
    {
        "name": "Sicily",
        "country_name": "Italy"},
    {
        "name": "Siena",
        "country_name": "Italy"},
    {
        "name": "Siracusa",
        "country_name": "Italy"},
    {
        "name": "Sondrio",
        "country_name": "Italy"},
    {
        "name": "South Sardinia",
        "country_name": "Italy"},
    {
        "name": "Taranto",
        "country_name": "Italy"},
    {
        "name": "Teramo",
        "country_name": "Italy"},
    {
        "name": "Terni",
        "country_name": "Italy"},
    {
        "name": "Trapani",
        "country_name": "Italy"},
    {
        "name": "Trentino-South Tyrol",
        "country_name": "Italy"},
    {
        "name": "Treviso",
        "country_name": "Italy"},
    {
        "name": "Trieste",
        "country_name": "Italy"},
    {
        "name": "Tuscany",
        "country_name": "Italy"},
    {
        "name": "Udine",
        "country_name": "Italy"},
    {
        "name": "Umbria",
        "country_name": "Italy"},
    {
        "name": "Varese",
        "country_name": "Italy"},
    {
        "name": "Veneto",
        "country_name": "Italy"},
    {
        "name": "Verbano-Cusio-Ossola",
        "country_name": "Italy"},
    {
        "name": "Vercelli",
        "country_name": "Italy"},
    {
        "name": "Verona",
        "country_name": "Italy"},
    {
        "name": "Vibo Valentia",
        "country_name": "Italy"},
    {
        "name": "Vicenza",
        "country_name": "Italy"},
    {
        "name": "Viterbo",
        "country_name": "Italy"},
    {
        "name": "Clarendon Parish",
        "country_name": "Jamaica"},
    {
        "name": "Hanover Parish",
        "country_name": "Jamaica"},
    {
        "name": "Kingston Parish",
        "country_name": "Jamaica"},
    {
        "name": "Manchester Parish",
        "country_name": "Jamaica"},
    {
        "name": "Portland Parish",
        "country_name": "Jamaica"},
    {
        "name": "Saint Andrew",
        "country_name": "Jamaica"},
    {
        "name": "Saint Ann Parish",
        "country_name": "Jamaica"},
    {
        "name": "Saint Catherine Parish",
        "country_name": "Jamaica"},
    {
        "name": "Saint Elizabeth Parish",
        "country_name": "Jamaica"},
    {
        "name": "Saint James Parish",
        "country_name": "Jamaica"},
    {
        "name": "Saint Mary Parish",
        "country_name": "Jamaica"},
    {
        "name": "Saint Thomas Parish",
        "country_name": "Jamaica"},
    {
        "name": "Trelawny Parish",
        "country_name": "Jamaica"},
    {
        "name": "Westmoreland Parish",
        "country_name": "Jamaica"},
    {
        "name": "Aichi Prefecture",
        "country_name": "Japan"},
    {
        "name": "Akita Prefecture",
        "country_name": "Japan"},
    {
        "name": "Aomori Prefecture",
        "country_name": "Japan"},
    {
        "name": "Chiba Prefecture",
        "country_name": "Japan"},
    {
        "name": "Ehime Prefecture",
        "country_name": "Japan"},
    {
        "name": "Fukui Prefecture",
        "country_name": "Japan"},
    {
        "name": "Fukuoka Prefecture",
        "country_name": "Japan"},
    {
        "name": "Fukushima Prefecture",
        "country_name": "Japan"},
    {
        "name": "Gifu Prefecture",
        "country_name": "Japan"},
    {
        "name": "Gunma Prefecture",
        "country_name": "Japan"},
    {
        "name": "Hiroshima Prefecture",
        "country_name": "Japan"},
    {
        "name": "Hokkaidō Prefecture",
        "country_name": "Japan"},
    {
        "name": "Hyōgo Prefecture",
        "country_name": "Japan"},
    {
        "name": "Ibaraki Prefecture",
        "country_name": "Japan"},
    {
        "name": "Ishikawa Prefecture",
        "country_name": "Japan"},
    {
        "name": "Iwate Prefecture",
        "country_name": "Japan"},
    {
        "name": "Kagawa Prefecture",
        "country_name": "Japan"},
    {
        "name": "Kagoshima Prefecture",
        "country_name": "Japan"},
    {
        "name": "Kanagawa Prefecture",
        "country_name": "Japan"},
    {
        "name": "Kōchi Prefecture",
        "country_name": "Japan"},
    {
        "name": "Kumamoto Prefecture",
        "country_name": "Japan"},
    {
        "name": "Kyōto Prefecture",
        "country_name": "Japan"},
    {
        "name": "Mie Prefecture",
        "country_name": "Japan"},
    {
        "name": "Miyagi Prefecture",
        "country_name": "Japan"},
    {
        "name": "Miyazaki Prefecture",
        "country_name": "Japan"},
    {
        "name": "Nagano Prefecture",
        "country_name": "Japan"},
    {
        "name": "Nagasaki Prefecture",
        "country_name": "Japan"},
    {
        "name": "Nara Prefecture",
        "country_name": "Japan"},
    {
        "name": "Niigata Prefecture",
        "country_name": "Japan"},
    {
        "name": "Ōita Prefecture",
        "country_name": "Japan"},
    {
        "name": "Okayama Prefecture",
        "country_name": "Japan"},
    {
        "name": "Okinawa Prefecture",
        "country_name": "Japan"},
    {
        "name": "Ōsaka Prefecture",
        "country_name": "Japan"},
    {
        "name": "Saga Prefecture",
        "country_name": "Japan"},
    {
        "name": "Saitama Prefecture",
        "country_name": "Japan"},
    {
        "name": "Shiga Prefecture",
        "country_name": "Japan"},
    {
        "name": "Shimane Prefecture",
        "country_name": "Japan"},
    {
        "name": "Shizuoka Prefecture",
        "country_name": "Japan"},
    {
        "name": "Tochigi Prefecture",
        "country_name": "Japan"},
    {
        "name": "Tokushima Prefecture",
        "country_name": "Japan"},
    {
        "name": "Tokyo",
        "country_name": "Japan"},
    {
        "name": "Tottori Prefecture",
        "country_name": "Japan"},
    {
        "name": "Toyama Prefecture",
        "country_name": "Japan"},
    {
        "name": "Wakayama Prefecture",
        "country_name": "Japan"},
    {
        "name": "Yamagata Prefecture",
        "country_name": "Japan"},
    {
        "name": "Yamaguchi Prefecture",
        "country_name": "Japan"},
    {
        "name": "Yamanashi Prefecture",
        "country_name": "Japan"},
    {
        "name": "Ajloun",
        "country_name": "Jordan"},
    {
        "name": "Amman",
        "country_name": "Jordan"},
    {
        "name": "Aqaba",
        "country_name": "Jordan"},
    {
        "name": "Balqa",
        "country_name": "Jordan"},
    {
        "name": "Irbid",
        "country_name": "Jordan"},
    {
        "name": "Jerash",
        "country_name": "Jordan"},
    {
        "name": "Karak",
        "country_name": "Jordan"},
    {
        "name": "Ma'an",
        "country_name": "Jordan"},
    {
        "name": "Madaba",
        "country_name": "Jordan"},
    {
        "name": "Mafraq",
        "country_name": "Jordan"},
    {
        "name": "Tafilah",
        "country_name": "Jordan"},
    {
        "name": "Zarqa",
        "country_name": "Jordan"},
    {
        "name": "Akmola Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Aktobe Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Almaty",
        "country_name": "Kazakhstan"},
    {
        "name": "Almaty Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Atyrau Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Baikonur",
        "country_name": "Kazakhstan"},
    {
        "name": "East Kazakhstan Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Jambyl Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Karaganda Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Kostanay Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Kyzylorda Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Mangystau Region",
        "country_name": "Kazakhstan"},
    {
        "name": "North Kazakhstan Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Nur-Sultan",
        "country_name": "Kazakhstan"},
    {
        "name": "Pavlodar Region",
        "country_name": "Kazakhstan"},
    {
        "name": "Turkestan Region",
        "country_name": "Kazakhstan"},
    {
        "name": "West Kazakhstan Province",
        "country_name": "Kazakhstan"},
    {
        "name": "Baringo",
        "country_name": "Kenya"},
    {
        "name": "Bomet",
        "country_name": "Kenya"},
    {
        "name": "Bungoma",
        "country_name": "Kenya"},
    {
        "name": "Busia",
        "country_name": "Kenya"},
    {
        "name": "Elgeyo-Marakwet",
        "country_name": "Kenya"},
    {
        "name": "Embu",
        "country_name": "Kenya"},
    {
        "name": "Garissa",
        "country_name": "Kenya"},
    {
        "name": "Homa Bay",
        "country_name": "Kenya"},
    {
        "name": "Isiolo",
        "country_name": "Kenya"},
    {
        "name": "Kajiado",
        "country_name": "Kenya"},
    {
        "name": "Kakamega",
        "country_name": "Kenya"},
    {
        "name": "Kericho",
        "country_name": "Kenya"},
    {
        "name": "Kiambu",
        "country_name": "Kenya"},
    {
        "name": "Kilifi",
        "country_name": "Kenya"},
    {
        "name": "Kirinyaga",
        "country_name": "Kenya"},
    {
        "name": "Kisii",
        "country_name": "Kenya"},
    {
        "name": "Kisumu",
        "country_name": "Kenya"},
    {
        "name": "Kitui",
        "country_name": "Kenya"},
    {
        "name": "Kwale",
        "country_name": "Kenya"},
    {
        "name": "Laikipia",
        "country_name": "Kenya"},
    {
        "name": "Lamu",
        "country_name": "Kenya"},
    {
        "name": "Machakos",
        "country_name": "Kenya"},
    {
        "name": "Makueni",
        "country_name": "Kenya"},
    {
        "name": "Mandera",
        "country_name": "Kenya"},
    {
        "name": "Marsabit",
        "country_name": "Kenya"},
    {
        "name": "Meru",
        "country_name": "Kenya"},
    {
        "name": "Migori",
        "country_name": "Kenya"},
    {
        "name": "Mombasa",
        "country_name": "Kenya"},
    {
        "name": "Murang'a",
        "country_name": "Kenya"},
    {
        "name": "Nairobi City",
        "country_name": "Kenya"},
    {
        "name": "Nakuru",
        "country_name": "Kenya"},
    {
        "name": "Nandi",
        "country_name": "Kenya"},
    {
        "name": "Narok",
        "country_name": "Kenya"},
    {
        "name": "Nyamira",
        "country_name": "Kenya"},
    {
        "name": "Nyandarua",
        "country_name": "Kenya"},
    {
        "name": "Nyeri",
        "country_name": "Kenya"},
    {
        "name": "Samburu",
        "country_name": "Kenya"},
    {
        "name": "Siaya",
        "country_name": "Kenya"},
    {
        "name": "Taita–Taveta",
        "country_name": "Kenya"},
    {
        "name": "Tana River",
        "country_name": "Kenya"},
    {
        "name": "Tharaka-Nithi",
        "country_name": "Kenya"},
    {
        "name": "Trans Nzoia",
        "country_name": "Kenya"},
    {
        "name": "Turkana",
        "country_name": "Kenya"},
    {
        "name": "Uasin Gishu",
        "country_name": "Kenya"},
    {
        "name": "Vihiga",
        "country_name": "Kenya"},
    {
        "name": "Wajir",
        "country_name": "Kenya"},
    {
        "name": "West Pokot",
        "country_name": "Kenya"},
    {
        "name": "Gilbert Islands",
        "country_name": "Kiribati"},
    {
        "name": "Line Islands",
        "country_name": "Kiribati"},
    {
        "name": "Phoenix Islands",
        "country_name": "Kiribati"},
    {
        "name": "Đakovica District (Gjakove)",
        "country_name": "Kosovo"},
    {
        "name": "Gjilan District",
        "country_name": "Kosovo"},
    {
        "name": "Kosovska Mitrovica District",
        "country_name": "Kosovo"},
    {
        "name": "Peć District",
        "country_name": "Kosovo"},
    {
        "name": "Pristina (Priştine)",
        "country_name": "Kosovo"},
    {
        "name": "Prizren District",
        "country_name": "Kosovo"},
    {
        "name": "Uroševac District (Ferizaj)",
        "country_name": "Kosovo"},
    {
        "name": "Al Ahmadi",
        "country_name": "Kuwait"},
    {
        "name": "Al Farwaniyah",
        "country_name": "Kuwait"},
    {
        "name": "Al Jahra",
        "country_name": "Kuwait"},
    {
        "name": "Capital",
        "country_name": "Kuwait"},
    {
        "name": "Hawalli",
        "country_name": "Kuwait"},
    {
        "name": "Mubarak Al-Kabeer",
        "country_name": "Kuwait"},
    {
        "name": "Batken Region",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Bishkek",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Chuy Region",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Issyk-Kul Region",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Jalal-Abad Region",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Naryn Region",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Osh",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Osh Region",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Talas Region",
        "country_name": "Kyrgyzstan"},
    {
        "name": "Attapeu Province",
        "country_name": "Laos"},
    {
        "name": "Bokeo Province",
        "country_name": "Laos"},
    {
        "name": "Bolikhamsai Province",
        "country_name": "Laos"},
    {
        "name": "Champasak Province",
        "country_name": "Laos"},
    {
        "name": "Houaphanh Province",
        "country_name": "Laos"},
    {
        "name": "Khammouane Province",
        "country_name": "Laos"},
    {
        "name": "Luang Namtha Province",
        "country_name": "Laos"},
    {
        "name": "Luang Prabang Province",
        "country_name": "Laos"},
    {
        "name": "Oudomxay Province",
        "country_name": "Laos"},
    {
        "name": "Phongsaly Province",
        "country_name": "Laos"},
    {
        "name": "Sainyabuli Province",
        "country_name": "Laos"},
    {
        "name": "Salavan Province",
        "country_name": "Laos"},
    {
        "name": "Savannakhet Province",
        "country_name": "Laos"},
    {
        "name": "Sekong Province",
        "country_name": "Laos"},
    {
        "name": "Vientiane Prefecture",
        "country_name": "Laos"},
    {
        "name": "Vientiane Province",
        "country_name": "Laos"},
    {
        "name": "Xaisomboun",
        "country_name": "Laos"},
    {
        "name": "Xaisomboun Province",
        "country_name": "Laos"},
    {
        "name": "Xiangkhouang Province",
        "country_name": "Laos"},
    {
        "name": "Aglona Municipality",
        "country_name": "Latvia"},
    {
        "name": "Aizkraukle Municipality",
        "country_name": "Latvia"},
    {
        "name": "Aizpute Municipality",
        "country_name": "Latvia"},
    {
        "name": "Aknīste Municipality",
        "country_name": "Latvia"},
    {
        "name": "Aloja Municipality",
        "country_name": "Latvia"},
    {
        "name": "Alsunga Municipality",
        "country_name": "Latvia"},
    {
        "name": "Alūksne Municipality",
        "country_name": "Latvia"},
    {
        "name": "Amata Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ape Municipality",
        "country_name": "Latvia"},
    {
        "name": "Auce Municipality",
        "country_name": "Latvia"},
    {
        "name": "Babīte Municipality",
        "country_name": "Latvia"},
    {
        "name": "Baldone Municipality",
        "country_name": "Latvia"},
    {
        "name": "Baltinava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Balvi Municipality",
        "country_name": "Latvia"},
    {
        "name": "Bauska Municipality",
        "country_name": "Latvia"},
    {
        "name": "Beverīna Municipality",
        "country_name": "Latvia"},
    {
        "name": "Brocēni Municipality",
        "country_name": "Latvia"},
    {
        "name": "Burtnieki Municipality",
        "country_name": "Latvia"},
    {
        "name": "Carnikava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Cēsis Municipality",
        "country_name": "Latvia"},
    {
        "name": "Cesvaine Municipality",
        "country_name": "Latvia"},
    {
        "name": "Cibla Municipality",
        "country_name": "Latvia"},
    {
        "name": "Dagda Municipality",
        "country_name": "Latvia"},
    {
        "name": "Daugavpils",
        "country_name": "Latvia"},
    {
        "name": "Daugavpils Municipality",
        "country_name": "Latvia"},
    {
        "name": "Dobele Municipality",
        "country_name": "Latvia"},
    {
        "name": "Dundaga Municipality",
        "country_name": "Latvia"},
    {
        "name": "Durbe Municipality",
        "country_name": "Latvia"},
    {
        "name": "Engure Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ērgļi Municipality",
        "country_name": "Latvia"},
    {
        "name": "Garkalne Municipality",
        "country_name": "Latvia"},
    {
        "name": "Grobiņa Municipality",
        "country_name": "Latvia"},
    {
        "name": "Gulbene Municipality",
        "country_name": "Latvia"},
    {
        "name": "Iecava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ikšķile Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ilūkste Municipality",
        "country_name": "Latvia"},
    {
        "name": "Inčukalns Municipality",
        "country_name": "Latvia"},
    {
        "name": "Jaunjelgava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Jaunpiebalga Municipality",
        "country_name": "Latvia"},
    {
        "name": "Jaunpils Municipality",
        "country_name": "Latvia"},
    {
        "name": "Jēkabpils",
        "country_name": "Latvia"},
    {
        "name": "Jēkabpils Municipality",
        "country_name": "Latvia"},
    {
        "name": "Jelgava",
        "country_name": "Latvia"},
    {
        "name": "Jelgava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Jūrmala",
        "country_name": "Latvia"},
    {
        "name": "Kandava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Kārsava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ķegums Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ķekava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Kocēni Municipality",
        "country_name": "Latvia"},
    {
        "name": "Koknese Municipality",
        "country_name": "Latvia"},
    {
        "name": "Krāslava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Krimulda Municipality",
        "country_name": "Latvia"},
    {
        "name": "Krustpils Municipality",
        "country_name": "Latvia"},
    {
        "name": "Kuldīga Municipality",
        "country_name": "Latvia"},
    {
        "name": "Lielvārde Municipality",
        "country_name": "Latvia"},
    {
        "name": "Liepāja",
        "country_name": "Latvia"},
    {
        "name": "Līgatne Municipality",
        "country_name": "Latvia"},
    {
        "name": "Limbaži Municipality",
        "country_name": "Latvia"},
    {
        "name": "Līvāni Municipality",
        "country_name": "Latvia"},
    {
        "name": "Lubāna Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ludza Municipality",
        "country_name": "Latvia"},
    {
        "name": "Madona Municipality",
        "country_name": "Latvia"},
    {
        "name": "Mālpils Municipality",
        "country_name": "Latvia"},
    {
        "name": "Mārupe Municipality",
        "country_name": "Latvia"},
    {
        "name": "Mazsalaca Municipality",
        "country_name": "Latvia"},
    {
        "name": "Mērsrags Municipality",
        "country_name": "Latvia"},
    {
        "name": "Naukšēni Municipality",
        "country_name": "Latvia"},
    {
        "name": "Nereta Municipality",
        "country_name": "Latvia"},
    {
        "name": "Nīca Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ogre Municipality",
        "country_name": "Latvia"},
    {
        "name": "Olaine Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ozolnieki Municipality",
        "country_name": "Latvia"},
    {
        "name": "Pārgauja Municipality",
        "country_name": "Latvia"},
    {
        "name": "Pāvilosta Municipality",
        "country_name": "Latvia"},
    {
        "name": "Pļaviņas Municipality",
        "country_name": "Latvia"},
    {
        "name": "Preiļi Municipality",
        "country_name": "Latvia"},
    {
        "name": "Priekule Municipality",
        "country_name": "Latvia"},
    {
        "name": "Priekuļi Municipality",
        "country_name": "Latvia"},
    {
        "name": "Rauna Municipality",
        "country_name": "Latvia"},
    {
        "name": "Rēzekne",
        "country_name": "Latvia"},
    {
        "name": "Rēzekne Municipality",
        "country_name": "Latvia"},
    {
        "name": "Riebiņi Municipality",
        "country_name": "Latvia"},
    {
        "name": "Riga",
        "country_name": "Latvia"},
    {
        "name": "Roja Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ropaži Municipality",
        "country_name": "Latvia"},
    {
        "name": "Rucava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Rugāji Municipality",
        "country_name": "Latvia"},
    {
        "name": "Rūjiena Municipality",
        "country_name": "Latvia"},
    {
        "name": "Rundāle Municipality",
        "country_name": "Latvia"},
    {
        "name": "Sala Municipality",
        "country_name": "Latvia"},
    {
        "name": "Salacgrīva Municipality",
        "country_name": "Latvia"},
    {
        "name": "Salaspils Municipality",
        "country_name": "Latvia"},
    {
        "name": "Saldus Municipality",
        "country_name": "Latvia"},
    {
        "name": "Saulkrasti Municipality",
        "country_name": "Latvia"},
    {
        "name": "Sēja Municipality",
        "country_name": "Latvia"},
    {
        "name": "Sigulda Municipality",
        "country_name": "Latvia"},
    {
        "name": "Skrīveri Municipality",
        "country_name": "Latvia"},
    {
        "name": "Skrunda Municipality",
        "country_name": "Latvia"},
    {
        "name": "Smiltene Municipality",
        "country_name": "Latvia"},
    {
        "name": "Stopiņi Municipality",
        "country_name": "Latvia"},
    {
        "name": "Strenči Municipality",
        "country_name": "Latvia"},
    {
        "name": "Talsi Municipality",
        "country_name": "Latvia"},
    {
        "name": "Tērvete Municipality",
        "country_name": "Latvia"},
    {
        "name": "Tukums Municipality",
        "country_name": "Latvia"},
    {
        "name": "Vaiņode Municipality",
        "country_name": "Latvia"},
    {
        "name": "Valka Municipality",
        "country_name": "Latvia"},
    {
        "name": "Valmiera",
        "country_name": "Latvia"},
    {
        "name": "Varakļāni Municipality",
        "country_name": "Latvia"},
    {
        "name": "Vārkava Municipality",
        "country_name": "Latvia"},
    {
        "name": "Vecpiebalga Municipality",
        "country_name": "Latvia"},
    {
        "name": "Vecumnieki Municipality",
        "country_name": "Latvia"},
    {
        "name": "Ventspils",
        "country_name": "Latvia"},
    {
        "name": "Ventspils Municipality",
        "country_name": "Latvia"},
    {
        "name": "Viesīte Municipality",
        "country_name": "Latvia"},
    {
        "name": "Viļaka Municipality",
        "country_name": "Latvia"},
    {
        "name": "Viļāni Municipality",
        "country_name": "Latvia"},
    {
        "name": "Zilupe Municipality",
        "country_name": "Latvia"},
    {
        "name": "Akkar",
        "country_name": "Lebanon"},
    {
        "name": "Baalbek-Hermel",
        "country_name": "Lebanon"},
    {
        "name": "Beirut",
        "country_name": "Lebanon"},
    {
        "name": "Beqaa",
        "country_name": "Lebanon"},
    {
        "name": "Mount Lebanon",
        "country_name": "Lebanon"},
    {
        "name": "Nabatieh",
        "country_name": "Lebanon"},
    {
        "name": "North",
        "country_name": "Lebanon"},
    {
        "name": "South",
        "country_name": "Lebanon"},
    {
        "name": "Berea District",
        "country_name": "Lesotho"},
    {
        "name": "Butha-Buthe District",
        "country_name": "Lesotho"},
    {
        "name": "Leribe District",
        "country_name": "Lesotho"},
    {
        "name": "Mafeteng District",
        "country_name": "Lesotho"},
    {
        "name": "Maseru District",
        "country_name": "Lesotho"},
    {
        "name": "Mohale's Hoek District",
        "country_name": "Lesotho"},
    {
        "name": "Mokhotlong District",
        "country_name": "Lesotho"},
    {
        "name": "Qacha's Nek District",
        "country_name": "Lesotho"},
    {
        "name": "Quthing District",
        "country_name": "Lesotho"},
    {
        "name": "Thaba-Tseka District",
        "country_name": "Lesotho"},
    {
        "name": "Bomi County",
        "country_name": "Liberia"},
    {
        "name": "Bong County",
        "country_name": "Liberia"},
    {
        "name": "Gbarpolu County",
        "country_name": "Liberia"},
    {
        "name": "Grand Bassa County",
        "country_name": "Liberia"},
    {
        "name": "Grand Cape Mount County",
        "country_name": "Liberia"},
    {
        "name": "Grand Gedeh County",
        "country_name": "Liberia"},
    {
        "name": "Grand Kru County",
        "country_name": "Liberia"},
    {
        "name": "Lofa County",
        "country_name": "Liberia"},
    {
        "name": "Margibi County",
        "country_name": "Liberia"},
    {
        "name": "Maryland County",
        "country_name": "Liberia"},
    {
        "name": "Montserrado County",
        "country_name": "Liberia"},
    {
        "name": "Nimba",
        "country_name": "Liberia"},
    {
        "name": "River Cess County",
        "country_name": "Liberia"},
    {
        "name": "River Gee County",
        "country_name": "Liberia"},
    {
        "name": "Sinoe County",
        "country_name": "Liberia"},
    {
        "name": "Al Wahat District",
        "country_name": "Libya"},
    {
        "name": "Benghazi",
        "country_name": "Libya"},
    {
        "name": "Derna District",
        "country_name": "Libya"},
    {
        "name": "Ghat District",
        "country_name": "Libya"},
    {
        "name": "Jabal al Akhdar",
        "country_name": "Libya"},
    {
        "name": "Jabal al Gharbi District",
        "country_name": "Libya"},
    {
        "name": "Jafara",
        "country_name": "Libya"},
    {
        "name": "Jufra",
        "country_name": "Libya"},
    {
        "name": "Kufra District",
        "country_name": "Libya"},
    {
        "name": "Marj District",
        "country_name": "Libya"},
    {
        "name": "Misrata District",
        "country_name": "Libya"},
    {
        "name": "Murqub",
        "country_name": "Libya"},
    {
        "name": "Murzuq District",
        "country_name": "Libya"},
    {
        "name": "Nalut District",
        "country_name": "Libya"},
    {
        "name": "Nuqat al Khams",
        "country_name": "Libya"},
    {
        "name": "Sabha District",
        "country_name": "Libya"},
    {
        "name": "Sirte District",
        "country_name": "Libya"},
    {
        "name": "Tripoli District",
        "country_name": "Libya"},
    {
        "name": "Wadi al Hayaa District",
        "country_name": "Libya"},
    {
        "name": "Wadi al Shatii District",
        "country_name": "Libya"},
    {
        "name": "Zawiya District",
        "country_name": "Libya"},
    {
        "name": "Balzers",
        "country_name": "Liechtenstein"},
    {
        "name": "Eschen",
        "country_name": "Liechtenstein"},
    {
        "name": "Gamprin",
        "country_name": "Liechtenstein"},
    {
        "name": "Mauren",
        "country_name": "Liechtenstein"},
    {
        "name": "Planken",
        "country_name": "Liechtenstein"},
    {
        "name": "Ruggell",
        "country_name": "Liechtenstein"},
    {
        "name": "Schaan",
        "country_name": "Liechtenstein"},
    {
        "name": "Schellenberg",
        "country_name": "Liechtenstein"},
    {
        "name": "Triesen",
        "country_name": "Liechtenstein"},
    {
        "name": "Triesenberg",
        "country_name": "Liechtenstein"},
    {
        "name": "Vaduz",
        "country_name": "Liechtenstein"},
    {
        "name": "Akmenė District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Alytus City Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Alytus County",
        "country_name": "Lithuania"},
    {
        "name": "Alytus District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Birštonas Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Biržai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Druskininkai municipality",
        "country_name": "Lithuania"},
    {
        "name": "Elektrėnai municipality",
        "country_name": "Lithuania"},
    {
        "name": "Ignalina District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Jonava District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Joniškis District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Jurbarkas District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kaišiadorys District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kalvarija municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kaunas City Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kaunas County",
        "country_name": "Lithuania"},
    {
        "name": "Kaunas District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kazlų Rūda municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kėdainiai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kelmė District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Klaipeda City Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Klaipėda County",
        "country_name": "Lithuania"},
    {
        "name": "Klaipėda District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kretinga District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Kupiškis District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Lazdijai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Marijampolė County",
        "country_name": "Lithuania"},
    {
        "name": "Marijampolė Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Mažeikiai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Molėtai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Neringa Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Pagėgiai municipality",
        "country_name": "Lithuania"},
    {
        "name": "Pakruojis District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Palanga City Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Panevėžys City Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Panevėžys County",
        "country_name": "Lithuania"},
    {
        "name": "Panevėžys District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Pasvalys District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Plungė District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Prienai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Radviliškis District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Raseiniai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Rietavas municipality",
        "country_name": "Lithuania"},
    {
        "name": "Rokiškis District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Šakiai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Šalčininkai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Šiauliai City Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Šiauliai County",
        "country_name": "Lithuania"},
    {
        "name": "Šiauliai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Šilalė District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Šilutė District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Širvintos District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Skuodas District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Švenčionys District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Tauragė County",
        "country_name": "Lithuania"},
    {
        "name": "Tauragė District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Telšiai County",
        "country_name": "Lithuania"},
    {
        "name": "Telšiai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Trakai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Ukmergė District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Utena County",
        "country_name": "Lithuania"},
    {
        "name": "Utena District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Varėna District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Vilkaviškis District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Vilnius City Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Vilnius County",
        "country_name": "Lithuania"},
    {
        "name": "Vilnius District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Visaginas Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Zarasai District Municipality",
        "country_name": "Lithuania"},
    {
        "name": "Canton of Capellen",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Clervaux",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Diekirch",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Echternach",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Esch-sur-Alzette",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Grevenmacher",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Luxembourg",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Mersch",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Redange",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Remich",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Vianden",
        "country_name": "Luxembourg"},
    {
        "name": "Canton of Wiltz",
        "country_name": "Luxembourg"},
    {
        "name": "Diekirch District",
        "country_name": "Luxembourg"},
    {
        "name": "Grevenmacher District",
        "country_name": "Luxembourg"},
    {
        "name": "Luxembourg District",
        "country_name": "Luxembourg"},
    {
        "name": "Antananarivo Province",
        "country_name": "Madagascar"},
    {
        "name": "Antsiranana Province",
        "country_name": "Madagascar"},
    {
        "name": "Fianarantsoa Province",
        "country_name": "Madagascar"},
    {
        "name": "Mahajanga Province",
        "country_name": "Madagascar"},
    {
        "name": "Toamasina Province",
        "country_name": "Madagascar"},
    {
        "name": "Toliara Province",
        "country_name": "Madagascar"},
    {
        "name": "Balaka District",
        "country_name": "Malawi"},
    {
        "name": "Blantyre District",
        "country_name": "Malawi"},
    {
        "name": "Central Region",
        "country_name": "Malawi"},
    {
        "name": "Chikwawa District",
        "country_name": "Malawi"},
    {
        "name": "Chiradzulu District",
        "country_name": "Malawi"},
    {
        "name": "Chitipa district",
        "country_name": "Malawi"},
    {
        "name": "Dedza District",
        "country_name": "Malawi"},
    {
        "name": "Dowa District",
        "country_name": "Malawi"},
    {
        "name": "Karonga District",
        "country_name": "Malawi"},
    {
        "name": "Kasungu District",
        "country_name": "Malawi"},
    {
        "name": "Likoma District",
        "country_name": "Malawi"},
    {
        "name": "Lilongwe District",
        "country_name": "Malawi"},
    {
        "name": "Machinga District",
        "country_name": "Malawi"},
    {
        "name": "Mangochi District",
        "country_name": "Malawi"},
    {
        "name": "Mchinji District",
        "country_name": "Malawi"},
    {
        "name": "Mulanje District",
        "country_name": "Malawi"},
    {
        "name": "Mwanza District",
        "country_name": "Malawi"},
    {
        "name": "Mzimba District",
        "country_name": "Malawi"},
    {
        "name": "Nkhata Bay District",
        "country_name": "Malawi"},
    {
        "name": "Nkhotakota District",
        "country_name": "Malawi"},
    {
        "name": "Northern Region",
        "country_name": "Malawi"},
    {
        "name": "Nsanje District",
        "country_name": "Malawi"},
    {
        "name": "Ntcheu District",
        "country_name": "Malawi"},
    {
        "name": "Ntchisi District",
        "country_name": "Malawi"},
    {
        "name": "Phalombe District",
        "country_name": "Malawi"},
    {
        "name": "Rumphi District",
        "country_name": "Malawi"},
    {
        "name": "Salima District",
        "country_name": "Malawi"},
    {
        "name": "Southern Region",
        "country_name": "Malawi"},
    {
        "name": "Thyolo District",
        "country_name": "Malawi"},
    {
        "name": "Zomba District",
        "country_name": "Malawi"},
    {
        "name": "Johor",
        "country_name": "Malaysia"},
    {
        "name": "Kedah",
        "country_name": "Malaysia"},
    {
        "name": "Kelantan",
        "country_name": "Malaysia"},
    {
        "name": "Kuala Lumpur",
        "country_name": "Malaysia"},
    {
        "name": "Labuan",
        "country_name": "Malaysia"},
    {
        "name": "Malacca",
        "country_name": "Malaysia"},
    {
        "name": "Negeri Sembilan",
        "country_name": "Malaysia"},
    {
        "name": "Pahang",
        "country_name": "Malaysia"},
    {
        "name": "Penang",
        "country_name": "Malaysia"},
    {
        "name": "Perak",
        "country_name": "Malaysia"},
    {
        "name": "Perlis",
        "country_name": "Malaysia"},
    {
        "name": "Putrajaya",
        "country_name": "Malaysia"},
    {
        "name": "Sabah",
        "country_name": "Malaysia"},
    {
        "name": "Sarawak",
        "country_name": "Malaysia"},
    {
        "name": "Selangor",
        "country_name": "Malaysia"},
    {
        "name": "Terengganu",
        "country_name": "Malaysia"},
    {
        "name": "Addu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Alif Alif Atoll",
        "country_name": "Maldives"},
    {
        "name": "Alif Dhaal Atoll",
        "country_name": "Maldives"},
    {
        "name": "Central Province",
        "country_name": "Maldives"},
    {
        "name": "Dhaalu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Faafu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Gaafu Alif Atoll",
        "country_name": "Maldives"},
    {
        "name": "Gaafu Dhaalu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Gnaviyani Atoll",
        "country_name": "Maldives"},
    {
        "name": "Haa Alif Atoll",
        "country_name": "Maldives"},
    {
        "name": "Haa Dhaalu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Kaafu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Laamu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Lhaviyani Atoll",
        "country_name": "Maldives"},
    {
        "name": "Malé",
        "country_name": "Maldives"},
    {
        "name": "Meemu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Noonu Atoll",
        "country_name": "Maldives"},
    {
        "name": "North Central Province",
        "country_name": "Maldives"},
    {
        "name": "North Province",
        "country_name": "Maldives"},
    {
        "name": "Raa Atoll",
        "country_name": "Maldives"},
    {
        "name": "Shaviyani Atoll",
        "country_name": "Maldives"},
    {
        "name": "South Central Province",
        "country_name": "Maldives"},
    {
        "name": "South Province",
        "country_name": "Maldives"},
    {
        "name": "Thaa Atoll",
        "country_name": "Maldives"},
    {
        "name": "Upper South Province",
        "country_name": "Maldives"},
    {
        "name": "Vaavu Atoll",
        "country_name": "Maldives"},
    {
        "name": "Bamako",
        "country_name": "Mali"},
    {
        "name": "Gao Region",
        "country_name": "Mali"},
    {
        "name": "Kayes Region",
        "country_name": "Mali"},
    {
        "name": "Kidal Region",
        "country_name": "Mali"},
    {
        "name": "Koulikoro Region",
        "country_name": "Mali"},
    {
        "name": "Ménaka Region",
        "country_name": "Mali"},
    {
        "name": "Mopti Region",
        "country_name": "Mali"},
    {
        "name": "Ségou Region",
        "country_name": "Mali"},
    {
        "name": "Sikasso Region",
        "country_name": "Mali"},
    {
        "name": "Taoudénit Region",
        "country_name": "Mali"},
    {
        "name": "Tombouctou Region",
        "country_name": "Mali"},
    {
        "name": "Attard",
        "country_name": "Malta"},
    {
        "name": "Balzan",
        "country_name": "Malta"},
    {
        "name": "Birgu",
        "country_name": "Malta"},
    {
        "name": "Birkirkara",
        "country_name": "Malta"},
    {
        "name": "Birżebbuġa",
        "country_name": "Malta"},
    {
        "name": "Cospicua",
        "country_name": "Malta"},
    {
        "name": "Dingli",
        "country_name": "Malta"},
    {
        "name": "Fgura",
        "country_name": "Malta"},
    {
        "name": "Floriana",
        "country_name": "Malta"},
    {
        "name": "Fontana",
        "country_name": "Malta"},
    {
        "name": "Għajnsielem",
        "country_name": "Malta"},
    {
        "name": "Għarb",
        "country_name": "Malta"},
    {
        "name": "Għargħur",
        "country_name": "Malta"},
    {
        "name": "Għasri",
        "country_name": "Malta"},
    {
        "name": "Għaxaq",
        "country_name": "Malta"},
    {
        "name": "Gudja",
        "country_name": "Malta"},
    {
        "name": "Gżira",
        "country_name": "Malta"},
    {
        "name": "Ħamrun",
        "country_name": "Malta"},
    {
        "name": "Iklin",
        "country_name": "Malta"},
    {
        "name": "Kalkara",
        "country_name": "Malta"},
    {
        "name": "Kerċem",
        "country_name": "Malta"},
    {
        "name": "Kirkop",
        "country_name": "Malta"},
    {
        "name": "Lija",
        "country_name": "Malta"},
    {
        "name": "Luqa",
        "country_name": "Malta"},
    {
        "name": "Marsa",
        "country_name": "Malta"},
    {
        "name": "Marsaskala",
        "country_name": "Malta"},
    {
        "name": "Marsaxlokk",
        "country_name": "Malta"},
    {
        "name": "Mdina",
        "country_name": "Malta"},
    {
        "name": "Mellieħa",
        "country_name": "Malta"},
    {
        "name": "Mġarr",
        "country_name": "Malta"},
    {
        "name": "Mosta",
        "country_name": "Malta"},
    {
        "name": "Mqabba",
        "country_name": "Malta"},
    {
        "name": "Msida",
        "country_name": "Malta"},
    {
        "name": "Mtarfa",
        "country_name": "Malta"},
    {
        "name": "Munxar",
        "country_name": "Malta"},
    {
        "name": "Nadur",
        "country_name": "Malta"},
    {
        "name": "Naxxar",
        "country_name": "Malta"},
    {
        "name": "Paola",
        "country_name": "Malta"},
    {
        "name": "Pembroke",
        "country_name": "Malta"},
    {
        "name": "Pietà",
        "country_name": "Malta"},
    {
        "name": "Qala",
        "country_name": "Malta"},
    {
        "name": "Qormi",
        "country_name": "Malta"},
    {
        "name": "Qrendi",
        "country_name": "Malta"},
    {
        "name": "Rabat",
        "country_name": "Malta"},
    {
        "name": "Saint Lawrence",
        "country_name": "Malta"},
    {
        "name": "San Ġwann",
        "country_name": "Malta"},
    {
        "name": "Sannat",
        "country_name": "Malta"},
    {
        "name": "Santa Luċija",
        "country_name": "Malta"},
    {
        "name": "Santa Venera",
        "country_name": "Malta"},
    {
        "name": "Senglea",
        "country_name": "Malta"},
    {
        "name": "Siġġiewi",
        "country_name": "Malta"},
    {
        "name": "Sliema",
        "country_name": "Malta"},
    {
        "name": "St. Julian's",
        "country_name": "Malta"},
    {
        "name": "St. Paul's Bay",
        "country_name": "Malta"},
    {
        "name": "Swieqi",
        "country_name": "Malta"},
    {
        "name": "Ta' Xbiex",
        "country_name": "Malta"},
    {
        "name": "Tarxien",
        "country_name": "Malta"},
    {
        "name": "Valletta",
        "country_name": "Malta"},
    {
        "name": "Victoria",
        "country_name": "Malta"},
    {
        "name": "Xagħra",
        "country_name": "Malta"},
    {
        "name": "Xewkija",
        "country_name": "Malta"},
    {
        "name": "Xgħajra",
        "country_name": "Malta"},
    {
        "name": "Żabbar",
        "country_name": "Malta"},
    {
        "name": "Żebbuġ Gozo",
        "country_name": "Malta"},
    {
        "name": "Żebbuġ Malta",
        "country_name": "Malta"},
    {
        "name": "Żejtun",
        "country_name": "Malta"},
    {
        "name": "Żurrieq",
        "country_name": "Malta"},
    {
        "name": "Ralik Chain",
        "country_name": "Marshall Islands"},
    {
        "name": "Ratak Chain",
        "country_name": "Marshall Islands"},
    {
        "name": "Adrar",
        "country_name": "Mauritania"},
    {
        "name": "Assaba",
        "country_name": "Mauritania"},
    {
        "name": "Brakna",
        "country_name": "Mauritania"},
    {
        "name": "Dakhlet Nouadhibou",
        "country_name": "Mauritania"},
    {
        "name": "Gorgol",
        "country_name": "Mauritania"},
    {
        "name": "Guidimaka",
        "country_name": "Mauritania"},
    {
        "name": "Hodh Ech Chargui",
        "country_name": "Mauritania"},
    {
        "name": "Hodh El Gharbi",
        "country_name": "Mauritania"},
    {
        "name": "Inchiri",
        "country_name": "Mauritania"},
    {
        "name": "Nouakchott-Nord",
        "country_name": "Mauritania"},
    {
        "name": "Nouakchott-Ouest",
        "country_name": "Mauritania"},
    {
        "name": "Nouakchott-Sud",
        "country_name": "Mauritania"},
    {
        "name": "Tagant",
        "country_name": "Mauritania"},
    {
        "name": "Tiris Zemmour",
        "country_name": "Mauritania"},
    {
        "name": "Trarza",
        "country_name": "Mauritania"},
    {
        "name": "Agalega Islands",
        "country_name": "Mauritius"},
    {
        "name": "Black River",
        "country_name": "Mauritius"},
    {
        "name": "Flacq",
        "country_name": "Mauritius"},
    {
        "name": "Grand Port",
        "country_name": "Mauritius"},
    {
        "name": "Moka",
        "country_name": "Mauritius"},
    {
        "name": "Pamplemousses",
        "country_name": "Mauritius"},
    {
        "name": "Plaines Wilhems",
        "country_name": "Mauritius"},
    {
        "name": "Port Louis",
        "country_name": "Mauritius"},
    {
        "name": "Rivière du Rempart",
        "country_name": "Mauritius"},
    {
        "name": "Rodrigues Island",
        "country_name": "Mauritius"},
    {
        "name": "Saint Brandon Islands",
        "country_name": "Mauritius"},
    {
        "name": "Savanne",
        "country_name": "Mauritius"},
    {
        "name": "Aguascalientes",
        "country_name": "Mexico"},
    {
        "name": "Baja California",
        "country_name": "Mexico"},
    {
        "name": "Baja California Sur",
        "country_name": "Mexico"},
    {
        "name": "Campeche",
        "country_name": "Mexico"},
    {
        "name": "Chiapas",
        "country_name": "Mexico"},
    {
        "name": "Chihuahua",
        "country_name": "Mexico"},
    {
        "name": "Ciudad de México",
        "country_name": "Mexico"},
    {
        "name": "Coahuila de Zaragoza",
        "country_name": "Mexico"},
    {
        "name": "Colima",
        "country_name": "Mexico"},
    {
        "name": "Durango",
        "country_name": "Mexico"},
    {
        "name": "Estado de México",
        "country_name": "Mexico"},
    {
        "name": "Guanajuato",
        "country_name": "Mexico"},
    {
        "name": "Guerrero",
        "country_name": "Mexico"},
    {
        "name": "Hidalgo",
        "country_name": "Mexico"},
    {
        "name": "Jalisco",
        "country_name": "Mexico"},
    {
        "name": "Michoacán de Ocampo",
        "country_name": "Mexico"},
    {
        "name": "Morelos",
        "country_name": "Mexico"},
    {
        "name": "Nayarit",
        "country_name": "Mexico"},
    {
        "name": "Nuevo León",
        "country_name": "Mexico"},
    {
        "name": "Oaxaca",
        "country_name": "Mexico"},
    {
        "name": "Puebla",
        "country_name": "Mexico"},
    {
        "name": "Querétaro",
        "country_name": "Mexico"},
    {
        "name": "Quintana Roo",
        "country_name": "Mexico"},
    {
        "name": "San Luis Potosí",
        "country_name": "Mexico"},
    {
        "name": "Sinaloa",
        "country_name": "Mexico"},
    {
        "name": "Sonora",
        "country_name": "Mexico"},
    {
        "name": "Tabasco",
        "country_name": "Mexico"},
    {
        "name": "Tamaulipas",
        "country_name": "Mexico"},
    {
        "name": "Tlaxcala",
        "country_name": "Mexico"},
    {
        "name": "Veracruz de Ignacio de la Llave",
        "country_name": "Mexico"},
    {
        "name": "Yucatán",
        "country_name": "Mexico"},
    {
        "name": "Zacatecas",
        "country_name": "Mexico"},
    {
        "name": "Chuuk State",
        "country_name": "Micronesia"},
    {
        "name": "Kosrae State",
        "country_name": "Micronesia"},
    {
        "name": "Pohnpei State",
        "country_name": "Micronesia"},
    {
        "name": "Yap State",
        "country_name": "Micronesia"},
    {
        "name": "Anenii Noi District",
        "country_name": "Moldova"},
    {
        "name": "Bălți Municipality",
        "country_name": "Moldova"},
    {
        "name": "Basarabeasca District",
        "country_name": "Moldova"},
    {
        "name": "Bender Municipality",
        "country_name": "Moldova"},
    {
        "name": "Briceni District",
        "country_name": "Moldova"},
    {
        "name": "Cahul District",
        "country_name": "Moldova"},
    {
        "name": "Călărași District",
        "country_name": "Moldova"},
    {
        "name": "Cantemir District",
        "country_name": "Moldova"},
    {
        "name": "Căușeni District",
        "country_name": "Moldova"},
    {
        "name": "Chișinău Municipality",
        "country_name": "Moldova"},
    {
        "name": "Cimișlia District",
        "country_name": "Moldova"},
    {
        "name": "Criuleni District",
        "country_name": "Moldova"},
    {
        "name": "Dondușeni District",
        "country_name": "Moldova"},
    {
        "name": "Drochia District",
        "country_name": "Moldova"},
    {
        "name": "Dubăsari District",
        "country_name": "Moldova"},
    {
        "name": "Edineț District",
        "country_name": "Moldova"},
    {
        "name": "Fălești District",
        "country_name": "Moldova"},
    {
        "name": "Florești District",
        "country_name": "Moldova"},
    {
        "name": "Gagauzia",
        "country_name": "Moldova"},
    {
        "name": "Glodeni District",
        "country_name": "Moldova"},
    {
        "name": "Hîncești District",
        "country_name": "Moldova"},
    {
        "name": "Ialoveni District",
        "country_name": "Moldova"},
    {
        "name": "Nisporeni District",
        "country_name": "Moldova"},
    {
        "name": "Ocnița District",
        "country_name": "Moldova"},
    {
        "name": "Orhei District",
        "country_name": "Moldova"},
    {
        "name": "Rezina District",
        "country_name": "Moldova"},
    {
        "name": "Rîșcani District",
        "country_name": "Moldova"},
    {
        "name": "Sîngerei District",
        "country_name": "Moldova"},
    {
        "name": "Șoldănești District",
        "country_name": "Moldova"},
    {
        "name": "Soroca District",
        "country_name": "Moldova"},
    {
        "name": "Ștefan Vodă District",
        "country_name": "Moldova"},
    {
        "name": "Strășeni District",
        "country_name": "Moldova"},
    {
        "name": "Taraclia District",
        "country_name": "Moldova"},
    {
        "name": "Telenești District",
        "country_name": "Moldova"},
    {
        "name": "Transnistria autonomous territorial unit",
        "country_name": "Moldova"},
    {
        "name": "Ungheni District",
        "country_name": "Moldova"},
    {
        "name": "La Colle",
        "country_name": "Monaco"},
    {
        "name": "La Condamine",
        "country_name": "Monaco"},
    {
        "name": "Moneghetti",
        "country_name": "Monaco"},
    {
        "name": "Arkhangai Province",
        "country_name": "Mongolia"},
    {
        "name": "Bayan-Ölgii Province",
        "country_name": "Mongolia"},
    {
        "name": "Bayankhongor Province",
        "country_name": "Mongolia"},
    {
        "name": "Bulgan Province",
        "country_name": "Mongolia"},
    {
        "name": "Darkhan-Uul Province",
        "country_name": "Mongolia"},
    {
        "name": "Dornod Province",
        "country_name": "Mongolia"},
    {
        "name": "Dornogovi Province",
        "country_name": "Mongolia"},
    {
        "name": "Dundgovi Province",
        "country_name": "Mongolia"},
    {
        "name": "Govi-Altai Province",
        "country_name": "Mongolia"},
    {
        "name": "Govisümber Province",
        "country_name": "Mongolia"},
    {
        "name": "Khentii Province",
        "country_name": "Mongolia"},
    {
        "name": "Khovd Province",
        "country_name": "Mongolia"},
    {
        "name": "Khövsgöl Province",
        "country_name": "Mongolia"},
    {
        "name": "Ömnögovi Province",
        "country_name": "Mongolia"},
    {
        "name": "Orkhon Province",
        "country_name": "Mongolia"},
    {
        "name": "Övörkhangai Province",
        "country_name": "Mongolia"},
    {
        "name": "Selenge Province",
        "country_name": "Mongolia"},
    {
        "name": "Sükhbaatar Province",
        "country_name": "Mongolia"},
    {
        "name": "Töv Province",
        "country_name": "Mongolia"},
    {
        "name": "Uvs Province",
        "country_name": "Mongolia"},
    {
        "name": "Zavkhan Province",
        "country_name": "Mongolia"},
    {
        "name": "Andrijevica Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Bar Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Berane Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Bijelo Polje Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Budva Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Danilovgrad Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Gusinje Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Kolašin Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Kotor Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Mojkovac Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Nikšić Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Old Royal Capital Cetinje",
        "country_name": "Montenegro"},
    {
        "name": "Petnjica Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Plav Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Pljevlja Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Plužine Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Podgorica Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Rožaje Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Šavnik Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Tivat Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Ulcinj Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Žabljak Municipality",
        "country_name": "Montenegro"},
    {
        "name": "Agadir-Ida-Ou-Tanane",
        "country_name": "Morocco"},
    {
        "name": "Al Haouz",
        "country_name": "Morocco"},
    {
        "name": "Al Hoceïma",
        "country_name": "Morocco"},
    {
        "name": "Aousserd (EH)",
        "country_name": "Morocco"},
    {
        "name": "Assa-Zag (EH-partial)",
        "country_name": "Morocco"},
    {
        "name": "Azilal",
        "country_name": "Morocco"},
    {
        "name": "Béni Mellal",
        "country_name": "Morocco"},
    {
        "name": "Béni Mellal-Khénifra",
        "country_name": "Morocco"},
    {
        "name": "Benslimane",
        "country_name": "Morocco"},
    {
        "name": "Berkane",
        "country_name": "Morocco"},
    {
        "name": "Berrechid",
        "country_name": "Morocco"},
    {
        "name": "Boujdour (EH)",
        "country_name": "Morocco"},
    {
        "name": "Boulemane",
        "country_name": "Morocco"},
    {
        "name": "Casablanca",
        "country_name": "Morocco"},
    {
        "name": "Casablanca-Settat",
        "country_name": "Morocco"},
    {
        "name": "Chefchaouen",
        "country_name": "Morocco"},
    {
        "name": "Chichaoua",
        "country_name": "Morocco"},
    {
        "name": "Chtouka-Ait Baha",
        "country_name": "Morocco"},
    {
        "name": "Dakhla-Oued Ed-Dahab (EH)",
        "country_name": "Morocco"},
    {
        "name": "Drâa-Tafilalet",
        "country_name": "Morocco"},
    {
        "name": "Driouch",
        "country_name": "Morocco"},
    {
        "name": "El Hajeb",
        "country_name": "Morocco"},
    {
        "name": "El Jadida",
        "country_name": "Morocco"},
    {
        "name": "El Kelâa des Sraghna",
        "country_name": "Morocco"},
    {
        "name": "Errachidia",
        "country_name": "Morocco"},
    {
        "name": "Es-Semara (EH-partial)",
        "country_name": "Morocco"},
    {
        "name": "Essaouira",
        "country_name": "Morocco"},
    {
        "name": "Fahs-Anjra",
        "country_name": "Morocco"},
    {
        "name": "Fès",
        "country_name": "Morocco"},
    {
        "name": "Fès-Meknès",
        "country_name": "Morocco"},
    {
        "name": "Figuig",
        "country_name": "Morocco"},
    {
        "name": "Fquih Ben Salah",
        "country_name": "Morocco"},
    {
        "name": "Guelmim",
        "country_name": "Morocco"},
    {
        "name": "Guelmim-Oued Noun (EH-partial)",
        "country_name": "Morocco"},
    {
        "name": "Guercif",
        "country_name": "Morocco"},
    {
        "name": "Ifrane",
        "country_name": "Morocco"},
    {
        "name": "Inezgane-Ait Melloul",
        "country_name": "Morocco"},
    {
        "name": "Jerada",
        "country_name": "Morocco"},
    {
        "name": "Kénitra",
        "country_name": "Morocco"},
    {
        "name": "Khémisset",
        "country_name": "Morocco"},
    {
        "name": "Khénifra",
        "country_name": "Morocco"},
    {
        "name": "Khouribga",
        "country_name": "Morocco"},
    {
        "name": "L'Oriental",
        "country_name": "Morocco"},
    {
        "name": "Laâyoune (EH)",
        "country_name": "Morocco"},
    {
        "name": "Laâyoune-Sakia El Hamra (EH-partial)",
        "country_name": "Morocco"},
    {
        "name": "Larache",
        "country_name": "Morocco"},
    {
        "name": "M’diq-Fnideq",
        "country_name": "Morocco"},
    {
        "name": "Marrakech",
        "country_name": "Morocco"},
    {
        "name": "Marrakesh-Safi",
        "country_name": "Morocco"},
    {
        "name": "Médiouna",
        "country_name": "Morocco"},
    {
        "name": "Meknès",
        "country_name": "Morocco"},
    {
        "name": "Midelt",
        "country_name": "Morocco"},
    {
        "name": "Mohammadia",
        "country_name": "Morocco"},
    {
        "name": "Moulay Yacoub",
        "country_name": "Morocco"},
    {
        "name": "Nador",
        "country_name": "Morocco"},
    {
        "name": "Nouaceur",
        "country_name": "Morocco"},
    {
        "name": "Ouarzazate",
        "country_name": "Morocco"},
    {
        "name": "Oued Ed-Dahab (EH)",
        "country_name": "Morocco"},
    {
        "name": "Ouezzane",
        "country_name": "Morocco"},
    {
        "name": "Oujda-Angad",
        "country_name": "Morocco"},
    {
        "name": "Rabat",
        "country_name": "Morocco"},
    {
        "name": "Rabat-Salé-Kénitra",
        "country_name": "Morocco"},
    {
        "name": "Rehamna",
        "country_name": "Morocco"},
    {
        "name": "Safi",
        "country_name": "Morocco"},
    {
        "name": "Salé",
        "country_name": "Morocco"},
    {
        "name": "Sefrou",
        "country_name": "Morocco"},
    {
        "name": "Settat",
        "country_name": "Morocco"},
    {
        "name": "Sidi Bennour",
        "country_name": "Morocco"},
    {
        "name": "Sidi Ifni",
        "country_name": "Morocco"},
    {
        "name": "Sidi Kacem",
        "country_name": "Morocco"},
    {
        "name": "Sidi Slimane",
        "country_name": "Morocco"},
    {
        "name": "Skhirate-Témara",
        "country_name": "Morocco"},
    {
        "name": "Souss-Massa",
        "country_name": "Morocco"},
    {
        "name": "Tan-Tan (EH-partial)",
        "country_name": "Morocco"},
    {
        "name": "Tanger-Assilah",
        "country_name": "Morocco"},
    {
        "name": "Tanger-Tétouan-Al Hoceïma",
        "country_name": "Morocco"},
    {
        "name": "Taounate",
        "country_name": "Morocco"},
    {
        "name": "Taourirt",
        "country_name": "Morocco"},
    {
        "name": "Tarfaya (EH-partial)",
        "country_name": "Morocco"},
    {
        "name": "Taroudannt",
        "country_name": "Morocco"},
    {
        "name": "Tata",
        "country_name": "Morocco"},
    {
        "name": "Taza",
        "country_name": "Morocco"},
    {
        "name": "Tétouan",
        "country_name": "Morocco"},
    {
        "name": "Tinghir",
        "country_name": "Morocco"},
    {
        "name": "Tiznit",
        "country_name": "Morocco"},
    {
        "name": "Youssoufia",
        "country_name": "Morocco"},
    {
        "name": "Zagora",
        "country_name": "Morocco"},
    {
        "name": "Cabo Delgado Province",
        "country_name": "Mozambique"},
    {
        "name": "Gaza Province",
        "country_name": "Mozambique"},
    {
        "name": "Inhambane Province",
        "country_name": "Mozambique"},
    {
        "name": "Manica Province",
        "country_name": "Mozambique"},
    {
        "name": "Maputo",
        "country_name": "Mozambique"},
    {
        "name": "Maputo Province",
        "country_name": "Mozambique"},
    {
        "name": "Nampula Province",
        "country_name": "Mozambique"},
    {
        "name": "Niassa Province",
        "country_name": "Mozambique"},
    {
        "name": "Sofala Province",
        "country_name": "Mozambique"},
    {
        "name": "Tete Province",
        "country_name": "Mozambique"},
    {
        "name": "Zambezia Province",
        "country_name": "Mozambique"},
    {
        "name": "Ayeyarwady Region",
        "country_name": "Myanmar"},
    {
        "name": "Bago",
        "country_name": "Myanmar"},
    {
        "name": "Chin State",
        "country_name": "Myanmar"},
    {
        "name": "Kachin State",
        "country_name": "Myanmar"},
    {
        "name": "Kayah State",
        "country_name": "Myanmar"},
    {
        "name": "Kayin State",
        "country_name": "Myanmar"},
    {
        "name": "Magway Region",
        "country_name": "Myanmar"},
    {
        "name": "Mandalay Region",
        "country_name": "Myanmar"},
    {
        "name": "Mon State",
        "country_name": "Myanmar"},
    {
        "name": "Naypyidaw Union Territory",
        "country_name": "Myanmar"},
    {
        "name": "Rakhine State",
        "country_name": "Myanmar"},
    {
        "name": "Sagaing Region",
        "country_name": "Myanmar"},
    {
        "name": "Shan State",
        "country_name": "Myanmar"},
    {
        "name": "Tanintharyi Region",
        "country_name": "Myanmar"},
    {
        "name": "Yangon Region",
        "country_name": "Myanmar"},
    {
        "name": "Erongo Region",
        "country_name": "Namibia"},
    {
        "name": "Hardap Region",
        "country_name": "Namibia"},
    {
        "name": "Karas Region",
        "country_name": "Namibia"},
    {
        "name": "Kavango East Region",
        "country_name": "Namibia"},
    {
        "name": "Kavango West Region",
        "country_name": "Namibia"},
    {
        "name": "Khomas Region",
        "country_name": "Namibia"},
    {
        "name": "Kunene Region",
        "country_name": "Namibia"},
    {
        "name": "Ohangwena Region",
        "country_name": "Namibia"},
    {
        "name": "Omaheke Region",
        "country_name": "Namibia"},
    {
        "name": "Omusati Region",
        "country_name": "Namibia"},
    {
        "name": "Oshana Region",
        "country_name": "Namibia"},
    {
        "name": "Oshikoto Region",
        "country_name": "Namibia"},
    {
        "name": "Otjozondjupa Region",
        "country_name": "Namibia"},
    {
        "name": "Zambezi Region",
        "country_name": "Namibia"},
    {
        "name": "Aiwo District",
        "country_name": "Nauru"},
    {
        "name": "Anabar District",
        "country_name": "Nauru"},
    {
        "name": "Anetan District",
        "country_name": "Nauru"},
    {
        "name": "Anibare District",
        "country_name": "Nauru"},
    {
        "name": "Baiti District",
        "country_name": "Nauru"},
    {
        "name": "Boe District",
        "country_name": "Nauru"},
    {
        "name": "Buada District",
        "country_name": "Nauru"},
    {
        "name": "Denigomodu District",
        "country_name": "Nauru"},
    {
        "name": "Ewa District",
        "country_name": "Nauru"},
    {
        "name": "Ijuw District",
        "country_name": "Nauru"},
    {
        "name": "Meneng District",
        "country_name": "Nauru"},
    {
        "name": "Nibok District",
        "country_name": "Nauru"},
    {
        "name": "Uaboe District",
        "country_name": "Nauru"},
    {
        "name": "Yaren District",
        "country_name": "Nauru"},
    {
        "name": "Bagmati Zone",
        "country_name": "Nepal"},
    {
        "name": "Bheri Zone",
        "country_name": "Nepal"},
    {
        "name": "Central Region",
        "country_name": "Nepal"},
    {
        "name": "Dhaulagiri Zone",
        "country_name": "Nepal"},
    {
        "name": "Eastern Development Region",
        "country_name": "Nepal"},
    {
        "name": "Far-Western Development Region",
        "country_name": "Nepal"},
    {
        "name": "Gandaki Zone",
        "country_name": "Nepal"},
    {
        "name": "Janakpur Zone",
        "country_name": "Nepal"},
    {
        "name": "Karnali Zone",
        "country_name": "Nepal"},
    {
        "name": "Kosi Zone",
        "country_name": "Nepal"},
    {
        "name": "Lumbini Zone",
        "country_name": "Nepal"},
    {
        "name": "Mahakali Zone",
        "country_name": "Nepal"},
    {
        "name": "Mechi Zone",
        "country_name": "Nepal"},
    {
        "name": "Mid-Western Region",
        "country_name": "Nepal"},
    {
        "name": "Narayani Zone",
        "country_name": "Nepal"},
    {
        "name": "Rapti Zone",
        "country_name": "Nepal"},
    {
        "name": "Sagarmatha Zone",
        "country_name": "Nepal"},
    {
        "name": "Seti Zone",
        "country_name": "Nepal"},
    {
        "name": "Western Region",
        "country_name": "Nepal"},
    {
        "name": "Bonaire",
        "country_name": "Netherlands"},
    {
        "name": "Drenthe",
        "country_name": "Netherlands"},
    {
        "name": "Flevoland",
        "country_name": "Netherlands"},
    {
        "name": "Friesland",
        "country_name": "Netherlands"},
    {
        "name": "Gelderland",
        "country_name": "Netherlands"},
    {
        "name": "Groningen",
        "country_name": "Netherlands"},
    {
        "name": "Limburg",
        "country_name": "Netherlands"},
    {
        "name": "North Brabant",
        "country_name": "Netherlands"},
    {
        "name": "North Holland",
        "country_name": "Netherlands"},
    {
        "name": "Overijssel",
        "country_name": "Netherlands"},
    {
        "name": "Saba",
        "country_name": "Netherlands"},
    {
        "name": "Sint Eustatius",
        "country_name": "Netherlands"},
    {
        "name": "South Holland",
        "country_name": "Netherlands"},
    {
        "name": "Utrecht",
        "country_name": "Netherlands"},
    {
        "name": "Zeeland",
        "country_name": "Netherlands"},
    {
        "name": "Loyalty Islands Province",
        "country_name": "New Caledonia"},
    {
        "name": "North Province",
        "country_name": "New Caledonia"},
    {
        "name": "South Province",
        "country_name": "New Caledonia"},
    {
        "name": "Auckland Region",
        "country_name": "New Zealand"},
    {
        "name": "Bay of Plenty Region",
        "country_name": "New Zealand"},
    {
        "name": "Canterbury Region",
        "country_name": "New Zealand"},
    {
        "name": "Chatham Islands",
        "country_name": "New Zealand"},
    {
        "name": "Gisborne District",
        "country_name": "New Zealand"},
    {
        "name": "Hawke's Bay Region",
        "country_name": "New Zealand"},
    {
        "name": "Manawatu-Wanganui Region",
        "country_name": "New Zealand"},
    {
        "name": "Marlborough Region",
        "country_name": "New Zealand"},
    {
        "name": "Nelson Region",
        "country_name": "New Zealand"},
    {
        "name": "Northland Region",
        "country_name": "New Zealand"},
    {
        "name": "Otago Region",
        "country_name": "New Zealand"},
    {
        "name": "Southland Region",
        "country_name": "New Zealand"},
    {
        "name": "Taranaki Region",
        "country_name": "New Zealand"},
    {
        "name": "Tasman District",
        "country_name": "New Zealand"},
    {
        "name": "Waikato Region",
        "country_name": "New Zealand"},
    {
        "name": "Wellington Region",
        "country_name": "New Zealand"},
    {
        "name": "West Coast Region",
        "country_name": "New Zealand"},
    {
        "name": "Boaco",
        "country_name": "Nicaragua"},
    {
        "name": "Carazo",
        "country_name": "Nicaragua"},
    {
        "name": "Chinandega",
        "country_name": "Nicaragua"},
    {
        "name": "Chontales",
        "country_name": "Nicaragua"},
    {
        "name": "Estelí",
        "country_name": "Nicaragua"},
    {
        "name": "Granada",
        "country_name": "Nicaragua"},
    {
        "name": "Jinotega",
        "country_name": "Nicaragua"},
    {
        "name": "León",
        "country_name": "Nicaragua"},
    {
        "name": "Madriz",
        "country_name": "Nicaragua"},
    {
        "name": "Managua",
        "country_name": "Nicaragua"},
    {
        "name": "Masaya",
        "country_name": "Nicaragua"},
    {
        "name": "Matagalpa",
        "country_name": "Nicaragua"},
    {
        "name": "North Caribbean Coast",
        "country_name": "Nicaragua"},
    {
        "name": "Nueva Segovia",
        "country_name": "Nicaragua"},
    {
        "name": "Río San Juan",
        "country_name": "Nicaragua"},
    {
        "name": "Rivas",
        "country_name": "Nicaragua"},
    {
        "name": "South Caribbean Coast",
        "country_name": "Nicaragua"},
    {
        "name": "Agadez Region",
        "country_name": "Niger"},
    {
        "name": "Diffa Region",
        "country_name": "Niger"},
    {
        "name": "Dosso Region",
        "country_name": "Niger"},
    {
        "name": "Maradi Region",
        "country_name": "Niger"},
    {
        "name": "Tahoua Region",
        "country_name": "Niger"},
    {
        "name": "Tillabéri Region",
        "country_name": "Niger"},
    {
        "name": "Zinder Region",
        "country_name": "Niger"},
    {
        "name": "Abia",
        "country_name": "Nigeria"},
    {
        "name": "Abuja Federal Capital Territory",
        "country_name": "Nigeria"},
    {
        "name": "Adamawa",
        "country_name": "Nigeria"},
    {
        "name": "Akwa Ibom",
        "country_name": "Nigeria"},
    {
        "name": "Anambra",
        "country_name": "Nigeria"},
    {
        "name": "Bauchi",
        "country_name": "Nigeria"},
    {
        "name": "Bayelsa",
        "country_name": "Nigeria"},
    {
        "name": "Benue",
        "country_name": "Nigeria"},
    {
        "name": "Borno",
        "country_name": "Nigeria"},
    {
        "name": "Cross River",
        "country_name": "Nigeria"},
    {
        "name": "Delta",
        "country_name": "Nigeria"},
    {
        "name": "Ebonyi",
        "country_name": "Nigeria"},
    {
        "name": "Edo",
        "country_name": "Nigeria"},
    {
        "name": "Ekiti",
        "country_name": "Nigeria"},
    {
        "name": "Enugu",
        "country_name": "Nigeria"},
    {
        "name": "Gombe",
        "country_name": "Nigeria"},
    {
        "name": "Imo",
        "country_name": "Nigeria"},
    {
        "name": "Jigawa",
        "country_name": "Nigeria"},
    {
        "name": "Kaduna",
        "country_name": "Nigeria"},
    {
        "name": "Kano",
        "country_name": "Nigeria"},
    {
        "name": "Katsina",
        "country_name": "Nigeria"},
    {
        "name": "Kebbi",
        "country_name": "Nigeria"},
    {
        "name": "Kogi",
        "country_name": "Nigeria"},
    {
        "name": "Kwara",
        "country_name": "Nigeria"},
    {
        "name": "Lagos",
        "country_name": "Nigeria"},
    {
        "name": "Nasarawa",
        "country_name": "Nigeria"},
    {
        "name": "Niger",
        "country_name": "Nigeria"},
    {
        "name": "Ogun",
        "country_name": "Nigeria"},
    {
        "name": "Ondo",
        "country_name": "Nigeria"},
    {
        "name": "Osun",
        "country_name": "Nigeria"},
    {
        "name": "Oyo",
        "country_name": "Nigeria"},
    {
        "name": "Plateau",
        "country_name": "Nigeria"},
    {
        "name": "Rivers",
        "country_name": "Nigeria"},
    {
        "name": "Sokoto",
        "country_name": "Nigeria"},
    {
        "name": "Taraba",
        "country_name": "Nigeria"},
    {
        "name": "Yobe",
        "country_name": "Nigeria"},
    {
        "name": "Zamfara",
        "country_name": "Nigeria"},
    {
        "name": "Chagang Province",
        "country_name": "North Korea"},
    {
        "name": "Kangwon Province",
        "country_name": "North Korea"},
    {
        "name": "North Hamgyong Province",
        "country_name": "North Korea"},
    {
        "name": "North Hwanghae Province",
        "country_name": "North Korea"},
    {
        "name": "North Pyongan Province",
        "country_name": "North Korea"},
    {
        "name": "Pyongyang",
        "country_name": "North Korea"},
    {
        "name": "Rason",
        "country_name": "North Korea"},
    {
        "name": "Ryanggang Province",
        "country_name": "North Korea"},
    {
        "name": "South Hamgyong Province",
        "country_name": "North Korea"},
    {
        "name": "South Hwanghae Province",
        "country_name": "North Korea"},
    {
        "name": "South Pyongan Province",
        "country_name": "North Korea"},
    {
        "name": "Aerodrom Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Aračinovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Berovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Bitola Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Bogdanci Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Bogovinje Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Bosilovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Brvenica Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Butel Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Čair Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Čaška Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Centar Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Centar Župa Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Češinovo-Obleševo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Čučer-Sandevo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Debarca Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Delčevo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Demir Hisar Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Demir Kapija Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Dojran Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Dolneni Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Drugovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Gazi Baba Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Gevgelija Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Gjorče Petrov Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Gostivar Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Gradsko Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Greater Skopje",
        "country_name": "North Macedonia"},
    {
        "name": "Ilinden Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Jegunovce Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Karbinci",
        "country_name": "North Macedonia"},
    {
        "name": "Karpoš Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kavadarci Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kičevo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kisela Voda Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kočani Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Konče Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kratovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kriva Palanka Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Krivogaštani Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kruševo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Kumanovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Lipkovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Lozovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Makedonska Kamenica Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Makedonski Brod Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Mavrovo and Rostuša Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Mogila Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Negotino Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Novaci Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Novo Selo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Ohrid Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Oslomej Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Pehčevo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Petrovec Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Plasnica Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Prilep Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Probištip Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Radoviš Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Rankovce Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Resen Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Rosoman Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Saraj Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Sopište Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Staro Nagoričane Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Štip Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Struga Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Strumica Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Studeničani Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Šuto Orizari Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Sveti Nikole Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Tearce Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Tetovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Valandovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Vasilevo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Veles Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Vevčani Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Vinica Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Vraneštica Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Vrapčište Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Zajas Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Zelenikovo Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Želino Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Zrnovci Municipality",
        "country_name": "North Macedonia"},
    {
        "name": "Agder",
        "country_name": "Norway"},
    {
        "name": "Innlandet",
        "country_name": "Norway"},
    {
        "name": "Jan Mayen",
        "country_name": "Norway"},
    {
        "name": "Møre og Romsdal",
        "country_name": "Norway"},
    {
        "name": "Nordland",
        "country_name": "Norway"},
    {
        "name": "Oslo",
        "country_name": "Norway"},
    {
        "name": "Rogaland",
        "country_name": "Norway"},
    {
        "name": "Svalbard",
        "country_name": "Norway"},
    {
        "name": "Troms og Finnmark",
        "country_name": "Norway"},
    {
        "name": "Trøndelag",
        "country_name": "Norway"},
    {
        "name": "Vestfold og Telemark",
        "country_name": "Norway"},
    {
        "name": "Vestland",
        "country_name": "Norway"},
    {
        "name": "Viken",
        "country_name": "Norway"},
    {
        "name": "Ad Dakhiliyah",
        "country_name": "Oman"},
    {
        "name": "Ad Dhahirah",
        "country_name": "Oman"},
    {
        "name": "Al Batinah North",
        "country_name": "Oman"},
    {
        "name": "Al Batinah Region",
        "country_name": "Oman"},
    {
        "name": "Al Batinah South",
        "country_name": "Oman"},
    {
        "name": "Al Buraimi",
        "country_name": "Oman"},
    {
        "name": "Al Wusta",
        "country_name": "Oman"},
    {
        "name": "Ash Sharqiyah North",
        "country_name": "Oman"},
    {
        "name": "Ash Sharqiyah Region",
        "country_name": "Oman"},
    {
        "name": "Ash Sharqiyah South",
        "country_name": "Oman"},
    {
        "name": "Dhofar",
        "country_name": "Oman"},
    {
        "name": "Musandam",
        "country_name": "Oman"},
    {
        "name": "Muscat",
        "country_name": "Oman"},
    {
        "name": "Azad Kashmir",
        "country_name": "Pakistan"},
    {
        "name": "Balochistan",
        "country_name": "Pakistan"},
    {
        "name": "Federally Administered Tribal Areas",
        "country_name": "Pakistan"},
    {
        "name": "Gilgit-Baltistan",
        "country_name": "Pakistan"},
    {
        "name": "Islamabad Capital Territory",
        "country_name": "Pakistan"},
    {
        "name": "Khyber Pakhtunkhwa",
        "country_name": "Pakistan"},
    {
        "name": "Punjab",
        "country_name": "Pakistan"},
    {
        "name": "Sindh",
        "country_name": "Pakistan"},
    {
        "name": "Aimeliik",
        "country_name": "Palau"},
    {
        "name": "Airai",
        "country_name": "Palau"},
    {
        "name": "Angaur",
        "country_name": "Palau"},
    {
        "name": "Hatohobei",
        "country_name": "Palau"},
    {
        "name": "Kayangel",
        "country_name": "Palau"},
    {
        "name": "Koror",
        "country_name": "Palau"},
    {
        "name": "Melekeok",
        "country_name": "Palau"},
    {
        "name": "Ngaraard",
        "country_name": "Palau"},
    {
        "name": "Ngarchelong",
        "country_name": "Palau"},
    {
        "name": "Ngardmau",
        "country_name": "Palau"},
    {
        "name": "Ngatpang",
        "country_name": "Palau"},
    {
        "name": "Ngchesar",
        "country_name": "Palau"},
    {
        "name": "Ngeremlengui",
        "country_name": "Palau"},
    {
        "name": "Ngiwal",
        "country_name": "Palau"},
    {
        "name": "Peleliu",
        "country_name": "Palau"},
    {
        "name": "Sonsorol",
        "country_name": "Palau"},
    {
        "name": "Bethlehem",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Deir El Balah",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Gaza",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Hebron",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Jenin",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Jericho and Al Aghwar",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Jerusalem",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Khan Yunis",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Nablus",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "North Gaza",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Qalqilya",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Rafah",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Ramallah",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Salfit",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Tubas",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Tulkarm",
        "country_name": "Palestinian Territory Occupied"},
    {
        "name": "Bocas del Toro Province",
        "country_name": "Panama"},
    {
        "name": "Chiriquí Province",
        "country_name": "Panama"},
    {
        "name": "Coclé Province",
        "country_name": "Panama"},
    {
        "name": "Colón Province",
        "country_name": "Panama"},
    {
        "name": "Darién Province",
        "country_name": "Panama"},
    {
        "name": "Emberá-Wounaan Comarca",
        "country_name": "Panama"},
    {
        "name": "Guna Yala",
        "country_name": "Panama"},
    {
        "name": "Herrera Province",
        "country_name": "Panama"},
    {
        "name": "Los Santos Province",
        "country_name": "Panama"},
    {
        "name": "Ngöbe-Buglé Comarca",
        "country_name": "Panama"},
    {
        "name": "Panamá Oeste Province",
        "country_name": "Panama"},
    {
        "name": "Panamá Province",
        "country_name": "Panama"},
    {
        "name": "Veraguas Province",
        "country_name": "Panama"},
    {
        "name": "Bougainville",
        "country_name": "Papua new Guinea"},
    {
        "name": "Central Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Chimbu Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "East New Britain",
        "country_name": "Papua new Guinea"},
    {
        "name": "Eastern Highlands Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Enga Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Gulf",
        "country_name": "Papua new Guinea"},
    {
        "name": "Hela",
        "country_name": "Papua new Guinea"},
    {
        "name": "Jiwaka Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Madang Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Manus Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Milne Bay Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Morobe Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "New Ireland Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Oro Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Port Moresby",
        "country_name": "Papua new Guinea"},
    {
        "name": "Sandaun Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Southern Highlands Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "West New Britain Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Western Highlands Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Western Province",
        "country_name": "Papua new Guinea"},
    {
        "name": "Alto Paraguay Department",
        "country_name": "Paraguay"},
    {
        "name": "Alto Paraná Department",
        "country_name": "Paraguay"},
    {
        "name": "Amambay Department",
        "country_name": "Paraguay"},
    {
        "name": "Asuncion",
        "country_name": "Paraguay"},
    {
        "name": "Boquerón Department",
        "country_name": "Paraguay"},
    {
        "name": "Caaguazú",
        "country_name": "Paraguay"},
    {
        "name": "Caazapá",
        "country_name": "Paraguay"},
    {
        "name": "Canindeyú",
        "country_name": "Paraguay"},
    {
        "name": "Central Department",
        "country_name": "Paraguay"},
    {
        "name": "Concepción Department",
        "country_name": "Paraguay"},
    {
        "name": "Cordillera Department",
        "country_name": "Paraguay"},
    {
        "name": "Guairá Department",
        "country_name": "Paraguay"},
    {
        "name": "Itapúa",
        "country_name": "Paraguay"},
    {
        "name": "Misiones Department",
        "country_name": "Paraguay"},
    {
        "name": "Ñeembucú Department",
        "country_name": "Paraguay"},
    {
        "name": "Paraguarí Department",
        "country_name": "Paraguay"},
    {
        "name": "Presidente Hayes Department",
        "country_name": "Paraguay"},
    {
        "name": "San Pedro Department",
        "country_name": "Paraguay"},
    {
        "name": "Amazonas",
        "country_name": "Peru"},
    {
        "name": "Áncash",
        "country_name": "Peru"},
    {
        "name": "Apurímac",
        "country_name": "Peru"},
    {
        "name": "Arequipa",
        "country_name": "Peru"},
    {
        "name": "Ayacucho",
        "country_name": "Peru"},
    {
        "name": "Cajamarca",
        "country_name": "Peru"},
    {
        "name": "Callao",
        "country_name": "Peru"},
    {
        "name": "Cusco",
        "country_name": "Peru"},
    {
        "name": "Huancavelica",
        "country_name": "Peru"},
    {
        "name": "Huanuco",
        "country_name": "Peru"},
    {
        "name": "Ica",
        "country_name": "Peru"},
    {
        "name": "Junín",
        "country_name": "Peru"},
    {
        "name": "La Libertad",
        "country_name": "Peru"},
    {
        "name": "Lambayeque",
        "country_name": "Peru"},
    {
        "name": "Lima",
        "country_name": "Peru"},
    {
        "name": "Loreto",
        "country_name": "Peru"},
    {
        "name": "Madre de Dios",
        "country_name": "Peru"},
    {
        "name": "Moquegua",
        "country_name": "Peru"},
    {
        "name": "Pasco",
        "country_name": "Peru"},
    {
        "name": "Piura",
        "country_name": "Peru"},
    {
        "name": "Puno",
        "country_name": "Peru"},
    {
        "name": "San Martín",
        "country_name": "Peru"},
    {
        "name": "Tacna",
        "country_name": "Peru"},
    {
        "name": "Tumbes",
        "country_name": "Peru"},
    {
        "name": "Ucayali",
        "country_name": "Peru"},
    {
        "name": "Abra",
        "country_name": "Philippines"},
    {
        "name": "Agusan del Norte",
        "country_name": "Philippines"},
    {
        "name": "Agusan del Sur",
        "country_name": "Philippines"},
    {
        "name": "Aklan",
        "country_name": "Philippines"},
    {
        "name": "Albay",
        "country_name": "Philippines"},
    {
        "name": "Antique",
        "country_name": "Philippines"},
    {
        "name": "Apayao",
        "country_name": "Philippines"},
    {
        "name": "Aurora",
        "country_name": "Philippines"},
    {
        "name": "Autonomous Region in Muslim Mindanao",
        "country_name": "Philippines"},
    {
        "name": "Basilan",
        "country_name": "Philippines"},
    {
        "name": "Bataan",
        "country_name": "Philippines"},
    {
        "name": "Batanes",
        "country_name": "Philippines"},
    {
        "name": "Batangas",
        "country_name": "Philippines"},
    {
        "name": "Benguet",
        "country_name": "Philippines"},
    {
        "name": "Bicol",
        "country_name": "Philippines"},
    {
        "name": "Biliran",
        "country_name": "Philippines"},
    {
        "name": "Bohol",
        "country_name": "Philippines"},
    {
        "name": "Bukidnon",
        "country_name": "Philippines"},
    {
        "name": "Bulacan",
        "country_name": "Philippines"},
    {
        "name": "Cagayan",
        "country_name": "Philippines"},
    {
        "name": "Cagayan Valley",
        "country_name": "Philippines"},
    {
        "name": "Calabarzon",
        "country_name": "Philippines"},
    {
        "name": "Camarines Norte",
        "country_name": "Philippines"},
    {
        "name": "Camarines Sur",
        "country_name": "Philippines"},
    {
        "name": "Camiguin",
        "country_name": "Philippines"},
    {
        "name": "Capiz",
        "country_name": "Philippines"},
    {
        "name": "Caraga",
        "country_name": "Philippines"},
    {
        "name": "Catanduanes",
        "country_name": "Philippines"},
    {
        "name": "Cavite",
        "country_name": "Philippines"},
    {
        "name": "Cebu",
        "country_name": "Philippines"},
    {
        "name": "Central Luzon",
        "country_name": "Philippines"},
    {
        "name": "Central Visayas",
        "country_name": "Philippines"},
    {
        "name": "Compostela Valley",
        "country_name": "Philippines"},
    {
        "name": "Cordillera Administrative",
        "country_name": "Philippines"},
    {
        "name": "Cotabato",
        "country_name": "Philippines"},
    {
        "name": "Davao",
        "country_name": "Philippines"},
    {
        "name": "Davao del Norte",
        "country_name": "Philippines"},
    {
        "name": "Davao del Sur",
        "country_name": "Philippines"},
    {
        "name": "Davao Occidental",
        "country_name": "Philippines"},
    {
        "name": "Davao Oriental",
        "country_name": "Philippines"},
    {
        "name": "Dinagat Islands",
        "country_name": "Philippines"},
    {
        "name": "Eastern Samar",
        "country_name": "Philippines"},
    {
        "name": "Eastern Visayas",
        "country_name": "Philippines"},
    {
        "name": "Guimaras",
        "country_name": "Philippines"},
    {
        "name": "Ifugao",
        "country_name": "Philippines"},
    {
        "name": "Ilocos",
        "country_name": "Philippines"},
    {
        "name": "Ilocos Norte",
        "country_name": "Philippines"},
    {
        "name": "Ilocos Sur",
        "country_name": "Philippines"},
    {
        "name": "Iloilo",
        "country_name": "Philippines"},
    {
        "name": "Isabela",
        "country_name": "Philippines"},
    {
        "name": "Kalinga",
        "country_name": "Philippines"},
    {
        "name": "La Union",
        "country_name": "Philippines"},
    {
        "name": "Laguna",
        "country_name": "Philippines"},
    {
        "name": "Lanao del Norte",
        "country_name": "Philippines"},
    {
        "name": "Lanao del Sur",
        "country_name": "Philippines"},
    {
        "name": "Leyte",
        "country_name": "Philippines"},
    {
        "name": "Maguindanao",
        "country_name": "Philippines"},
    {
        "name": "Marinduque",
        "country_name": "Philippines"},
    {
        "name": "Masbate",
        "country_name": "Philippines"},
    {
        "name": "Metro Manila",
        "country_name": "Philippines"},
    {
        "name": "Mimaropa",
        "country_name": "Philippines"},
    {
        "name": "Misamis Occidental",
        "country_name": "Philippines"},
    {
        "name": "Misamis Oriental",
        "country_name": "Philippines"},
    {
        "name": "Mountain Province",
        "country_name": "Philippines"},
    {
        "name": "Negros Occidental",
        "country_name": "Philippines"},
    {
        "name": "Negros Oriental",
        "country_name": "Philippines"},
    {
        "name": "Northern Mindanao",
        "country_name": "Philippines"},
    {
        "name": "Northern Samar",
        "country_name": "Philippines"},
    {
        "name": "Nueva Ecija",
        "country_name": "Philippines"},
    {
        "name": "Nueva Vizcaya",
        "country_name": "Philippines"},
    {
        "name": "Occidental Mindoro",
        "country_name": "Philippines"},
    {
        "name": "Oriental Mindoro",
        "country_name": "Philippines"},
    {
        "name": "Palawan",
        "country_name": "Philippines"},
    {
        "name": "Pampanga",
        "country_name": "Philippines"},
    {
        "name": "Pangasinan",
        "country_name": "Philippines"},
    {
        "name": "Quezon",
        "country_name": "Philippines"},
    {
        "name": "Quirino",
        "country_name": "Philippines"},
    {
        "name": "Rizal",
        "country_name": "Philippines"},
    {
        "name": "Romblon",
        "country_name": "Philippines"},
    {
        "name": "Sarangani",
        "country_name": "Philippines"},
    {
        "name": "Siquijor",
        "country_name": "Philippines"},
    {
        "name": "Soccsksargen",
        "country_name": "Philippines"},
    {
        "name": "Sorsogon",
        "country_name": "Philippines"},
    {
        "name": "South Cotabato",
        "country_name": "Philippines"},
    {
        "name": "Southern Leyte",
        "country_name": "Philippines"},
    {
        "name": "Sultan Kudarat",
        "country_name": "Philippines"},
    {
        "name": "Sulu",
        "country_name": "Philippines"},
    {
        "name": "Surigao del Norte",
        "country_name": "Philippines"},
    {
        "name": "Surigao del Sur",
        "country_name": "Philippines"},
    {
        "name": "Tarlac",
        "country_name": "Philippines"},
    {
        "name": "Tawi-Tawi",
        "country_name": "Philippines"},
    {
        "name": "Western Samar",
        "country_name": "Philippines"},
    {
        "name": "Western Visayas",
        "country_name": "Philippines"},
    {
        "name": "Zambales",
        "country_name": "Philippines"},
    {
        "name": "Zamboanga del Norte",
        "country_name": "Philippines"},
    {
        "name": "Zamboanga del Sur",
        "country_name": "Philippines"},
    {
        "name": "Zamboanga Peninsula",
        "country_name": "Philippines"},
    {
        "name": "Zamboanga Sibugay",
        "country_name": "Philippines"},
    {
        "name": "Greater Poland Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Kuyavian-Pomeranian Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Lesser Poland Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Lower Silesian Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Lublin Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Lubusz Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Łódź Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Masovian Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Opole Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Podkarpackie Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Podlaskie Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Pomeranian Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Silesian Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Świętokrzyskie Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Warmian-Masurian Voivodeship",
        "country_name": "Poland"},
    {
        "name": "West Pomeranian Voivodeship",
        "country_name": "Poland"},
    {
        "name": "Açores",
        "country_name": "Portugal"},
    {
        "name": "Aveiro",
        "country_name": "Portugal"},
    {
        "name": "Beja",
        "country_name": "Portugal"},
    {
        "name": "Braga",
        "country_name": "Portugal"},
    {
        "name": "Bragança",
        "country_name": "Portugal"},
    {
        "name": "Castelo Branco",
        "country_name": "Portugal"},
    {
        "name": "Coimbra",
        "country_name": "Portugal"},
    {
        "name": "Évora",
        "country_name": "Portugal"},
    {
        "name": "Faro",
        "country_name": "Portugal"},
    {
        "name": "Guarda",
        "country_name": "Portugal"},
    {
        "name": "Leiria",
        "country_name": "Portugal"},
    {
        "name": "Lisbon",
        "country_name": "Portugal"},
    {
        "name": "Madeira",
        "country_name": "Portugal"},
    {
        "name": "Portalegre",
        "country_name": "Portugal"},
    {
        "name": "Porto",
        "country_name": "Portugal"},
    {
        "name": "Santarém",
        "country_name": "Portugal"},
    {
        "name": "Setúbal",
        "country_name": "Portugal"},
    {
        "name": "Viana do Castelo",
        "country_name": "Portugal"},
    {
        "name": "Vila Real",
        "country_name": "Portugal"},
    {
        "name": "Viseu",
        "country_name": "Portugal"},
    {
        "name": "Adjuntas",
        "country_name": "Puerto Rico"},
    {
        "name": "Aguada",
        "country_name": "Puerto Rico"},
    {
        "name": "Aguadilla",
        "country_name": "Puerto Rico"},
    {
        "name": "Aguas Buenas",
        "country_name": "Puerto Rico"},
    {
        "name": "Aibonito",
        "country_name": "Puerto Rico"},
    {
        "name": "Añasco",
        "country_name": "Puerto Rico"},
    {
        "name": "Arecibo",
        "country_name": "Puerto Rico"},
    {
        "name": "Arecibo",
        "country_name": "Puerto Rico"},
    {
        "name": "Arroyo",
        "country_name": "Puerto Rico"},
    {
        "name": "Barceloneta",
        "country_name": "Puerto Rico"},
    {
        "name": "Barranquitas",
        "country_name": "Puerto Rico"},
    {
        "name": "Bayamon",
        "country_name": "Puerto Rico"},
    {
        "name": "Bayamón",
        "country_name": "Puerto Rico"},
    {
        "name": "Cabo Rojo",
        "country_name": "Puerto Rico"},
    {
        "name": "Caguas",
        "country_name": "Puerto Rico"},
    {
        "name": "Caguas",
        "country_name": "Puerto Rico"},
    {
        "name": "Camuy",
        "country_name": "Puerto Rico"},
    {
        "name": "Canóvanas",
        "country_name": "Puerto Rico"},
    {
        "name": "Carolina",
        "country_name": "Puerto Rico"},
    {
        "name": "Carolina",
        "country_name": "Puerto Rico"},
    {
        "name": "Cataño",
        "country_name": "Puerto Rico"},
    {
        "name": "Cayey",
        "country_name": "Puerto Rico"},
    {
        "name": "Ceiba",
        "country_name": "Puerto Rico"},
    {
        "name": "Ciales",
        "country_name": "Puerto Rico"},
    {
        "name": "Cidra",
        "country_name": "Puerto Rico"},
    {
        "name": "Coamo",
        "country_name": "Puerto Rico"},
    {
        "name": "Comerío",
        "country_name": "Puerto Rico"},
    {
        "name": "Corozal",
        "country_name": "Puerto Rico"},
    {
        "name": "Culebra",
        "country_name": "Puerto Rico"},
    {
        "name": "Dorado",
        "country_name": "Puerto Rico"},
    {
        "name": "Fajardo",
        "country_name": "Puerto Rico"},
    {
        "name": "Florida",
        "country_name": "Puerto Rico"},
    {
        "name": "Guánica",
        "country_name": "Puerto Rico"},
    {
        "name": "Guayama",
        "country_name": "Puerto Rico"},
    {
        "name": "Guayanilla",
        "country_name": "Puerto Rico"},
    {
        "name": "Guaynabo",
        "country_name": "Puerto Rico"},
    {
        "name": "Guaynabo",
        "country_name": "Puerto Rico"},
    {
        "name": "Gurabo",
        "country_name": "Puerto Rico"},
    {
        "name": "Hatillo",
        "country_name": "Puerto Rico"},
    {
        "name": "Hormigueros",
        "country_name": "Puerto Rico"},
    {
        "name": "Humacao",
        "country_name": "Puerto Rico"},
    {
        "name": "Isabela",
        "country_name": "Puerto Rico"},
    {
        "name": "Jayuya",
        "country_name": "Puerto Rico"},
    {
        "name": "Juana Díaz",
        "country_name": "Puerto Rico"},
    {
        "name": "Juncos",
        "country_name": "Puerto Rico"},
    {
        "name": "Lajas",
        "country_name": "Puerto Rico"},
    {
        "name": "Lares",
        "country_name": "Puerto Rico"},
    {
        "name": "Las Marías",
        "country_name": "Puerto Rico"},
    {
        "name": "Las Piedras",
        "country_name": "Puerto Rico"},
    {
        "name": "Loíza",
        "country_name": "Puerto Rico"},
    {
        "name": "Luquillo",
        "country_name": "Puerto Rico"},
    {
        "name": "Manatí",
        "country_name": "Puerto Rico"},
    {
        "name": "Maricao",
        "country_name": "Puerto Rico"},
    {
        "name": "Maunabo",
        "country_name": "Puerto Rico"},
    {
        "name": "Mayagüez",
        "country_name": "Puerto Rico"},
    {
        "name": "Mayagüez",
        "country_name": "Puerto Rico"},
    {
        "name": "Moca",
        "country_name": "Puerto Rico"},
    {
        "name": "Morovis",
        "country_name": "Puerto Rico"},
    {
        "name": "Naguabo",
        "country_name": "Puerto Rico"},
    {
        "name": "Naranjito",
        "country_name": "Puerto Rico"},
    {
        "name": "Orocovis",
        "country_name": "Puerto Rico"},
    {
        "name": "Patillas",
        "country_name": "Puerto Rico"},
    {
        "name": "Peñuelas",
        "country_name": "Puerto Rico"},
    {
        "name": "Ponce",
        "country_name": "Puerto Rico"},
    {
        "name": "Ponce",
        "country_name": "Puerto Rico"},
    {
        "name": "Quebradillas",
        "country_name": "Puerto Rico"},
    {
        "name": "Rincón",
        "country_name": "Puerto Rico"},
    {
        "name": "Río Grande",
        "country_name": "Puerto Rico"},
    {
        "name": "Sabana Grande",
        "country_name": "Puerto Rico"},
    {
        "name": "Salinas",
        "country_name": "Puerto Rico"},
    {
        "name": "San Germán",
        "country_name": "Puerto Rico"},
    {
        "name": "San Juan",
        "country_name": "Puerto Rico"},
    {
        "name": "San Juan",
        "country_name": "Puerto Rico"},
    {
        "name": "San Lorenzo",
        "country_name": "Puerto Rico"},
    {
        "name": "San Sebastián",
        "country_name": "Puerto Rico"},
    {
        "name": "Santa Isabel",
        "country_name": "Puerto Rico"},
    {
        "name": "Toa Alta",
        "country_name": "Puerto Rico"},
    {
        "name": "Toa Baja",
        "country_name": "Puerto Rico"},
    {
        "name": "Toa Baja",
        "country_name": "Puerto Rico"},
    {
        "name": "Trujillo Alto",
        "country_name": "Puerto Rico"},
    {
        "name": "Trujillo Alto",
        "country_name": "Puerto Rico"},
    {
        "name": "Utuado",
        "country_name": "Puerto Rico"},
    {
        "name": "Vega Alta",
        "country_name": "Puerto Rico"},
    {
        "name": "Vega Baja",
        "country_name": "Puerto Rico"},
    {
        "name": "Vieques",
        "country_name": "Puerto Rico"},
    {
        "name": "Villalba",
        "country_name": "Puerto Rico"},
    {
        "name": "Yabucoa",
        "country_name": "Puerto Rico"},
    {
        "name": "Yauco",
        "country_name": "Puerto Rico"},
    {
        "name": "Al Daayen",
        "country_name": "Qatar"},
    {
        "name": "Al Khor",
        "country_name": "Qatar"},
    {
        "name": "Al Rayyan Municipality",
        "country_name": "Qatar"},
    {
        "name": "Al Wakrah",
        "country_name": "Qatar"},
    {
        "name": "Al-Shahaniya",
        "country_name": "Qatar"},
    {
        "name": "Doha",
        "country_name": "Qatar"},
    {
        "name": "Madinat ash Shamal",
        "country_name": "Qatar"},
    {
        "name": "Umm Salal Municipality",
        "country_name": "Qatar"},
    {
        "name": "Alba",
        "country_name": "Romania"},
    {
        "name": "Arad County",
        "country_name": "Romania"},
    {
        "name": "Arges",
        "country_name": "Romania"},
    {
        "name": "Bacău County",
        "country_name": "Romania"},
    {
        "name": "Bihor County",
        "country_name": "Romania"},
    {
        "name": "Bistrița-Năsăud County",
        "country_name": "Romania"},
    {
        "name": "Botoșani County",
        "country_name": "Romania"},
    {
        "name": "Braila",
        "country_name": "Romania"},
    {
        "name": "Brașov County",
        "country_name": "Romania"},
    {
        "name": "Bucharest",
        "country_name": "Romania"},
    {
        "name": "Buzău County",
        "country_name": "Romania"},
    {
        "name": "Călărași County",
        "country_name": "Romania"},
    {
        "name": "Caraș-Severin County",
        "country_name": "Romania"},
    {
        "name": "Cluj County",
        "country_name": "Romania"},
    {
        "name": "Constanța County",
        "country_name": "Romania"},
    {
        "name": "Covasna County",
        "country_name": "Romania"},
    {
        "name": "Dâmbovița County",
        "country_name": "Romania"},
    {
        "name": "Dolj County",
        "country_name": "Romania"},
    {
        "name": "Galați County",
        "country_name": "Romania"},
    {
        "name": "Giurgiu County",
        "country_name": "Romania"},
    {
        "name": "Gorj County",
        "country_name": "Romania"},
    {
        "name": "Harghita County",
        "country_name": "Romania"},
    {
        "name": "Hunedoara County",
        "country_name": "Romania"},
    {
        "name": "Ialomița County",
        "country_name": "Romania"},
    {
        "name": "Iași County",
        "country_name": "Romania"},
    {
        "name": "Ilfov County",
        "country_name": "Romania"},
    {
        "name": "Maramureș County",
        "country_name": "Romania"},
    {
        "name": "Mehedinți County",
        "country_name": "Romania"},
    {
        "name": "Mureș County",
        "country_name": "Romania"},
    {
        "name": "Neamț County",
        "country_name": "Romania"},
    {
        "name": "Olt County",
        "country_name": "Romania"},
    {
        "name": "Prahova County",
        "country_name": "Romania"},
    {
        "name": "Sălaj County",
        "country_name": "Romania"},
    {
        "name": "Satu Mare County",
        "country_name": "Romania"},
    {
        "name": "Sibiu County",
        "country_name": "Romania"},
    {
        "name": "Suceava County",
        "country_name": "Romania"},
    {
        "name": "Teleorman County",
        "country_name": "Romania"},
    {
        "name": "Timiș County",
        "country_name": "Romania"},
    {
        "name": "Tulcea County",
        "country_name": "Romania"},
    {
        "name": "Vâlcea County",
        "country_name": "Romania"},
    {
        "name": "Vaslui County",
        "country_name": "Romania"},
    {
        "name": "Vrancea County",
        "country_name": "Romania"},
    {
        "name": "Altai Krai",
        "country_name": "Russia"},
    {
        "name": "Altai Republic",
        "country_name": "Russia"},
    {
        "name": "Amur Oblast",
        "country_name": "Russia"},
    {
        "name": "Arkhangelsk",
        "country_name": "Russia"},
    {
        "name": "Astrakhan Oblast",
        "country_name": "Russia"},
    {
        "name": "Belgorod Oblast",
        "country_name": "Russia"},
    {
        "name": "Bryansk Oblast",
        "country_name": "Russia"},
    {
        "name": "Chechen Republic",
        "country_name": "Russia"},
    {
        "name": "Chelyabinsk Oblast",
        "country_name": "Russia"},
    {
        "name": "Chukotka Autonomous Okrug",
        "country_name": "Russia"},
    {
        "name": "Chuvash Republic",
        "country_name": "Russia"},
    {
        "name": "Irkutsk",
        "country_name": "Russia"},
    {
        "name": "Ivanovo Oblast",
        "country_name": "Russia"},
    {
        "name": "Jewish Autonomous Oblast",
        "country_name": "Russia"},
    {
        "name": "Kabardino-Balkar Republic",
        "country_name": "Russia"},
    {
        "name": "Kaliningrad",
        "country_name": "Russia"},
    {
        "name": "Kaluga Oblast",
        "country_name": "Russia"},
    {
        "name": "Kamchatka Krai",
        "country_name": "Russia"},
    {
        "name": "Karachay-Cherkess Republic",
        "country_name": "Russia"},
    {
        "name": "Kemerovo Oblast",
        "country_name": "Russia"},
    {
        "name": "Khabarovsk Krai",
        "country_name": "Russia"},
    {
        "name": "Khanty-Mansi Autonomous Okrug",
        "country_name": "Russia"},
    {
        "name": "Kirov Oblast",
        "country_name": "Russia"},
    {
        "name": "Komi Republic",
        "country_name": "Russia"},
    {
        "name": "Kostroma Oblast",
        "country_name": "Russia"},
    {
        "name": "Krasnodar Krai",
        "country_name": "Russia"},
    {
        "name": "Krasnoyarsk Krai",
        "country_name": "Russia"},
    {
        "name": "Kurgan Oblast",
        "country_name": "Russia"},
    {
        "name": "Kursk Oblast",
        "country_name": "Russia"},
    {
        "name": "Leningrad Oblast",
        "country_name": "Russia"},
    {
        "name": "Lipetsk Oblast",
        "country_name": "Russia"},
    {
        "name": "Magadan Oblast",
        "country_name": "Russia"},
    {
        "name": "Mari El Republic",
        "country_name": "Russia"},
    {
        "name": "Moscow",
        "country_name": "Russia"},
    {
        "name": "Moscow Oblast",
        "country_name": "Russia"},
    {
        "name": "Murmansk Oblast",
        "country_name": "Russia"},
    {
        "name": "Nenets Autonomous Okrug",
        "country_name": "Russia"},
    {
        "name": "Nizhny Novgorod Oblast",
        "country_name": "Russia"},
    {
        "name": "Novgorod Oblast",
        "country_name": "Russia"},
    {
        "name": "Novosibirsk",
        "country_name": "Russia"},
    {
        "name": "Omsk Oblast",
        "country_name": "Russia"},
    {
        "name": "Orenburg Oblast",
        "country_name": "Russia"},
    {
        "name": "Oryol Oblast",
        "country_name": "Russia"},
    {
        "name": "Penza Oblast",
        "country_name": "Russia"},
    {
        "name": "Perm Krai",
        "country_name": "Russia"},
    {
        "name": "Primorsky Krai",
        "country_name": "Russia"},
    {
        "name": "Pskov Oblast",
        "country_name": "Russia"},
    {
        "name": "Republic of Adygea",
        "country_name": "Russia"},
    {
        "name": "Republic of Bashkortostan",
        "country_name": "Russia"},
    {
        "name": "Republic of Buryatia",
        "country_name": "Russia"},
    {
        "name": "Republic of Dagestan",
        "country_name": "Russia"},
    {
        "name": "Republic of Ingushetia",
        "country_name": "Russia"},
    {
        "name": "Republic of Kalmykia",
        "country_name": "Russia"},
    {
        "name": "Republic of Karelia",
        "country_name": "Russia"},
    {
        "name": "Republic of Khakassia",
        "country_name": "Russia"},
    {
        "name": "Republic of Mordovia",
        "country_name": "Russia"},
    {
        "name": "Republic of North Ossetia-Alania",
        "country_name": "Russia"},
    {
        "name": "Republic of Tatarstan",
        "country_name": "Russia"},
    {
        "name": "Rostov Oblast",
        "country_name": "Russia"},
    {
        "name": "Ryazan Oblast",
        "country_name": "Russia"},
    {
        "name": "Saint Petersburg",
        "country_name": "Russia"},
    {
        "name": "Sakha Republic",
        "country_name": "Russia"},
    {
        "name": "Sakhalin",
        "country_name": "Russia"},
    {
        "name": "Samara Oblast",
        "country_name": "Russia"},
    {
        "name": "Saratov Oblast",
        "country_name": "Russia"},
    {
        "name": "Smolensk Oblast",
        "country_name": "Russia"},
    {
        "name": "Stavropol Krai",
        "country_name": "Russia"},
    {
        "name": "Sverdlovsk",
        "country_name": "Russia"},
    {
        "name": "Tambov Oblast",
        "country_name": "Russia"},
    {
        "name": "Tomsk Oblast",
        "country_name": "Russia"},
    {
        "name": "Tula Oblast",
        "country_name": "Russia"},
    {
        "name": "Tuva Republic",
        "country_name": "Russia"},
    {
        "name": "Tver Oblast",
        "country_name": "Russia"},
    {
        "name": "Tyumen Oblast",
        "country_name": "Russia"},
    {
        "name": "Udmurt Republic",
        "country_name": "Russia"},
    {
        "name": "Ulyanovsk Oblast",
        "country_name": "Russia"},
    {
        "name": "Vladimir Oblast",
        "country_name": "Russia"},
    {
        "name": "Volgograd Oblast",
        "country_name": "Russia"},
    {
        "name": "Vologda Oblast",
        "country_name": "Russia"},
    {
        "name": "Voronezh Oblast",
        "country_name": "Russia"},
    {
        "name": "Yamalo-Nenets Autonomous Okrug",
        "country_name": "Russia"},
    {
        "name": "Yaroslavl Oblast",
        "country_name": "Russia"},
    {
        "name": "Zabaykalsky Krai",
        "country_name": "Russia"},
    {
        "name": "Eastern Province",
        "country_name": "Rwanda"},
    {
        "name": "Kigali district",
        "country_name": "Rwanda"},
    {
        "name": "Northern Province",
        "country_name": "Rwanda"},
    {
        "name": "Southern Province",
        "country_name": "Rwanda"},
    {
        "name": "Western Province",
        "country_name": "Rwanda"},
    {
        "name": "Christ Church Nichola Town Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Nevis",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Anne Sandy Point Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint George Gingerland Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint James Windward Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint John Capisterre Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint John Figtree Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Kitts",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Mary Cayon Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Paul Capisterre Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Paul Charlestown Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Peter Basseterre Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Thomas Lowland Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Saint Thomas Middle Island Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Trinity Palmetto Point Parish",
        "country_name": "Saint Kitts And Nevis"},
    {
        "name": "Anse la Raye Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Canaries",
        "country_name": "Saint Lucia"},
    {
        "name": "Castries Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Choiseul Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Dauphin Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Dennery Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Gros Islet Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Laborie Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Micoud Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Praslin Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Soufrière Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Vieux Fort Quarter",
        "country_name": "Saint Lucia"},
    {
        "name": "Charlotte Parish",
        "country_name": "Saint Vincent And The Grenadines"},
    {
        "name": "Grenadines Parish",
        "country_name": "Saint Vincent And The Grenadines"},
    {
        "name": "Saint Andrew Parish",
        "country_name": "Saint Vincent And The Grenadines"},
    {
        "name": "Saint David Parish",
        "country_name": "Saint Vincent And The Grenadines"},
    {
        "name": "Saint George Parish",
        "country_name": "Saint Vincent And The Grenadines"},
    {
        "name": "Saint Patrick Parish",
        "country_name": "Saint Vincent And The Grenadines"},
    {
        "name": "A'ana",
        "country_name": "Samoa"},
    {
        "name": "Aiga-i-le-Tai",
        "country_name": "Samoa"},
    {
        "name": "Atua",
        "country_name": "Samoa"},
    {
        "name": "Fa'asaleleaga",
        "country_name": "Samoa"},
    {
        "name": "Gaga'emauga",
        "country_name": "Samoa"},
    {
        "name": "Gaga'ifomauga",
        "country_name": "Samoa"},
    {
        "name": "Palauli",
        "country_name": "Samoa"},
    {
        "name": "Satupa'itea",
        "country_name": "Samoa"},
    {
        "name": "Tuamasaga",
        "country_name": "Samoa"},
    {
        "name": "Va'a-o-Fonoti",
        "country_name": "Samoa"},
    {
        "name": "Vaisigano",
        "country_name": "Samoa"},
    {
        "name": "Acquaviva",
        "country_name": "San Marino"},
    {
        "name": "Borgo Maggiore",
        "country_name": "San Marino"},
    {
        "name": "Chiesanuova",
        "country_name": "San Marino"},
    {
        "name": "Domagnano",
        "country_name": "San Marino"},
    {
        "name": "Faetano",
        "country_name": "San Marino"},
    {
        "name": "Fiorentino",
        "country_name": "San Marino"},
    {
        "name": "Montegiardino",
        "country_name": "San Marino"},
    {
        "name": "San Marino",
        "country_name": "San Marino"},
    {
        "name": "Serravalle",
        "country_name": "San Marino"},
    {
        "name": "Príncipe Province",
        "country_name": "Sao Tome and Principe"},
    {
        "name": "São Tomé Province",
        "country_name": "Sao Tome and Principe"},
    {
        "name": "'Asir",
        "country_name": "Saudi Arabia"},
    {
        "name": "Al Bahah",
        "country_name": "Saudi Arabia"},
    {
        "name": "Al Jawf",
        "country_name": "Saudi Arabia"},
    {
        "name": "Al Madinah",
        "country_name": "Saudi Arabia"},
    {
        "name": "Al-Qassim",
        "country_name": "Saudi Arabia"},
    {
        "name": "Eastern Province",
        "country_name": "Saudi Arabia"},
    {
        "name": "Ha'il",
        "country_name": "Saudi Arabia"},
    {
        "name": "Jizan",
        "country_name": "Saudi Arabia"},
    {
        "name": "Makkah",
        "country_name": "Saudi Arabia"},
    {
        "name": "Najran",
        "country_name": "Saudi Arabia"},
    {
        "name": "Northern Borders",
        "country_name": "Saudi Arabia"},
    {
        "name": "Riyadh",
        "country_name": "Saudi Arabia"},
    {
        "name": "Tabuk",
        "country_name": "Saudi Arabia"},
    {
        "name": "Dakar",
        "country_name": "Senegal"},
    {
        "name": "Diourbel Region",
        "country_name": "Senegal"},
    {
        "name": "Fatick",
        "country_name": "Senegal"},
    {
        "name": "Kaffrine",
        "country_name": "Senegal"},
    {
        "name": "Kaolack",
        "country_name": "Senegal"},
    {
        "name": "Kédougou",
        "country_name": "Senegal"},
    {
        "name": "Kolda",
        "country_name": "Senegal"},
    {
        "name": "Louga",
        "country_name": "Senegal"},
    {
        "name": "Matam",
        "country_name": "Senegal"},
    {
        "name": "Saint-Louis",
        "country_name": "Senegal"},
    {
        "name": "Sédhiou",
        "country_name": "Senegal"},
    {
        "name": "Tambacounda Region",
        "country_name": "Senegal"},
    {
        "name": "Thiès Region",
        "country_name": "Senegal"},
    {
        "name": "Ziguinchor",
        "country_name": "Senegal"},
    {
        "name": "Belgrade",
        "country_name": "Serbia"},
    {
        "name": "Bor District",
        "country_name": "Serbia"},
    {
        "name": "Braničevo District",
        "country_name": "Serbia"},
    {
        "name": "Central Banat District",
        "country_name": "Serbia"},
    {
        "name": "Jablanica District",
        "country_name": "Serbia"},
    {
        "name": "Kolubara District",
        "country_name": "Serbia"},
    {
        "name": "Mačva District",
        "country_name": "Serbia"},
    {
        "name": "Moravica District",
        "country_name": "Serbia"},
    {
        "name": "Nišava District",
        "country_name": "Serbia"},
    {
        "name": "North Bačka District",
        "country_name": "Serbia"},
    {
        "name": "North Banat District",
        "country_name": "Serbia"},
    {
        "name": "Pčinja District",
        "country_name": "Serbia"},
    {
        "name": "Pirot District",
        "country_name": "Serbia"},
    {
        "name": "Podunavlje District",
        "country_name": "Serbia"},
    {
        "name": "Pomoravlje District",
        "country_name": "Serbia"},
    {
        "name": "Rasina District",
        "country_name": "Serbia"},
    {
        "name": "Raška District",
        "country_name": "Serbia"},
    {
        "name": "South Bačka District",
        "country_name": "Serbia"},
    {
        "name": "South Banat District",
        "country_name": "Serbia"},
    {
        "name": "Srem District",
        "country_name": "Serbia"},
    {
        "name": "Šumadija District",
        "country_name": "Serbia"},
    {
        "name": "Toplica District",
        "country_name": "Serbia"},
    {
        "name": "Vojvodina",
        "country_name": "Serbia"},
    {
        "name": "West Bačka District",
        "country_name": "Serbia"},
    {
        "name": "Zaječar District",
        "country_name": "Serbia"},
    {
        "name": "Zlatibor District",
        "country_name": "Serbia"},
    {
        "name": "Anse Boileau",
        "country_name": "Seychelles"},
    {
        "name": "Anse Royale",
        "country_name": "Seychelles"},
    {
        "name": "Anse-aux-Pins",
        "country_name": "Seychelles"},
    {
        "name": "Au Cap",
        "country_name": "Seychelles"},
    {
        "name": "Baie Lazare",
        "country_name": "Seychelles"},
    {
        "name": "Baie Sainte Anne",
        "country_name": "Seychelles"},
    {
        "name": "Beau Vallon",
        "country_name": "Seychelles"},
    {
        "name": "Bel Air",
        "country_name": "Seychelles"},
    {
        "name": "Bel Ombre",
        "country_name": "Seychelles"},
    {
        "name": "Cascade",
        "country_name": "Seychelles"},
    {
        "name": "Glacis",
        "country_name": "Seychelles"},
    {
        "name": "Grand'Anse Mahé",
        "country_name": "Seychelles"},
    {
        "name": "Grand'Anse Praslin",
        "country_name": "Seychelles"},
    {
        "name": "La Digue",
        "country_name": "Seychelles"},
    {
        "name": "La Rivière Anglaise",
        "country_name": "Seychelles"},
    {
        "name": "Les Mamelles",
        "country_name": "Seychelles"},
    {
        "name": "Mont Buxton",
        "country_name": "Seychelles"},
    {
        "name": "Mont Fleuri",
        "country_name": "Seychelles"},
    {
        "name": "Plaisance",
        "country_name": "Seychelles"},
    {
        "name": "Pointe La Rue",
        "country_name": "Seychelles"},
    {
        "name": "Port Glaud",
        "country_name": "Seychelles"},
    {
        "name": "Roche Caiman",
        "country_name": "Seychelles"},
    {
        "name": "Saint Louis",
        "country_name": "Seychelles"},
    {
        "name": "Takamaka",
        "country_name": "Seychelles"},
    {
        "name": "Eastern Province",
        "country_name": "Sierra Leone"},
    {
        "name": "Northern Province",
        "country_name": "Sierra Leone"},
    {
        "name": "Southern Province",
        "country_name": "Sierra Leone"},
    {
        "name": "Western Area",
        "country_name": "Sierra Leone"},
    {
        "name": "Central Singapore",
        "country_name": "Singapore"},
    {
        "name": "North East",
        "country_name": "Singapore"},
    {
        "name": "North West",
        "country_name": "Singapore"},
    {
        "name": "South East",
        "country_name": "Singapore"},
    {
        "name": "South West",
        "country_name": "Singapore"},
    {
        "name": "Banská Bystrica Region",
        "country_name": "Slovakia"},
    {
        "name": "Bratislava Region",
        "country_name": "Slovakia"},
    {
        "name": "Košice Region",
        "country_name": "Slovakia"},
    {
        "name": "Nitra Region",
        "country_name": "Slovakia"},
    {
        "name": "Prešov Region",
        "country_name": "Slovakia"},
    {
        "name": "Trenčín Region",
        "country_name": "Slovakia"},
    {
        "name": "Trnava Region",
        "country_name": "Slovakia"},
    {
        "name": "Žilina Region",
        "country_name": "Slovakia"},
    {
        "name": "Ajdovščina Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ankaran Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Beltinci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Benedikt Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Bistrica ob Sotli Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Bled Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Bloke Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Bohinj Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Borovnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Bovec Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Braslovče Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Brda Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Brežice Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Brezovica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Cankova Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Cerklje na Gorenjskem Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Cerknica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Cerkno Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Cerkvenjak Municipality",
        "country_name": "Slovenia"},
    {
        "name": "City Municipality of Celje",
        "country_name": "Slovenia"},
    {
        "name": "City Municipality of Novo Mesto",
        "country_name": "Slovenia"},
    {
        "name": "Črenšovci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Črna na Koroškem Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Črnomelj Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Destrnik Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Divača Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dobje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dobrepolje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dobrna Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dobrova–Polhov Gradec Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dobrovnik Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dol pri Ljubljani Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dolenjske Toplice Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Domžale Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dornava Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Dravograd Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Duplek Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Gorenja Vas–Poljane Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Gorišnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Gorje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Gornja Radgona Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Gornji Grad Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Gornji Petrovci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Grad Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Grosuplje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Hajdina Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Hoče–Slivnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Hodoš Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Horjul Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Hrastnik Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Hrpelje–Kozina Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Idrija Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ig Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ivančna Gorica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Izola Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Jesenice Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Jezersko Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Juršinci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kamnik Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kanal ob Soči Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kidričevo Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kobarid Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kobilje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kočevje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Komen Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Komenda Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Koper City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kostanjevica na Krki Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kostel Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kozje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kranj City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kranjska Gora Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Križevci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Kungota",
        "country_name": "Slovenia"},
    {
        "name": "Kuzma Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Laško Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Lenart Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Lendava Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Litija Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ljubljana City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ljubno Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ljutomer Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Log–Dragomer Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Logatec Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Loška Dolina Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Loški Potok Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Lovrenc na Pohorju Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Luče Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Lukovica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Majšperk Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Makole Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Maribor City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Markovci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Medvode Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Mengeš Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Metlika Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Mežica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Miklavž na Dravskem Polju Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Miren–Kostanjevica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Mirna Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Mirna Peč Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Mislinja Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Mokronog–Trebelno Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Moravče Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Moravske Toplice Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Mozirje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Municipality of Apače",
        "country_name": "Slovenia"},
    {
        "name": "Municipality of Cirkulane",
        "country_name": "Slovenia"},
    {
        "name": "Municipality of Ilirska Bistrica",
        "country_name": "Slovenia"},
    {
        "name": "Municipality of Krško",
        "country_name": "Slovenia"},
    {
        "name": "Municipality of Škofljica",
        "country_name": "Slovenia"},
    {
        "name": "Murska Sobota City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Muta Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Naklo Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Nazarje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Nova Gorica City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Odranci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Oplotnica",
        "country_name": "Slovenia"},
    {
        "name": "Ormož Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Osilnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Pesnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Piran Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Pivka Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Podčetrtek Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Podlehnik Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Podvelka Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Poljčane Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Polzela Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Postojna Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Prebold Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Preddvor Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Prevalje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ptuj City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Puconci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Rače–Fram Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Radeče Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Radenci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Radlje ob Dravi Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Radovljica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ravne na Koroškem Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Razkrižje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Rečica ob Savinji Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Renče–Vogrsko Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ribnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ribnica na Pohorju Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Rogaška Slatina Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Rogašovci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Rogatec Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Ruše Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šalovci Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Selnica ob Dravi Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Semič Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šempeter–Vrtojba Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šenčur Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šentilj Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šentjernej Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šentjur Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šentrupert Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sevnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sežana Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Škocjan Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Škofja Loka Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Slovenj Gradec City Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Slovenska Bistrica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Slovenske Konjice Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šmarje pri Jelšah Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šmarješke Toplice Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šmartno ob Paki Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šmartno pri Litiji Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sodražica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Solčava Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Šoštanj Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Središče ob Dravi",
        "country_name": "Slovenia"},
    {
        "name": "Starše Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Štore Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Straža Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sveta Ana Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sveta Trojica v Slovenskih Goricah Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sveti Andraž v Slovenskih Goricah Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sveti Jurij ob Ščavnici Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sveti Jurij v Slovenskih Goricah Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Sveti Tomaž Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Tabor Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Tišina Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Tolmin Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Trbovlje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Trebnje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Trnovska Vas Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Tržič Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Trzin Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Turnišče Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Velika Polana Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Velike Lašče Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Veržej Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Videm Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Vipava Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Vitanje Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Vodice Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Vojnik Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Vransko Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Vrhnika Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Vuzenica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Zagorje ob Savi Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Žalec Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Zavrč Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Železniki Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Žetale Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Žiri Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Žirovnica Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Zreče Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Žužemberk Municipality",
        "country_name": "Slovenia"},
    {
        "name": "Central Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Choiseul Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Guadalcanal Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Honiara",
        "country_name": "Solomon Islands"},
    {
        "name": "Isabel Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Makira-Ulawa Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Malaita Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Rennell and Bellona Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Temotu Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Western Province",
        "country_name": "Solomon Islands"},
    {
        "name": "Awdal Region",
        "country_name": "Somalia"},
    {
        "name": "Bakool",
        "country_name": "Somalia"},
    {
        "name": "Banaadir",
        "country_name": "Somalia"},
    {
        "name": "Bari",
        "country_name": "Somalia"},
    {
        "name": "Bay",
        "country_name": "Somalia"},
    {
        "name": "Galguduud",
        "country_name": "Somalia"},
    {
        "name": "Gedo",
        "country_name": "Somalia"},
    {
        "name": "Hiran",
        "country_name": "Somalia"},
    {
        "name": "Lower Juba",
        "country_name": "Somalia"},
    {
        "name": "Lower Shebelle",
        "country_name": "Somalia"},
    {
        "name": "Middle Juba",
        "country_name": "Somalia"},
    {
        "name": "Middle Shebelle",
        "country_name": "Somalia"},
    {
        "name": "Mudug",
        "country_name": "Somalia"},
    {
        "name": "Nugal",
        "country_name": "Somalia"},
    {
        "name": "Sanaag Region",
        "country_name": "Somalia"},
    {
        "name": "Togdheer Region",
        "country_name": "Somalia"},
    {
        "name": "Eastern Cape",
        "country_name": "South Africa"},
    {
        "name": "Free State",
        "country_name": "South Africa"},
    {
        "name": "Gauteng",
        "country_name": "South Africa"},
    {
        "name": "KwaZulu-Natal",
        "country_name": "South Africa"},
    {
        "name": "Limpopo",
        "country_name": "South Africa"},
    {
        "name": "Mpumalanga",
        "country_name": "South Africa"},
    {
        "name": "North West",
        "country_name": "South Africa"},
    {
        "name": "Northern Cape",
        "country_name": "South Africa"},
    {
        "name": "Western Cape",
        "country_name": "South Africa"},
    {
        "name": "Busan",
        "country_name": "South Korea"},
    {
        "name": "Daegu",
        "country_name": "South Korea"},
    {
        "name": "Daejeon",
        "country_name": "South Korea"},
    {
        "name": "Gangwon Province",
        "country_name": "South Korea"},
    {
        "name": "Gwangju",
        "country_name": "South Korea"},
    {
        "name": "Gyeonggi Province",
        "country_name": "South Korea"},
    {
        "name": "Incheon",
        "country_name": "South Korea"},
    {
        "name": "Jeju",
        "country_name": "South Korea"},
    {
        "name": "North Chungcheong Province",
        "country_name": "South Korea"},
    {
        "name": "North Gyeongsang Province",
        "country_name": "South Korea"},
    {
        "name": "North Jeolla Province",
        "country_name": "South Korea"},
    {
        "name": "Sejong City",
        "country_name": "South Korea"},
    {
        "name": "Seoul",
        "country_name": "South Korea"},
    {
        "name": "South Chungcheong Province",
        "country_name": "South Korea"},
    {
        "name": "South Gyeongsang Province",
        "country_name": "South Korea"},
    {
        "name": "South Jeolla Province",
        "country_name": "South Korea"},
    {
        "name": "Ulsan",
        "country_name": "South Korea"},
    {
        "name": "Central Equatoria",
        "country_name": "South Sudan"},
    {
        "name": "Eastern Equatoria",
        "country_name": "South Sudan"},
    {
        "name": "Jonglei State",
        "country_name": "South Sudan"},
    {
        "name": "Lakes",
        "country_name": "South Sudan"},
    {
        "name": "Northern Bahr el Ghazal",
        "country_name": "South Sudan"},
    {
        "name": "Unity",
        "country_name": "South Sudan"},
    {
        "name": "Upper Nile",
        "country_name": "South Sudan"},
    {
        "name": "Warrap",
        "country_name": "South Sudan"},
    {
        "name": "Western Bahr el Ghazal",
        "country_name": "South Sudan"},
    {
        "name": "Western Equatoria",
        "country_name": "South Sudan"},
    {
        "name": "A Coruña",
        "country_name": "Spain"},
    {
        "name": "Albacete",
        "country_name": "Spain"},
    {
        "name": "Alicante",
        "country_name": "Spain"},
    {
        "name": "Almeria",
        "country_name": "Spain"},
    {
        "name": "Araba",
        "country_name": "Spain"},
    {
        "name": "Asturias",
        "country_name": "Spain"},
    {
        "name": "Ávila",
        "country_name": "Spain"},
    {
        "name": "Badajoz",
        "country_name": "Spain"},
    {
        "name": "Barcelona",
        "country_name": "Spain"},
    {
        "name": "Bizkaia",
        "country_name": "Spain"},
    {
        "name": "Burgos",
        "country_name": "Spain"},
    {
        "name": "Caceres",
        "country_name": "Spain"},
    {
        "name": "Cádiz",
        "country_name": "Spain"},
    {
        "name": "Canarias",
        "country_name": "Spain"},
    {
        "name": "Cantabria",
        "country_name": "Spain"},
    {
        "name": "Castellón",
        "country_name": "Spain"},
    {
        "name": "Ceuta",
        "country_name": "Spain"},
    {
        "name": "Ciudad Real",
        "country_name": "Spain"},
    {
        "name": "Córdoba",
        "country_name": "Spain"},
    {
        "name": "Cuenca",
        "country_name": "Spain"},
    {
        "name": "Gipuzkoa",
        "country_name": "Spain"},
    {
        "name": "Girona",
        "country_name": "Spain"},
    {
        "name": "Granada",
        "country_name": "Spain"},
    {
        "name": "Guadalajara",
        "country_name": "Spain"},
    {
        "name": "Huelva",
        "country_name": "Spain"},
    {
        "name": "Huesca",
        "country_name": "Spain"},
    {
        "name": "Islas Baleares",
        "country_name": "Spain"},
    {
        "name": "Jaén",
        "country_name": "Spain"},
    {
        "name": "La Rioja",
        "country_name": "Spain"},
    {
        "name": "Las Palmas",
        "country_name": "Spain"},
    {
        "name": "Léon",
        "country_name": "Spain"},
    {
        "name": "Lleida",
        "country_name": "Spain"},
    {
        "name": "Lugo",
        "country_name": "Spain"},
    {
        "name": "Madrid",
        "country_name": "Spain"},
    {
        "name": "Málaga",
        "country_name": "Spain"},
    {
        "name": "Melilla",
        "country_name": "Spain"},
    {
        "name": "Murcia",
        "country_name": "Spain"},
    {
        "name": "Navarra",
        "country_name": "Spain"},
    {
        "name": "Ourense",
        "country_name": "Spain"},
    {
        "name": "Palencia",
        "country_name": "Spain"},
    {
        "name": "Pontevedra",
        "country_name": "Spain"},
    {
        "name": "Salamanca",
        "country_name": "Spain"},
    {
        "name": "Santa Cruz de Tenerife",
        "country_name": "Spain"},
    {
        "name": "Segovia",
        "country_name": "Spain"},
    {
        "name": "Sevilla",
        "country_name": "Spain"},
    {
        "name": "Soria",
        "country_name": "Spain"},
    {
        "name": "Tarragona",
        "country_name": "Spain"},
    {
        "name": "Teruel",
        "country_name": "Spain"},
    {
        "name": "Toledo",
        "country_name": "Spain"},
    {
        "name": "Valencia",
        "country_name": "Spain"},
    {
        "name": "Valladolid",
        "country_name": "Spain"},
    {
        "name": "Zamora",
        "country_name": "Spain"},
    {
        "name": "Zaragoza",
        "country_name": "Spain"},
    {
        "name": "Ampara District",
        "country_name": "Sri Lanka"},
    {
        "name": "Anuradhapura District",
        "country_name": "Sri Lanka"},
    {
        "name": "Badulla District",
        "country_name": "Sri Lanka"},
    {
        "name": "Batticaloa District",
        "country_name": "Sri Lanka"},
    {
        "name": "Central Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Colombo District",
        "country_name": "Sri Lanka"},
    {
        "name": "Eastern Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Galle District",
        "country_name": "Sri Lanka"},
    {
        "name": "Gampaha District",
        "country_name": "Sri Lanka"},
    {
        "name": "Hambantota District",
        "country_name": "Sri Lanka"},
    {
        "name": "Jaffna District",
        "country_name": "Sri Lanka"},
    {
        "name": "Kalutara District",
        "country_name": "Sri Lanka"},
    {
        "name": "Kandy District",
        "country_name": "Sri Lanka"},
    {
        "name": "Kegalle District",
        "country_name": "Sri Lanka"},
    {
        "name": "Kilinochchi District",
        "country_name": "Sri Lanka"},
    {
        "name": "Mannar District",
        "country_name": "Sri Lanka"},
    {
        "name": "Matale District",
        "country_name": "Sri Lanka"},
    {
        "name": "Matara District",
        "country_name": "Sri Lanka"},
    {
        "name": "Monaragala District",
        "country_name": "Sri Lanka"},
    {
        "name": "Mullaitivu District",
        "country_name": "Sri Lanka"},
    {
        "name": "North Central Province",
        "country_name": "Sri Lanka"},
    {
        "name": "North Western Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Northern Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Nuwara Eliya District",
        "country_name": "Sri Lanka"},
    {
        "name": "Polonnaruwa District",
        "country_name": "Sri Lanka"},
    {
        "name": "Puttalam District",
        "country_name": "Sri Lanka"},
    {
        "name": "Ratnapura district",
        "country_name": "Sri Lanka"},
    {
        "name": "Sabaragamuwa Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Southern Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Trincomalee District",
        "country_name": "Sri Lanka"},
    {
        "name": "Uva Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Vavuniya District",
        "country_name": "Sri Lanka"},
    {
        "name": "Western Province",
        "country_name": "Sri Lanka"},
    {
        "name": "Al Jazirah",
        "country_name": "Sudan"},
    {
        "name": "Al Qadarif",
        "country_name": "Sudan"},
    {
        "name": "Blue Nile",
        "country_name": "Sudan"},
    {
        "name": "Central Darfur",
        "country_name": "Sudan"},
    {
        "name": "East Darfur",
        "country_name": "Sudan"},
    {
        "name": "Kassala",
        "country_name": "Sudan"},
    {
        "name": "Khartoum",
        "country_name": "Sudan"},
    {
        "name": "North Darfur",
        "country_name": "Sudan"},
    {
        "name": "North Kordofan",
        "country_name": "Sudan"},
    {
        "name": "Northern",
        "country_name": "Sudan"},
    {
        "name": "Red Sea",
        "country_name": "Sudan"},
    {
        "name": "River Nile",
        "country_name": "Sudan"},
    {
        "name": "Sennar",
        "country_name": "Sudan"},
    {
        "name": "South Darfur",
        "country_name": "Sudan"},
    {
        "name": "South Kordofan",
        "country_name": "Sudan"},
    {
        "name": "West Darfur",
        "country_name": "Sudan"},
    {
        "name": "West Kordofan",
        "country_name": "Sudan"},
    {
        "name": "White Nile",
        "country_name": "Sudan"},
    {
        "name": "Brokopondo District",
        "country_name": "Suriname"},
    {
        "name": "Commewijne District",
        "country_name": "Suriname"},
    {
        "name": "Coronie District",
        "country_name": "Suriname"},
    {
        "name": "Marowijne District",
        "country_name": "Suriname"},
    {
        "name": "Nickerie District",
        "country_name": "Suriname"},
    {
        "name": "Para District",
        "country_name": "Suriname"},
    {
        "name": "Paramaribo District",
        "country_name": "Suriname"},
    {
        "name": "Saramacca District",
        "country_name": "Suriname"},
    {
        "name": "Sipaliwini District",
        "country_name": "Suriname"},
    {
        "name": "Wanica District",
        "country_name": "Suriname"},
    {
        "name": "Hhohho District",
        "country_name": "Swaziland"},
    {
        "name": "Lubombo District",
        "country_name": "Swaziland"},
    {
        "name": "Manzini District",
        "country_name": "Swaziland"},
    {
        "name": "Shiselweni District",
        "country_name": "Swaziland"},
    {
        "name": "Blekinge County",
        "country_name": "Sweden"},
    {
        "name": "Dalarna County",
        "country_name": "Sweden"},
    {
        "name": "Gävleborg County",
        "country_name": "Sweden"},
    {
        "name": "Gotland County",
        "country_name": "Sweden"},
    {
        "name": "Halland County",
        "country_name": "Sweden"},
    {
        "name": "Jämtland County",
        "country_name": "Sweden"},
    {
        "name": "Jönköping County",
        "country_name": "Sweden"},
    {
        "name": "Kalmar County",
        "country_name": "Sweden"},
    {
        "name": "Kronoberg County",
        "country_name": "Sweden"},
    {
        "name": "Norrbotten County",
        "country_name": "Sweden"},
    {
        "name": "Örebro County",
        "country_name": "Sweden"},
    {
        "name": "Östergötland County",
        "country_name": "Sweden"},
    {
        "name": "Skåne County",
        "country_name": "Sweden"},
    {
        "name": "Södermanland County",
        "country_name": "Sweden"},
    {
        "name": "Stockholm County",
        "country_name": "Sweden"},
    {
        "name": "Uppsala County",
        "country_name": "Sweden"},
    {
        "name": "Värmland County",
        "country_name": "Sweden"},
    {
        "name": "Västerbotten County",
        "country_name": "Sweden"},
    {
        "name": "Västernorrland County",
        "country_name": "Sweden"},
    {
        "name": "Västmanland County",
        "country_name": "Sweden"},
    {
        "name": "Västra Götaland County",
        "country_name": "Sweden"},
    {
        "name": "Aargau",
        "country_name": "Switzerland"},
    {
        "name": "Appenzell Ausserrhoden",
        "country_name": "Switzerland"},
    {
        "name": "Appenzell Innerrhoden",
        "country_name": "Switzerland"},
    {
        "name": "Basel-Land",
        "country_name": "Switzerland"},
    {
        "name": "Basel-Stadt",
        "country_name": "Switzerland"},
    {
        "name": "Bern",
        "country_name": "Switzerland"},
    {
        "name": "Fribourg",
        "country_name": "Switzerland"},
    {
        "name": "Geneva",
        "country_name": "Switzerland"},
    {
        "name": "Glarus",
        "country_name": "Switzerland"},
    {
        "name": "Graubünden",
        "country_name": "Switzerland"},
    {
        "name": "Jura",
        "country_name": "Switzerland"},
    {
        "name": "Lucerne",
        "country_name": "Switzerland"},
    {
        "name": "Neuchâtel",
        "country_name": "Switzerland"},
    {
        "name": "Nidwalden",
        "country_name": "Switzerland"},
    {
        "name": "Obwalden",
        "country_name": "Switzerland"},
    {
        "name": "Schaffhausen",
        "country_name": "Switzerland"},
    {
        "name": "Schwyz",
        "country_name": "Switzerland"},
    {
        "name": "Solothurn",
        "country_name": "Switzerland"},
    {
        "name": "St. Gallen",
        "country_name": "Switzerland"},
    {
        "name": "Thurgau",
        "country_name": "Switzerland"},
    {
        "name": "Ticino",
        "country_name": "Switzerland"},
    {
        "name": "Uri",
        "country_name": "Switzerland"},
    {
        "name": "Valais",
        "country_name": "Switzerland"},
    {
        "name": "Vaud",
        "country_name": "Switzerland"},
    {
        "name": "Zug",
        "country_name": "Switzerland"},
    {
        "name": "Zürich",
        "country_name": "Switzerland"},
    {
        "name": "Al-Hasakah",
        "country_name": "Syria"},
    {
        "name": "Al-Raqqah",
        "country_name": "Syria"},
    {
        "name": "Aleppo",
        "country_name": "Syria"},
    {
        "name": "As-Suwayda",
        "country_name": "Syria"},
    {
        "name": "Damascus",
        "country_name": "Syria"},
    {
        "name": "Daraa",
        "country_name": "Syria"},
    {
        "name": "Deir ez-Zor",
        "country_name": "Syria"},
    {
        "name": "Hama",
        "country_name": "Syria"},
    {
        "name": "Homs",
        "country_name": "Syria"},
    {
        "name": "Idlib",
        "country_name": "Syria"},
    {
        "name": "Latakia",
        "country_name": "Syria"},
    {
        "name": "Quneitra",
        "country_name": "Syria"},
    {
        "name": "Rif Dimashq",
        "country_name": "Syria"},
    {
        "name": "Tartus",
        "country_name": "Syria"},
    {
        "name": "Changhua",
        "country_name": "Taiwan"},
    {
        "name": "Chiayi",
        "country_name": "Taiwan"},
    {
        "name": "Chiayi",
        "country_name": "Taiwan"},
    {
        "name": "Hsinchu",
        "country_name": "Taiwan"},
    {
        "name": "Hsinchu",
        "country_name": "Taiwan"},
    {
        "name": "Hualien",
        "country_name": "Taiwan"},
    {
        "name": "Kaohsiung",
        "country_name": "Taiwan"},
    {
        "name": "Keelung",
        "country_name": "Taiwan"},
    {
        "name": "Kinmen",
        "country_name": "Taiwan"},
    {
        "name": "Lienchiang",
        "country_name": "Taiwan"},
    {
        "name": "Miaoli",
        "country_name": "Taiwan"},
    {
        "name": "Nantou",
        "country_name": "Taiwan"},
    {
        "name": "New Taipei",
        "country_name": "Taiwan"},
    {
        "name": "Penghu",
        "country_name": "Taiwan"},
    {
        "name": "Pingtung",
        "country_name": "Taiwan"},
    {
        "name": "Taichung",
        "country_name": "Taiwan"},
    {
        "name": "Tainan",
        "country_name": "Taiwan"},
    {
        "name": "Taipei",
        "country_name": "Taiwan"},
    {
        "name": "Taitung",
        "country_name": "Taiwan"},
    {
        "name": "Taoyuan",
        "country_name": "Taiwan"},
    {
        "name": "Yilan",
        "country_name": "Taiwan"},
    {
        "name": "Yunlin",
        "country_name": "Taiwan"},
    {
        "name": "districts of Republican Subordination",
        "country_name": "Tajikistan"},
    {
        "name": "Gorno-Badakhshan Autonomous Province",
        "country_name": "Tajikistan"},
    {
        "name": "Khatlon Province",
        "country_name": "Tajikistan"},
    {
        "name": "Sughd Province",
        "country_name": "Tajikistan"},
    {
        "name": "Arusha",
        "country_name": "Tanzania"},
    {
        "name": "Dar es Salaam",
        "country_name": "Tanzania"},
    {
        "name": "Dodoma",
        "country_name": "Tanzania"},
    {
        "name": "Geita",
        "country_name": "Tanzania"},
    {
        "name": "Iringa",
        "country_name": "Tanzania"},
    {
        "name": "Kagera",
        "country_name": "Tanzania"},
    {
        "name": "Katavi",
        "country_name": "Tanzania"},
    {
        "name": "Kigoma",
        "country_name": "Tanzania"},
    {
        "name": "Kilimanjaro",
        "country_name": "Tanzania"},
    {
        "name": "Lindi",
        "country_name": "Tanzania"},
    {
        "name": "Manyara",
        "country_name": "Tanzania"},
    {
        "name": "Mara",
        "country_name": "Tanzania"},
    {
        "name": "Mbeya",
        "country_name": "Tanzania"},
    {
        "name": "Morogoro",
        "country_name": "Tanzania"},
    {
        "name": "Mtwara",
        "country_name": "Tanzania"},
    {
        "name": "Mwanza",
        "country_name": "Tanzania"},
    {
        "name": "Njombe",
        "country_name": "Tanzania"},
    {
        "name": "Pemba North",
        "country_name": "Tanzania"},
    {
        "name": "Pemba South",
        "country_name": "Tanzania"},
    {
        "name": "Pwani",
        "country_name": "Tanzania"},
    {
        "name": "Rukwa",
        "country_name": "Tanzania"},
    {
        "name": "Ruvuma",
        "country_name": "Tanzania"},
    {
        "name": "Shinyanga",
        "country_name": "Tanzania"},
    {
        "name": "Simiyu",
        "country_name": "Tanzania"},
    {
        "name": "Singida",
        "country_name": "Tanzania"},
    {
        "name": "Songwe",
        "country_name": "Tanzania"},
    {
        "name": "Tabora",
        "country_name": "Tanzania"},
    {
        "name": "Tanga",
        "country_name": "Tanzania"},
    {
        "name": "Zanzibar North",
        "country_name": "Tanzania"},
    {
        "name": "Zanzibar South",
        "country_name": "Tanzania"},
    {
        "name": "Zanzibar West",
        "country_name": "Tanzania"},
    {
        "name": "Amnat Charoen",
        "country_name": "Thailand"},
    {
        "name": "Ang Thong",
        "country_name": "Thailand"},
    {
        "name": "Bangkok",
        "country_name": "Thailand"},
    {
        "name": "Bueng Kan",
        "country_name": "Thailand"},
    {
        "name": "Buri Ram",
        "country_name": "Thailand"},
    {
        "name": "Chachoengsao",
        "country_name": "Thailand"},
    {
        "name": "Chai Nat",
        "country_name": "Thailand"},
    {
        "name": "Chaiyaphum",
        "country_name": "Thailand"},
    {
        "name": "Chanthaburi",
        "country_name": "Thailand"},
    {
        "name": "Chiang Mai",
        "country_name": "Thailand"},
    {
        "name": "Chiang Rai",
        "country_name": "Thailand"},
    {
        "name": "Chon Buri",
        "country_name": "Thailand"},
    {
        "name": "Chumphon",
        "country_name": "Thailand"},
    {
        "name": "Kalasin",
        "country_name": "Thailand"},
    {
        "name": "Kamphaeng Phet",
        "country_name": "Thailand"},
    {
        "name": "Kanchanaburi",
        "country_name": "Thailand"},
    {
        "name": "Khon Kaen",
        "country_name": "Thailand"},
    {
        "name": "Krabi",
        "country_name": "Thailand"},
    {
        "name": "Lampang",
        "country_name": "Thailand"},
    {
        "name": "Lamphun",
        "country_name": "Thailand"},
    {
        "name": "Loei",
        "country_name": "Thailand"},
    {
        "name": "Lop Buri",
        "country_name": "Thailand"},
    {
        "name": "Mae Hong Son",
        "country_name": "Thailand"},
    {
        "name": "Maha Sarakham",
        "country_name": "Thailand"},
    {
        "name": "Mukdahan",
        "country_name": "Thailand"},
    {
        "name": "Nakhon Nayok",
        "country_name": "Thailand"},
    {
        "name": "Nakhon Pathom",
        "country_name": "Thailand"},
    {
        "name": "Nakhon Phanom",
        "country_name": "Thailand"},
    {
        "name": "Nakhon Ratchasima",
        "country_name": "Thailand"},
    {
        "name": "Nakhon Sawan",
        "country_name": "Thailand"},
    {
        "name": "Nakhon Si Thammarat",
        "country_name": "Thailand"},
    {
        "name": "Nan",
        "country_name": "Thailand"},
    {
        "name": "Narathiwat",
        "country_name": "Thailand"},
    {
        "name": "Nong Bua Lam Phu",
        "country_name": "Thailand"},
    {
        "name": "Nong Khai",
        "country_name": "Thailand"},
    {
        "name": "Nonthaburi",
        "country_name": "Thailand"},
    {
        "name": "Pathum Thani",
        "country_name": "Thailand"},
    {
        "name": "Pattani",
        "country_name": "Thailand"},
    {
        "name": "Pattaya",
        "country_name": "Thailand"},
    {
        "name": "Phangnga",
        "country_name": "Thailand"},
    {
        "name": "Phatthalung",
        "country_name": "Thailand"},
    {
        "name": "Phayao",
        "country_name": "Thailand"},
    {
        "name": "Phetchabun",
        "country_name": "Thailand"},
    {
        "name": "Phetchaburi",
        "country_name": "Thailand"},
    {
        "name": "Phichit",
        "country_name": "Thailand"},
    {
        "name": "Phitsanulok",
        "country_name": "Thailand"},
    {
        "name": "Phra Nakhon Si Ayutthaya",
        "country_name": "Thailand"},
    {
        "name": "Phrae",
        "country_name": "Thailand"},
    {
        "name": "Phuket",
        "country_name": "Thailand"},
    {
        "name": "Prachin Buri",
        "country_name": "Thailand"},
    {
        "name": "Prachuap Khiri Khan",
        "country_name": "Thailand"},
    {
        "name": "Ranong",
        "country_name": "Thailand"},
    {
        "name": "Ratchaburi",
        "country_name": "Thailand"},
    {
        "name": "Rayong",
        "country_name": "Thailand"},
    {
        "name": "Roi Et",
        "country_name": "Thailand"},
    {
        "name": "Sa Kaeo",
        "country_name": "Thailand"},
    {
        "name": "Sakon Nakhon",
        "country_name": "Thailand"},
    {
        "name": "Samut Prakan",
        "country_name": "Thailand"},
    {
        "name": "Samut Sakhon",
        "country_name": "Thailand"},
    {
        "name": "Samut Songkhram",
        "country_name": "Thailand"},
    {
        "name": "Saraburi",
        "country_name": "Thailand"},
    {
        "name": "Satun",
        "country_name": "Thailand"},
    {
        "name": "Si Sa Ket",
        "country_name": "Thailand"},
    {
        "name": "Sing Buri",
        "country_name": "Thailand"},
    {
        "name": "Songkhla",
        "country_name": "Thailand"},
    {
        "name": "Sukhothai",
        "country_name": "Thailand"},
    {
        "name": "Suphan Buri",
        "country_name": "Thailand"},
    {
        "name": "Surat Thani",
        "country_name": "Thailand"},
    {
        "name": "Surin",
        "country_name": "Thailand"},
    {
        "name": "Tak",
        "country_name": "Thailand"},
    {
        "name": "Trang",
        "country_name": "Thailand"},
    {
        "name": "Trat",
        "country_name": "Thailand"},
    {
        "name": "Ubon Ratchathani",
        "country_name": "Thailand"},
    {
        "name": "Udon Thani",
        "country_name": "Thailand"},
    {
        "name": "Uthai Thani",
        "country_name": "Thailand"},
    {
        "name": "Uttaradit",
        "country_name": "Thailand"},
    {
        "name": "Yala",
        "country_name": "Thailand"},
    {
        "name": "Yasothon",
        "country_name": "Thailand"},
    {
        "name": "Acklins",
        "country_name": "The Bahamas"},
    {
        "name": "Acklins and Crooked Islands",
        "country_name": "The Bahamas"},
    {
        "name": "Berry Islands",
        "country_name": "The Bahamas"},
    {
        "name": "Bimini",
        "country_name": "The Bahamas"},
    {
        "name": "Black Point",
        "country_name": "The Bahamas"},
    {
        "name": "Cat Island",
        "country_name": "The Bahamas"},
    {
        "name": "Central Abaco",
        "country_name": "The Bahamas"},
    {
        "name": "Central Andros",
        "country_name": "The Bahamas"},
    {
        "name": "Central Eleuthera",
        "country_name": "The Bahamas"},
    {
        "name": "Crooked Island",
        "country_name": "The Bahamas"},
    {
        "name": "East Grand Bahama",
        "country_name": "The Bahamas"},
    {
        "name": "Exuma",
        "country_name": "The Bahamas"},
    {
        "name": "Freeport",
        "country_name": "The Bahamas"},
    {
        "name": "Fresh Creek",
        "country_name": "The Bahamas"},
    {
        "name": "Governor's Harbour",
        "country_name": "The Bahamas"},
    {
        "name": "Grand Cay",
        "country_name": "The Bahamas"},
    {
        "name": "Green Turtle Cay",
        "country_name": "The Bahamas"},
    {
        "name": "Harbour Island",
        "country_name": "The Bahamas"},
    {
        "name": "High Rock",
        "country_name": "The Bahamas"},
    {
        "name": "Hope Town",
        "country_name": "The Bahamas"},
    {
        "name": "Inagua",
        "country_name": "The Bahamas"},
    {
        "name": "Kemps Bay",
        "country_name": "The Bahamas"},
    {
        "name": "Long Island",
        "country_name": "The Bahamas"},
    {
        "name": "Mangrove Cay",
        "country_name": "The Bahamas"},
    {
        "name": "Marsh Harbour",
        "country_name": "The Bahamas"},
    {
        "name": "Mayaguana District",
        "country_name": "The Bahamas"},
    {
        "name": "New Providence",
        "country_name": "The Bahamas"},
    {
        "name": "Nichollstown and Berry Islands",
        "country_name": "The Bahamas"},
    {
        "name": "North Abaco",
        "country_name": "The Bahamas"},
    {
        "name": "North Andros",
        "country_name": "The Bahamas"},
    {
        "name": "North Eleuthera",
        "country_name": "The Bahamas"},
    {
        "name": "Ragged Island",
        "country_name": "The Bahamas"},
    {
        "name": "Rock Sound",
        "country_name": "The Bahamas"},
    {
        "name": "Rum Cay District",
        "country_name": "The Bahamas"},
    {
        "name": "San Salvador and Rum Cay",
        "country_name": "The Bahamas"},
    {
        "name": "San Salvador Island",
        "country_name": "The Bahamas"},
    {
        "name": "Sandy Point",
        "country_name": "The Bahamas"},
    {
        "name": "South Abaco",
        "country_name": "The Bahamas"},
    {
        "name": "South Andros",
        "country_name": "The Bahamas"},
    {
        "name": "South Eleuthera",
        "country_name": "The Bahamas"},
    {
        "name": "Spanish Wells",
        "country_name": "The Bahamas"},
    {
        "name": "West Grand Bahama",
        "country_name": "The Bahamas"},
    {
        "name": "Centrale Region",
        "country_name": "Togo"},
    {
        "name": "Kara Region",
        "country_name": "Togo"},
    {
        "name": "Maritime",
        "country_name": "Togo"},
    {
        "name": "Plateaux Region",
        "country_name": "Togo"},
    {
        "name": "Savanes Region",
        "country_name": "Togo"},
    {
        "name": "Haʻapai",
        "country_name": "Tonga"},
    {
        "name": "ʻEua",
        "country_name": "Tonga"},
    {
        "name": "Niuas",
        "country_name": "Tonga"},
    {
        "name": "Tongatapu",
        "country_name": "Tonga"},
    {
        "name": "Vavaʻu",
        "country_name": "Tonga"},
    {
        "name": "Arima",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Chaguanas",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Couva-Tabaquite-Talparo Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Diego Martin Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Eastern Tobago",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Penal-Debe Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Point Fortin",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Port of Spain",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Princes Town Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Rio Claro-Mayaro Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "San Fernando",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "San Juan-Laventille Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Sangre Grande Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Siparia Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Tunapuna-Piarco Regional Corporation",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Western Tobago",
        "country_name": "Trinidad And Tobago"},
    {
        "name": "Ariana",
        "country_name": "Tunisia"},
    {
        "name": "Béja",
        "country_name": "Tunisia"},
    {
        "name": "Ben Arous",
        "country_name": "Tunisia"},
    {
        "name": "Bizerte",
        "country_name": "Tunisia"},
    {
        "name": "Gabès",
        "country_name": "Tunisia"},
    {
        "name": "Gafsa",
        "country_name": "Tunisia"},
    {
        "name": "Jendouba",
        "country_name": "Tunisia"},
    {
        "name": "Kairouan",
        "country_name": "Tunisia"},
    {
        "name": "Kasserine",
        "country_name": "Tunisia"},
    {
        "name": "Kebili",
        "country_name": "Tunisia"},
    {
        "name": "Kef",
        "country_name": "Tunisia"},
    {
        "name": "Mahdia",
        "country_name": "Tunisia"},
    {
        "name": "Manouba",
        "country_name": "Tunisia"},
    {
        "name": "Medenine",
        "country_name": "Tunisia"},
    {
        "name": "Monastir",
        "country_name": "Tunisia"},
    {
        "name": "Nabeul",
        "country_name": "Tunisia"},
    {
        "name": "Sfax",
        "country_name": "Tunisia"},
    {
        "name": "Sidi Bouzid",
        "country_name": "Tunisia"},
    {
        "name": "Siliana",
        "country_name": "Tunisia"},
    {
        "name": "Sousse",
        "country_name": "Tunisia"},
    {
        "name": "Tataouine",
        "country_name": "Tunisia"},
    {
        "name": "Tozeur",
        "country_name": "Tunisia"},
    {
        "name": "Tunis",
        "country_name": "Tunisia"},
    {
        "name": "Zaghouan",
        "country_name": "Tunisia"},
    {
        "name": "Adana",
        "country_name": "Turkey"},
    {
        "name": "Adıyaman",
        "country_name": "Turkey"},
    {
        "name": "Afyonkarahisar",
        "country_name": "Turkey"},
    {
        "name": "Ağrı",
        "country_name": "Turkey"},
    {
        "name": "Aksaray",
        "country_name": "Turkey"},
    {
        "name": "Amasya",
        "country_name": "Turkey"},
    {
        "name": "Ankara",
        "country_name": "Turkey"},
    {
        "name": "Antalya",
        "country_name": "Turkey"},
    {
        "name": "Ardahan",
        "country_name": "Turkey"},
    {
        "name": "Artvin",
        "country_name": "Turkey"},
    {
        "name": "Aydın",
        "country_name": "Turkey"},
    {
        "name": "Balıkesir",
        "country_name": "Turkey"},
    {
        "name": "Bartın",
        "country_name": "Turkey"},
    {
        "name": "Batman",
        "country_name": "Turkey"},
    {
        "name": "Bayburt",
        "country_name": "Turkey"},
    {
        "name": "Bilecik",
        "country_name": "Turkey"},
    {
        "name": "Bingöl",
        "country_name": "Turkey"},
    {
        "name": "Bitlis",
        "country_name": "Turkey"},
    {
        "name": "Bolu",
        "country_name": "Turkey"},
    {
        "name": "Burdur",
        "country_name": "Turkey"},
    {
        "name": "Bursa",
        "country_name": "Turkey"},
    {
        "name": "Çanakkale",
        "country_name": "Turkey"},
    {
        "name": "Çankırı",
        "country_name": "Turkey"},
    {
        "name": "Çorum",
        "country_name": "Turkey"},
    {
        "name": "Denizli",
        "country_name": "Turkey"},
    {
        "name": "Diyarbakır",
        "country_name": "Turkey"},
    {
        "name": "Düzce",
        "country_name": "Turkey"},
    {
        "name": "Edirne",
        "country_name": "Turkey"},
    {
        "name": "Elazığ",
        "country_name": "Turkey"},
    {
        "name": "Erzincan",
        "country_name": "Turkey"},
    {
        "name": "Erzurum",
        "country_name": "Turkey"},
    {
        "name": "Eskişehir",
        "country_name": "Turkey"},
    {
        "name": "Gaziantep",
        "country_name": "Turkey"},
    {
        "name": "Giresun",
        "country_name": "Turkey"},
    {
        "name": "Gümüşhane",
        "country_name": "Turkey"},
    {
        "name": "Hakkâri",
        "country_name": "Turkey"},
    {
        "name": "Hatay",
        "country_name": "Turkey"},
    {
        "name": "Iğdır",
        "country_name": "Turkey"},
    {
        "name": "Isparta",
        "country_name": "Turkey"},
    {
        "name": "İstanbul",
        "country_name": "Turkey"},
    {
        "name": "İzmir",
        "country_name": "Turkey"},
    {
        "name": "Kahramanmaraş",
        "country_name": "Turkey"},
    {
        "name": "Karabük",
        "country_name": "Turkey"},
    {
        "name": "Karaman",
        "country_name": "Turkey"},
    {
        "name": "Kars",
        "country_name": "Turkey"},
    {
        "name": "Kastamonu",
        "country_name": "Turkey"},
    {
        "name": "Kayseri",
        "country_name": "Turkey"},
    {
        "name": "Kilis",
        "country_name": "Turkey"},
    {
        "name": "Kırıkkale",
        "country_name": "Turkey"},
    {
        "name": "Kırklareli",
        "country_name": "Turkey"},
    {
        "name": "Kırşehir",
        "country_name": "Turkey"},
    {
        "name": "Kocaeli",
        "country_name": "Turkey"},
    {
        "name": "Konya",
        "country_name": "Turkey"},
    {
        "name": "Kütahya",
        "country_name": "Turkey"},
    {
        "name": "Malatya",
        "country_name": "Turkey"},
    {
        "name": "Manisa",
        "country_name": "Turkey"},
    {
        "name": "Mardin",
        "country_name": "Turkey"},
    {
        "name": "Mersin",
        "country_name": "Turkey"},
    {
        "name": "Muğla",
        "country_name": "Turkey"},
    {
        "name": "Muş",
        "country_name": "Turkey"},
    {
        "name": "Nevşehir",
        "country_name": "Turkey"},
    {
        "name": "Niğde",
        "country_name": "Turkey"},
    {
        "name": "Ordu",
        "country_name": "Turkey"},
    {
        "name": "Osmaniye",
        "country_name": "Turkey"},
    {
        "name": "Rize",
        "country_name": "Turkey"},
    {
        "name": "Sakarya",
        "country_name": "Turkey"},
    {
        "name": "Samsun",
        "country_name": "Turkey"},
    {
        "name": "Şanlıurfa",
        "country_name": "Turkey"},
    {
        "name": "Siirt",
        "country_name": "Turkey"},
    {
        "name": "Sinop",
        "country_name": "Turkey"},
    {
        "name": "Sivas",
        "country_name": "Turkey"},
    {
        "name": "Şırnak",
        "country_name": "Turkey"},
    {
        "name": "Tekirdağ",
        "country_name": "Turkey"},
    {
        "name": "Tokat",
        "country_name": "Turkey"},
    {
        "name": "Trabzon",
        "country_name": "Turkey"},
    {
        "name": "Tunceli",
        "country_name": "Turkey"},
    {
        "name": "Uşak",
        "country_name": "Turkey"},
    {
        "name": "Van",
        "country_name": "Turkey"},
    {
        "name": "Yalova",
        "country_name": "Turkey"},
    {
        "name": "Yozgat",
        "country_name": "Turkey"},
    {
        "name": "Zonguldak",
        "country_name": "Turkey"},
    {
        "name": "Ahal Region",
        "country_name": "Turkmenistan"},
    {
        "name": "Ashgabat",
        "country_name": "Turkmenistan"},
    {
        "name": "Balkan Region",
        "country_name": "Turkmenistan"},
    {
        "name": "Daşoguz Region",
        "country_name": "Turkmenistan"},
    {
        "name": "Lebap Region",
        "country_name": "Turkmenistan"},
    {
        "name": "Mary Region",
        "country_name": "Turkmenistan"},
    {
        "name": "Funafuti",
        "country_name": "Tuvalu"},
    {
        "name": "Nanumanga",
        "country_name": "Tuvalu"},
    {
        "name": "Nanumea",
        "country_name": "Tuvalu"},
    {
        "name": "Niutao Island Council",
        "country_name": "Tuvalu"},
    {
        "name": "Nui",
        "country_name": "Tuvalu"},
    {
        "name": "Nukufetau",
        "country_name": "Tuvalu"},
    {
        "name": "Nukulaelae",
        "country_name": "Tuvalu"},
    {
        "name": "Vaitupu",
        "country_name": "Tuvalu"},
    {
        "name": "Abim District",
        "country_name": "Uganda"},
    {
        "name": "Adjumani District",
        "country_name": "Uganda"},
    {
        "name": "Agago District",
        "country_name": "Uganda"},
    {
        "name": "Alebtong District",
        "country_name": "Uganda"},
    {
        "name": "Amolatar District",
        "country_name": "Uganda"},
    {
        "name": "Amudat District",
        "country_name": "Uganda"},
    {
        "name": "Amuria District",
        "country_name": "Uganda"},
    {
        "name": "Amuru District",
        "country_name": "Uganda"},
    {
        "name": "Apac District",
        "country_name": "Uganda"},
    {
        "name": "Arua District",
        "country_name": "Uganda"},
    {
        "name": "Budaka District",
        "country_name": "Uganda"},
    {
        "name": "Bududa District",
        "country_name": "Uganda"},
    {
        "name": "Bugiri District",
        "country_name": "Uganda"},
    {
        "name": "Buhweju District",
        "country_name": "Uganda"},
    {
        "name": "Buikwe District",
        "country_name": "Uganda"},
    {
        "name": "Bukedea District",
        "country_name": "Uganda"},
    {
        "name": "Bukomansimbi District",
        "country_name": "Uganda"},
    {
        "name": "Bukwo District",
        "country_name": "Uganda"},
    {
        "name": "Bulambuli District",
        "country_name": "Uganda"},
    {
        "name": "Buliisa District",
        "country_name": "Uganda"},
    {
        "name": "Bundibugyo District",
        "country_name": "Uganda"},
    {
        "name": "Bunyangabu District",
        "country_name": "Uganda"},
    {
        "name": "Bushenyi District",
        "country_name": "Uganda"},
    {
        "name": "Busia District",
        "country_name": "Uganda"},
    {
        "name": "Butaleja District",
        "country_name": "Uganda"},
    {
        "name": "Butambala District",
        "country_name": "Uganda"},
    {
        "name": "Butebo District",
        "country_name": "Uganda"},
    {
        "name": "Buvuma District",
        "country_name": "Uganda"},
    {
        "name": "Buyende District",
        "country_name": "Uganda"},
    {
        "name": "Central Region",
        "country_name": "Uganda"},
    {
        "name": "Dokolo District",
        "country_name": "Uganda"},
    {
        "name": "Eastern Region",
        "country_name": "Uganda"},
    {
        "name": "Gomba District",
        "country_name": "Uganda"},
    {
        "name": "Gulu District",
        "country_name": "Uganda"},
    {
        "name": "Ibanda District",
        "country_name": "Uganda"},
    {
        "name": "Iganga District",
        "country_name": "Uganda"},
    {
        "name": "Isingiro District",
        "country_name": "Uganda"},
    {
        "name": "Jinja District",
        "country_name": "Uganda"},
    {
        "name": "Kaabong District",
        "country_name": "Uganda"},
    {
        "name": "Kabale District",
        "country_name": "Uganda"},
    {
        "name": "Kabarole District",
        "country_name": "Uganda"},
    {
        "name": "Kaberamaido District",
        "country_name": "Uganda"},
    {
        "name": "Kagadi District",
        "country_name": "Uganda"},
    {
        "name": "Kakumiro District",
        "country_name": "Uganda"},
    {
        "name": "Kalangala District",
        "country_name": "Uganda"},
    {
        "name": "Kaliro District",
        "country_name": "Uganda"},
    {
        "name": "Kalungu District",
        "country_name": "Uganda"},
    {
        "name": "Kampala District",
        "country_name": "Uganda"},
    {
        "name": "Kamuli District",
        "country_name": "Uganda"},
    {
        "name": "Kamwenge District",
        "country_name": "Uganda"},
    {
        "name": "Kanungu District",
        "country_name": "Uganda"},
    {
        "name": "Kapchorwa District",
        "country_name": "Uganda"},
    {
        "name": "Kasese District",
        "country_name": "Uganda"},
    {
        "name": "Katakwi District",
        "country_name": "Uganda"},
    {
        "name": "Kayunga District",
        "country_name": "Uganda"},
    {
        "name": "Kibaale District",
        "country_name": "Uganda"},
    {
        "name": "Kiboga District",
        "country_name": "Uganda"},
    {
        "name": "Kibuku District",
        "country_name": "Uganda"},
    {
        "name": "Kiruhura District",
        "country_name": "Uganda"},
    {
        "name": "Kiryandongo District",
        "country_name": "Uganda"},
    {
        "name": "Kisoro District",
        "country_name": "Uganda"},
    {
        "name": "Kitgum District",
        "country_name": "Uganda"},
    {
        "name": "Koboko District",
        "country_name": "Uganda"},
    {
        "name": "Kole District",
        "country_name": "Uganda"},
    {
        "name": "Kotido District",
        "country_name": "Uganda"},
    {
        "name": "Kumi District",
        "country_name": "Uganda"},
    {
        "name": "Kween District",
        "country_name": "Uganda"},
    {
        "name": "Kyankwanzi District",
        "country_name": "Uganda"},
    {
        "name": "Kyegegwa District",
        "country_name": "Uganda"},
    {
        "name": "Kyenjojo District",
        "country_name": "Uganda"},
    {
        "name": "Kyotera District",
        "country_name": "Uganda"},
    {
        "name": "Lamwo District",
        "country_name": "Uganda"},
    {
        "name": "Lira District",
        "country_name": "Uganda"},
    {
        "name": "Luuka District",
        "country_name": "Uganda"},
    {
        "name": "Luwero District",
        "country_name": "Uganda"},
    {
        "name": "Lwengo District",
        "country_name": "Uganda"},
    {
        "name": "Lyantonde District",
        "country_name": "Uganda"},
    {
        "name": "Manafwa District",
        "country_name": "Uganda"},
    {
        "name": "Maracha District",
        "country_name": "Uganda"},
    {
        "name": "Masaka District",
        "country_name": "Uganda"},
    {
        "name": "Masindi District",
        "country_name": "Uganda"},
    {
        "name": "Mayuge District",
        "country_name": "Uganda"},
    {
        "name": "Mbale District",
        "country_name": "Uganda"},
    {
        "name": "Mbarara District",
        "country_name": "Uganda"},
    {
        "name": "Mitooma District",
        "country_name": "Uganda"},
    {
        "name": "Mityana District",
        "country_name": "Uganda"},
    {
        "name": "Moroto District",
        "country_name": "Uganda"},
    {
        "name": "Moyo District",
        "country_name": "Uganda"},
    {
        "name": "Mpigi District",
        "country_name": "Uganda"},
    {
        "name": "Mubende District",
        "country_name": "Uganda"},
    {
        "name": "Mukono District",
        "country_name": "Uganda"},
    {
        "name": "Nakapiripirit District",
        "country_name": "Uganda"},
    {
        "name": "Nakaseke District",
        "country_name": "Uganda"},
    {
        "name": "Nakasongola District",
        "country_name": "Uganda"},
    {
        "name": "Namayingo District",
        "country_name": "Uganda"},
    {
        "name": "Namisindwa District",
        "country_name": "Uganda"},
    {
        "name": "Namutumba District",
        "country_name": "Uganda"},
    {
        "name": "Napak District",
        "country_name": "Uganda"},
    {
        "name": "Nebbi District",
        "country_name": "Uganda"},
    {
        "name": "Ngora District",
        "country_name": "Uganda"},
    {
        "name": "Northern Region",
        "country_name": "Uganda"},
    {
        "name": "Ntoroko District",
        "country_name": "Uganda"},
    {
        "name": "Ntungamo District",
        "country_name": "Uganda"},
    {
        "name": "Nwoya District",
        "country_name": "Uganda"},
    {
        "name": "Omoro District",
        "country_name": "Uganda"},
    {
        "name": "Otuke District",
        "country_name": "Uganda"},
    {
        "name": "Oyam District",
        "country_name": "Uganda"},
    {
        "name": "Pader District",
        "country_name": "Uganda"},
    {
        "name": "Pakwach District",
        "country_name": "Uganda"},
    {
        "name": "Pallisa District",
        "country_name": "Uganda"},
    {
        "name": "Rakai District",
        "country_name": "Uganda"},
    {
        "name": "Rubanda District",
        "country_name": "Uganda"},
    {
        "name": "Rubirizi District",
        "country_name": "Uganda"},
    {
        "name": "Rukiga District",
        "country_name": "Uganda"},
    {
        "name": "Rukungiri District",
        "country_name": "Uganda"},
    {
        "name": "Sembabule District",
        "country_name": "Uganda"},
    {
        "name": "Serere District",
        "country_name": "Uganda"},
    {
        "name": "Sheema District",
        "country_name": "Uganda"},
    {
        "name": "Sironko District",
        "country_name": "Uganda"},
    {
        "name": "Soroti District",
        "country_name": "Uganda"},
    {
        "name": "Tororo District",
        "country_name": "Uganda"},
    {
        "name": "Wakiso District",
        "country_name": "Uganda"},
    {
        "name": "Western Region",
        "country_name": "Uganda"},
    {
        "name": "Yumbe District",
        "country_name": "Uganda"},
    {
        "name": "Zombo District",
        "country_name": "Uganda"},
    {
        "name": "Autonomous Republic of Crimea",
        "country_name": "Ukraine"},
    {
        "name": "Cherkaska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Chernihivska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Chernivetska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Dnipropetrovska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Donetska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Ivano-Frankivska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Kharkivska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Khersonska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Khmelnytska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Kirovohradska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Kyiv",
        "country_name": "Ukraine"},
    {
        "name": "Kyivska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Luhanska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Lvivska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Mykolaivska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Odeska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Poltavska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Rivnenska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Sevastopol",
        "country_name": "Ukraine"},
    {
        "name": "Sumska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Ternopilska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Vinnytska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Volynska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Zakarpatska Oblast",
        "country_name": "Ukraine"},
    {
        "name": "Zaporizka oblast",
        "country_name": "Ukraine"},
    {
        "name": "Zhytomyrska oblast",
        "country_name": "Ukraine"},
    {
        "name": "Abu Dhabi Emirate",
        "country_name": "United Arab Emirates"},
    {
        "name": "Ajman Emirate",
        "country_name": "United Arab Emirates"},
    {
        "name": "Dubai",
        "country_name": "United Arab Emirates"},
    {
        "name": "Fujairah",
        "country_name": "United Arab Emirates"},
    {
        "name": "Ras al-Khaimah",
        "country_name": "United Arab Emirates"},
    {
        "name": "Sharjah Emirate",
        "country_name": "United Arab Emirates"},
    {
        "name": "Umm al-Quwain",
        "country_name": "United Arab Emirates"},
    {
        "name": "Aberdeen",
        "country_name": "United Kingdom"},
    {
        "name": "Aberdeenshire",
        "country_name": "United Kingdom"},
    {
        "name": "Angus",
        "country_name": "United Kingdom"},
    {
        "name": "Antrim",
        "country_name": "United Kingdom"},
    {
        "name": "Antrim and Newtownabbey",
        "country_name": "United Kingdom"},
    {
        "name": "Ards",
        "country_name": "United Kingdom"},
    {
        "name": "Ards and North Down",
        "country_name": "United Kingdom"},
    {
        "name": "Argyll and Bute",
        "country_name": "United Kingdom"},
    {
        "name": "Armagh City and District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Armagh, Banbridge and Craigavon",
        "country_name": "United Kingdom"},
    {
        "name": "Ascension Island",
        "country_name": "United Kingdom"},
    {
        "name": "Ballymena Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Ballymoney",
        "country_name": "United Kingdom"},
    {
        "name": "Banbridge",
        "country_name": "United Kingdom"},
    {
        "name": "Barnsley",
        "country_name": "United Kingdom"},
    {
        "name": "Bath and North East Somerset",
        "country_name": "United Kingdom"},
    {
        "name": "Bedford",
        "country_name": "United Kingdom"},
    {
        "name": "Belfast district",
        "country_name": "United Kingdom"},
    {
        "name": "Birmingham",
        "country_name": "United Kingdom"},
    {
        "name": "Blackburn with Darwen",
        "country_name": "United Kingdom"},
    {
        "name": "Blackpool",
        "country_name": "United Kingdom"},
    {
        "name": "Blaenau Gwent County Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Bolton",
        "country_name": "United Kingdom"},
    {
        "name": "Bournemouth",
        "country_name": "United Kingdom"},
    {
        "name": "Bracknell Forest",
        "country_name": "United Kingdom"},
    {
        "name": "Bradford",
        "country_name": "United Kingdom"},
    {
        "name": "Bridgend County Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Brighton and Hove",
        "country_name": "United Kingdom"},
    {
        "name": "Buckinghamshire",
        "country_name": "United Kingdom"},
    {
        "name": "Bury",
        "country_name": "United Kingdom"},
    {
        "name": "Caerphilly County Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Calderdale",
        "country_name": "United Kingdom"},
    {
        "name": "Cambridgeshire",
        "country_name": "United Kingdom"},
    {
        "name": "Carmarthenshire",
        "country_name": "United Kingdom"},
    {
        "name": "Carrickfergus Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "Castlereagh",
        "country_name": "United Kingdom"},
    {
        "name": "Causeway Coast and Glens",
        "country_name": "United Kingdom"},
    {
        "name": "Central Bedfordshire",
        "country_name": "United Kingdom"},
    {
        "name": "Ceredigion",
        "country_name": "United Kingdom"},
    {
        "name": "Cheshire East",
        "country_name": "United Kingdom"},
    {
        "name": "Cheshire West and Chester",
        "country_name": "United Kingdom"},
    {
        "name": "City and County of Cardiff",
        "country_name": "United Kingdom"},
    {
        "name": "City and County of Swansea",
        "country_name": "United Kingdom"},
    {
        "name": "City of Bristol",
        "country_name": "United Kingdom"},
    {
        "name": "City of Derby",
        "country_name": "United Kingdom"},
    {
        "name": "City of Kingston upon Hull",
        "country_name": "United Kingdom"},
    {
        "name": "City of Leicester",
        "country_name": "United Kingdom"},
    {
        "name": "City of London",
        "country_name": "United Kingdom"},
    {
        "name": "City of Nottingham",
        "country_name": "United Kingdom"},
    {
        "name": "City of Peterborough",
        "country_name": "United Kingdom"},
    {
        "name": "City of Plymouth",
        "country_name": "United Kingdom"},
    {
        "name": "City of Portsmouth",
        "country_name": "United Kingdom"},
    {
        "name": "City of Southampton",
        "country_name": "United Kingdom"},
    {
        "name": "City of Stoke-on-Trent",
        "country_name": "United Kingdom"},
    {
        "name": "City of Sunderland",
        "country_name": "United Kingdom"},
    {
        "name": "City of Westminster",
        "country_name": "United Kingdom"},
    {
        "name": "City of Wolverhampton",
        "country_name": "United Kingdom"},
    {
        "name": "City of York",
        "country_name": "United Kingdom"},
    {
        "name": "Clackmannanshire",
        "country_name": "United Kingdom"},
    {
        "name": "Coleraine Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "Conwy County Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Cookstown District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Cornwall",
        "country_name": "United Kingdom"},
    {
        "name": "County Durham",
        "country_name": "United Kingdom"},
    {
        "name": "Coventry",
        "country_name": "United Kingdom"},
    {
        "name": "Craigavon Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "Cumbria",
        "country_name": "United Kingdom"},
    {
        "name": "Darlington",
        "country_name": "United Kingdom"},
    {
        "name": "Denbighshire",
        "country_name": "United Kingdom"},
    {
        "name": "Derbyshire",
        "country_name": "United Kingdom"},
    {
        "name": "Derry City and Strabane",
        "country_name": "United Kingdom"},
    {
        "name": "Derry City Council",
        "country_name": "United Kingdom"},
    {
        "name": "Devon",
        "country_name": "United Kingdom"},
    {
        "name": "Doncaster",
        "country_name": "United Kingdom"},
    {
        "name": "Dorset",
        "country_name": "United Kingdom"},
    {
        "name": "Down District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Dudley",
        "country_name": "United Kingdom"},
    {
        "name": "Dumfries and Galloway",
        "country_name": "United Kingdom"},
    {
        "name": "Dundee",
        "country_name": "United Kingdom"},
    {
        "name": "Dungannon and South Tyrone Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "East Ayrshire",
        "country_name": "United Kingdom"},
    {
        "name": "East Dunbartonshire",
        "country_name": "United Kingdom"},
    {
        "name": "East Lothian",
        "country_name": "United Kingdom"},
    {
        "name": "East Renfrewshire",
        "country_name": "United Kingdom"},
    {
        "name": "East Riding of Yorkshire",
        "country_name": "United Kingdom"},
    {
        "name": "East Sussex",
        "country_name": "United Kingdom"},
    {
        "name": "Edinburgh",
        "country_name": "United Kingdom"},
    {
        "name": "England",
        "country_name": "United Kingdom"},
    {
        "name": "Essex",
        "country_name": "United Kingdom"},
    {
        "name": "Falkirk",
        "country_name": "United Kingdom"},
    {
        "name": "Fermanagh and Omagh",
        "country_name": "United Kingdom"},
    {
        "name": "Fermanagh District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Fife",
        "country_name": "United Kingdom"},
    {
        "name": "Flintshire",
        "country_name": "United Kingdom"},
    {
        "name": "Gateshead",
        "country_name": "United Kingdom"},
    {
        "name": "Glasgow",
        "country_name": "United Kingdom"},
    {
        "name": "Gloucestershire",
        "country_name": "United Kingdom"},
    {
        "name": "Gwynedd",
        "country_name": "United Kingdom"},
    {
        "name": "Halton",
        "country_name": "United Kingdom"},
    {
        "name": "Hampshire",
        "country_name": "United Kingdom"},
    {
        "name": "Hartlepool",
        "country_name": "United Kingdom"},
    {
        "name": "Herefordshire",
        "country_name": "United Kingdom"},
    {
        "name": "Hertfordshire",
        "country_name": "United Kingdom"},
    {
        "name": "Highland",
        "country_name": "United Kingdom"},
    {
        "name": "Inverclyde",
        "country_name": "United Kingdom"},
    {
        "name": "Isle of Wight",
        "country_name": "United Kingdom"},
    {
        "name": "Isles of Scilly",
        "country_name": "United Kingdom"},
    {
        "name": "Kent",
        "country_name": "United Kingdom"},
    {
        "name": "Kirklees",
        "country_name": "United Kingdom"},
    {
        "name": "Knowsley",
        "country_name": "United Kingdom"},
    {
        "name": "Lancashire",
        "country_name": "United Kingdom"},
    {
        "name": "Larne Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "Leeds",
        "country_name": "United Kingdom"},
    {
        "name": "Leicestershire",
        "country_name": "United Kingdom"},
    {
        "name": "Limavady Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "Lincolnshire",
        "country_name": "United Kingdom"},
    {
        "name": "Lisburn and Castlereagh",
        "country_name": "United Kingdom"},
    {
        "name": "Lisburn City Council",
        "country_name": "United Kingdom"},
    {
        "name": "Liverpool",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Barking and Dagenham",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Barnet",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Bexley",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Brent",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Bromley",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Camden",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Croydon",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Ealing",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Enfield",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Hackney",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Hammersmith and Fulham",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Haringey",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Harrow",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Havering",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Hillingdon",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Hounslow",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Islington",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Lambeth",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Lewisham",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Merton",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Newham",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Redbridge",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Richmond upon Thames",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Southwark",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Sutton",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Tower Hamlets",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Waltham Forest",
        "country_name": "United Kingdom"},
    {
        "name": "London Borough of Wandsworth",
        "country_name": "United Kingdom"},
    {
        "name": "Magherafelt District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Manchester",
        "country_name": "United Kingdom"},
    {
        "name": "Medway",
        "country_name": "United Kingdom"},
    {
        "name": "Merthyr Tydfil County Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Metropolitan Borough of Wigan",
        "country_name": "United Kingdom"},
    {
        "name": "Mid and East Antrim",
        "country_name": "United Kingdom"},
    {
        "name": "Mid Ulster",
        "country_name": "United Kingdom"},
    {
        "name": "Middlesbrough",
        "country_name": "United Kingdom"},
    {
        "name": "Midlothian",
        "country_name": "United Kingdom"},
    {
        "name": "Milton Keynes",
        "country_name": "United Kingdom"},
    {
        "name": "Monmouthshire",
        "country_name": "United Kingdom"},
    {
        "name": "Moray",
        "country_name": "United Kingdom"},
    {
        "name": "Moyle District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Neath Port Talbot County Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Newcastle upon Tyne",
        "country_name": "United Kingdom"},
    {
        "name": "Newport",
        "country_name": "United Kingdom"},
    {
        "name": "Newry and Mourne District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Newry, Mourne and Down",
        "country_name": "United Kingdom"},
    {
        "name": "Newtownabbey Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "Norfolk",
        "country_name": "United Kingdom"},
    {
        "name": "North Ayrshire",
        "country_name": "United Kingdom"},
    {
        "name": "North Down Borough Council",
        "country_name": "United Kingdom"},
    {
        "name": "North East Lincolnshire",
        "country_name": "United Kingdom"},
    {
        "name": "North Lanarkshire",
        "country_name": "United Kingdom"},
    {
        "name": "North Lincolnshire",
        "country_name": "United Kingdom"},
    {
        "name": "North Somerset",
        "country_name": "United Kingdom"},
    {
        "name": "North Tyneside",
        "country_name": "United Kingdom"},
    {
        "name": "North Yorkshire",
        "country_name": "United Kingdom"},
    {
        "name": "Northamptonshire",
        "country_name": "United Kingdom"},
    {
        "name": "Northern Ireland",
        "country_name": "United Kingdom"},
    {
        "name": "Northumberland",
        "country_name": "United Kingdom"},
    {
        "name": "Nottinghamshire",
        "country_name": "United Kingdom"},
    {
        "name": "Oldham",
        "country_name": "United Kingdom"},
    {
        "name": "Omagh District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Orkney Islands",
        "country_name": "United Kingdom"},
    {
        "name": "Outer Hebrides",
        "country_name": "United Kingdom"},
    {
        "name": "Oxfordshire",
        "country_name": "United Kingdom"},
    {
        "name": "Pembrokeshire",
        "country_name": "United Kingdom"},
    {
        "name": "Perth and Kinross",
        "country_name": "United Kingdom"},
    {
        "name": "Poole",
        "country_name": "United Kingdom"},
    {
        "name": "Powys",
        "country_name": "United Kingdom"},
    {
        "name": "Reading",
        "country_name": "United Kingdom"},
    {
        "name": "Redcar and Cleveland",
        "country_name": "United Kingdom"},
    {
        "name": "Renfrewshire",
        "country_name": "United Kingdom"},
    {
        "name": "Rhondda Cynon Taf",
        "country_name": "United Kingdom"},
    {
        "name": "Rochdale",
        "country_name": "United Kingdom"},
    {
        "name": "Rotherham",
        "country_name": "United Kingdom"},
    {
        "name": "Royal Borough of Greenwich",
        "country_name": "United Kingdom"},
    {
        "name": "Royal Borough of Kensington and Chelsea",
        "country_name": "United Kingdom"},
    {
        "name": "Royal Borough of Kingston upon Thames",
        "country_name": "United Kingdom"},
    {
        "name": "Rutland",
        "country_name": "United Kingdom"},
    {
        "name": "Saint Helena",
        "country_name": "United Kingdom"},
    {
        "name": "Salford",
        "country_name": "United Kingdom"},
    {
        "name": "Sandwell",
        "country_name": "United Kingdom"},
    {
        "name": "Scotland",
        "country_name": "United Kingdom"},
    {
        "name": "Scottish Borders",
        "country_name": "United Kingdom"},
    {
        "name": "Sefton",
        "country_name": "United Kingdom"},
    {
        "name": "Sheffield",
        "country_name": "United Kingdom"},
    {
        "name": "Shetland Islands",
        "country_name": "United Kingdom"},
    {
        "name": "Shropshire",
        "country_name": "United Kingdom"},
    {
        "name": "Slough",
        "country_name": "United Kingdom"},
    {
        "name": "Solihull",
        "country_name": "United Kingdom"},
    {
        "name": "Somerset",
        "country_name": "United Kingdom"},
    {
        "name": "South Ayrshire",
        "country_name": "United Kingdom"},
    {
        "name": "South Gloucestershire",
        "country_name": "United Kingdom"},
    {
        "name": "South Lanarkshire",
        "country_name": "United Kingdom"},
    {
        "name": "South Tyneside",
        "country_name": "United Kingdom"},
    {
        "name": "Southend-on-Sea",
        "country_name": "United Kingdom"},
    {
        "name": "St Helens",
        "country_name": "United Kingdom"},
    {
        "name": "Staffordshire",
        "country_name": "United Kingdom"},
    {
        "name": "Stirling",
        "country_name": "United Kingdom"},
    {
        "name": "Stockport",
        "country_name": "United Kingdom"},
    {
        "name": "Stockton-on-Tees",
        "country_name": "United Kingdom"},
    {
        "name": "Strabane District Council",
        "country_name": "United Kingdom"},
    {
        "name": "Suffolk",
        "country_name": "United Kingdom"},
    {
        "name": "Surrey",
        "country_name": "United Kingdom"},
    {
        "name": "Swindon",
        "country_name": "United Kingdom"},
    {
        "name": "Tameside",
        "country_name": "United Kingdom"},
    {
        "name": "Telford and Wrekin",
        "country_name": "United Kingdom"},
    {
        "name": "Thurrock",
        "country_name": "United Kingdom"},
    {
        "name": "Torbay",
        "country_name": "United Kingdom"},
    {
        "name": "Torfaen",
        "country_name": "United Kingdom"},
    {
        "name": "Trafford",
        "country_name": "United Kingdom"},
    {
        "name": "United Kingdom",
        "country_name": "United Kingdom"},
    {
        "name": "Vale of Glamorgan",
        "country_name": "United Kingdom"},
    {
        "name": "Wakefield",
        "country_name": "United Kingdom"},
    {
        "name": "Wales",
        "country_name": "United Kingdom"},
    {
        "name": "Walsall",
        "country_name": "United Kingdom"},
    {
        "name": "Warrington",
        "country_name": "United Kingdom"},
    {
        "name": "Warwickshire",
        "country_name": "United Kingdom"},
    {
        "name": "West Berkshire",
        "country_name": "United Kingdom"},
    {
        "name": "West Dunbartonshire",
        "country_name": "United Kingdom"},
    {
        "name": "West Lothian",
        "country_name": "United Kingdom"},
    {
        "name": "West Sussex",
        "country_name": "United Kingdom"},
    {
        "name": "Wiltshire",
        "country_name": "United Kingdom"},
    {
        "name": "Windsor and Maidenhead",
        "country_name": "United Kingdom"},
    {
        "name": "Wirral",
        "country_name": "United Kingdom"},
    {
        "name": "Wokingham",
        "country_name": "United Kingdom"},
    {
        "name": "Worcestershire",
        "country_name": "United Kingdom"},
    {
        "name": "Wrexham County Borough",
        "country_name": "United Kingdom"},
    {
        "name": "Alabama",
        "country_name": "United States"},
    {
        "name": "Alaska",
        "country_name": "United States"},
    {
        "name": "American Samoa",
        "country_name": "United States"},
    {
        "name": "Arizona",
        "country_name": "United States"},
    {
        "name": "Arkansas",
        "country_name": "United States"},
    {
        "name": "Baker Island",
        "country_name": "United States"},
    {
        "name": "California",
        "country_name": "United States"},
    {
        "name": "Colorado",
        "country_name": "United States"},
    {
        "name": "Connecticut",
        "country_name": "United States"},
    {
        "name": "Delaware",
        "country_name": "United States"},
    {
        "name": "District of Columbia",
        "country_name": "United States"},
    {
        "name": "Florida",
        "country_name": "United States"},
    {
        "name": "Georgia",
        "country_name": "United States"},
    {
        "name": "Guam",
        "country_name": "United States"},
    {
        "name": "Hawaii",
        "country_name": "United States"},
    {
        "name": "Howland Island",
        "country_name": "United States"},
    {
        "name": "Idaho",
        "country_name": "United States"},
    {
        "name": "Illinois",
        "country_name": "United States"},
    {
        "name": "Indiana",
        "country_name": "United States"},
    {
        "name": "Iowa",
        "country_name": "United States"},
    {
        "name": "Jarvis Island",
        "country_name": "United States"},
    {
        "name": "Johnston Atoll",
        "country_name": "United States"},
    {
        "name": "Kansas",
        "country_name": "United States"},
    {
        "name": "Kentucky",
        "country_name": "United States"},
    {
        "name": "Kingman Reef",
        "country_name": "United States"},
    {
        "name": "Louisiana",
        "country_name": "United States"},
    {
        "name": "Maine",
        "country_name": "United States"},
    {
        "name": "Maryland",
        "country_name": "United States"},
    {
        "name": "Massachusetts",
        "country_name": "United States"},
    {
        "name": "Michigan",
        "country_name": "United States"},
    {
        "name": "Midway Atoll",
        "country_name": "United States"},
    {
        "name": "Minnesota",
        "country_name": "United States"},
    {
        "name": "Mississippi",
        "country_name": "United States"},
    {
        "name": "Missouri",
        "country_name": "United States"},
    {
        "name": "Montana",
        "country_name": "United States"},
    {
        "name": "Navassa Island",
        "country_name": "United States"},
    {
        "name": "Nebraska",
        "country_name": "United States"},
    {
        "name": "Nevada",
        "country_name": "United States"},
    {
        "name": "New Hampshire",
        "country_name": "United States"},
    {
        "name": "New Jersey",
        "country_name": "United States"},
    {
        "name": "New Mexico",
        "country_name": "United States"},
    {
        "name": "New York",
        "country_name": "United States"},
    {
        "name": "North Carolina",
        "country_name": "United States"},
    {
        "name": "North Dakota",
        "country_name": "United States"},
    {
        "name": "Northern Mariana Islands",
        "country_name": "United States"},
    {
        "name": "Ohio",
        "country_name": "United States"},
    {
        "name": "Oklahoma",
        "country_name": "United States"},
    {
        "name": "Oregon",
        "country_name": "United States"},
    {
        "name": "Palmyra Atoll",
        "country_name": "United States"},
    {
        "name": "Pennsylvania",
        "country_name": "United States"},
    {
        "name": "Puerto Rico",
        "country_name": "United States"},
    {
        "name": "Rhode Island",
        "country_name": "United States"},
    {
        "name": "South Carolina",
        "country_name": "United States"},
    {
        "name": "South Dakota",
        "country_name": "United States"},
    {
        "name": "Tennessee",
        "country_name": "United States"},
    {
        "name": "Texas",
        "country_name": "United States"},
    {
        "name": "United States Minor Outlying Islands",
        "country_name": "United States"},
    {
        "name": "United States Virgin Islands",
        "country_name": "United States"},
    {
        "name": "Utah",
        "country_name": "United States"},
    {
        "name": "Vermont",
        "country_name": "United States"},
    {
        "name": "Virginia",
        "country_name": "United States"},
    {
        "name": "Wake Island",
        "country_name": "United States"},
    {
        "name": "Washington",
        "country_name": "United States"},
    {
        "name": "West Virginia",
        "country_name": "United States"},
    {
        "name": "Wisconsin",
        "country_name": "United States"},
    {
        "name": "Wyoming",
        "country_name": "United States"},
    {
        "name": "Baker Island",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Howland Island",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Jarvis Island",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Johnston Atoll",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Kingman Reef",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Midway Islands",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Navassa Island",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Palmyra Atoll",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Wake Island",
        "country_name": "United States Minor Outlying Islands"},
    {
        "name": "Artigas",
        "country_name": "Uruguay"},
    {
        "name": "Canelones",
        "country_name": "Uruguay"},
    {
        "name": "Cerro Largo",
        "country_name": "Uruguay"},
    {
        "name": "Colonia",
        "country_name": "Uruguay"},
    {
        "name": "Durazno",
        "country_name": "Uruguay"},
    {
        "name": "Flores",
        "country_name": "Uruguay"},
    {
        "name": "Florida",
        "country_name": "Uruguay"},
    {
        "name": "Lavalleja",
        "country_name": "Uruguay"},
    {
        "name": "Maldonado",
        "country_name": "Uruguay"},
    {
        "name": "Montevideo",
        "country_name": "Uruguay"},
    {
        "name": "Paysandú",
        "country_name": "Uruguay"},
    {
        "name": "Río Negro",
        "country_name": "Uruguay"},
    {
        "name": "Rivera",
        "country_name": "Uruguay"},
    {
        "name": "Rocha",
        "country_name": "Uruguay"},
    {
        "name": "Salto",
        "country_name": "Uruguay"},
    {
        "name": "San José",
        "country_name": "Uruguay"},
    {
        "name": "Soriano",
        "country_name": "Uruguay"},
    {
        "name": "Tacuarembó",
        "country_name": "Uruguay"},
    {
        "name": "Treinta y Tres",
        "country_name": "Uruguay"},
    {
        "name": "Andijan Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Bukhara Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Fergana Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Jizzakh Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Karakalpakstan",
        "country_name": "Uzbekistan"},
    {
        "name": "Namangan Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Navoiy Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Qashqadaryo Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Samarqand Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Sirdaryo Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Surxondaryo Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Tashkent",
        "country_name": "Uzbekistan"},
    {
        "name": "Tashkent Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Xorazm Region",
        "country_name": "Uzbekistan"},
    {
        "name": "Malampa",
        "country_name": "Vanuatu"},
    {
        "name": "Penama",
        "country_name": "Vanuatu"},
    {
        "name": "Sanma",
        "country_name": "Vanuatu"},
    {
        "name": "Shefa",
        "country_name": "Vanuatu"},
    {
        "name": "Tafea",
        "country_name": "Vanuatu"},
    {
        "name": "Torba",
        "country_name": "Vanuatu"},
    {
        "name": "Amazonas",
        "country_name": "Venezuela"},
    {
        "name": "Anzoátegui",
        "country_name": "Venezuela"},
    {
        "name": "Apure",
        "country_name": "Venezuela"},
    {
        "name": "Aragua",
        "country_name": "Venezuela"},
    {
        "name": "Barinas",
        "country_name": "Venezuela"},
    {
        "name": "Bolívar",
        "country_name": "Venezuela"},
    {
        "name": "Carabobo",
        "country_name": "Venezuela"},
    {
        "name": "Cojedes",
        "country_name": "Venezuela"},
    {
        "name": "Delta Amacuro",
        "country_name": "Venezuela"},
    {
        "name": "Distrito Capital",
        "country_name": "Venezuela"},
    {
        "name": "Falcón",
        "country_name": "Venezuela"},
    {
        "name": "Federal Dependencies of Venezuela",
        "country_name": "Venezuela"},
    {
        "name": "Guárico",
        "country_name": "Venezuela"},
    {
        "name": "La Guaira",
        "country_name": "Venezuela"},
    {
        "name": "Lara",
        "country_name": "Venezuela"},
    {
        "name": "Mérida",
        "country_name": "Venezuela"},
    {
        "name": "Miranda",
        "country_name": "Venezuela"},
    {
        "name": "Monagas",
        "country_name": "Venezuela"},
    {
        "name": "Nueva Esparta",
        "country_name": "Venezuela"},
    {
        "name": "Portuguesa",
        "country_name": "Venezuela"},
    {
        "name": "Sucre",
        "country_name": "Venezuela"},
    {
        "name": "Táchira",
        "country_name": "Venezuela"},
    {
        "name": "Trujillo",
        "country_name": "Venezuela"},
    {
        "name": "Yaracuy",
        "country_name": "Venezuela"},
    {
        "name": "Zulia",
        "country_name": "Venezuela"},
    {
        "name": "An Giang",
        "country_name": "Vietnam"},
    {
        "name": "Bà Rịa-Vũng Tàu",
        "country_name": "Vietnam"},
    {
        "name": "Bắc Giang",
        "country_name": "Vietnam"},
    {
        "name": "Bắc Kạn",
        "country_name": "Vietnam"},
    {
        "name": "Bạc Liêu",
        "country_name": "Vietnam"},
    {
        "name": "Bắc Ninh",
        "country_name": "Vietnam"},
    {
        "name": "Bến Tre",
        "country_name": "Vietnam"},
    {
        "name": "Bình Dương",
        "country_name": "Vietnam"},
    {
        "name": "Bình Định",
        "country_name": "Vietnam"},
    {
        "name": "Bình Phước",
        "country_name": "Vietnam"},
    {
        "name": "Bình Thuận",
        "country_name": "Vietnam"},
    {
        "name": "Cà Mau",
        "country_name": "Vietnam"},
    {
        "name": "Cần Thơ",
        "country_name": "Vietnam"},
    {
        "name": "Cao Bằng",
        "country_name": "Vietnam"},
    {
        "name": "Đà Nẵng",
        "country_name": "Vietnam"},
    {
        "name": "Đắk Lắk",
        "country_name": "Vietnam"},
    {
        "name": "Đắk Nông",
        "country_name": "Vietnam"},
    {
        "name": "Điện Biên",
        "country_name": "Vietnam"},
    {
        "name": "Đồng Nai",
        "country_name": "Vietnam"},
    {
        "name": "Đồng Tháp",
        "country_name": "Vietnam"},
    {
        "name": "Gia Lai",
        "country_name": "Vietnam"},
    {
        "name": "Hà Giang",
        "country_name": "Vietnam"},
    {
        "name": "Hà Nam",
        "country_name": "Vietnam"},
    {
        "name": "Hà Nội",
        "country_name": "Vietnam"},
    {
        "name": "Hà Tĩnh",
        "country_name": "Vietnam"},
    {
        "name": "Hải Dương",
        "country_name": "Vietnam"},
    {
        "name": "Hải Phòng",
        "country_name": "Vietnam"},
    {
        "name": "Hậu Giang",
        "country_name": "Vietnam"},
    {
        "name": "Hồ Chí Minh",
        "country_name": "Vietnam"},
    {
        "name": "Hòa Bình",
        "country_name": "Vietnam"},
    {
        "name": "Hưng Yên",
        "country_name": "Vietnam"},
    {
        "name": "Khánh Hòa",
        "country_name": "Vietnam"},
    {
        "name": "Kiên Giang",
        "country_name": "Vietnam"},
    {
        "name": "Kon Tum",
        "country_name": "Vietnam"},
    {
        "name": "Lai Châu",
        "country_name": "Vietnam"},
    {
        "name": "Lâm Đồng",
        "country_name": "Vietnam"},
    {
        "name": "Lạng Sơn",
        "country_name": "Vietnam"},
    {
        "name": "Lào Cai",
        "country_name": "Vietnam"},
    {
        "name": "Long An",
        "country_name": "Vietnam"},
    {
        "name": "Nam Định",
        "country_name": "Vietnam"},
    {
        "name": "Nghệ An",
        "country_name": "Vietnam"},
    {
        "name": "Ninh Bình",
        "country_name": "Vietnam"},
    {
        "name": "Ninh Thuận",
        "country_name": "Vietnam"},
    {
        "name": "Phú Thọ",
        "country_name": "Vietnam"},
    {
        "name": "Phú Yên",
        "country_name": "Vietnam"},
    {
        "name": "Quảng Bình",
        "country_name": "Vietnam"},
    {
        "name": "Quảng Nam",
        "country_name": "Vietnam"},
    {
        "name": "Quảng Ngãi",
        "country_name": "Vietnam"},
    {
        "name": "Quảng Ninh",
        "country_name": "Vietnam"},
    {
        "name": "Quảng Trị",
        "country_name": "Vietnam"},
    {
        "name": "Sóc Trăng",
        "country_name": "Vietnam"},
    {
        "name": "Sơn La",
        "country_name": "Vietnam"},
    {
        "name": "Tây Ninh",
        "country_name": "Vietnam"},
    {
        "name": "Thái Bình",
        "country_name": "Vietnam"},
    {
        "name": "Thái Nguyên",
        "country_name": "Vietnam"},
    {
        "name": "Thanh Hóa",
        "country_name": "Vietnam"},
    {
        "name": "Thừa Thiên-Huế",
        "country_name": "Vietnam"},
    {
        "name": "Tiền Giang",
        "country_name": "Vietnam"},
    {
        "name": "Trà Vinh",
        "country_name": "Vietnam"},
    {
        "name": "Tuyên Quang",
        "country_name": "Vietnam"},
    {
        "name": "Vĩnh Long",
        "country_name": "Vietnam"},
    {
        "name": "Vĩnh Phúc",
        "country_name": "Vietnam"},
    {
        "name": "Yên Bái",
        "country_name": "Vietnam"},
    {
        "name": "Saint Croix",
        "country_name": "Virgin Islands (US)"},
    {
        "name": "Saint John",
        "country_name": "Virgin Islands (US)"},
    {
        "name": "Saint Thomas",
        "country_name": "Virgin Islands (US)"},
    {
        "name": "'Adan",
        "country_name": "Yemen"},
    {
        "name": "'Amran",
        "country_name": "Yemen"},
    {
        "name": "Abyan",
        "country_name": "Yemen"},
    {
        "name": "Al Bayda'",
        "country_name": "Yemen"},
    {
        "name": "Al Hudaydah",
        "country_name": "Yemen"},
    {
        "name": "Al Jawf",
        "country_name": "Yemen"},
    {
        "name": "Al Mahrah",
        "country_name": "Yemen"},
    {
        "name": "Al Mahwit",
        "country_name": "Yemen"},
    {
        "name": "Amanat Al Asimah",
        "country_name": "Yemen"},
    {
        "name": "Dhamar",
        "country_name": "Yemen"},
    {
        "name": "Hadhramaut",
        "country_name": "Yemen"},
    {
        "name": "Hajjah",
        "country_name": "Yemen"},
    {
        "name": "Ibb",
        "country_name": "Yemen"},
    {
        "name": "Lahij",
        "country_name": "Yemen"},
    {
        "name": "Ma'rib",
        "country_name": "Yemen"},
    {
        "name": "Raymah",
        "country_name": "Yemen"},
    {
        "name": "Saada",
        "country_name": "Yemen"},
    {
        "name": "Sana'a",
        "country_name": "Yemen"},
    {
        "name": "Shabwah",
        "country_name": "Yemen"},
    {
        "name": "Socotra",
        "country_name": "Yemen"},
    {
        "name": "Ta'izz",
        "country_name": "Yemen"},
    {
        "name": "Central Province",
        "country_name": "Zambia"},
    {
        "name": "Copperbelt Province",
        "country_name": "Zambia"},
    {
        "name": "Eastern Province",
        "country_name": "Zambia"},
    {
        "name": "Luapula Province",
        "country_name": "Zambia"},
    {
        "name": "Lusaka Province",
        "country_name": "Zambia"},
    {
        "name": "Muchinga Province",
        "country_name": "Zambia"},
    {
        "name": "Northern Province",
        "country_name": "Zambia"},
    {
        "name": "Northwestern Province",
        "country_name": "Zambia"},
    {
        "name": "Southern Province",
        "country_name": "Zambia"},
    {
        "name": "Western Province",
        "country_name": "Zambia"},
    {
        "name": "Bulawayo Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Harare Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Manicaland",
        "country_name": "Zimbabwe"},
    {
        "name": "Mashonaland Central Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Mashonaland East Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Mashonaland West Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Masvingo Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Matabeleland North Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Matabeleland South Province",
        "country_name": "Zimbabwe"},
    {
        "name": "Midlands Province",
        "country_name": "Zimbabwe"}
];
export {states}