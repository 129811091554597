<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import axios from 'axios';
import CryptoJS from 'crypto-js';

import _ from 'lodash';
export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            submitted: false,
            app_URL: process.env.VUE_APP_URL,
            assistanceForm: {
                assistanceCompl: "",
                assName: "",
                assCompName: "",
                assAddress: "",
                assContact: "",
                assInvestmentUnint: "",
                userName: "",
                assAuthName: "",
            }
        }
    },
    activated() {
        if (this.$route.params.id) {
            this.editAssistance();
        } else {
            this.getAssistance();
        }
    },
    beforeMount() {
        if (this.$route.params.id) {
            this.editAssistance();
        } else {
            this.getAssistance();
        }
    },
    validations: {
        assistanceForm: {
            assistanceCompl: {
                required: helpers.withMessage("This field is required ", required),
            },
            assName: {
                required: helpers.withMessage("This field is required", requiredIf(function () {
                    return this.assistanceForm.assistanceCompl == true;
                }),),
            },
            assCompName: {
                required: helpers.withMessage("This field is required", requiredIf(function () {
                    return this.assistanceForm.assistanceCompl == true;
                }),),
            },
            assAddress: {
                required: helpers.withMessage("This field is required", requiredIf(function () {
                    return this.assistanceForm.assistanceCompl == true;
                }),),
            },
            assContact: {
                required: helpers.withMessage("This field is required", requiredIf(function () {
                    return this.assistanceForm.assistanceCompl == true;
                }),),
            },
            assInvestmentUnint: {
                required: helpers.withMessage("This field is required ", required),
            },
            userName: {
                required: helpers.withMessage("This field is required ", required),
            },
            assAuthName: {
                required: helpers.withMessage("This field is required ", required),
            },
        }
    },
    methods: {
        validateAssistanceForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                let data = new FormData();
                data.append('user_id', this.$store.state.authenticatedUserId);
                data.append('disclosure_id', this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id);
                _.each(this.assistanceForm, (value, key) => {
                    data.append(key, value);
                });
                return axios.post(this.app_URL + "api/addAssistance", data).then(() => {
                    localStorage.setItem('currentStep', 8);
                    this.getAssistance();
                    return true;
                });
            }
        },
        getAssistance() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/getAssistance", { user_id: this.$store.state.authenticatedUserId, disclosure_id: this.$store.state.disclosure_id }).then(res => {
                    if (res.data) {
                        this.assistanceForm = res.data;
                    }
                })
            }, 2000)
        },
        editAssistance() {
            axios.post(this.app_URL + "api/editAssistance", { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                if (res.data) {
                    this.assistanceForm = res.data;
                }
            })
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }
    }
}
</script>

<template>
    <div>
        <div class="text-main-title">
            <h2 class="titleStepForm">PART G: Assistance with this Form</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-lg-6 mb-3">
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">G93.</span> Did you receive assistance
                            completing this form?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="receiveYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="assistanceForm.assistanceCompl" :value="true"
                                        name="receive" class="inputRadioCstm d-none" id="receiveYes"
                                        :class="{ 'is-invalid': submitted && v$.assistanceForm.assistanceCompl.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="receiveNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="assistanceForm.assistanceCompl" :value="false"
                                        name="receive" class="inputRadioCstm d-none" id="receiveNo"
                                        :class="{ 'is-invalid': submitted && v$.assistanceForm.assistanceCompl.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.assistanceForm.assistanceCompl.$error">{{
                            v$.assistanceForm.assistanceCompl.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">G94.</span> If Yes, please give the details of
                            the person who assisted you:</label>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">a.</span> Name</label>
                        <input type="text" v-model="assistanceForm.assName" class="form-control mb-2" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.assistanceForm.assName.$error, }">
                        <span class="text-danger" v-if="submitted && v$.assistanceForm.assName.$error">{{
                            v$.assistanceForm.assName.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">b.</span> Company name</label>
                        <input type="text" v-model="assistanceForm.assCompName" class="form-control mb-2" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.assistanceForm.assCompName.$error, }">
                        <span class="text-danger" v-if="submitted && v$.assistanceForm.assCompName.$error">{{
                            v$.assistanceForm.assCompName.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">c.</span> Address</label>
                        <input type="text" v-model="assistanceForm.assAddress" class="form-control mb-2" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.assistanceForm.assAddress.$error, }">
                        <span class="text-danger" v-if="submitted && v$.assistanceForm.assAddress.$error">{{
                            v$.assistanceForm.assAddress.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">d.</span> Contact information (email and
                            telephone)</label>
                        <input type="text" v-model="assistanceForm.assContact" class="form-control mb-2" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.assistanceForm.assContact.$error, }">
                        <span class="text-danger" v-if="submitted && v$.assistanceForm.assContact.$error">{{
                            v$.assistanceForm.assContact.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">G95.</span> Is the person an agent registered
                            by the Citizenship by Investment Unit?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="agentUnitUnsure" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="assistanceForm.assInvestmentUnint" value="Unsure"
                                        name="agentUnit" class="inputRadioCstm d-none" id="agentUnitUnsure"
                                        :class="{ 'is-invalid': submitted && v$.assistanceForm.assInvestmentUnint.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Unsure</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="agentUnitNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="assistanceForm.assInvestmentUnint" :value="true"
                                        name="agentUnit" class="inputRadioCstm d-none" id="agentUnitNo"
                                        :class="{ 'is-invalid': submitted && v$.assistanceForm.assInvestmentUnint.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="agentUnitNo1" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="assistanceForm.assInvestmentUnint" :value="false"
                                        name="agentUnit" class="inputRadioCstm d-none" id="agentUnitNo1"
                                        :class="{ 'is-invalid': submitted && v$.assistanceForm.assInvestmentUnint.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.assistanceForm.assInvestmentUnint.$error">{{
                            v$.assistanceForm.assInvestmentUnint.required.$message }}</span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="groupFormStyle p-2 mb-3">
                        <h2 class="titleStepForm fs-4 mb-3">Appointment of authorised agent</h2>
                        <p class="">
                            Please provide your authorisation for the agent who will represent you to the Citizenship by
                            Investment Unit:
                        </p>
                        <p class="">
                            I <input type="text" v-model="assistanceForm.userName" class="cstmInputDesign" placeholder=""
                                :class="{ 'is-invalid': submitted && v$.assistanceForm.userName.$error, }">
                            <span class="text-danger" v-if="submitted && v$.assistanceForm.userName.$error">{{
                                v$.assistanceForm.userName.required.$message }}</span>
                            hereby authorise
                            <input type="text" v-model="assistanceForm.assAuthName" class="cstmInputDesign" placeholder=""
                                :class="{ 'is-invalid': submitted && v$.assistanceForm.assAuthName.$error, }">
                            <span class="text-danger" v-if="submitted && v$.assistanceForm.assAuthName.$error">{{
                                v$.assistanceForm.assAuthName.required.$message }}</span>
                            to act on my
                            behalf with regard to this application,
                            submit the application, receive communications including my comfort letter and citizenship
                            certificate and submit replies to any queries on my behalf. Please Note: you may cancel or
                            revoke this authorisation at any point in the application process. In order to do so you must
                            inform the Citizenship by Investment Unit in writing that you have cancelled or revoked your
                            authorisation and provide the Citizenship by Investment Unit with the name and full contact
                            details of your replacement authorised agent
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>