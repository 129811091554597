<script>
import axios from 'axios';
import CryptoJS from 'crypto-js';

// import _ from 'lodash';
export default {
    data() {
        return {
            app_URL: process.env.VUE_APP_URL,
            additionalForm: Array(25).fill().map(() => ({ question: '', info: '' })),
        }
    },
    activated() {
        if (this.$route.params.id) {
            this.editAdditionalInfo();
        } else {
            this.getAdditionalInfo();
        }
    },
    beforeMount() {
        if (this.$route.params.id) {
            this.editAdditionalInfo();
        } else {
            this.getAdditionalInfo();
        }
    },
    methods: {
        validateAdditionalForm() {
            return axios.post(this.app_URL + "api/addAdditionalInfo", {
                user_id: this.$store.state.authenticatedUserId,
                disclosure_id: this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id,
                data: this.additionalForm,
            }).then(() => {
                localStorage.setItem('currentStep', 6);
                this.getAdditionalInfo();
                return true;
            })
        },
        getAdditionalInfo() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/getAdditionalInfo", { user_id: this.$store.state.authenticatedUserId, disclosure_id: this.$store.state.disclosure_id, }).then(res => {
                    if (res.data) {
                        let data = JSON.parse(JSON.stringify(res.data.additionalData));
                        this.additionalForm = JSON.parse(data);
                        this.additionalForm = { ...this.additionalForm, id: res.data.id }
                    }
                })
            }, 2000)
        },
        editAdditionalInfo() {
            axios.post(this.app_URL + "api/editAdditionalInfo", { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                if (res.data) {
                    let data = JSON.parse(JSON.stringify(res.data.additionalData));
                    this.additionalForm = JSON.parse(data);
                    this.additionalForm = { ...this.additionalForm, id: res.data.id }
                }
            })
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }
    }
}
</script>

<template>
    <div>
        <div class="text-main-title">
            <h2 class="titleStepForm">PART E: Additional Information</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-lg-4 text-center">
                    <label class="font-size-14"><span class="text-theme"></span>Question Number</label>
                </div>
                <div class="col-lg-8 text-center">
                    <label class="font-size-14"><span class="text-theme"></span>Additional information and / or description
                        of attachments</label>
                </div>
                <div class="row" v-for="(item, index) in additionalForm" :key="index">
                    <div class="col-lg-4">
                        <input type="text" v-model="item.question" class="form-control mb-2" placeholder="">
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="item.info" class="form-control mb-2" placeholder="">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>