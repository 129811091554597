<script>
import Select2 from 'vue3-select2-component';
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import { countries } from '../applicant/country.js';
import axios from 'axios';
import _ from 'lodash';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            app_URL: process.env.VUE_APP_URL,
            resizedImageDataUrl: null,
            showErrorUpload: false,
            submitted: false,
            forFamily: false,
            disclosureForm: {
                surName: '',
                firstName: '',
                passportNumber: '',
                countryIssuance: '',
                familyRelationship: '',
                dateOfBirth: '',
                reffNumber: '',
                receiveDate: '',
                agentNumber: '',
                image: ""
            },
            familyMember: ['Mother', 'Father', 'Sister', 'Brother'],
            countryIssueOption: countries,
        }
    },
    components: {
        Select2,
    },

    validations: {
        disclosureForm: {
            surName: {
                required: helpers.withMessage("Sur Name is required", required),
            },
            firstName: {
                required: helpers.withMessage("First Name is required", required),
            },
            passportNumber: {
                required: helpers.withMessage("Passport Number is required", required),
            },
            countryIssuance: {
                required: helpers.withMessage("Country Issuance is required", required),
            },
            familyRelationship: {
                required: helpers.withMessage("Family Relationship is required", requiredIf(function () {
                    return (this.forFamily || this.$store.state.forFamily) == true;
                }),),
            },
            dateOfBirth: {
                required: helpers.withMessage("D-O-B  is required", required),
            },
            reffNumber: {
                required: helpers.withMessage("Refference Number is required", required),
            },
            receiveDate: {
                required: helpers.withMessage("Receive Date is required", required),
            },
            agentNumber: {
                required: helpers.withMessage("Agent Number is required", required),
            },
            image: {
                required: helpers.withMessage("Image is required", required),
            }
        },

    },
    // emits: ['select'], // Declare the emitted event 'select'
    created() {
        if (this.$route.params.id) {
            this.editDisclosureForm();
        } else {
            this.getDisclosureForm();
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.editDisclosureForm();
        } else {
            this.getDisclosureForm();
        }
    },

    methods: {
        validateForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                let data = new FormData();
                data.append('image', this.disclosureForm.image);
                data.append('user_id', this.$store.state.authenticatedUserId);
                _.each(this.disclosureForm, (value, key) => {
                    data.append(key, value);
                });
                return axios.post(this.app_URL + 'api/addDisclosureForm', data).then((res) => {
                    console.log(res.data);
                    this.$store.state.disclosure_id = res.data.id;
                    localStorage.setItem('currentStep', 1);
                    this.getDisclosureForm();
                    this.getFirst();
                    return true;
                });
            }
        },
        getDisclosureForm() {
            setTimeout(() => {
                axios.post(this.app_URL + 'api/getDisclosureForm', { user_id: this.$store.state.authenticatedUser.formStatus == 'progress' ? this.$store.state.authenticatedUserId : '', }).then(res => {
                    if (res.data.closureForm) {
                        this.disclosureForm = res.data.closureForm;
                        this.$store.state.disclosure_id = res.data.closureForm.id;
                        this.resizedImageDataUrl = this.app_URL + 'image/' + res.data.closureForm.image;
                    }
                    this.forFamily = res.data.forFamily;
                })
            }, 2000)
        },
        editDisclosureForm() {
            axios.post(this.app_URL + 'api/editDisclosureForm', { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                if (res.data.closureForm) {
                    this.disclosureForm = res.data.closureForm;
                    this.$store.state.disclosure_id = res.data.closureForm.id;
                    this.resizedImageDataUrl = this.app_URL + 'image/' + res.data.closureForm.image;
                }
                this.forFamily = res.data.forFamily;
                this.$store.state.forFamily = false;
            })
        },
        getFirst() {
                axios.post(this.app_URL + 'api/getDisclosureForm', { user_id: this.$store.state.authenticatedUser.formStatus == 'progress' ? this.$store.state.authenticatedUserId : '', }).then(res => {
                    if (res.data.closureForm) {
                        this.disclosureForm = res.data.closureForm;
                        this.$store.state.disclosure_id = res.data.closureForm.id;
                        this.resizedImageDataUrl = this.app_URL + 'image/' + res.data.closureForm.image;
                    }
                    this.forFamily = res.data.forFamily;
                });
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const image = new Image();
                image.onload = () => {
                    if (!(image.width < 142 && image.height < 180 && image.width > 122 && image.height > 160)) {
                        // alert('Please upload images with dimensions of 35mm x 45mm.')
                        this.showErrorUpload = true;
                        // Remove the CSS class after a timeout (e.g., 2 seconds)
                        setTimeout(() => {
                            this.showErrorUpload = false;
                        }, 2800);
                    }
                    else {
                    this.disclosureForm.image = event.target.files[0];
                    this.showErrorUpload = false;
                    const desiredWidthInPixels = 35 * (96 / 25.4); // 35mm converted to pixels
                    const desiredHeightInPixels = 45 * (96 / 25.4); // 45mm converted to pixels
                    let newWidth, newHeight;
                    newHeight = desiredHeightInPixels;
                    newWidth = desiredWidthInPixels;
                    const canvas = document.createElement('canvas');
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, newWidth, newHeight);
                    this.resizedImageDataUrl = canvas.toDataURL(file.type);
                    }
                };
                image.src = URL.createObjectURL(file);
            }
        },
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },
        isNumber(e) {
            let char = String.fromCharCode(e.keyCode);
            // if(/[^0-9\s]+/.test(char)) return true; // Match with regex
            // else e.preventDefault(); // If not match, don't add to input
            if (/[^0-9]/.test(char)) {
                e.preventDefault();
            }
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }

    }
}
</script>
<template>
    <div class="">
        <div class="text-main-title">
            <h2 class="titleStepForm">Disclosure Form </h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-lg-6 mb-3">
                            <div class="d-block">
                                <label class="font-size-14">Surname / Family Name</label>
                                <input type="text" v-model="disclosureForm.surName" class="form-control"
                                    placeholder="Surname / Family Name" v-on:keypress="isLetter($event)"
                                    :class="{ 'is-invalid': submitted && v$.disclosureForm.surName.$error, }">
                                <div v-for="(errorMsg, index) in v$.disclosureForm.surName.$errors" :key="index"
                                    class="invalid-feedback">
                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                            <div class="d-block">
                                <label class="font-size-14">First / Given name </label>
                                <input type="text" v-model="disclosureForm.firstName" class="form-control"
                                    placeholder="First Name" v-on:keypress="isLetter($event)"
                                    :class="{ 'is-invalid': submitted && v$.disclosureForm.firstName.$error, }">
                                <div v-for="(errorMsg, index) in v$.disclosureForm.firstName.$errors" :key="index"
                                    class="invalid-feedback">
                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$store.state.forFamily || forFamily == true">
                            <label class="font-size-14">Relationship</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="disclosureForm.familyRelationship" placeholder="Select"
                                    :options="familyMember"
                                    :class="{ 'is-invalid': submitted && v$.disclosureForm.familyRelationship.$error, }" />
                                <div v-for="(errorMsg, index) in v$.disclosureForm.familyRelationship.$errors" :key="index"
                                    class="invalid-feedback">
                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 mb-3">
                            <div class="d-block">
                                <label class="font-size-14">Passport Number</label>
                                <input type="number" v-model="disclosureForm.passportNumber" class="form-control"
                                    placeholder="Passport Number" v-on:keypress="isNumber($event)"
                                    :class="{ 'is-invalid': submitted && v$.disclosureForm.passportNumber.$error, }">
                                <div v-for="(errorMsg, index) in v$.disclosureForm.passportNumber.$errors" :key="index"
                                    class="invalid-feedback">
                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                            <div class="d-block">
                                <label class="font-size-14">Country of Issuance</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="disclosureForm.countryIssuance" placeholder="Select"
                                        :options="countryIssueOption"
                                        :class="{ 'is-invalid': submitted && v$.disclosureForm.countryIssuance.$error, }" />
                                    <div v-for="(errorMsg, index) in v$.disclosureForm.countryIssuance.$errors" :key="index"
                                        class="invalid-feedback">
                                        <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                            <div class="d-block">
                                <label class="font-size-14">Date Of Birth</label>
                                <input type="date" v-model="disclosureForm.dateOfBirth" class="form-control"
                                    :class="{ 'is-invalid': submitted && v$.disclosureForm.dateOfBirth.$error, }">
                                <div v-for="(errorMsg, index) in v$.disclosureForm.dateOfBirth.$errors" :key="index"
                                    class="invalid-feedback">
                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <div class="imageProfileUpload">
                            <div class="imageGroupMain">
                                <div class="iconUploadPencil" v-if="this.$route.name != 'single Applicant'">
                                    <label for="my-file" class="pencilEditUpload"><i class="mdi mdi-pencil"></i></label>
                                    <input type="file" accept="image/*" @change="handleImageUpload"
                                        class="form-control d-none" id="my-file"
                                        :class="{ 'is-invalid': submitted && v$.disclosureForm.image.$error, }">
                                </div>
                                <div v-if="!resizedImageDataUrl" class="uploadImageContent">
                                    <p>Securely attach 45mm x 35mm photograph of the applicant here</p>
                                </div>
                                <img v-else :src="resizedImageDataUrl" class="imageUploadMain" />
                            </div>
                        </div>
                        <p v-if="showErrorUpload" class="text-center mb-0 text-danger">Please upload images with dimensions
                            of 35mm x 45mm.</p>
                        <p v-if="submitted && v$.disclosureForm.image.$error" class="text-center mb-0 text-danger">
                            Image is required
                        </p>
                    </div>


                </div>
            </div>
            <div class="groupFormStyle mt-4">
                <h2 class="font-size-18">For Official Use Only</h2>
                <div class="row">
                    <div class="col-lg-4 mb-3">
                        <div class="d-block">
                            <label class="font-size-14">Reference Number</label>
                            <input type="number" v-model="disclosureForm.reffNumber" class="form-control"
                                placeholder="Reference Number" v-on:keypress="isNumber($event)"
                                :class="{ 'is-invalid': submitted && v$.disclosureForm.reffNumber.$error, }">
                            <div v-for="(errorMsg, index) in v$.disclosureForm.reffNumber.$errors" :key="index"
                                class="invalid-feedback">
                                <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <div class="d-block">
                            <label class="font-size-14">Date Received</label>
                            <input type="date" v-model="disclosureForm.receiveDate" class="form-control"
                                :class="{ 'is-invalid': submitted && v$.disclosureForm.receiveDate.$error, }">
                            <div v-for="(errorMsg, index) in v$.disclosureForm.receiveDate.$errors" :key="index"
                                class="invalid-feedback">
                                <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <div class="d-block">
                            <label class="font-size-14">Authorised Agent’s Number</label>
                            <input type="number" v-model="disclosureForm.agentNumber" class="form-control"
                                placeholder="Authorised Agent’s number" v-on:keypress="isNumber($event)"
                                :class="{ 'is-invalid': submitted && v$.disclosureForm.agentNumber.$error, }">
                            <div v-for="(errorMsg, index) in v$.disclosureForm.agentNumber.$errors" :key="index"
                                class="invalid-feedback">
                                <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>