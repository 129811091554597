<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            // showRadio: false,
            submitted: false,
            app_URL: process.env.VUE_APP_URL,
            undertakingForm: {
                userName: "",
                datePlace: "",
                image: "",
            },
        }
    },

    validations: {
        undertakingForm: {
            userName: {
                required: helpers.withMessage("This field is required", required),
            },
            datePlace: {
                required: helpers.withMessage("This field is required", required),
            },
            image: {
                required: helpers.withMessage("This field is required", required),
            },
        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.editUndertaking();
        }
    },
    methods: {
        validateUndertakingForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                let data = new FormData();
                data.append('image', this.undertakingForm.image);
                data.append('user_id', this.$store.state.authenticatedUserId);
                data.append('userName', this.undertakingForm.userName);
                data.append('datePlace', this.undertakingForm.datePlace);
                data.append('disclosure_id', this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id);
                return axios.post(this.app_URL + "api/addUndertaking", data).then(() => {
                    localStorage.setItem('currentStep', 0);
                    this.$router.push({ path: '/dashboard' });
                    return true;
                })
            }
        },
        previewImage(event) {
            this.undertakingForm.image = event.target.files[0];
        },
        editUndertaking() {
            axios.post(this.app_URL + "api/editUndertaking", { disclosure_id: this.decode(this.$route.params.id) }).then((res) => {
                if (res.data) {
                    this.undertakingForm = res.data;
                    this.undertakingForm.image = res.data.image ? 1 + ' ' + 'file' : '';
                }
            })
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }
    }

}
</script>

<template>
    <div>
        <div class="text-main-title">
            <h2 class="titleStepForm">PART H: Undertakings, Signature and Authorisation</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-lg-12">
                    <b class="fs-5 mb-3">NOTE - Please ensure that you carefully read the undertakings below before signing
                        this document. </b>
                    <p class="">
                        Please ensure that the information that you have provided on this form is true and correct. If you
                        have made any false statements or omitted information requested on this form, your citizenship
                        application could be declined. If it is found later that you have provided false or incorrect
                        information, you may be deprived of your citizenship and you may face criminal prosecution.
                    </p>
                    <b class="fs-5 mb-3">Declarations</b>
                    <div class="d-block mb-2">
                        <p class="">
                            <span class="text-theme">I.</span>
                            <input type="text" v-model="undertakingForm.userName" class="cstmInputDesign" placeholder=""
                                :class="{ 'is-invalid': submitted && v$.undertakingForm.userName.$error, }">
                            <span class="text-danger" v-if="submitted && v$.undertakingForm.userName.$error">{{
                                v$.undertakingForm.userName.required.$message }}</span>
                            hereby make the following declarations:
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">i.</span> I certify that I have read and understood all of the
                            questions in this form and that the information supplied in or with this form, and any
                            attachments, whether supplied directly by myself or through an agent completing the form on my
                            behalf, is true and up to date in every detail.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">ii.</span>I authorise, without reservation, the Government of
                            Dominica to verify any personal information about me or my family. Accordingly, I authorise the
                            Government of Dominica, either directly or through any agent that the Government may decide to
                            engage, in order to obtain further information, credit reports, criminal records or other kinds
                            of records about me or my family, which the Government may deem necessary and I understand that
                            such information, reports, and records may be obtained from public sources, government agencies
                            or private agencies. I authorise any agencies contacted to furnish the requested information,
                            reports or records about me or my family and I release all parties involved from any
                            responsibility and liability for doing so. I authorise the release by the Government of Dominica
                            of any personal information about me or my family given on this form or otherwise obtained by
                            the Government in order to verify such information or obtain such reports or records about me or
                            my family, which may assist the Government of Dominica in deciding whether I qualify for
                            citizenship.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">iii.</span> I confirm that my wealth has been obtained from
                            completely legitimate sources, and is not, whether directly or indirectly, from the proceeds of
                            criminal activities of any kind.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">iv.</span> I understand that I may be required to attend an
                            interview in person with officials of the Commonwealth of Dominica prior to the granting of my
                            citizenship.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">v.</span> I understand that becoming a citizen of the
                            Commonwealth of Dominica may affect my present citizenship status
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">vi.</span> If there is any change in my circumstances between
                            the date of this application and the date of granting of citizenship, which affects the
                            information I have given in this application, I confirm that I will inform the Citizenship by
                            Investment Unit in writing of this change promptly.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">vii.</span> vii. In the event of the citizenship of the
                            Commonwealth of Dominica being granted to me, I do solemnly pledge that:
                        </p>
                        <ul>
                            <li>I will faithfully observe the laws of the Commonwealth of Dominica,</li>
                            <li>I have read and understood the fundamental principles, beliefs and values of the
                                Commonwealth of Dominica and will respect these,</li>
                            <li>I will conduct myself in a manner which will at no time bring disrepute to the Commonwealth
                                of Dominica, and</li>
                            <li>I will not act against the interests of Commonwealth of Dominica.</li>
                        </ul>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">viii.</span> I confirm that I will put the required amount for
                            the qualifying investment in an approved escrow account and that I am ready to proceed with my
                            investment in the event that citizenship of the Commonwealth of Dominica is granted to me.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">ix.</span> I confirm that I have been fully and clearly
                            informed and had explained to me, and I fully understand the requirement that as a condition of
                            the approval of my application for citizenship of the Commonwealth of Dominica under the
                            Citizenship by Investment Programme, I am prohibited from seeking or applying for asylum in any
                            country.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">x.</span> I hereby give my solemn undertaking that I will not
                            at any time whilst the holder of citizenship of the Commonwealth of Dominica seek or apply for
                            asylum in any country.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class=""><span class="text-theme">xi.</span> I also confirm that I have been informed and had
                            fully and clearly explained to me and I understand that if I breach this prohibition I will
                            forthwith forfeit my entitlement to citizenship of the Commonwealth of Dominica and my said
                            citizenship shall be revoked.
                        </p>
                    </div>
                    <div class="d-block mb-2">
                        <p class="">I certify that the facts contained in this part and in this disclosure form are true and
                            complete to the best of my knowledge and belief and I further understand that any false
                            statement on this form shall be grounds for rejection. I declare that I have fully read and
                            understood all the statements on this form having asked and obtained an explanation for every
                            point that was not clear to me. I hereby apply to be granted citizenship of the Commonwealth of
                            Dominica.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="d-block mb-3">
                        <label class="font-size-14"> Place and Date</label>
                        <input type="text" v-model="undertakingForm.datePlace" class="form-control mb-2" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.undertakingForm.datePlace.$error, }">
                        <span class="text-danger" v-if="submitted && v$.undertakingForm.datePlace.$error">{{
                            v$.undertakingForm.datePlace.required.$message }}</span>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="my-file">Signature of applicant (in case of children under the age of 18, both parents
                            must sign in this space)</label>
                            <div class="col-12">
                                <div class="d-block border border-light ps-1 mb-3 mt-2">
                                    <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                    <input type="file" accept="image/*"
                                    @change="previewImage($event)" class="form-control" id="my-file"
                                    :class="{ 'is-invalid': submitted && v$.undertakingForm.image.$error, }">
                                    <span v-if="undertakingForm.image.length > 0">{{ undertakingForm.image }}</span>
                                </div>
                            </div>
                       
                        <!-- <template v-if="preview[9]">
                        <div class="border bg-white p-2 mt-3">
                          <h4 class="font-size-15 mb-3">Signature of applicant (in case of children under the age of 18, both parents must sign in this space)</h4>
                          <img
                            v-if="preview[9].type === 'pdf' || preview[9].type === 'docx' || preview[9].type === 'xlsx'"
                            src="@/assets/images/citizenship/icon-file.png" alt="icon-image" class="icon-file-upload" />
                          <img v-else :src="preview[9].value" class="img-custom-preview" />
                          <p class="mb-0">file name: {{ image[9].name }}</p>
                          <p class="mb-0">size: {{ image[9].size / 1024 }}KB</p>
                        </div>
                      </template> -->
                        <span class="text-danger" v-if="submitted && v$.undertakingForm.image.$error">{{
                            v$.undertakingForm.image.required.$message }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>