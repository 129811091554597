<script>
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import axios from 'axios';
// import _ from 'lodash';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            submitted: false,
            app_URL: process.env.VUE_APP_URL,
            employmentForm: {
                occupationTraining: "",
                currentPrimaryOcc: "",
                selfEmployed: "",
                primaryBussinessName: "",
                natureBussiness: "",
                registeredAddress: "",
                bussinessTeleNum: "",
                countryIncorparation: "",
                bussinessWebsite: "",
                discilinaryAction: "",
                actionTaken: "",
                shareholderCompanies: "",
                annualNetIncome: "",
                estimateNetWorth: "",
                mainSourceIncome: "",
                geographicalLocation: "",
                importantBussinessPerson: "",
                fixedAssestAmount: "",
                savingAmount: "",
                investmentAmount: "",
                othersAssestsAmount: "",
                assestsTotalAmount: "",
                longTermLoanAmount: "",
                shortTermLoanAmount: "",
                othersLiabilitiesAmount: "",
                othersLiabilitiesAmount2: "",
                othersLiabilitiesAmount3: "",
                liabilitieTotalAmount: "",
                accountHolderName: "",
                accountNumber: "",
                ibanCode: "",
                bankName: "",
                professionalLicence: Array(2).fill().map(() => ({ designationHeld: "", licenseNumber: "", licenseAuthority: "" })),
                acquisitionDesposition: Array(5).fill().map(() => ({ acquisition: '' })),
                educationDetails: Array(4).fill().map(() => ({ startDateSchool: '', endDateSchool: '', nameOfSchool: '', schoolAddress: '', achievedQualification: '' })),
                employmentHistory: Array(6).fill().map(() => ({ startDateEmp: "", endDateEmp: "", nameOfEmployer: "", addressOfEmployer: "", positionHeld: "", bussinessType: "", reasonLeaving: "" })),
            }
        }
    },
    validations: {
        employmentForm: {
            occupationTraining: {
                required: helpers.withMessage('This Field is required', required),
            },
            currentPrimaryOcc: {
                required: helpers.withMessage('This Field is required', required),
            },
            selfEmployed: {
                required: helpers.withMessage('This Field is required', required),
            },
            primaryBussinessName: {
                required: helpers.withMessage('This Field is required', required),
            },
            natureBussiness: {
                required: helpers.withMessage('This Field is required', required),
            },
            registeredAddress: {
                required: helpers.withMessage('This Field is required', required),
            },
            bussinessTeleNum: {
                required: helpers.withMessage('This Field is required', required),
            },
            countryIncorparation: {
                required: helpers.withMessage('This Field is required', required),
            },
            bussinessWebsite: {
                required: helpers.withMessage('This Field is required', required),
            },
            discilinaryAction: {
                required: helpers.withMessage('This Field is required', required),
            },
            actionTaken: {
                required: helpers.withMessage('This Field is required', requiredIf(function () {
                    return this.employmentForm.discilinaryAction == true;
                }),),
            },
            shareholderCompanies: {
                required: helpers.withMessage('This Field is required', required),
            },
            annualNetIncome: {
                required: helpers.withMessage('This Field is required', required),
            },
            estimateNetWorth: {
                required: helpers.withMessage('This Field is required', required),
            },
            mainSourceIncome: {
                required: helpers.withMessage('This Field is required', required),
            },
            geographicalLocation: {
                required: helpers.withMessage('This Field is required', required),
            },
            importantBussinessPerson: {
                required: helpers.withMessage('This Field is required', required),
            },

            fixedAssestAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            savingAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            investmentAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            othersAssestsAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            assestsTotalAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            longTermLoanAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            shortTermLoanAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            othersLiabilitiesAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            liabilitieTotalAmount: {
                required: helpers.withMessage('This Field is required', required),
            },
            accountHolderName: {
                required: helpers.withMessage('This Field is required', required),
            },
            accountNumber: {
                required: helpers.withMessage('This Field is required', required),
            },
            ibanCode: {
                required: helpers.withMessage('This Field is required', required),
            },
            bankName: {
                required: helpers.withMessage('This Field is required', required),
            },
        }
    },
    activated() {
        if (this.$route.params.id) {
            this.editEmploymentForm();
        } else {
            this.getEmploymentForm();
        }
    },
    beforeMount() {
        if (this.$route.params.id) {
            this.editEmploymentForm();
        } else {
            this.getEmploymentForm();
        }
    },
    methods: {
        validateEmploymentForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                // let data = new FormData();
                // data.append('user_id', this.$store.state.authenticatedUserId);
                // data.append('disclosure_id', this.$store.state.disclosure_id);
                // _.each(this.employmentForm, (value, key) => {
                //     data.append(key, value);
                // });
                return axios.post(this.app_URL + "api/addEmploymentForm", {
                    user_id: this.$store.state.authenticatedUserId,
                    disclosure_id: this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id,
                    employement: this.employmentForm,

                }).then(() => {
                    localStorage.setItem('currentStep', 3);
                    this.getEmploymentForm();
                    return true;
                })
            }
        },

        getEmploymentForm() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/getEmploymentForm", { user_id: this.$store.state.authenticatedUserId, disclosure_id: this.$store.state.disclosure_id }).then(res => {
                    if (res.data) {
                        this.employmentForm = res.data;
                        this.employmentForm.selfEmployed = res.data.selfEmployed == '0' ? false : true;
                        this.employmentForm.discilinaryAction = res.data.discilinaryAction == '0' ? false : true;
                        this.employmentForm.professionalLicence = res.data.professionalLicence ? JSON.parse(res.data.professionalLicence) : Array(2).fill().map(() => ({ designationHeld: "", licenseNumber: "", licenseAuthority: "" }));
                        this.employmentForm.acquisitionDesposition = res.data.acquisitionDesposition ? JSON.parse(res.data.acquisitionDesposition) : Array(5).fill().map(() => ({ acquisition: '' }));
                        this.employmentForm.educationDetails = res.data.educationDetails ? JSON.parse(res.data.educationDetails) : Array(4).fill().map(() => ({ startDateSchool: '', endDateSchool: '', nameOfSchool: '', schoolAddress: '', achievedQualification: '' }));
                        this.employmentForm.employmentHistory = res.data.employmentHistory ? JSON.parse(res.data.employmentHistory) : Array(6).fill().map(() => ({ startDateEmp: "", endDateEmp: "", nameOfEmployer: "", addressOfEmployer: "", positionHeld: "", bussinessType: "", reasonLeaving: "" }));
                    }
                })
            }, 2000)
        },
        editEmploymentForm() {
            axios.post(this.app_URL + "api/editEmploymentForm", { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                if (res.data) {
                    this.employmentForm = res.data;
                    this.employmentForm.selfEmployed = res.data.selfEmployed == '0' ? false : true;
                        this.employmentForm.discilinaryAction = res.data.discilinaryAction == '0' ? false : true;
                    this.employmentForm.professionalLicence = res.data.professionalLicence ? JSON.parse(res.data.professionalLicence) : Array(2).fill().map(() => ({ designationHeld: "", licenseNumber: "", licenseAuthority: "" }));
                    this.employmentForm.acquisitionDesposition = res.data.acquisitionDesposition ? JSON.parse(res.data.acquisitionDesposition) : Array(5).fill().map(() => ({ acquisition: '' }));
                    this.employmentForm.educationDetails = res.data.educationDetails ? JSON.parse(res.data.educationDetails) : Array(4).fill().map(() => ({ startDateSchool: '', endDateSchool: '', nameOfSchool: '', schoolAddress: '', achievedQualification: '' }));
                    this.employmentForm.employmentHistory = res.data.employmentHistory ? JSON.parse(res.data.employmentHistory) : Array(6).fill().map(() => ({ startDateEmp: "", endDateEmp: "", nameOfEmployer: "", addressOfEmployer: "", positionHeld: "", bussinessType: "", reasonLeaving: "" }));
                }
            })
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }
    }
}
</script>

<template>
    <div>
        <div class="text-main-title">
            <h2 class="titleStepForm">PART B: Work, Business and Source of Wealth Information</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-lg-6 ">
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B36.</span> Occupation by training</label>
                        <input type="text" v-model="employmentForm.occupationTraining" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.occupationTraining.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.occupationTraining.$error">{{
                            v$.employmentForm.occupationTraining.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B37.</span> Current Primary Occupation</label>
                        <input type="text" v-model="employmentForm.currentPrimaryOcc" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.currentPrimaryOcc.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.currentPrimaryOcc.$error">{{
                            v$.employmentForm.currentPrimaryOcc.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B38.</span> Are you self-employed?
                        </label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="self_yes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="employmentForm.selfEmployed" :value="true"
                                        name="selfEmploye" class="inputRadioCstm d-none" id="self_yes"
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.selfEmployed.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="self_no" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="employmentForm.selfEmployed" :value="false"
                                        name="selfEmploye" class="inputRadioCstm d-none" id="self_no"
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.selfEmployed.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.employmentForm.selfEmployed.$error">{{
                            v$.employmentForm.selfEmployed.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B39.</span> Name of your primary business or
                            employer</label>
                        <input type="text" v-model="employmentForm.primaryBussinessName" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.primaryBussinessName.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.primaryBussinessName.$error">{{
                            v$.employmentForm.primaryBussinessName.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B40.</span> Nature of business or employer’s
                            business</label>
                        <input type="text" v-model="employmentForm.natureBussiness" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.natureBussiness.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.natureBussiness.$error">{{
                            v$.employmentForm.natureBussiness.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <h4 class="font-size-14"><span class="text-theme">B41.</span> Registered address of business or
                            employer</h4>
                        <textarea type="textarea" v-model="employmentForm.registeredAddress"
                            class="form-control resize-none" rows="5" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.registeredAddress.$error, }"></textarea>
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.registeredAddress.$error">{{
                            v$.employmentForm.registeredAddress.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B42.</span>Business telephone number</label>
                        <input type="number" v-model="employmentForm.bussinessTeleNum" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.bussinessTeleNum.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.bussinessTeleNum.$error">{{
                            v$.employmentForm.bussinessTeleNum.required.$message }}</span>

                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B43.</span> If own business,country of
                            incorporation and registration number</label>
                        <input type="text" v-model="employmentForm.countryIncorparation" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.countryIncorparation.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.countryIncorparation.$error">{{
                            v$.employmentForm.countryIncorparation.required.$message }}</span>

                    </div>
                </div>
                <div class="col-lg-6 order-lg-2">
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B44.</span> Business or employers website
                            address</label>
                        <input type="text" v-model="employmentForm.bussinessWebsite" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.bussinessWebsite.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.bussinessWebsite.$error">{{
                            v$.employmentForm.bussinessWebsite.required.$message }}</span>

                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B45.</span> Please provide the details for any
                            privileged or professional license in any state, (e.g. liquor, real estate, professional,
                            financial services or gambling)</label>
                        <div class="row align-items-end">
                            <div class="col-lg-4 mb-3">
                                <h3 class="font-size-15">Position / Designation Held</h3>

                                <input type="text" v-for="(child, index) in employmentForm.professionalLicence" :key="index"
                                    v-model="child.designationHeld" class="form-control mb-1 mb-lg-3" placeholder="">
                            </div>
                            <div class="col-lg-4 mb-3">
                                <h3 class="font-size-15">Licence / Registration / Practice Number</h3>

                                <input type="number" v-for="(child, index) in employmentForm.professionalLicence" :key="index"
                                    v-model="child.licenseNumber" class="form-control mb-1 mb-lg-3" placeholder="">
                            </div>
                            <div class="col-lg-4 mb-3">
                                <h3 class="font-size-15">Licensing Authority</h3>

                                <input type="text" v-for="(child, index) in employmentForm.professionalLicence" :key="index"
                                    v-model="child.licenseAuthority" class="form-control mb-1 mb-lg-3" placeholder="">
                            </div>
                        </div>
                    </div>
                    <div class="d-block ">
                        <label class="font-size-14"><span class="text-theme">B46.</span> Have you ever had any isciplinary
                            action taken against you in respect to any of these licences?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="action_yes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="employmentForm.discilinaryAction" :value="true"
                                        name="selfEmploye1" class="inputRadioCstm d-none" id="action_yes"
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.discilinaryAction.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="action_no" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="employmentForm.discilinaryAction" :value="false"
                                        name="selfEmploye1" class="inputRadioCstm d-none" id="action_no"
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.discilinaryAction.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                            <span class="text-danger" v-if="submitted && v$.employmentForm.discilinaryAction.$error">{{
                                v$.employmentForm.discilinaryAction.required.$message }}</span>
                        </div>
                        <div class="d-block ">
                            <label class="font-size-14">If yes explain the nature of the actions taken.</label>
                            <input type="text" v-model="employmentForm.actionTaken" class="form-control mb-2"
                                :class="{ 'is-invalid': submitted && v$.employmentForm.actionTaken.$error, }">
                            <span class="invalid-feedback" v-if="submitted && v$.employmentForm.actionTaken.$error">{{
                                v$.employmentForm.actionTaken.required.$message }}</span>
                        </div>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B47.</span> List all companies you are
                            currently a shareholder or director</label>
                        <textarea type="textarea" v-model="employmentForm.shareholderCompanies"
                            class="form-control resize-none" rows="5" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.employmentForm.shareholderCompanies.$error, }"></textarea>
                        <span class="invalid-feedback" v-if="submitted && v$.employmentForm.shareholderCompanies.$error">{{
                            v$.employmentForm.shareholderCompanies.required.$message }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="groupFormStyle p-2 mb-3">
                        <h2 class="text-theme font-size-16">Income, Source of Funds and Source of Wealth</h2>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">B48.</span> Your gross stimated
                                        annual net income (in USD)</label>
                                    <input type="number" v-model="employmentForm.annualNetIncome" class="form-control"
                                        placeholder="$"
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.annualNetIncome.$error, }">
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.employmentForm.annualNetIncome.$error">{{
                                            v$.employmentForm.annualNetIncome.required.$message }}</span>
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">B49.</span> Your total estimated
                                        net worth (personal assets minus personal liabilities)</label>
                                    <input type="number" v-model="employmentForm.estimateNetWorth" class="form-control"
                                        placeholder="$"
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.estimateNetWorth.$error, }">
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.employmentForm.estimateNetWorth.$error">{{
                                            v$.employmentForm.estimateNetWorth.required.$message }}</span>
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">B50.</span> Sources of income
                                        (business activities from which you generate your main source of income)</label>
                                    <input type="text" v-model="employmentForm.mainSourceIncome" class="form-control"
                                        placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.mainSourceIncome.$error, }">
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.employmentForm.mainSourceIncome.$error">{{
                                            v$.employmentForm.mainSourceIncome.required.$message }}</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">B51.</span> Geographical locations
                                        that you conduct business in </label>
                                    <input type="text" v-model="employmentForm.geographicalLocation" class="form-control"
                                        placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.geographicalLocation.$error, }">
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.employmentForm.geographicalLocation.$error">{{
                                            v$.employmentForm.geographicalLocation.required.$message }}</span>
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">B52.</span> Most important
                                        companies/persons with whom you do business </label>
                                    <input type="text" v-model="employmentForm.importantBussinessPerson"
                                        class="form-control" placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.importantBussinessPerson.$error, }">
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.employmentForm.importantBussinessPerson.$error">{{
                                            v$.employmentForm.importantBussinessPerson.required.$message }}</span>

                                </div>
                            </div>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"><span class="text-theme">B53.</span> Please provide a summarised
                                statement of how you have accumulated your Total Net Worth by listing the main acquisitions
                                /dispositions and events (continue on additional paper if necessary) </label>
                            <input type="text" v-for="(child, index) in employmentForm.acquisitionDesposition" :key="index"
                                v-model="child.acquisition" class="form-control mb-3" placeholder="">
                            <!-- <input type="text" v-model="employmentForm.acquisitionDesposition2" class="form-control mb-3"
                                placeholder="">
                            <input type="text" v-model="employmentForm.acquisitionDesposition3" class="form-control mb-3"
                                placeholder="">
                            <input type="text" v-model="employmentForm.acquisitionDesposition4" class="form-control mb-3"
                                placeholder="">
                            <input type="text" v-model="employmentForm.acquisitionDesposition5" class="form-control mb-3"
                                placeholder=""> -->
                        </div>
                    </div>

                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">B54.</span> In the table below, please provide
                            the estimated value of your assets and liabilities (please provide documentary support for these
                            estimations). </label>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="groupFormStyle text-center">
                                    <label class="font-size-14 m-0">Assets</label>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="groupFormStyle text-center">
                                    <label class="font-size-14 m-0">Amount</label>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="groupFormStyle text-center">
                                    <label class="font-size-14 m-0">Liabilities</label>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="groupFormStyle text-center">
                                    <label class="font-size-14 m-0">Amount</label>
                                </div>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Fixed Assets (eg.property, vehicle, etc)</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.fixedAssestAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.fixedAssestAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.fixedAssestAmount.$error">{{
                                        v$.employmentForm.fixedAssestAmount.required.$message }}</span>
                            </div>
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Outstanding Long Term Loans (eg. mortgage, car loan,
                                    personal loan, etc)</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.longTermLoanAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.longTermLoanAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.longTermLoanAmount.$error">{{
                                        v$.employmentForm.longTermLoanAmount.required.$message }}</span>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Savings / Deposits</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.savingAmount" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.savingAmount.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.employmentForm.savingAmount.$error">{{
                                    v$.employmentForm.savingAmount.required.$message }}</span>
                            </div>
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Outstanding short Term Loans (eg. credit card bills, tax
                                    liabilitity)</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.shortTermLoanAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.shortTermLoanAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.shortTermLoanAmount.$error">{{
                                        v$.employmentForm.shortTermLoanAmount.required.$message }}</span>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Investments (eg.stocks and shares, bonds, debentures,
                                    managed investments, etc)</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.investmentAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.investmentAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.investmentAmount.$error">{{
                                        v$.employmentForm.investmentAmount.required.$message }}</span>
                            </div>
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Others ( please specify) 1</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.othersLiabilitiesAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.othersLiabilitiesAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.othersLiabilitiesAmount.$error">{{
                                        v$.employmentForm.othersLiabilitiesAmount.required.$message }}</span>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Others (please specify)</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.othersAssestsAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.othersAssestsAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.othersAssestsAmount.$error">{{
                                        v$.employmentForm.othersAssestsAmount.required.$message }}</span>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.othersLiabilitiesAmount2" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.othersLiabilitiesAmount3" class="form-control"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Total</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.assestsTotalAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.assestsTotalAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.assestsTotalAmount.$error">{{
                                        v$.employmentForm.assestsTotalAmount.required.$message }}</span>
                            </div>
                            <div class="col-lg-3">
                                <label class="font-size-14 m-0">Total</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="number" v-model="employmentForm.liabilitieTotalAmount" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.employmentForm.liabilitieTotalAmount.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.employmentForm.liabilitieTotalAmount.$error">{{
                                        v$.employmentForm.liabilitieTotalAmount.required.$message }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-block ">
                        <label class="font-size-14"><span class="text-theme">B55.</span> In the table below, please provide
                            the estimated value of your assets and liabilities (please provide documentary support for these
                            estimations). </label>
                        <div class="row ">
                            <div class="col-lg-6 mb-3">
                                <div class="d-block ">
                                    <h3 class="font-size-15">Name of account holder</h3>
                                    <input type="text" v-model="employmentForm.accountHolderName"
                                        class="form-control mb-1 mb-lg-3" placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.accountHolderName.$error, }">
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.employmentForm.accountHolderName.$error">{{
                                            v$.employmentForm.accountHolderName.required.$message }}</span>
                                </div>
                                <div class="d-block ">
                                    <h3 class="font-size-15">Account number</h3>
                                    <input type="number" v-model="employmentForm.accountNumber"
                                        class="form-control mb-1 mb-lg-3" placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.accountNumber.$error, }">
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.employmentForm.accountNumber.$error">{{
                                            v$.employmentForm.accountNumber.required.$message }}</span>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-3">
                                <div class="d-block">
                                    <h3 class="font-size-15">IBAN/BIC code</h3>
                                    <input type="text" v-model="employmentForm.ibanCode" class="form-control mb-1 mb-lg-3"
                                        placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.ibanCode.$error, }">
                                    <span class="invalid-feedback" v-if="submitted && v$.employmentForm.ibanCode.$error">{{
                                        v$.employmentForm.ibanCode.required.$message }}</span>
                                </div>
                                <div class="d-block ">
                                    <h3 class="font-size-15">Bank name and address</h3>
                                    <input type="text" v-model="employmentForm.bankName" class="form-control mb-1 mb-lg-3"
                                        placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.employmentForm.bankName.$error, }">
                                    <span class="invalid-feedback" v-if="submitted && v$.employmentForm.bankName.$error">{{
                                        v$.employmentForm.bankName.required.$message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-block ">
                        <label class="font-size-14"><span class="text-theme">B56.</span> Please give details of all schools,
                            or training institutions attended and all qualifications obtained up to the highest level of
                            education you succesfully completed. </label>

                        <div class="row ">
                            <div class="col-lg-3 mb-3">
                                <label class="ps-4 w-100 text-center font-size-14">Period</label>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Name of school</label>
                                </div>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Address</label>
                                </div>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Qualification / diploma achieved</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-for="(child, index) in employmentForm.educationDetails" :key="index">
                            <div class="col-lg-3 mb-3">
                                <!-- <label class="ps-4 w-100 text-center font-size-14">Period</label> -->
                                <div class="d-flex align-items-center" :key="index">
                                    <h3 class="font-size-15 me-2">Start</h3>
                                    <input type="month" v-model="child.startDateSchool" class="form-control mb-1 mb-lg-2"
                                        placeholder="">

                                </div>
                                <div class="d-flex align-items-center" :key="index">
                                    <h3 class="font-size-15 me-3">End</h3>
                                    <input type="month" v-model="child.endDateSchool" class="form-control mb-1 mb-lg-2"
                                        placeholder="">

                                </div>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 ">Name of school</label> -->
                                    <textarea type="textarea" :key="index" v-model="child.nameOfSchool"
                                        class="form-control resize-none" rows="4" placeholder=""></textarea>
                                </div>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 ">Address</label> -->
                                    <textarea type="textarea" :key="index" v-model="child.schoolAddress"
                                        class="form-control resize-none" rows="4" placeholder=""></textarea>

                                </div>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 ">Qualification / diploma achieved</label> -->
                                    <textarea type="textarea" :key="index" v-model="child.achievedQualification"
                                        class="form-control resize-none" rows="4" placeholder=""></textarea>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="d-block ">
                        <label class="font-size-14"><span class="text-theme">B57.</span> Please give details of your
                            employment history during the last 10 years ( List most recent experience first)</label>

                        <div class="row align-items-end ">
                            <div class="col-lg-2 mb-1 px-1">
                                <label class="ps-4 w-100 text-center  font-size-14">Period (Start/End)</label>
                                <div class="d-flex align-items-center">
                                </div>
                                <div class="d-flex align-items-center">
                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Name of Employer</label>
                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Address of Employer and contact telephone
                                        number</label>
                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Position held and supervisor</label>
                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Type of business / Industry</label>
                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <label class="font-size-14 ">Reasons for leaving</label>
                                </div>
                            </div>
                        </div>
                        <div class="row " v-for="(child, index) in employmentForm.employmentHistory" :key="index">
                            <div class="col-lg-2 mb-1 px-1">
                                <!-- <label class="ps-4 w-100 d-block d-lg-none font-size-14">Period (Start/End)</label> -->
                                <div class="d-flex align-items-center">
                                    <input type="month" v-model="child.startDateEmp" class="form-control mb-1 mb-lg-2"
                                        placeholder="">

                                </div>
                                <div class="d-flex align-items-center">
                                    <input type="month" v-model="child.endDateEmp" class="form-control mb-1 mb-lg-2"
                                        placeholder="">

                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 d-block d-lg-none">Name of Employer</label> -->
                                    <textarea type="textarea" v-model="child.nameOfEmployer"
                                        class="form-control resize-none" rows="4" placeholder=""></textarea>

                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 d-block d-lg-none">Address of Employer and contact -->
                                    <!-- telephone
                                        number</label> -->
                                    <textarea type="textarea" v-model="child.addressOfEmployer"
                                        class="form-control resize-none" rows="4" placeholder=""></textarea>

                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 d-block d-lg-none">Position held and supervisor</label> -->
                                    <textarea type="textarea" v-model="child.positionHeld" class="form-control resize-none"
                                        rows="4" placeholder=""></textarea>

                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 d-block d-lg-none">Type of business / Industry</label> -->
                                    <textarea type="textarea" v-model="child.bussinessType" class="form-control resize-none"
                                        rows="4" placeholder=""></textarea>

                                </div>
                            </div>
                            <div class="col-lg-2 mb-1 px-1">
                                <div class="d-block ">
                                    <!-- <label class="font-size-14 d-block d-lg-none">Reasons for leaving</label> -->
                                    <textarea type="textarea" v-model="child.reasonLeaving" class="form-control resize-none"
                                        rows="4" placeholder=""></textarea>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>