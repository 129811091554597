<script>
import useVuelidate from '@vuelidate/core';
import { sameAs, requiredIf, helpers } from '@vuelidate/validators';
import axios from 'axios';
import _ from 'lodash';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            submitted: false,
            app_URL: process.env.VUE_APP_URL,
            documentForm: {
                applicationForm12: false,
                d2Verification: false,
                d3Medical: false,
                d4Inverstment: false,
                passportCopy: false,
                birthCerticateCopy: false,
                nationalIdCopy: false,
                marriageCertificateCopy: false,
                militaryDocument: false,
                policeRecord: false,
                passportSizePhoto: false,
                employmentLetter: false,
                bankStatement: false,
                residentialProof: false,
                professionalRef: false,
                diplomasCopy: false,
            },
            imagesForm12: [],
            fileFormD2: "",
            fileFormD3: "",
            fileFormD4: "",
            passportCp: "",
            birthCp: "",
            nationalIdCp: "",
            mrgCertificateCp: "",
            militaryDocCp: "",
            policRecCp: "",
            photos: [],
            emplyLetterCp: "",
            bankStatementCp: "",
            residentPrfCp: "",
            professionalRefCp: [],
            diplomaCp: "",
            // ------Dateof Birth
            checkDateOfBirth: 0
        }
    },
    validations: {
        documentForm: {
            applicationForm12: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),
            },
            // d2Verification: {
            //     sameAs: helpers.withMessage("This field is required", sameAs(true)),
            // },
            d3Medical: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            d4Inverstment: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            passportCopy: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            birthCerticateCopy: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            nationalIdCopy: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },

            policeRecord: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),
            },
            passportSizePhoto: {
                // required: requiredIf(function () {
                //     return this.photos.length == 6;
                // }),
                // required: helpers.withMessage('Six Photos is required', requiredIf(function () {
                //     return this.photos.length == 6;
                // }),),
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            employmentLetter: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            bankStatement: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            residentialProof: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            professionalRef: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },
            diplomasCopy: {
                sameAs: helpers.withMessage("This field is required", sameAs(true)),

            },

        },
        photos: {
            required: helpers.withMessage('Exactly six photos are required', requiredIf(function () {
                return this.photos.length == 6;
            })),
            // maxLength: maxLength(this.photos.length == 6)
        }
    },
    created() {
        if (this.$route.params.id) {
            this.editDocument();
        } else {
            this.getDocument();
        }
        this.checkAge();
    },
    mounted() {
        if (this.$route.params.id) {
            this.editDocument();
        } else {
            this.getDocument();
        }
        this.checkAge();
    },
    methods: {
        validateRequiredStepForm() {
            //
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                let data = new FormData();
                data.append('user_id', this.$store.state.authenticatedUserId);
                data.append('disclosure_id', this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id);
                let form12 = this.$refs.form12;
                const f12 = form12.files;
                if (f12.length > 0) {
                    for (let i = 0; i < f12.length; i++) {
                        data.append("imagesForm12[]", f12[i])
                    }
                }
                // for (let i = 0; i < this.imagesForm12.length; i++) {
                //     data.append("imagesForm12[]", this.imagesForm12[i])
                // }
                let six = this.$refs.sixPhotos;
                const photos = six.files;
                if (photos.length > 0) {
                    for (let j = 0; j < photos.length; j++) {
                        data.append("photos[]", photos[j])
                    }
                }

                let pro = this.$refs.professional;
                const proRef = pro.files;
                if (proRef.length > 0) {
                    for (let k = 0; k < proRef.length; k++) {
                        data.append("professionalRefCp[]", proRef[k])
                    }
                }
                data.append("fileFormD2", this.fileFormD2);
                data.append("fileFormD3", this.fileFormD3);
                data.append("fileFormD4", this.fileFormD4);
                data.append("passportCp", this.passportCp);
                data.append("birthCp", this.birthCp);
                data.append("nationalIdCp", this.nationalIdCp);
                data.append("mrgCertificateCp", this.mrgCertificateCp);
                data.append("militaryDocCp", this.militaryDocCp);
                // for (let j = 0; j < this.photos.length; j++) {
                //     data.append("photos[]", this.photos[j])
                // }
                data.append("emplyLetterCp", this.emplyLetterCp);
                data.append("bankStatementCp", this.bankStatementCp);
                // for (let k = 0; k < this.professionalRefCp.length; k++) {
                //     data.append("professionalRefCp[]", this.professionalRefCp[k])
                // }
                data.append("diplomaCp", this.diplomaCp);
                _.each(this.documentForm, (value, key) => {
                    data.append(key, value);
                });
                return axios.post(this.app_URL + "api/addDocument", data).then(() => {

                    localStorage.setItem('currentStep', 7);
                    this.getDocument();
                    return true;
                })
            }
        },
        getDocument() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/getDocument", { user_id: this.$store.state.authenticatedUserId, disclosure_id: this.$store.state.disclosure_id }).then((res) => {
                    if (res.data) {

                        let data = res.data;
                        for (let key in data) {
                            if (data[key] === "true" || data[key] === "false") {
                                data[key] = data[key] === "true";
                            }
                        }
                        this.documentForm = data;
                        this.imagesForm12 = data.imagesForm12 ? data.imagesForm12.length == 1 ? data.imagesForm12.length + ' ' + 'file' : data.imagesForm12.split(',').length + ' ' + 'files' : '';
                        this.fileFormD2 = data.fileFormD2 ? 1 + ' ' + 'file' : '';
                        this.fileFormD3 = data.fileFormD3 ? 1 + ' ' + 'file' : '';
                        this.fileFormD4 = data.fileFormD4 ? 1 + ' ' + 'file' : '';
                        this.passportCp = data.passportCp ? 1 + ' ' + 'file' : '';
                        this.birthCp = data.birthCp ? 1 + ' ' + 'file' : '';
                        this.nationalIdCp = data.nationalIdCp ? 1 + ' ' + 'file' : '';
                        this.mrgCertificateCp = data.mrgCertificateCp ? 1 + ' ' + 'file' : '';
                        this.militaryDocCp = data.militaryDocCp ? 1 + ' ' + 'file' : '';
                        this.photos = data.photos ? data.photos.length == 1 ? data.photos.length + ' ' + 'file' : data.photos.split(',').length + ' ' + 'files' : '';
                        this.emplyLetterCp = data.emplyLetterCp ? 1 + ' ' + 'file' : '';
                        this.bankStatementCp = data.bankStatementCp ? 1 + ' ' + 'file' : '';
                        this.professionalRefCp = data.professionalRefCp ? data.professionalRefCp.length == 1 ? data.professionalRefCp.length + ' ' + 'file' : data.professionalRefCp.split(',').length + ' ' + 'files' : '';
                        this.diplomaCp = data.diplomaCp ? 1 + ' ' + 'file' : '';
                        this.residentPrfCp = data.residentPrfCp ? 1 + ' ' + 'file' : '';
                        this.policRecCp = data.policRecCp ? 1 + ' ' + 'file' : '';
                    }
                })
            }, 2000)
        },
        editDocument() {
            axios.post(this.app_URL + "api/editDocument", { disclosure_id: this.decode(this.$route.params.id) }).then((res) => {
                if (res.data) {
                    let data = res.data;
                    for (let key in data) {
                        if (data[key] === "true" || data[key] === "false") {
                            data[key] = data[key] === "true";
                        }
                    }
                    this.documentForm = data;
                    this.imagesForm12 = data.imagesForm12 ? data.imagesForm12.length == 1 ? data.imagesForm12.length + ' ' + 'file' : data.imagesForm12.split(',').length + ' ' + 'files' : '';
                    this.fileFormD2 = data.fileFormD2 ? 1 + ' ' + 'file' : '';
                    this.fileFormD3 = data.fileFormD3 ? 1 + ' ' + 'file' : '';
                    this.fileFormD4 = data.fileFormD4 ? 1 + ' ' + 'file' : '';
                    this.passportCp = data.passportCp ? 1 + ' ' + 'file' : '';
                    this.birthCp = data.birthCp ? 1 + ' ' + 'file' : '';
                    this.nationalIdCp = data.nationalIdCp ? 1 + ' ' + 'file' : '';
                    this.mrgCertificateCp = data.mrgCertificateCp ? 1 + ' ' + 'file' : '';
                    this.militaryDocCp = data.militaryDocCp ? 1 + ' ' + 'file' : '';
                    this.photos = data.photos ? data.photos.length == 1 ? data.photos.length + ' ' + 'file' : data.photos.split(',').length + ' ' + 'files' : '';
                    this.emplyLetterCp = data.emplyLetterCp ? 1 + ' ' + 'file' : '';
                    this.bankStatementCp = data.bankStatementCp ? 1 + ' ' + 'file' : '';
                    this.professionalRefCp = data.professionalRefCp ? data.professionalRefCp.length == 1 ? data.professionalRefCp.length + ' ' + 'file' : data.professionalRefCp.split(',').length + ' ' + 'files' : '';
                    this.diplomaCp = data.diplomaCp ? 1 + ' ' + 'file' : '';
                    this.residentPrfCp = data.residentPrfCp ? 1 + ' ' + 'file' : '';
                    this.policRecCp = data.policRecCp ? 1 + ' ' + 'file' : '';

                }
            })
        },
        checkAge() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/checkAge", { disclosure_id: this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id }).then(res => {
                    if (res.data) {
                        this.checkDateOfBirth = res.data;
                    }
                })
            }, 2000)
        },

        getFormD2() {
            axios.post(this.app_URL + 'api/getFormD', { user_id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))) }).then(res => {
                if (res.data) {
                    this.disclosureForm = res.data;

                    this.resizedImageDataUrl = this.app_URL + 'image/' + res.data.image;
                    this.disclosureForm.gender = res.data.user.gender;
                    this.disclosureForm.countryBirth = res.data.user.countryBirth;
                    this.disclosureForm.address = res.data.user.currentFullAddress;
                }
            })

        },
        // form12Copies(event) {
        //     var selectedFiles = event.target.files;
        //     // for (var i = 0; i < selectedFiles.length; i++) {
        //     //     // this.imagesForm12.push(selectedFiles[i]);
        //     // }
        // },
        formD2(event) {
            this.fileFormD2 = event.target.files[0];


        },
        formD3(event) {
            this.fileFormD3 = event.target.files[0];


        },
        formD4(event) {
            this.fileFormD4 = event.target.files[0];


        },
        passport(event) {
            this.passportCp = event.target.files[0];


        },
        birthC(event) {
            this.birthCp = event.target.files[0];


        },
        nationalId(event) {
            this.nationalIdCp = event.target.files[0];


        },
        mrgCertificate(event) {
            this.mrgCertificateCp = event.target.files[0];


        },
        militaryDoc(event) {
            this.militaryDocCp = event.target.files[0];


        },
        policeRec(event) {
            this.policRecCp = event.target.files[0];
        },
        // uploadPhotos(event) {
        //     var selectedFiles = event.target.files;
        //     // for (var i = 0; i < selectedFiles.length; i++) {
        //     //     this.photos.push(selectedFiles[i]);
        //     // }


        // },
        emplyLetter(event) {
            this.emplyLetterCp = event.target.files[0];


        },
        bankStatement(event) {
            this.bankStatementCp = event.target.files[0];

        },
        residentPrf(event) {
            this.residentPrfCp = event.target.files[0];
        },
        // professionalRef(event) {
        //     var selectedFiles = event.target.files;
        //     for (var i = 0; i < selectedFiles.length; i++) {
        //         this.professionalRefCp.push(selectedFiles[i]);
        //     }

        // },
        diploma(event) {
            this.diplomaCp = event.target.files[0];

        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        },
        encode(id) {
            return encodeURIComponent(
                CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
            );
        },
    }
}
</script>

<template>
    <div>
        <div class="text-main-title">
            <h2 class="titleStepForm">PART F: Required Documents</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="font-size-14"><span class="text-theme"></span>You must provide the following documents
                        with your application. Please ensure that these comply with the requeriments of the Citizenship by
                        Investment Regulations and any circulars issued by the Citizenship by Investment Unit:</label>
                </div>
                <div class="col-lg-6">
                    <div class="d-block mb-2">
                        <label for="applicationForm12" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.applicationForm12" name="applicationForm12"
                                class="inputRadioCstm d-none" id="applicationForm12"
                                :class="{ 'is-invalid': submitted && v$.documentForm.applicationForm12.$error, }">

                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">Two
                                        (2) completed and signed copies of Application Form 12 for each applicant</p>
                                </div>
                                <div class="col-2 p-0 text-end pe-1 ">
                                    <b-button class="bg-transparent border-0">
                                        <router-link :to="`/d12/${this.encode(this.$store.state.disclosure_id)}`">
                                            <i class="mdi mdi-eye font-size-22 text-theme"></i></router-link>
                                    </b-button>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="application/pdf" ref="form12" @change="form12Copies" class="form-control border-0" id="form12" 
                                            multiple> <span v-if="imagesForm12.length > 0">{{ this.imagesForm12 }}</span>
                                    </div>
                                </div>
                            </div>
                        </label>

                        <span class="text-danger" v-if="submitted && v$.documentForm.applicationForm12.$error">{{
                            v$.documentForm.applicationForm12.sameAs.$message }}</span>

                    </div>
                    <div class="d-block mb-2" v-if="checkDateOfBirth > 10">
                        <label for="d2Verification" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.d2Verification" name="d2Verification"
                                class="inputRadioCstm d-none" id="d2Verification">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                                        D2 Fingerprint and Photo Verification Form
                                    </p>
                                </div>
                                <div class="col-2 p-0 text-end pe-1 ">
                                    <b-button class="bg-transparent border-0">
                                        <router-link :to="`/d2/${this.encode(this.$store.state.disclosure_id)}`">
                                            <i class="mdi mdi-eye font-size-22 text-theme"></i></router-link>
                                    </b-button>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="application/pdf" @change="formD2" class="form-control border-0" id="form12"><span v-if="fileFormD2.length > 0">{{ this.fileFormD2 }}</span>
                                    </div>
                                </div>
                            </div>
                        </label>
                        <!-- <span class="text-danger" v-if="submitted && v$.documentForm.d2Verification.$error">{{
                            v$.documentForm.d2Verification.sameAs.$message }}</span> -->

                    </div>
                    <div class="d-block mb-2">
                        <label for="d3Medical" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                d3Medical" name="d3Medical" class="inputRadioCstm d-none" id="d3Medical"
                                :class="{ 'is-invalid': submitted && v$.documentForm.d3Medical.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">D3
                                        Medical Questionnaire + HIV, blood, urine test results</p>
                                </div>
                                <div class="col-2 p-0 text-end pe-1 ">
                                    <b-button class="bg-transparent border-0">
                                        <router-link :to="`/d3/${this.encode(this.$store.state.disclosure_id)}`">
                                            <i class="mdi mdi-eye font-size-22 text-theme"></i></router-link>
                                    </b-button>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="application/pdf" @change="formD3" class="form-control border-0" id="form12"><span v-if="fileFormD3.length > 0">{{ this.fileFormD3 }}</span>
                                    </div>
                                </div>
                            </div>
                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.d3Medical.$error">{{
                            v$.documentForm.d3Medical.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="d4Inverstment" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                d4Inverstment" name="d4Inverstment" class="inputRadioCstm d-none" id="d4Inverstment"
                                :class="{ 'is-invalid': submitted && v$.documentForm.d4Inverstment.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">D4
                                        Investment Agreement or sale and purchase agreement </p>
                                </div>
                                <div class="col-2 p-0 text-end pe-1 ">
                                    <b-button class="bg-transparent border-0">
                                        <router-link :to="`/d4/${this.encode(this.$store.state.disclosure_id)}`">
                                            <i class="mdi mdi-eye font-size-22 text-theme"></i></router-link>
                                    </b-button>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="application/pdf" @change="formD4" class="form-control border-0" id="form12"><span v-if="fileFormD4.length > 0">{{ this.fileFormD4 }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.d4Inverstment.$error">{{
                            v$.documentForm.d4Inverstment.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="passportCopy" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                passportCopy" name="passportCopy" class="inputRadioCstm d-none" id="passportCopy"
                                :class="{ 'is-invalid': submitted && v$.documentForm.passportCopy.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-12">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                                        Certified copy of passport </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="passport" class="form-control border-0" id="form12"><span v-if="passportCp.length > 0">{{ this.passportCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.passportCopy.$error">{{
                            v$.documentForm.passportCopy.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="birthCerticateCopy" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                birthCerticateCopy" name="birthCerticateCopy" class="inputRadioCstm d-none"
                                id="birthCerticateCopy"
                                :class="{ 'is-invalid': submitted && v$.documentForm.birthCerticateCopy.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-12">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                                        Original and/or certified copy of birth certificate </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="birthC" class="form-control border-0" id="form12"><span v-if="birthCp.length > 0">{{ this.birthCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.birthCerticateCopy.$error">{{
                            v$.documentForm.birthCerticateCopy.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="nationalIdCopy" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                nationalIdCopy" name="nationalIdCopy" class="inputRadioCstm d-none" id="nationalIdCopy"
                                :class="{ 'is-invalid': submitted && v$.documentForm.nationalIdCopy.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                                        Certified copy of national ID document </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="nationalId" class="form-control border-0" id="form12"> <span v-if="nationalIdCp.length > 0">{{ this.nationalIdCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.nationalIdCopy.$error">{{
                            v$.documentForm.nationalIdCopy.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="marriageCertificateCopy" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                marriageCertificateCopy" name="marriageCertificateCopy" class="inputRadioCstm d-none"
                                id="marriageCertificateCopy">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">

                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                                        Certified copy of marriage certificate/disolution of marriage (if applicable)</p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="mrgCertificate" class="form-control border-0"
                                            id="form12"> <span v-if="mrgCertificateCp.length > 0">{{ this.mrgCertificateCp }}</span>
                                    </div>
                                </div>
                            </div>
                        </label>
                        <!-- <span class="text-danger" v-if="submitted && v$.documentForm.marriageCertificateCopy.$error">{{
                            v$.documentForm.marriageCertificateCopy.sameAs.$message }}</span> -->
                    </div>
                    <div class="d-block mb-2">
                        <label for="militaryDocument" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                militaryDocument" name="militaryDocument" class="inputRadioCstm d-none"
                                id="militaryDocument">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                                        Military Service and Discharge Documents (if applicable) </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="militaryDoc" class="form-control border-0" id="form12"> <span v-if="militaryDocCp.length > 0">{{ this.militaryDocCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <!-- <span class="text-danger" v-if="submitted && v$.documentForm.militaryDocument.$error">{{
                            v$.documentForm.militaryDocument.sameAs.$message }}</span> -->
                    </div>
                    <div class="d-block mb-2">
                        <label for="policeRecord" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                policeRecord" name="policeRecord" class="inputRadioCstm d-none" id="policeRecord"
                                :class="{ 'is-invalid': submitted && v$.documentForm.policeRecord.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">Police
                                        Record, from country of birth, current country of residence and any previous
                                        countries where you resided for six months or more (each applicant aged 16 and over)
                                    </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="policeRec" class="form-control border-0" id="form12"> <span v-if="policRecCp.length > 0">{{ this.policRecCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.policeRecord.$error">{{
                            v$.documentForm.policeRecord.sameAs.$message }}</span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="d-block mb-2">
                        <label for="passportSizePhoto" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.
                                passportSizePhoto" name="passportSizePhoto" class="inputRadioCstm d-none"
                                id="passportSizePhoto"
                                :class="{ 'is-invalid': submitted && v$.documentForm.passportSizePhoto.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">Six
                                        (6)
                                        passport size photos for each applicant </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file"  ref="sixPhotos" accept="image/*" @change="uploadPhotos"
                                            class="form-control border-0" id="form12" multiple
                                            :class="{ 'is-invalid': submitted && v$.photos.$error, }"><span
                                            v-if="photos.length > 0">{{ this.photos }}</span>
                                        <div v-for="(error, index ) in v$.photos.$errors" :key="index">
                                            <span v-if="error.$message">{{ error.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.passportSizePhoto.$error">{{
                            v$.documentForm.passportSizePhoto.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="employmentLetter" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.employmentLetter" name="employmentLetter"
                                class="inputRadioCstm d-none" id="employmentLetter"
                                :class="{ 'is-invalid': submitted && v$.documentForm.employmentLetter.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">Letter
                                        of Employment/Audited Financial Statement / Letter of Incorporation </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="emplyLetter" class="form-control border-0" id="form12"> <span v-if="emplyLetterCp.length > 0">{{ this.emplyLetterCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.employmentLetter.$error">{{
                            v$.documentForm.employmentLetter.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="bankStatement" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.bankStatement" name="bankStatement"
                                class="inputRadioCstm d-none" id="bankStatement"
                                :class="{ 'is-invalid': submitted && v$.documentForm.bankStatement.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">12
                                        months bank statements </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf"  @change="bankStatement" class="form-control border-0"
                                            id="form12"> <span v-if="bankStatementCp.length > 0">{{ this.bankStatementCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.bankStatement.$error">{{
                            v$.documentForm.bankStatement.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="residentialProof" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.residentialProof" name="residentialProof"
                                class="inputRadioCstm d-none" id="residentialProof"
                                :class="{ 'is-invalid': submitted && v$.documentForm.residentialProof.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">Proof
                                        of residential address </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="residentPrf" class="form-control border-0" id="form12"> <span v-if="residentPrfCp.length > 0">{{ this.residentPrfCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.residentialProof.$error">{{
                            v$.documentForm.residentialProof.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="professionalRef" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.professionalRef" name="professionalRef"
                                class="inputRadioCstm d-none" id="professionalRef"
                                :class="{ 'is-invalid': submitted && v$.documentForm.professionalRef.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">Two
                                        (2) professional references </p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" ref="professional" @change="professionalRef" class="form-control border-0"
                                            id="form12" multiple><span v-if="professionalRefCp.length > 0">{{ this.professionalRefCp }}</span>
                                    </div>
                                </div>
                            </div>
                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.professionalRef.$error">{{
                            v$.documentForm.professionalRef.sameAs.$message }}</span>
                    </div>
                    <div class="d-block mb-2">
                        <label for="diplomasCopy" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <input type="checkbox" v-model="documentForm.diplomasCopy" name="diplomasCopy"
                                class="inputRadioCstm d-none" id="diplomasCopy"
                                :class="{ 'is-invalid': submitted && v$.documentForm.diplomasCopy.$error, }">
                            <span class="radioCircleIcon line-height-1">
                                <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                                <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <p class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                                        Certified copies of educational diplomas</p>
                                </div>
                                <div class="col-12">
                                    <div class="d-block border border-light ps-1 mb-3 mt-2">
                                        <!-- <label for="form12" class="text-dark">Upload File</label> -->
                                        <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf" @change="diploma" class="form-control border-0" id="form12"> <span v-if="diplomaCp.length > 0">{{ this.diplomaCp }}</span>
                                    </div>
                                </div>
                            </div>

                        </label>
                        <span class="text-danger" v-if="submitted && v$.documentForm.diplomasCopy.$error">{{
                            v$.documentForm.diplomasCopy.sameAs.$message }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>