<script>
import Select2 from 'vue3-select2-component';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { countries } from '../applicant/country.js';
import { states } from '../applicant/state.js';
import { cities } from '../applicant/city.js';
import { india } from '../applicant/indiaCities.js';
import { usa } from '../applicant/usaCities.js';
import { turkey } from '../applicant/turkeyCities.js';
import { russia } from '../applicant/russiaCities.js';
import { thailand } from '../applicant/thailandCities.js';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            submitted: false,
            app_URL: process.env.VUE_APP_URL,
            familyInformation: {
                relationshipStatus: "",
                currentMarried: "",
                marriedPlace: "",
                spouseName: "",
                spouseBirthPlace: "",
                spouseNationality: "",
                spousePassportNo: "",
                spouseFulladdress: "",
                spouseCityModal: "",
                spouseStateModal: "",
                spouseCountryModal: "",
                spousePostalZipCode: "",
                spouseHomeTele: "",
                spouseWorkPhoneNo: "",
                spouseCellPhone: "",
                spouseOccupation: "",
                spouseEmployer: "",
                spouseBussFulladdress: "",
                bsFullAdress: "",
                bsCityModal: "",
                bsStateModal: "",
                bsCountryModal: "",
                bsZipCodePstl: "",

                motherInc: "",
                mLastName: "",
                mFirstName: "",
                mDateOfBirth: "",
                mBirthPlace: "",
                mNationality: "",
                mResidentialAddress: "",
                mOccupation: "",
                fatherInc: "",
                fLastName: "",
                fFirstName: "",
                fDateOfBirth: "",
                fBirthPlace: "",
                fNationality: "",
                fResidentialAddress: "",
                fOccupation: "",
                fatherInLawInc: "",
                fInLawLastName: "",
                fInLawFirstName: "",
                fInLawDateOfBirth: "",
                fInLawBirthPlace: "",
                fInLawNationality: "",
                fInLawResidentialAddress: "",
                fInLawOccupation: "",
                motherInLawInc: "",
                mInLawLastName: "",
                mInLawFirstName: "",
                mInLawDateOfBirth: "",
                mInLawBirthPlace: "",
                mInLawNationality: "",
                mInLawResidentialAddress: "",
                mInLawOccupation: "",
                spouseDetail: Array(2).fill().map(() => ({ preSpouseName: '', preSpousedateOfBirth: '', preSpouseNationality: '', preSpouseDivorceOrder: '', preSpousePeriodMary: '' })),
                siblingList: Array(2).fill().map(() => ({ sibLastName: '', sibFirstName: '', sibGender: '', sibDateOfBirth: '', sibBirthPlace: '', sibNationality: '', sibResidentialAddress: '', sibOccupation: '' })),
                siblingList2: Array(2).fill().map(() => ({ sibLastName: '', sibFirstName: '', sibGender: '', sibDateOfBirth: '', sibBirthPlace: '', sibNationality: '', sibResidentialAddress: '', sibOccupation: '' })),
                childList: Array(6).fill().map(() => ({ childLastName: '', childFirstName: '', childGender: '', childDateOfBirth: '', childBirthPlace: '', childNationality: '', childResidentialAddress: '', childIn: '' })),

            },
            allStates: states,
            allCities: [...cities, ...india, ...usa, ...turkey, ...russia, ...thailand],
            cityOption: [],
            stateOption: [],
            countryOption: countries,
            preCityOption: [],
            preStateOption: [],
            preCountryOption: countries,
        }
    },
    components: {
        Select2
    },
    validations: {
        familyInformation: {
            relationshipStatus: {
                required: helpers.withMessage("This Field is required", required),
            },
            motherInc: {
                required: helpers.withMessage("This Field is required", required),
            },
            mLastName: {
                required: helpers.withMessage("This Field is required", required),
            },
            mFirstName: {
                required: helpers.withMessage("This Field is required", required),
            },
            mDateOfBirth: {
                required: helpers.withMessage("This Field is required", required),
            },
            mBirthPlace: {
                required: helpers.withMessage("This Field is required", required),
            },
            mNationality: {
                required: helpers.withMessage("This Field is required", required),
            },
            mResidentialAddress: {
                required: helpers.withMessage("This Field is required", required),
            },
            mOccupation: {
                required: helpers.withMessage("This Field is required", required),
            },
            fatherInc: {
                required: helpers.withMessage("This Field is required", required),
            },
            fLastName: {
                required: helpers.withMessage("This Field is required", required),
            },
            fFirstName: {
                required: helpers.withMessage("This Field is required", required),
            },
            fDateOfBirth: {
                required: helpers.withMessage("This Field is required", required),
            },
            fBirthPlace: {
                required: helpers.withMessage("This Field is required", required),
            },
            fNationality: {
                required: helpers.withMessage("This Field is required", required),
            },
            fResidentialAddress: {
                required: helpers.withMessage("This Field is required", required),
            },
            fOccupation: {
                required: helpers.withMessage("This Field is required", required),
            },
        }
    },
    activate() {
        if (this.$route.params.id) {
            this.editFamilyInformation();
        } else {
            this.getFamilyInformation();
        }
    },
    beforeMount() {
        if (this.$route.params.id) {
            this.editFamilyInformation();
        } else {
            this.getFamilyInformation();
        }
    },
    methods: {
        validateInformationForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {

                return axios.post(this.app_URL + "api/addFamilyInformation", {
                    user_id: this.$store.state.authenticatedUserId,
                    disclosure_id: this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id,
                    family: this.familyInformation
                }).then((res) => {
                    console.log(res);
                    localStorage.setItem('currentStep', 4);
                    this.getFamilyInformation();
                    return true;
                });
            }
        },
        countrySelect(event,id) {
            if (id == 1) {
                let stateArray = this.allStates.filter((item) => item.country_name == event.id)
                this.stateOption = stateArray.map((jsonObject) => jsonObject["name"])
            } else {
                let stateArray = this.allStates.filter((item) => item.country_name == event.id)
                this.preStateOption = stateArray.map((jsonObject) => jsonObject["name"])
            }
        },
        stateSelect(event,id) {
            if (id == 1) {
                let cityArray = this.allCities.filter((item) => item.state_name == event.id)
                this.cityOption = cityArray.map((jsonObject) => jsonObject["name"])
            } else {
                let cityArray = this.allCities.filter((item) => item.state_name == event.id)
                this.preCityOption = cityArray.map((jsonObject) => jsonObject["name"])
            }
        },
        getFamilyInformation() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/getFamilyInformation", { user_id: this.$store.state.authenticatedUserId, disclosure_id: this.$store.state.disclosure_id }).then((res) => {
                    if (res.data) {
                        this.familyInformation = res.data;

                        // 1 state  and cities
                        let stateArray = this.allStates.filter((item) => item.country_name == res.data.spouseCountryModal);
                        this.stateOption = stateArray.map((jsonObject) => jsonObject["name"]);
                        let cityArray = this.allCities.filter((item) => item.state_name == res.data.spouseStateModal);
                        this.cityOption = cityArray.map((jsonObject) => jsonObject["name"]);
                        // end
                        //2 state  and cities
                        let preStateArray = this.allStates.filter((item) => item.country_name == res.data.bsCountryModal);
                        this.stateOption = preStateArray.map((jsonObject) => jsonObject["name"]);
                        let preCityArray = this.allCities.filter((item) => item.state_name == res.data.bsCountryModal);
                        this.cityOption = preCityArray.map((jsonObject) => jsonObject["name"]);
                        // end


                        this.familyInformation.motherInc = res.data.motherInc == '0' ? false : res.data.motherInc == '1' ? true : null;
                        this.familyInformation.fatherInc = res.data.fatherInc == '0' ? false : res.data.fatherInc == '1' ? true : null;
                        this.familyInformation.fatherInLawInc = res.data.fatherInLawInc == '0' ? false : res.data.fatherInLawInc == '1' ? true : null;
                        this.familyInformation.motherInLawInc = res.data.motherInLawInc == '0' ? false : res.data.motherInLawInc == '1' ? true : null;
                        this.familyInformation.spouseDetail = res.data.spouseDetail ? JSON.parse(res.data.spouseDetail) : Array(2).fill().map(() => ({ preSpouseName: '', preSpousedateOfBirth: '', preSpouseNationality: '', preSpouseDivorceOrder: '', preSpousePeriodMary: '' }));
                        this.familyInformation.siblingList = res.data.siblingList ? JSON.parse(res.data.siblingList) : Array(2).fill().map(() => ({ sibLastName: '', sibFirstName: '', sibGender: '', sibDateOfBirth: '', sibBirthPlace: '', sibNationality: '', sibResidentialAddress: '', sibOccupation: '' }));
                        this.familyInformation.siblingList2 = res.data.siblingList2 ? JSON.parse(res.data.siblingList2) : Array(2).fill().map(() => ({ sibLastName: '', sibFirstName: '', sibGender: '', sibDateOfBirth: '', sibBirthPlace: '', sibNationality: '', sibResidentialAddress: '', sibOccupation: '' }));
                        this.familyInformation.childList = res.data.childList ? JSON.parse(res.data.childList) : Array(6).fill().map(() => ({ childLastName: '', childFirstName: '', childGender: '', childDateOfBirth: '', childBirthPlace: '', childNationality: '', childResidentialAddress: '', childIn: '' }));
                    }
                })
            }, 2000)
        },
        editFamilyInformation() {
            axios.post(this.app_URL + "api/editFamilyInformation", { disclosure_id: this.decode(this.$route.params.id) }).then((res) => {
                if (res.data) {
                    this.familyInformation = res.data;
                    // 1 state  and cities
                    let stateArray = this.allStates.filter((item) => item.country_name == res.data.spouseCountryModal);
                    this.stateOption = stateArray.map((jsonObject) => jsonObject["name"]);
                    let cityArray = this.allCities.filter((item) => item.state_name == res.data.spouseStateModal);
                    this.cityOption = cityArray.map((jsonObject) => jsonObject["name"]);
                    // end
                    //2 state  and cities
                    let preStateArray = this.allStates.filter((item) => item.country_name == res.data.bsCountryModal);
                    this.stateOption = preStateArray.map((jsonObject) => jsonObject["name"]);
                    let preCityArray = this.allCities.filter((item) => item.state_name == res.data.bsCountryModal);
                    this.cityOption = preCityArray.map((jsonObject) => jsonObject["name"]);
                    // end
                    this.familyInformation.motherInc = res.data.motherInc == '0' ? false : res.data.motherInc == '1' ? true : null;
                    this.familyInformation.fatherInc = res.data.fatherInc == '0' ? false : res.data.fatherInc == '1' ? true : null;
                    this.familyInformation.fatherInLawInc = res.data.fatherInLawInc == '0' ? false : res.data.fatherInLawInc == '1' ? true : null;
                    this.familyInformation.motherInLawInc = res.data.motherInLawInc == '0' ? false : res.data.motherInLawInc == '1' ? true : null;
                    this.familyInformation.spouseDetail = res.data.spouseDetail ? JSON.parse(res.data.spouseDetail) : Array(2).fill().map(() => ({ preSpouseName: '', preSpousedateOfBirth: '', preSpouseNationality: '', preSpouseDivorceOrder: '', preSpousePeriodMary: '' }));
                    this.familyInformation.siblingList = res.data.siblingList ? JSON.parse(res.data.siblingList) : Array(2).fill().map(() => ({ sibLastName: '', sibFirstName: '', sibGender: '', sibDateOfBirth: '', sibBirthPlace: '', sibNationality: '', sibResidentialAddress: '', sibOccupation: '' }));
                    this.familyInformation.siblingList2 = res.data.siblingList2 ? JSON.parse(res.data.siblingList2) : Array(2).fill().map(() => ({ sibLastName: '', sibFirstName: '', sibGender: '', sibDateOfBirth: '', sibBirthPlace: '', sibNationality: '', sibResidentialAddress: '', sibOccupation: '' }));
                    this.familyInformation.childList = res.data.childList ? JSON.parse(res.data.childList) : Array(6).fill().map(() => ({ childLastName: '', childFirstName: '', childGender: '', childDateOfBirth: '', childBirthPlace: '', childNationality: '', childResidentialAddress: '', childIn: '' }));
                }
            })
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }
    }
}
</script>

<template>
    <div>
        <div class="text-main-title">
            <h2 class="titleStepForm">PART C: Information about your family</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-lg-6 ">
                    <div class="d-block mb-4">
                        <label class="font-size-14"><span class="text-theme">C58.</span> Are you</label>
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="single" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="familyInformation.relationshipStatus" value="Single"
                                        name="InfoDetail" class="inputRadioCstm d-none" id="single"
                                        :class="{ 'is-invalid': submitted && v$.familyInformation.relationshipStatus.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Single</h4>
                                </label>
                            </div>
                            <div class="col-lg-6">
                                <label for="married" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="familyInformation.relationshipStatus" value="Married"
                                        name="InfoDetail" class="inputRadioCstm d-none" id="married"
                                        :class="{ 'is-invalid': submitted && v$.familyInformation.relationshipStatus.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Married</h4>
                                </label>
                            </div>
                            <div class="col-lg-6">
                                <label for="separated" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="familyInformation.relationshipStatus" value="Separated"
                                        name="InfoDetail" class="inputRadioCstm d-none" id="separated"
                                        :class="{ 'is-invalid': submitted && v$.familyInformation.relationshipStatus.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Separated</h4>
                                </label>
                            </div>
                            <div class="col-lg-6">
                                <label for="divorced" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="familyInformation.relationshipStatus" value="Divorced"
                                        name="InfoDetail" class="inputRadioCstm d-none" id="divorced"
                                        :class="{ 'is-invalid': submitted && v$.familyInformation.relationshipStatus.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Divorced</h4>
                                </label>
                            </div>
                            <div class="col-lg-6">
                                <label for="widowed" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="familyInformation.relationshipStatus" value="Widowed"
                                        name="InfoDetail" class="inputRadioCstm d-none" id="widowed"
                                        :class="{ 'is-invalid': submitted && v$.familyInformation.relationshipStatus.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Widowed</h4>
                                </label>
                            </div>
                            <div class="col-lg-6">
                                <label for="engaged" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="familyInformation.relationshipStatus" value="Engaged
                                    " name="InfoDetail" class="inputRadioCstm d-none" id="engaged"
                                        :class="{ 'is-invalid': submitted && v$.familyInformation.relationshipStatus.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Engaged</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.familyInformation.relationshipStatus.$error">{{
                            v$.familyInformation.relationshipStatus.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">C59.</span> If currently married, please
                            provide details of your marriage</label>
                        <input type="date" v-model="familyInformation.currentMarried" class="form-control" placeholder="">
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14">Place of marriage (City / State/County / Country)</label>
                        <input type="text" v-model="familyInformation.marriedPlace" class="form-control" placeholder="">
                    </div>
                    <div class="d-block mb-4 mt-5">
                        <label class="font-size-14"><span class="text-theme">Details of your spouse</span><br> (If engaged,
                            enter details of future spouse)</label>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">C60.</span> Spouse’s Full Name (Maiden)</label>
                        <input type="text" v-model="familyInformation.spouseName" class="form-control" placeholder="">
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">C61.</span> Spouse’s Place of Birth</label>
                        <input type="text" v-model="familyInformation.spouseBirthPlace" class="form-control" placeholder="">
                    </div>
                    <div class="d-block mb-3">
                        <h4 class="font-size-14"><span class="text-theme">C62.</span> Spouse’s Nationality / Citizenship
                        </h4>
                        <input type="text" v-model="familyInformation.spouseNationality" class="form-control"
                            placeholder="">
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">C63.</span> Passport Number</label>
                        <input type="number" v-model="familyInformation.spousePassportNo" class="form-control"
                            placeholder="">
                    </div>

                    <div class="mb-3">
                        <label class="font-size-14"><span class="text-theme">C64.</span> Spouse’s Residential address (if
                            different )</label>
                        <div class="d-block mb-3">
                            <label class="font-size-14">Full Address</label>
                            <textarea type="textarea" v-model="familyInformation.spouseFulladdress"
                                class="form-control resize-none" rows="5"></textarea>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"> Country</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="familyInformation.spouseCountryModal" placeholder="Select"
                                    :options="countryOption" @select="countrySelect($event,1)" />
                            </div>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"> State</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="familyInformation.spouseStateModal" placeholder="Select"
                                    :options="stateOption" @select="stateSelect($event,1)" />
                            </div>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"> City</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="familyInformation.spouseCityModal" placeholder="Select"
                                    :options="cityOption" />
                            </div>
                        </div>


                        <div class="d-block mb-3">
                            <label class="font-size-14">Postal / Zip Code</label>
                            <input type="number" v-model="familyInformation.spousePostalZipCode" class="form-control"
                                placeholder="">
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14">Spouse’s Home Telephone (if different)</label>
                            <input type="number" v-model="familyInformation.spouseHomeTele" class="form-control"
                                placeholder="">
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14">Spouse’s Work Telephone</label>
                            <input type="number" v-model="familyInformation.spouseWorkPhoneNo" class="form-control"
                                placeholder="">
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14">Cell Phone / Mobile</label>
                            <input type="number" v-model="familyInformation.spouseCellPhone" class="form-control"
                                placeholder="">
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 order-lg-2">
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">C65.</span> Spouse’s Occupation</label>
                        <input type="text" v-model="familyInformation.spouseOccupation" class="form-control" placeholder="">
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">C66.</span> Spouse’s Employer</label>
                        <input type="text" v-model="familyInformation.spouseEmployer" class="form-control" placeholder="">
                    </div>
                    <div class="">
                        <label class="font-size-14"><span class="text-theme">C67.</span> Address of Spouse’s
                            Employer/Business Entity</label>
                        <div class="d-block mb-3">
                            <label class="font-size-14">Full Address</label>
                            <textarea type="textarea" v-model="familyInformation.bsFullAdress"
                                class="form-control resize-none" rows="3"></textarea>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"> Country</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="familyInformation.bsCountryModal" placeholder="Select"
                                    :options="preCountryOption" @select="countrySelect($event,2)" />
                            </div>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"> State</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="familyInformation.bsStateModal" placeholder="Select"
                                    :options="preStateOption" @select="stateSelect($event,2)" />
                            </div>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"> City</label>
                            <div class="single-select2-cstm">
                                <Select2 v-model="familyInformation.bsCityModal" placeholder="Select"
                                    :options="preCityOption" />
                            </div>
                        </div>


                        <div class="d-block">
                            <label class="font-size-14">Postal / Zip Code</label>
                            <input type="number" v-model="familyInformation.bsZipCodePstl" class="form-control"
                                placeholder="">
                        </div>
                    </div>

                    <div class="mt-3 mb-3">
                        <label class="font-size-14"><span class="text-theme">C68.</span> Please provide the following
                            details about any of your previous spouses. </label>
                        <div v-for="(child, index) in familyInformation.spouseDetail" :key="index">
                            <div class="d-block mb-3">
                                <label class="font-size-14">Name</label>
                                <input type="text" v-model="child.preSpouseName" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"> Place and Date of Birth</label>
                                <input type="text" v-model="child.preSpousedateOfBirth" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"> Nationality</label>
                                <input type="text" v-model="child.preSpouseNationality" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"> Date of Divorce Order / Decree</label> <input type="text"
                                    v-model="child.preSpouseDivorceOrder" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"> Period of Marriage</label>
                                <input type="text" v-model="child.preSpousePeriodMary" class="form-control" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-block mb-3">
                <div class="groupFormStyle p-2 mb-3">
                    <h2 class="text-theme font-size-16">Details of your family</h2>
                    <label class="font-size-14 mb-3"> Please provide details of all family members, whether applying for
                        citizenship with you or not, including where relevant, those legally adopted. If any family member
                        is deceased, please give their details and write ‘deceased’ in the field “Residential
                        Address”</label>
                    <div class="row">
                        <div class="col-lg-6">
                            <label class="font-size-14"><span class="text-theme">C69. Details of your father</span> </label>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">a.</span> Last name / Family
                                    name</label>
                                <input type="text" v-model="familyInformation.fLastName" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.fLastName.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.familyInformation.fLastName.$error">{{
                                    v$.familyInformation.fLastName.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">b.</span> First / Given name</label>
                                <input type="text" v-model="familyInformation.fFirstName" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.fFirstName.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.familyInformation.fFirstName.$error">{{
                                    v$.familyInformation.fFirstName.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">c.</span> Date of Birth</label>
                                <input type="date" v-model="familyInformation.fDateOfBirth" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.fDateOfBirth.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.fDateOfBirth.$error">{{
                                        v$.familyInformation.fDateOfBirth.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">d.</span> Place of Birth</label>
                                <input type="text" v-model="familyInformation.fBirthPlace" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.fBirthPlace.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.fBirthPlace.$error">{{
                                        v$.familyInformation.fBirthPlace.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">e.</span> Citizenship /
                                    Nationality</label>
                                <input type="text" v-model="familyInformation.fNationality" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.fNationality.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.fNationality.$error">{{
                                        v$.familyInformation.fNationality.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">f.</span> Residential Address</label>
                                <textarea type="textarea" v-model="familyInformation.fResidentialAddress"
                                    class="form-control resize-none" rows="3"
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.fResidentialAddress.$error, }"></textarea>
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.fResidentialAddress.$error">{{
                                        v$.familyInformation.fResidentialAddress.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">g.</span> Occupation</label>
                                <input type="text" v-model="familyInformation.fOccupation" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.fOccupation.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.fOccupation.$error">{{
                                        v$.familyInformation.fOccupation.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">h.</span> Is this parent included in
                                    your application </label>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label for="parent_yes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.fatherInc" :value="true"
                                                name="parentCheck" class="inputRadioCstm d-none" id="parent_yes"
                                                :class="{ 'is-invalid': submitted && v$.familyInformation.fatherInc.$error, }"><span
                                                class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                        </label>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="parent_no" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.fatherInc" :value="false"
                                                name="parentCheck" class="inputRadioCstm d-none" id="parent_no"
                                                :class="{ 'is-invalid': submitted && v$.familyInformation.fatherInc.$error, }"><span
                                                class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                        </label>
                                    </div>
                                </div>
                                <span class="text-danger" v-if="submitted && v$.familyInformation.fatherInc.$error">{{
                                    v$.familyInformation.fatherInc.required.$message }}</span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label class="font-size-14"><span class="text-theme">C70. Details of your mother</span> </label>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">a.</span> Last name / Family
                                    name</label>
                                <input type="text" v-model="familyInformation.mLastName" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.mLastName.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.familyInformation.mLastName.$error">{{
                                    v$.familyInformation.mLastName.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">b.</span> First / Given name</label>
                                <input type="text" v-model="familyInformation.mFirstName" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.mFirstName.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.familyInformation.mFirstName.$error">{{
                                    v$.familyInformation.mFirstName.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">c.</span> Date of Birth</label>
                                <input type="date" v-model="familyInformation.mDateOfBirth" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.mDateOfBirth.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.mDateOfBirth.$error">{{
                                        v$.familyInformation.mDateOfBirth.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">d.</span> Place of Birth</label>
                                <input type="text" v-model="familyInformation.mBirthPlace" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.mBirthPlace.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.mBirthPlace.$error">{{
                                        v$.familyInformation.mBirthPlace.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">e.</span> Citizenship /
                                    Nationality</label>
                                <input type="text" v-model="familyInformation.mNationality" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.mNationality.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.mNationality.$error">{{
                                        v$.familyInformation.mNationality.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">f.</span> Residential Address</label>
                                <textarea type="textarea" v-model="familyInformation.mResidentialAddress"
                                    class="form-control resize-none" rows="3"
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.mResidentialAddress.$error, }"></textarea>
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.mResidentialAddress.$error">{{
                                        v$.familyInformation.mResidentialAddress.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">g.</span> Occupation</label>
                                <input type="text" v-model="familyInformation.mOccupation" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.familyInformation.mOccupation.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.familyInformation.mOccupation.$error">{{
                                        v$.familyInformation.mOccupation.required.$message }}</span>

                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">h.</span> Is this parent included in
                                    your application </label>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label for="cparent_yes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.motherInc" :value="true"
                                                name="cparentCheck" class="inputRadioCstm d-none" id="cparent_yes"
                                                :class="{ 'is-invalid': submitted && v$.familyInformation.motherInc.$error, }"><span
                                                class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                        </label>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="cparent_no" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.motherInc" :value="false"
                                                name="cparentCheck" class="inputRadioCstm d-none" id="cparent_no"
                                                :class="{ 'is-invalid': submitted && v$.familyInformation.motherInc.$error, }"><span
                                                class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                        </label>
                                    </div>
                                </div>
                                <span class="text-danger" v-if="submitted && v$.familyInformation.motherInc.$error">{{
                                    v$.familyInformation.motherInc.required.$message }}</span>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <label class="font-size-14"><span class="text-theme">C71. </span> Details of your
                                father-in-law</label>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">a.</span> Last name / Family
                                    name</label>
                                <input type="text" v-model="familyInformation.fInLawLawLastName" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">b.</span> First / Given name</label>
                                <input type="text" v-model="familyInformation.fInLawFirstName" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">c.</span> Date of Birth</label>
                                <input type="date" v-model="familyInformation.fInLawDateOfBirth" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">d.</span> Place of Birth</label>
                                <input type="text" v-model="familyInformation.fInLawBirthPlace" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">e.</span> Citizenship /
                                    Nationality</label>
                                <input type="text" v-model="familyInformation.fInLawNationality" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">f.</span> Residential Address</label>
                                <textarea type="textarea" v-model="familyInformation.fInLawResidentialAddress"
                                    class="form-control resize-none" rows="3"></textarea>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">g.</span> Occupation</label>
                                <input type="text" v-model="familyInformation.fInLawOccupation" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">h.</span> Is this parent included in
                                    your application </label>
                                <div class="row">
                                    <div class="col-auto">
                                        <label for="application_yes"
                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.fatherInLawInc" :value="true"
                                                name="applicationCheck" class="inputRadioCstm d-none"
                                                id="application_yes"><span class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <label for="application_no"
                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.fatherInLawInc" :value="false"
                                                name="applicationCheck" class="inputRadioCstm d-none"
                                                id="application_no"><span class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block mb-4  mt-6">
                                <label class="font-size-14"><span class="text-theme">C72.</span> Details of all brothers and
                                    sisters (including half, step and adopted siblings) </label>
                                <div class="d-block mb-4" v-for="(child, index) in familyInformation.siblingList"
                                    :key="index">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">a.</span> Last name /
                                            Family
                                            name</label>
                                        <input type="text" v-model="child.sibLastName" class="form-control" placeholder="">
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">b.</span> First / Given
                                            name</label>
                                        <input type="text" v-model="child.sibFirstName" class="form-control" placeholder="">
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">c.</span> Gender</label>
                                        <div class="row">
                                            <div class="col-auto">
                                                <label :for="'gender_m' + index"
                                                    class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                    <input type="radio" v-model="child.sibGender" value="M"
                                                        :name="'genderCheck' + index" class="inputRadioCstm d-none"
                                                        :id="'gender_m' + index"><span class="radioCircleIcon"><i
                                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">M</h4>
                                                </label>
                                            </div>
                                            <div class="col-auto">
                                                <label :for="'gender_f' + index"
                                                    class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                    <input type="radio" v-model="child.sibGender" value="F"
                                                        :name="'genderCheck' + index" class="inputRadioCstm d-none"
                                                        :id="'gender_f' + index"><span class="radioCircleIcon"><i
                                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">F</h4>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">d.</span> Date of
                                            Birth</label>
                                        <input type="date" v-model="child.sibDateOfBirth" class="form-control"
                                            placeholder="">
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">e.</span> Place of
                                            Birth</label>
                                        <input type="text" v-model="child.sibBirthPlace" class="form-control"
                                            placeholder="">
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">f.</span> Citizenship /
                                            Nationality</label>
                                        <input type="text" v-model="child.sibNationality" class="form-control"
                                            placeholder="">
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">g.</span> Residential
                                            Address</label>
                                        <textarea type="textarea" v-model="child.sibResidentialAddress"
                                            class="form-control resize-none" rows="3"></textarea>
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">h.</span>
                                            Occupation</label>
                                        <input type="text" v-model="child.sibOccupation" class="form-control"
                                            placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">

                            <label class="font-size-14"><span class="text-theme">C73. </span>Details of your mother-in-law
                            </label>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">a.</span> Last name / Family
                                    name</label>
                                <input type="text" v-model="familyInformation.mInLawLastName" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">b.</span> First / Given name</label>
                                <input type="text" v-model="familyInformation.mInLawFirstName" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">c.</span> Date of Birth</label>
                                <input type="date" v-model="familyInformation.mInLawDateOfBirth" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">d.</span> Place of Birth</label>
                                <input type="text" v-model="familyInformation.mInLawBirthPlace" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">e.</span> Citizenship /
                                    Nationality</label>
                                <input type="text" v-model="familyInformation.mInLawNationality" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">f.</span> Residential Address</label>
                                <textarea type="textarea" v-model="familyInformation.mInLawResidentialAddress"
                                    class="form-control resize-none" rows="3"></textarea>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">g.</span> Occupation</label>
                                <input type="text" v-model="familyInformation.mInLawOccupation" class="form-control"
                                    placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">h.</span> Is this parent included in
                                    your application</label>
                                <div class="row">
                                    <div class="col-auto">
                                        <label for="include_yes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.motherInLawInc" :value="true"
                                                name="includeCheck" class="inputRadioCstm d-none" id="include_yes"><span
                                                class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <label for="include_no" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="familyInformation.motherInLawInc" :value="false"
                                                name="includeCheck" class="inputRadioCstm d-none" id="include_no"><span
                                                class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block mb-4 pt-4 mt-5" v-for="(child, index) in familyInformation.siblingList2"
                                :key="index">
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">a.</span> Last name / Family
                                        name</label>
                                    <input type="text" v-model="child.sibLastName" class="form-control" placeholder="">
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">b.</span> First / Given
                                        name</label>
                                    <input type="text" v-model="child.sibFirstName" class="form-control" placeholder="">
                                </div>

                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">c.</span> Gender</label>
                                    <div class="row">
                                        <div class="col-auto">
                                            <label :for="'f2_gender_m' + index"
                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                <input type="radio" v-model="child.sibGender" value="M"
                                                    :name="'genderCheckf2' + index" class="inputRadioCstm d-none"
                                                    :id="'f2_gender_m' + index"><span class="radioCircleIcon"><i
                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">M</h4>
                                            </label>
                                        </div>
                                        <div class="col-auto">
                                            <label :for="'f2_gender_f' + index"
                                                class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                                <input type="radio" v-model="child.sibGender" value="F"
                                                    :name="'genderCheckf2' + index" class="inputRadioCstm d-none"
                                                    :id="'f2_gender_f' + index"><span class="radioCircleIcon"><i
                                                        class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                        class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">F</h4>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">d.</span> Date of Birth</label>
                                    <input type="date" v-model="child.sibDateOfBirth" class="form-control" placeholder="">
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">e.</span> Place of
                                        Birth</label>
                                    <input type="text" v-model="child.sibBirthPlace" class="form-control" placeholder="">
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">f.</span> Citizenship /
                                        Nationality</label>
                                    <input type="text" v-model="child.sibNationality" class="form-control" placeholder="">
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">g.</span> Residential
                                        Address</label>
                                    <textarea type="textarea" v-model="child.sibResidentialAddress"
                                        class="form-control resize-none" rows="3"></textarea>
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">h.</span> Occupation</label>
                                    <input type="text" v-model="child.sibOccupation" class="form-control" placeholder="">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="d-block mb-3 mt-5">
                <label class="font-size-14"><span class="text-theme">C74.</span> Details of children (biological, adopted
                    and step-children) </label>
                <div class="row">
                    <div class="col-lg-6 my-3" v-for="(child, index) in familyInformation.childList" :key="index">
                        <div class="d-block mb-4 mt-4">
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">a.</span> Last name / Family
                                    name</label>
                                <input type="text" v-model="child.childLastName" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">b.</span> First / Given name</label>
                                <input type="text" v-model="child.childFirstName" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">c.</span> Gender</label>
                                <div class="row">
                                    <div class="col-auto">
                                        <label :for="'biological_m' + index"
                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="child.childGender" value="M"
                                                :name="'biologicalCheck' + index" class="inputRadioCstm d-none"
                                                :id="'biological_m' + index"><span class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">M</h4>
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <label :for="'biological_f' + index"
                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="child.childGender" value="F"
                                                :name="'biologicalCheck' + index" class="inputRadioCstm d-none"
                                                :id="'biological_f' + index"><span class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">F</h4>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">d.</span> Date of Birth</label>
                                <input type="date" v-model="child.childDateOfBirth" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">e.</span> Place of Birth</label>
                                <input type="text" v-model="child.childBirthPlace" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">f.</span> Citizenship /
                                    Nationality</label>
                                <input type="text" v-model="child.childNationality" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">g.</span> Residential Address</label>
                                <textarea type="textarea" v-model="child.childResidentialAddress"
                                    class="form-control resize-none" rows="3"></textarea>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">h.</span> Occupation</label>
                                <input type="text" v-model="child.childOccupation" class="form-control" placeholder="">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">i.</span> Is this child included in
                                    your application?</label>
                                <div class="row">
                                    <div class="col-auto">
                                        <label :for="'child_yesf4' + index"
                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="child.childIn" :value="true"
                                                :name="'child_id4' + index" class="inputRadioCstm d-none"
                                                :id="'child_yesf4' + index"><span class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <label :for="'child_nof4' + index"
                                            class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                            <input type="radio" v-model="child.childIn" :value="false"
                                                :name="'child_id4' + index" class="inputRadioCstm d-none"
                                                :id="'child_nof4' + index"><span class="radioCircleIcon"><i
                                                    class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>