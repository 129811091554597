<script>
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import axios from 'axios';
import _ from 'lodash';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            // showRadio: false,
            app_URL: process.env.VUE_APP_URL,
            submitted: false,
            declarationForm: {
                offense: "",
                testified: "",
                accusationOfIllegal: "",
                detentionBeen: "",
                criminalOffence: "",
                sealedByCourt: "",
                everTestify: "",
                returnedComplaint: "",
                partyToLawsuit: "",
                criminalOrganisation: "",
                underInvestigation: "",
                bankruptcy: "",
                beenDeported: "",
                cancelledVisa: "",
                citizenshipNotGranted: "",
                suspending: "",
                govermentServent: "",
                anyQuestion1: "",
                anyQuestion2: "",
                anyQuestion3: "",
                anyQuestion4: "",
                anyQuestion5: "",
                refFullName1: "",
                refStreetAddress1: "",
                refCityState1: "",
                refCountryPstlCode1: "",
                refHomePhone1: "",
                refCellPhone1: "",
                refEmailAddress1: "",
                refKnownYears1: "",
                refOccupation1: "",
                refEmployer1: "",
                refWorkPhone1: "",
                refFullName2: "",
                refStreetAddress2: "",
                refCityState2: "",
                refCountryPstlCode2: "",
                refHomePhone2: "",
                refCellPhone2: "",
                refEmailAddress2: "",
                refKnownYears2: "",
                refOccupation2: "",
                refEmployer2: "",
                refWorkPhone2: "",
            },

        }
    },
    validations: {
        declarationForm: {
            offense: {
                required: helpers.withMessage("This Field is required", required),
            },
            testified: {
                required: helpers.withMessage("This Field is required", required),
            },
            accusationOfIllegal: {
                required: helpers.withMessage("This Field is required", required),
            },
            criminalOffence: {
                required: helpers.withMessage("This Field is required", required),
            },
            detentionBeen: {
                required: helpers.withMessage("This Field is required", required),
            },
            sealedByCourt: {
                required: helpers.withMessage("This Field is required", required),
            },
            everTestify: {
                required: helpers.withMessage("This Field is required", required),
            },
            returnedComplaint: {
                required: helpers.withMessage("This Field is required", required),
            },
            partyToLawsuit: {
                required: helpers.withMessage("This Field is required", required),
            },
            criminalOrganisation: {
                required: helpers.withMessage("This Field is required", required),
            },
            underInvestigation: {
                required: helpers.withMessage("This Field is required", required),
            },
            bankruptcy: {
                required: helpers.withMessage("This Field is required", required),
            },
            beenDeported: {
                required: helpers.withMessage("This Field is required", required),
            },
            cancelledVisa: {
                required: helpers.withMessage("This Field is required", required),
            },
            citizenshipNotGranted: {
                required: helpers.withMessage("This Field is required", required),
            },
            suspending: {
                required: helpers.withMessage("This Field is required", required),
            },
            govermentServent: {
                required: helpers.withMessage("This Field is required", required),
            },

        }
    },
    activated() {
        if (this.$route.params.id) {
            this.editDeclaration();
        } else {
            this.getDeclarationForm();
        }

    },
    beforeMount() {
        if (this.$route.params.id) {
            this.editDeclaration();
        } else {
            this.getDeclarationForm();
        }
    },
    methods: {
        validateDeclarationForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                let data = new FormData();
                data.append('user_id', this.$store.state.authenticatedUserId);
                data.append('disclosure_id', this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id);
                _.each(this.declarationForm, (value, key) => {
                    data.append(key, value);
                });
                return axios.post(this.app_URL + "api/addDeclaration", data).then(() => {
                    localStorage.setItem('currentStep', 5);
                    this.getDeclarationForm();
                    return true;
                })
            }
        },
        getDeclarationForm() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/getDeclaration", { user_id: this.$store.state.authenticatedUserId, disclosure_id: this.$store.state.disclosure_id }).then(res => {
                    if (res.data) {
                        this.declarationForm = res.data;
                    }

                })
            }, 2000)
        },
        editDeclaration() {
            axios.post(this.app_URL + "api/editDeclaration", { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                if (res.data) {
                    this.declarationForm = res.data;
                }
            })
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }

    }
}
</script>

<template>
    <div>

        <div class="text-main-title">
            <h2 class="titleStepForm">PART D: Declarations</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-lg-6 ">
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D75.</span> Have you ever been arrested,
                            detained, charged, indicted, convicted, found guilty or been expunged of any offence(s) against
                            the law in any country (except minor traffic infrigements)?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="arrestedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.offense" :value="true" name="arrested"
                                        class="inputRadioCstm d-none" id="arrestedYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.offense.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="arrestedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.offense" :value="false" name="arrested"
                                        class="inputRadioCstm d-none" id="arrestedNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.offense.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.offense.$error">{{
                            v$.declarationForm.offense.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D76.</span> Have you ever testified before a
                            grand jury or investigative hearing or probe?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="testifiedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.testified" :value="true" name="testified"
                                        class="inputRadioCstm d-none" id="testifiedYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.testified.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="testifiedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.testified" :value="false" name="testified"
                                        class="inputRadioCstm d-none" id="testifiedNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.testified.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.testified.$error">{{
                            v$.declarationForm.testified.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D77.</span> Have any charges, or accusations of
                            illegal activity of any nature been made against you in any country?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="chargesYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.accusationOfIllegal" :value="true"
                                        name="charges" class="inputRadioCstm d-none" id="chargesYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.accusationOfIllegal.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="chargesNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.accusationOfIllegal" :value="false"
                                        name="charges" class="inputRadioCstm d-none" id="chargesNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.accusationOfIllegal.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.accusationOfIllegal.$error">{{
                            v$.declarationForm.accusationOfIllegal.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D78.</span> Have you ever been sentenced to
                            serve a period of time in detention or been in probation?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="everYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.detentionBeen" :value="true" name="ever"
                                        class="inputRadioCstm d-none" id="everYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.detentionBeen.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="everNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.detentionBeen" :value="false" name="ever"
                                        class="inputRadioCstm d-none" id="everNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.detentionBeen.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.detentionBeen.$error">{{
                            v$.declarationForm.detentionBeen.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D79.</span> Have you ever received a pardon for
                            any criminal offence? (If yes, note Date, City, County, State and Country.)</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="receivedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.criminalOffence" :value="true"
                                        name="received" class="inputRadioCstm d-none" id="receivedYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.criminalOffence.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="receivedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.criminalOffence" :value="false"
                                        name="received" class="inputRadioCstm d-none" id="receivedNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.criminalOffence.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.criminalOffence.$error">{{
                            v$.declarationForm.criminalOffence.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <h4 class="font-size-14"><span class="text-theme">D80.</span> Have you ever had a civil or criminal
                            record expunged or sealed by a court order? Yes /No If yes, give details</h4>
                        <div class="row">
                            <div class="col-auto">
                                <label for="civilYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.sealedByCourt" :value="true" name="civil"
                                        class="inputRadioCstm d-none" id="civilYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.sealedByCourt.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="civilNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.sealedByCourt" :value="false" name="civil"
                                        class="inputRadioCstm d-none" id="civilNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.sealedByCourt.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.sealedByCourt.$error">{{
                            v$.declarationForm.sealedByCourt.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D81.</span> Have you ever been subpoenaed to
                            appear to testify before a federal, state, or county grand jury, board or commission?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="subpoenaedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.everTestify" :value="true"
                                        name="subpoenaed" class="inputRadioCstm d-none" id="subpoenaedYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.everTestify.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="subpoenaedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.everTestify" :value="false"
                                        name="subpoenaed" class="inputRadioCstm d-none" id="subpoenaedNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.everTestify.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.everTestify.$error">{{
                            v$.declarationForm.everTestify.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D82.</span> Has a criminal indictment,
                            information, or complaint ever been returned against you, but for which you were not arrested or
                            in which you were named as an un-indicted co-party?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="criminalYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.returnedComplaint" :value="true"
                                        name="criminal" class="inputRadioCstm d-none" id="criminalYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.returnedComplaint.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="criminalNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.returnedComplaint" :value="false"
                                        name="criminal" class="inputRadioCstm d-none" id="criminalNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.returnedComplaint.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.returnedComplaint.$error">{{
                            v$.declarationForm.returnedComplaint.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D83.</span> Have you, as an individual, or as
                            an owner, partner, director or officer of any partnership, corporation or other entity, ever
                            been a party to a lawsuit as either a plaintiff or defendant? (Other than divorces).</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="individualYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.partyToLawsuit" :value="true"
                                        name="individual" class="inputRadioCstm d-none" id="individualYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.partyToLawsuit.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="individualNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.partyToLawsuit" :value="false"
                                        name="individual" class="inputRadioCstm d-none" id="individualNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.partyToLawsuit.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.partyToLawsuit.$error">{{
                            v$.declarationForm.partyToLawsuit.required.$message }}</span>
                    </div>
                </div>
                <div class="col-lg-6 ">
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D84.</span> Have you ever been involved,
                            directly or indirectly, in the financing of terrorism or in any terrorist or criminal
                            organisation? </label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="beenYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.criminalOrganisation" :value="true"
                                        name="been" class="inputRadioCstm d-none" id="beenYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.criminalOrganisation.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="beenNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.criminalOrganisation" :value="false"
                                        name="been" class="inputRadioCstm d-none" id="beenNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.criminalOrganisation.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.criminalOrganisation.$error">{{
                            v$.declarationForm.criminalOrganisation.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D85.</span> Have you ever been under
                            investigation by any law enforcement agency or tax authority in any country?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="underYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.underInvestigation" :value="true"
                                        name="under" class="inputRadioCstm d-none" id="underYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.underInvestigation.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="underNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.underInvestigation" :value="false"
                                        name="under" class="inputRadioCstm d-none" id="underNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.underInvestigation.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.underInvestigation.$error">{{
                            v$.declarationForm.underInvestigation.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D86.</span> Have you ever been involved
                            personally, or as a director in any bankruptcy, insolvency or liquidation?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="involvedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.bankruptcy" :value="true" name="involved"
                                        class="inputRadioCstm d-none" id="involvedYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.bankruptcy.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="involvedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.bankruptcy" :value="false" name="involved"
                                        class="inputRadioCstm d-none" id="involvedNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.bankruptcy.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.bankruptcy.$error">{{
                            v$.declarationForm.bankruptcy.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">D87.</span> Have you ever been refused an entry
                            visa to, or residency permit in any country, been unlawfully present in, been deported from any
                            country, or sought to assist others to do the same? </label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="refusedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.beenDeported" :value="true" name="refused"
                                        class="inputRadioCstm d-none" id="refusedYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.beenDeported.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="refusedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.beenDeported" :value="false" name="refused"
                                        class="inputRadioCstm d-none" id="refusedNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.beenDeported.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.declarationForm.beenDeported.$error">{{
                            v$.declarationForm.beenDeported.required.$message }}</span>
                    </div>
                    <div class="d-block">
                        <label class="font-size-14"><span class="text-theme">D88.</span> Have you ever had a visa
                            cancelled?</label>
                        <div class="row">
                            <div class="col-auto">
                                <label for="cancelledYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.cancelledVisa" :value="true"
                                        name="applicationcancelled" class="inputRadioCstm d-none" id="cancelledYes"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.cancelledVisa.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="cancelledNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" v-model="declarationForm.cancelledVisa" :value="false"
                                        name="applicationcancelled" class="inputRadioCstm d-none" id="cancelledNo"
                                        :class="{ 'is-invalid': submitted && v$.declarationForm.cancelledVisa.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                            <span class="text-danger" v-if="submitted && v$.declarationForm.cancelledVisa.$error">{{
                                v$.declarationForm.cancelledVisa.required.$message }}</span>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"><span class="text-theme">D89.</span> Have you ever applied for
                                citizenship in any country and citizenship has not been granted?</label>
                            <div class="row">
                                <div class="col-auto">
                                    <label for="appliedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                        <input type="radio" v-model="declarationForm.citizenshipNotGranted" :value="true"
                                            name="applied" class="inputRadioCstm d-none" id="appliedYes"
                                            :class="{ 'is-invalid': submitted && v$.declarationForm.citizenshipNotGranted.$error, }"><span
                                            class="radioCircleIcon"><i
                                                class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                        <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                    </label>
                                </div>
                                <div class="col-auto">
                                    <label for="appliedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                        <input type="radio" v-model="declarationForm.citizenshipNotGranted" :value="false"
                                            name="applied" class="inputRadioCstm d-none" id="appliedNo"
                                            :class="{ 'is-invalid': submitted && v$.declarationForm.citizenshipNotGranted.$error, }"><span
                                            class="radioCircleIcon"><i
                                                class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                        <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                    </label>
                                </div>
                            </div>
                            <span class="text-danger" v-if="submitted && v$.declarationForm.citizenshipNotGranted.$error">{{
                                v$.declarationForm.citizenshipNotGranted.required.$message }}</span>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"><span class="text-theme">D90.</span> Have you ever been the subject
                                of any order, judgment or decree of any federal or state authority barring, suspending, or
                                otherwise limiting your right to engage in any professional or business practice or
                                activity? </label>
                            <div class="row">
                                <div class="col-auto">
                                    <label for="subjectYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                        <input type="radio" v-model="declarationForm.suspending" :value="true"
                                            name="subject" class="inputRadioCstm d-none" id="subjectYes"
                                            :class="{ 'is-invalid': submitted && v$.declarationForm.suspending.$error, }"><span
                                            class="radioCircleIcon"><i
                                                class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                        <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                    </label>
                                </div>
                                <div class="col-auto">
                                    <label for="subjectNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                        <input type="radio" v-model="declarationForm.suspending" :value="false"
                                            name="subject" class="inputRadioCstm d-none" id="subjectNo"
                                            :class="{ 'is-invalid': submitted && v$.declarationForm.suspending.$error, }"><span
                                            class="radioCircleIcon"><i
                                                class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                        <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                    </label>
                                </div>
                            </div>
                            <span class="text-danger" v-if="submitted && v$.declarationForm.suspending.$error">{{
                                v$.declarationForm.suspending.required.$message }}</span>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14"><span class="text-theme">D91.</span> Have you ever been a senior
                                politician, head of state or government, official of a political party, senior judicial or
                                ilitary official, and/or senior executive of stateowned enterprise?</label>
                            <div class="row">
                                <div class="col-auto">
                                    <label for="judicialYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                        <input type="radio" v-model="declarationForm.govermentServent" :value="true"
                                            name="judicial" class="inputRadioCstm d-none" id="judicialYes"
                                            :class="{ 'is-invalid': submitted && v$.declarationForm.govermentServent.$error, }"><span
                                            class="radioCircleIcon"><i
                                                class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                        <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                    </label>
                                </div>
                                <div class="col-auto">
                                    <label for="judicialNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                        <input type="radio" v-model="declarationForm.govermentServent" :value="false"
                                            name="judicial" class="inputRadioCstm d-none" id="judicialNo"
                                            :class="{ 'is-invalid': submitted && v$.declarationForm.govermentServent.$error, }"><span
                                            class="radioCircleIcon"><i
                                                class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                                class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                        <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                    </label>
                                </div>
                            </div>
                            <span class="text-danger" v-if="submitted && v$.declarationForm.govermentServent.$error">{{
                                v$.declarationForm.govermentServent.required.$message }}</span>
                        </div>
                        <div class="d-block mb-3">
                            <label class="font-size-14">If you have answered yes to any questions from D75-D91 please
                                provide us with further details</label>
                            <input type="text" v-model="declarationForm.anyQuestion1" class="form-control" placeholder="">
                            <input type="text" v-model="declarationForm.anyQuestion2" class="form-control" placeholder="">
                            <input type="text" v-model="declarationForm.anyQuestion3" class="form-control" placeholder="">
                            <input type="text" v-model="declarationForm.anyQuestion4" class="form-control" placeholder="">
                            <input type="text" v-model="declarationForm.anyQuestion5" class="form-control" placeholder="">
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12">
            <label class="font-size-14"><span class="text-theme">D92.</span> Please provide details of two character
                references who have known you for five (5) years or more. DO NOT include relatives, present employer, or
                employees:</label>

            <div class="mb-4 pb-2">
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14 pt-lg-3 mb-0">Full Name</label>
                    </div>
                    <div class="col-lg mb-2 text-lg-center">
                        <label class="font-size-12">Reference 1</label>
                        <input type="text" v-model="declarationForm.refFullName1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2 text-lg-center">
                        <label class="font-size-12">Reference 2</label>
                        <input type="text" v-model="declarationForm.refFullName2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Street Address</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="text" v-model="declarationForm.refStreetAddress1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="text" v-model="declarationForm.refStreetAddress2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">City / State</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="text" v-model="declarationForm.refCityState1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="text" v-model="declarationForm.refCityState2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Country and postal code</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="text" v-model="declarationForm.refCountryPstlCode1"
                            class="form-control form-control-sm" placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="text" v-model="declarationForm.refCountryPstlCode2"
                            class="form-control form-control-sm" placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Home Phone</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="number" v-model="declarationForm.refHomePhone1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="number" v-model="declarationForm.refHomePhone2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Cell Phone / Mobile Number</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="number" v-model="declarationForm.refCellPhone1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="number" v-model="declarationForm.refCellPhone2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Email Address</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="email" v-model="declarationForm.refEmailAddress1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="email" v-model="declarationForm.refEmailAddress2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Years Known</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="text" v-model="declarationForm.refKnownYears1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="text" v-model="declarationForm.refKnownYears2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Occupation</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="text" v-model="declarationForm.refOccupation1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="text" v-model="declarationForm.refOccupation2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Employer</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="text" v-model="declarationForm.refEmployer1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="text" v-model="declarationForm.refEmployer2" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-3 mnWdthCol mb-1">
                        <label class="font-size-14  mb-0">Work Phone</label>
                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 1</label>
                        <input type="number" v-model="declarationForm.refWorkPhone1" class="form-control form-control-sm"
                            placeholder="">

                    </div>
                    <div class="col-lg mb-2">
                        <label class="font-size-12 d-block d-lg-none">Reference 2</label>
                        <input type="number" v-model="declarationForm.refWorkPhone2" class="form-control form-control-sm">

                    </div>
                </div>
                <div class="col-lg-6 order-lg-2">

                </div>

            </div>
        </div>
        <!-- </div> -->
    </div>
</template>