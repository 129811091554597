<script>
import Select2 from 'vue3-select2-component';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, email, helpers } from '@vuelidate/validators';
import axios from 'axios';
import { countries } from '../applicant/country.js';
import { states } from '../applicant/state.js';
import { cities } from '../applicant/city.js';
import { india } from '../applicant/indiaCities.js';
import { usa } from '../applicant/usaCities.js';
import { turkey } from '../applicant/turkeyCities.js';
import { russia } from '../applicant/russiaCities.js';
import { thailand } from '../applicant/thailandCities.js';
// import _ from 'lodash';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            submitted: false,
            app_URL: process.env.VUE_APP_URL,
            personalForm: {
                surName: "",
                firstName: "",
                middleName: "",
                textAreaFld: "",
                dateOfBirth: "",
                gender: null,
                countryBirth: "",
                countryCitizen: "",
                sponsoredApp: null, localCharacter: "", motherName: "",
                citizenshipAppHold: "",
                passportCountryName1: "",
                passportCountryName2: "",
                speakFluent: "",
                currentCity: "",
                currentState: "",
                currentCountry: "",
                zipCodePstl: "",
                monthDate: null,
                permanentFullAddress: "",
                permanentCity: "",
                permanentState: "",
                permanentCountry: "",
                permanentZipCodePstl: "",
                permanentMonthDate: "",
                telephoneHome: "",
                cellPhoneNum: "",
                emailAddress: "",
                colorEye: "",
                colorHair: "",
                weightKg: "",
                heightCm: "",
                distinguishingMarks: "",
                armedForce: null,
                branchMdl: "",
                serviceDate: null,
                separationDate: "",
                dischargeMdl: "",
                rankSeparate: "",
                serialNumberMdl: "",
                militaryMdl: null,
                provideDetailList: Array(4).fill().map(() => ({ provideDetails: '' })),
                allAddress: Array(7).fill().map(() => ({ monthFromMdl: '', monthToMdl: '', fullAddressMdl: '' })),
                passportInfo: Array(2).fill().map(() => ({ passport: '', issueCountry: '', dateIssue: '', dateExpiry: '' })),
                identificationId: Array(2).fill().map(() => ({ nationalId: '', nationalCountryId: '' })),
                driverLicenceNo: Array(2).fill().map(() => ({ countryId: '', licenceNum: '' })),
                applicationDependent: Array(7).fill().map(() => ({ fullName: '', dateBirth: '', citizenshipNat: '', passportMultiple: '', relationshipDepend: '' })),

            },
            allStates: states,
            allCities: [...cities, ...india, ...usa, ...turkey, ...russia, ...thailand],
            cityOption: [],
            stateOption: [],
            countryOption: countries,
            preCityOption: [],
            preStateOption: [],
            preCountryOption: countries,
        }
    },
    components: {
        Select2
    },
    validations: {
        personalForm: {
            surName: {
                required: helpers.withMessage("Sur Name is required", required),
            },
            firstName: {
                required: helpers.withMessage("First Name is required", required),
            },
            dateOfBirth: {
                required: helpers.withMessage("Date of Birth is required", required),
            },
            // Checkbox 
            gender: {
                required: helpers.withMessage("Gender is required", required),
            },
            countryBirth: {
                required: helpers.withMessage("Place is required", required),
            },
            countryCitizen: {
                required: helpers.withMessage("Country Citizenship is required", required),
            },


            // Sponsored 
            sponsoredApp: {
                required: helpers.withMessage("Sponsored is required", required),
            },
            localCharacter: {
                required: helpers.withMessage("Local Langauage Name is required", required),
            },
            motherName: {
                required: helpers.withMessage("Mother Name is required", required),
            },
            citizenshipAppHold: {
                required: helpers.withMessage("Citizenship is required", required),
            },
            passportCountryName1: {
                required: helpers.withMessage("Passport Country Name is required", requiredIf(function () {
                    return this.personalForm.citizenshipAppHold == true;
                }),),

            },
            speakFluent: {
                required: helpers.withMessage("Language is required", required),
            },

            currentFullAddress: {
                required: helpers.withMessage("Full address is required", required),
            },
            currentCity: {
                required: helpers.withMessage("City is required", required),
            },
            currentState: {
                required: helpers.withMessage("State is required", required),
            },
            currentCountry: {
                required: helpers.withMessage("Country is required", required),
            },
            zipCodePstl: {
                required: helpers.withMessage("Zip Code is required", required),
            },
            monthDate: {
                required: helpers.withMessage("Month Date Name is required", required),
            },
            permanentFullAddress: {
                required: helpers.withMessage("Full address is required", required),
            },
            permanentCity: {
                required: helpers.withMessage("City is required", required),
            },
            permanentState: {
                required: helpers.withMessage("State is required", required),
            },
            permanentCountry: {
                required: helpers.withMessage("Country is required", required),
            },
            permanentZipCodePstl: {
                required: helpers.withMessage("Zip Code is required", required),
            },
            permanentMonthDate: {
                required: helpers.withMessage("Month Date is required", required),
            },
            telephoneHome: {
                required: helpers.withMessage("Telephone Number is required", required),
            },
            cellPhoneNum: {
                required: helpers.withMessage("Cell Phone Number is required", required),
            },
            emailAddress: {
                required: helpers.withMessage("Email Address is required", required),
                email: helpers.withMessage("Enter a valid Email address", email),
            },
            colorEye: {
                required: helpers.withMessage("Eye color is required", required),
            },
            colorHair: {
                required: helpers.withMessage("Hair color is required", required),
            },
            weightKg: {
                required: helpers.withMessage("Weight is required", required),
            },
            heightCm: {
                required: helpers.withMessage("Height is required", required),
            },
            distinguishingMarks: {
                required: helpers.withMessage("Distinguishing Marks is required", required),
            },
            armedForce: {
                required: helpers.withMessage("Armed Force Field is required", required),
            },
            branchMdl: {
                required: helpers.withMessage("Branch is required", requiredIf(function () {
                    return this.personalForm.armedForce == true;
                }),
                ),
            },
            serviceDate: {
                required: helpers.withMessage("Service Date is required", requiredIf(function () {
                    return this.personalForm.armedForce == true;
                }),
                ),
            },
            separationDate: {
                required: helpers.withMessage("Separation Date is required", requiredIf(function () {
                    return this.personalForm.armedForce == true;
                }),
                ),
            },
            dischargeMdl: {
                required: helpers.withMessage("Type of Discharge is required", requiredIf(function () {
                    return this.personalForm.armedForce == true;
                }),
                ),
            },
            rankSeparate: {
                required: helpers.withMessage("Rank Seperation is required", requiredIf(function () {
                    return this.personalForm.armedForce == true;
                }),),
            },
            serialNumberMdl: {
                required: helpers.withMessage("Serial Number is required", requiredIf(function () {
                    return this.personalForm.armedForce == true;
                }),),
            },
            // true and false
            militaryMdl: {
                required: helpers.withMessage("This Field is required", required),
            },

        }
    },
    created() {
        if (this.$route.params.id) {
            this.editPersonalForm();
            this.editDisclosureForm();
        } else {
            this.getPersonalForm();
            this.getDisclosureForm();
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.editPersonalForm();
            this.editDisclosureForm();
        } else {
            this.getPersonalForm();
            this.getDisclosureForm();
        }
    },
    methods: {
        validatePersonalForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                // let data = new FormData();
                // data.append('user_id', this.$store.state.authenticatedUserId);
                // data.append('disclosure_id', this.$store.state.disclosure_id);
                // _.each(this.personalForm, (value, key) => {
                //     data.append(key, value);
                // });
                return axios.post(this.app_URL + 'api/addPersonalForm', {
                    user_id: this.$store.state.authenticatedUserId,
                    disclosure_id: this.$route.params.id ? this.decode(this.$route.params.id) : this.$store.state.disclosure_id,
                    personal: this.personalForm,
                }).then(() => {
                    localStorage.setItem('currentStep', 2);
                    this.getPersonalForm();
                    return true;
                });
            }
        },

        getPersonalForm() {
            setTimeout(() => {
                axios.post(this.app_URL + "api/getPersonalForm", { user_id: this.$store.state.authenticatedUserId, disclosure_id: this.$store.state.disclosure_id }).then((res) => {

                    if (res.data) {
                        this.personalForm = res.data;
                        // 1 state  and cities
                        let preStateArray = this.allStates.filter((item) => item.country_name == res.data.permanentCountry);
                        this.preStateOption = preStateArray.map((jsonObject) => jsonObject["name"]);
                        let preCityArray = this.allCities.filter((item) => item.state_name == res.data.permanentState);
                        this.preCityOption = preCityArray.map((jsonObject) => jsonObject["name"]);
                        // end
                        //2 state  and cities
                        let stateArray = this.allStates.filter((item) => item.country_name == res.data.currentCountry);
                        this.stateOption = stateArray.map((jsonObject) => jsonObject["name"]);
                        let cityArray = this.allCities.filter((item) => item.state_name == res.data.currentState);
                        this.cityOption = cityArray.map((jsonObject) => jsonObject["name"]);
                        // end
                        this.personalForm.sponsoredApp = res.data.sponsoredApp == '0' ? false : res.data.sponsoredApp == '1' ? true : null;
                        this.personalForm.citizenshipAppHold = res.data.citizenshipAppHold == '0' ? false : res.data.citizenshipAppHold == '1' ? true : null;
                        this.personalForm.armedForce = res.data.armedForce == '0' ? false : res.data.armedForce == '1' ? true : null;
                        this.personalForm.militaryMdl = res.data.militaryMdl == '0' ? false : res.data.militaryMdl == '1' ? true : null;
                        this.personalForm.allAddress = res.data.allAddress ? JSON.parse(res.data.allAddress) : Array(7).fill().map(() => ({ monthFromMdl: '', monthToMdl: '', fullAddressMdl: '' }));
                        this.personalForm.provideDetailList = res.data.provideDetailList ? JSON.parse(res.data.provideDetailList) : Array(4).fill().map(() => ({ provideDetails: '' }));
                        this.personalForm.applicationDependent = res.data.applicationDependent ? JSON.parse(res.data.applicationDependent) : Array(7).fill().map(() => ({ fullName: '', dateBirth: '', citizenshipNat: '', passportMultiple: '', relationshipDepend: '' }));
                        this.personalForm.driverLicenceNo = res.data.driverLicenceNo ? JSON.parse(res.data.driverLicenceNo) : Array(2).fill().map(() => ({ countryId: '', licenceNum: '' }));
                        this.personalForm.identificationId = res.data.identificationId ? JSON.parse(res.data.identificationId) : Array(2).fill().map(() => ({ nationalId: '', nationalCountryId: '' }));
                        this.personalForm.passportInfo = res.data.passportInfo ? JSON.parse(res.data.passportInfo) : Array(2).fill().map(() => ({ passport: '', issueCountry: '', dateIssue: '', dateExpiry: '' }));

                    }
                })
            }, 2000)
        },
        editPersonalForm() {
            axios.post(this.app_URL + "api/editPersonalForm", { disclosure_id: this.decode(this.$route.params.id) }).then((res) => {
                if (res.data) {
                    this.personalForm = res.data;
                    // state  and cities
                    // 1 state  and cities
                    let preStateArray = this.allStates.filter((item) => item.country_name == res.data.permanentCountry);
                    this.preStateOption = preStateArray.map((jsonObject) => jsonObject["name"]);
                    let preCityArray = this.allCities.filter((item) => item.state_name == res.data.permanentState);
                    this.preCityOption = preCityArray.map((jsonObject) => jsonObject["name"]);
                    // end
                    //2 state  and cities
                    let stateArray = this.allStates.filter((item) => item.country_name == res.data.currentCountry);
                    this.stateOption = stateArray.map((jsonObject) => jsonObject["name"]);
                    let cityArray = this.allCities.filter((item) => item.state_name == res.data.currentState);
                    this.cityOption = cityArray.map((jsonObject) => jsonObject["name"]);
                    // end
                    this.personalForm.sponsoredApp = res.data.sponsoredApp == '0' ? false : res.data.sponsoredApp == '1' ? true : null;
                    this.personalForm.citizenshipAppHold = res.data.citizenshipAppHold == '0' ? false : res.data.citizenshipAppHold == '1' ? true : null;
                    this.personalForm.armedForce = res.data.armedForce == '0' ? false : res.data.armedForce == '1' ? true : null;
                    this.personalForm.militaryMdl = res.data.militaryMdl == '0' ? false : res.data.militaryMdl == '1' ? true : null;
                    this.personalForm.allAddress = res.data.allAddress ? JSON.parse(res.data.allAddress) : Array(7).fill().map(() => ({ monthFromMdl: '', monthToMdl: '', fullAddressMdl: '' }));
                    this.personalForm.provideDetailList = res.data.provideDetailList ? JSON.parse(res.data.provideDetailList) : Array(4).fill().map(() => ({ provideDetails: '' }));
                    this.personalForm.applicationDependent = res.data.applicationDependent ? JSON.parse(res.data.applicationDependent) : Array(7).fill().map(() => ({ fullName: '', dateBirth: '', citizenshipNat: '', passportMultiple: '', relationshipDepend: '' }));
                    this.personalForm.driverLicenceNo = res.data.driverLicenceNo ? JSON.parse(res.data.driverLicenceNo) : Array(2).fill().map(() => ({ countryId: '', licenceNum: '' }));
                    this.personalForm.identificationId = res.data.identificationId ? JSON.parse(res.data.identificationId) : Array(2).fill().map(() => ({ nationalId: '', nationalCountryId: '' }));
                    this.personalForm.passportInfo = res.data.passportInfo ? JSON.parse(res.data.passportInfo) : Array(2).fill().map(() => ({ passport: '', issueCountry: '', dateIssue: '', dateExpiry: '' }));
                }

            })
        },
        getDisclosureForm() {
            setTimeout(() => {
                axios.post(this.app_URL + 'api/getDisclosureForm', { user_id: this.$store.state.authenticatedUserId, }).then((res) => {
                    if (res.data.closureForm) {
                        this.personalForm.surName = res.data.closureForm.surName;
                        this.personalForm.firstName = res.data.closureForm.firstName;
                        this.personalForm.dateOfBirth = res.data.closureForm.dateOfBirth;
                    }
                })
            }, 2000)
        },
        editDisclosureForm() {
            axios.post(this.app_URL + 'api/editDisclosureForm', { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
                if (res.data.closureForm) {
                    this.personalForm.surName = res.data.closureForm.surName;
                    this.personalForm.firstName = res.data.closureForm.firstName;
                    this.personalForm.dateOfBirth = res.data.closureForm.dateOfBirth;
                }
            })
        },
        countrySelect(event, id) {
            if (id == 1) {
                let stateArray = this.allStates.filter((item) => item.country_name == event.id)
                this.stateOption = stateArray.map((jsonObject) => jsonObject["name"])
            } else {
                let stateArray = this.allStates.filter((item) => item.country_name == event.id)
                this.preStateOption = stateArray.map((jsonObject) => jsonObject["name"])

            }
        },
        stateSelect(event, id) {
            if (id == 1) {
                let cityArray = this.allCities.filter((item) => item.state_name == event.id)
                this.cityOption = cityArray.map((jsonObject) => jsonObject["name"])
            } else {
                let cityArray = this.allCities.filter((item) => item.state_name == event.id)
                this.preCityOption = cityArray.map((jsonObject) => jsonObject["name"])
            }
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }

    }
}
</script>

<template>
    <div>
        <div class="text-main-title">
            <h2 class="titleStepForm">PART A: Personal Information</h2>
        </div>
        <div class="main-from-group my-4">
            <div class="row">
                <div class="col-lg-6 ">
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A1.</span> Last / Family Name</label>
                        <input type="text" disabled="true" v-model="personalForm.surName" class="form-control"
                            placeholder="" :class="{ 'is-invalid': submitted && v$.personalForm.surName.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.personalForm.surName.$error">{{
                            v$.personalForm.surName.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A2.</span> First / Given Name</label>
                        <input type="text" disabled="true" v-model="personalForm.firstName" class="form-control"
                            placeholder="" :class="{ 'is-invalid': submitted && v$.personalForm.firstName.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.personalForm.firstName.$error">{{
                            v$.personalForm.firstName.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A3.</span> Middle Name(s)</label>
                        <input type="text" v-model="personalForm.middleName" class="form-control" placeholder="">
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A4.</span> Other names you are, or have, been
                            known by (name at birth, previous married name or aliases) Include date of change and reason for
                            change</label>
                        <textarea type="textarea" v-model="personalForm.textAreaFld" class="form-control resize-none"
                            rows="5" placeholder=""></textarea>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A5.</span> Date Of Birth</label>
                        <input type="date" disabled="true" class="form-control" v-model="personalForm.dateOfBirth"
                            placeholder="Date" :class="{ 'is-invalid': submitted && v$.personalForm.dateOfBirth.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.personalForm.dateOfBirth.$error">{{
                            v$.personalForm.dateOfBirth.required.$message }}</span>

                    </div>
                    <div class="d-block mb-3">
                        <h4 class="font-size-14"><span class="text-theme">A6.</span> Gender </h4>
                        <div class="row">
                            <div class="col-auto">
                                <label for="maleRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" name="genderStatus" v-model="personalForm.gender"
                                        class="inputRadioCstm d-none" value="M" id="maleRd"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.gender.$error, }">
                                    <span class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Male</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="femaleRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" name="genderStatus" v-model="personalForm.gender"
                                        class="inputRadioCstm d-none" value="F" id="femaleRd"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.gender.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Female</h4>
                                </label>
                            </div>
                        </div>
                        <span class="text-danger" v-if="submitted && v$.personalForm.gender.$error">{{
                            v$.personalForm.gender.required.$message }}</span>

                    </div>

                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A7.</span> Place and Country of Birth</label>
                        <input type="text" v-model="personalForm.countryBirth" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.personalForm.countryBirth.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.personalForm.countryBirth.$error">{{
                            v$.personalForm.countryBirth.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A8.</span> Country of Citizenship</label>
                        <input type="text" v-model="personalForm.countryCitizen" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.personalForm.countryCitizen.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.personalForm.countryCitizen.$error">{{
                            v$.personalForm.countryCitizen.required.$message }}</span>
                    </div>
                </div>
                <div class="col-12 order-lg-3">
                    <label class="font-size-14"><span class="text-theme">A9.</span> Passport Information</label>
                    <div class="mb-4 pb-2" style="max-width:900px;">
                        <div class="row align-items-center">
                            <div class="col-lg-auto mnWdthCol mb-1">
                                <label class="font-size-14 pt-lg-3 mb-0">Passport Number</label>
                            </div>

                            <div class="col-lg mb-2 text-lg-center" v-for="(child, index) in personalForm.passportInfo"
                                :key="index">
                                <label class="font-size-12">Passport {{ index + 1 }}</label>
                                <input type="number" v-model="child.passport" class="form-control form-control-sm"
                                    placeholder="">
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-auto mnWdthCol mb-1">
                                <label class="font-size-14  mb-0">Issuing Country</label>
                            </div>

                            <div class="col-lg mb-2" v-for="(child, index) in personalForm.passportInfo" :key="index">
                                <label class="font-size-12 d-block d-lg-none">Passport {{ index + 1 }}</label>
                                <!-- <input type="text" v-model="child.issueCountry" class="form-control form-control-sm"
                                    placeholder=""> -->
                                <div class="single-select2-cstm">
                                    <!-- <Select2 v-model="child.nationalCountryId" placeholder="Select"
                                        class=" mb-1 mb-lg-3" :options="countryOption"   v-for="(child, index) in personalForm.identificationId" :key="index"/> -->
                                    <Select2 v-model="child.issueCountry" placeholder="Select" class=" mb-1 mb-lg-3"
                                        :options="countryOption" />
                                </div>

                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-auto mnWdthCol mb-1">
                                <label class="font-size-14  mb-0">Date of Issue</label>
                            </div>

                            <div class="col-lg mb-2" v-for="(child, index) in personalForm.passportInfo" :key="index">
                                <label class="font-size-12 d-block d-lg-none">Passport {{ index + 1 }}</label>
                                <input type="date" v-model="child.dateIssue" class="form-control form-control-sm">
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-auto mnWdthCol mb-1">
                                <label class="font-size-14  mb-0">Date of Expiry</label>
                            </div>

                            <div class="col-lg mb-2" v-for="(child, index) in personalForm.passportInfo" :key="index">
                                <label class="font-size-12 d-block d-lg-none">Passport {{ index + 1 }}</label>
                                <input type="date" v-model="child.dateExpiry" class="form-control form-control-sm"
                                    placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 order-lg-2">
                    <div class="groupFormStyle mb-4 mb-lg-5 d-block">
                        <h4 class="font-size-14">Is this a sponsored application?</h4>
                        <div class="row">
                            <div class="col-auto">
                                <label for="appYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" name="applicationApp" v-model="personalForm.sponsoredApp"
                                        class="inputRadioCstm d-none" id="appYes" :value="true"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.sponsoredApp.$error, }"><span
                                        class="radioCircleIcon"><i
                                            class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                </label>
                            </div>
                            <div class="col-auto">
                                <label for="appNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <input type="radio" name="applicationApp" v-model="personalForm.sponsoredApp"
                                        class="inputRadioCstm d-none" id="appNo" :value="false"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.sponsoredApp.$error, }"><span
                                        class="radioCircleIcon">

                                        <i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                            class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                    </span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                </label>
                            </div>
                            <span class="text-danger" v-if="submitted && v$.personalForm.sponsoredApp.$error">{{
                                v$.personalForm.sponsoredApp.required.$message }}</span>
                        </div>
                        <h3 class="font-size-16">Please provide a D1 for the sponsor if applicable</h3>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A10.</span> Name in Local Language
                            Characters</label>
                        <input type="text" v-model="personalForm.localCharacter" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.personalForm.localCharacter.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.personalForm.localCharacter.$error">{{
                            v$.personalForm.localCharacter.required.$message }}</span>
                    </div>
                    <div class="d-block mb-3">
                        <label class="font-size-14"><span class="text-theme">A11.</span> Mothers Maiden Name</label>
                        <input type="text" v-model="personalForm.motherName" class="form-control" placeholder=""
                            :class="{ 'is-invalid': submitted && v$.personalForm.motherName.$error, }">
                        <span class="invalid-feedback" v-if="submitted && v$.personalForm.motherName.$error">{{
                            v$.personalForm.motherName.required.$message }}</span>
                    </div>
                    <div class="d-block ">
                        <label class="font-size-14"><span class="text-theme">A12.</span> Social Security/National
                            Identification number / Tax Number:</label>
                        <div class="row ">
                            <div class="col-lg-6 mb-3">
                                <h3 class="font-size-15">Social Security / National ID No.</h3>

                                <input type="number" v-for="(child, index) in personalForm.identificationId" :key="index"
                                    v-model="child.nationalId" class="form-control mb-1 mb-lg-3" placeholder="">
                            </div>
                            <div class="col-lg-6 mb-3">
                                <h3 class="font-size-15">Country</h3>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="child.nationalCountryId" placeholder="Select" class=" mb-1 mb-lg-3"
                                        :options="countryOption" v-for="(child, index) in personalForm.identificationId"
                                        :key="index" />
                                </div>
                                <!-- <Select2 v-model="child.nationalCountryId" placeholder="Select"
                                    class="mb-1 mb-lg-3" :options="countryOption"   v-for="(child, index) in personalForm.identificationId" :key="index"/> -->

                                <!-- <input type="text" v-for="(child, index) in personalForm.identificationId" :key="index"
                                //     v-model="child.nationalCountryId" class="form-control mb-1 mb-lg-3" placeholder=""> -->
                            </div>
                        </div>
                    </div>
                    <div class="d-block ">
                        <label class="font-size-14"><span class="text-theme">A13.</span> Drivers Licence Number</label>
                        <div class="row ">
                            <div class="col-lg-6 mb-3">
                                <h3 class="font-size-15">Licence Number</h3>

                                <input type="number" v-for="(child, index) in personalForm.driverLicenceNo" :key="index"
                                    v-model="child.licenceNum" class="form-control mb-1 mb-lg-3" placeholder="">
                            </div>
                            <div class="col-lg-6 mb-3">
                                <h3 class="font-size-15">Country</h3>
                                <div class="single-select2-cstm">
                                    <!-- <Select2 v-model="child.nationalCountryId" placeholder="Select"
                                    class=" mb-1 mb-lg-3" :options="countryOption"   v-for="(child, index) in personalForm.identificationId" :key="index"/> -->
                                    <Select2 class=" mb-1 mb-lg-3" v-model="child.countryId" placeholder="Select"
                                        :options="countryOption" v-for="(child, index) in personalForm.driverLicenceNo"
                                        :key="index" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-block">
                <h4 class="font-size-14"><span class="text-theme">A14.</span> Do you hold, or have you ever held, any other
                    citizenships?</h4>
                <div class="row">
                    <div class="col-auto">
                        <label for="holdYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                            <input type="radio" name="applicationHold" v-model="personalForm.citizenshipAppHold"
                                class="inputRadioCstm d-none" id="holdYes" :value="true"
                                :class="{ 'is-invalid': submitted && v$.personalForm.citizenshipAppHold.$error, }"><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                        </label>
                    </div>
                    <div class="col-auto">
                        <label for="holdNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                            <input type="radio" name="applicationHold" v-model="personalForm.citizenshipAppHold"
                                class="inputRadioCstm d-none" id="holdNo" :value="false"
                                :class="{ 'is-invalid': submitted && v$.personalForm.citizenshipAppHold.$error, }"><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                        </label>
                    </div>
                    <span class="text-danger" v-if="submitted && v$.personalForm.citizenshipAppHold.$error">{{
                        v$.personalForm.citizenshipAppHold.required.$message }}</span>
                </div>
                <div class="d-block mb-3">
                    <h4 class="font-size-14">If yes, please specify the country and passport number or ID number in that
                        country. List any dates of any changes of citizenship including relinquishing citizenship and the
                        place at which such changes were made.</h4>
                    <input type="text" v-model="personalForm.passportCountryName1" class="form-control mb-2"
                        :class="{ 'is-invalid': submitted && (v$.personalForm.passportCountryName1.$error && personalForm.citizenshipAppHold == true), }">
                    <span class="invalid-feedback"
                        v-if="submitted && (v$.personalForm.passportCountryName1.$error && personalForm.citizenshipAppHold == true)">{{
                            v$.personalForm.passportCountryName1.required.$message }}</span>

                    <input type="text" v-model="personalForm.passportCountryName2" class="form-control mb-2">
                </div>
                <div class="d-block mb-3">
                    <label class="font-size-14"><span class="text-theme">A15.</span> Languages that you read, understand,
                        speak and / or write fluently</label>
                    <input type="text" v-model="personalForm.speakFluent" class="form-control" placeholder=""
                        :class="{ 'is-invalid': submitted && v$.personalForm.speakFluent.$error, }">
                    <span class="invalid-feedback" v-if="submitted && v$.personalForm.speakFluent.$error">{{
                        v$.personalForm.speakFluent.required.$message }}</span>
                </div>
                <div class="d-block mb-3">
                    <label class="font-size-14"><span class="text-theme">A16.</span> Please specify how many dependants are
                        included in your application and list the dependants below</label>

                    <div class="mainRowTbl">
                        <div class="innerColumnTbl">
                            <div class="cstmMinHght groupFormStyle mb-2 p-2">
                                Full name
                            </div>

                            <input type="text" class="form-control-sm form-control mb-2"
                                v-for="(dependent, index) in personalForm.applicationDependent" :key="index"
                                v-model="dependent.fullName" name="applicationDependent[{{ index }}][full_name]" />

                        </div>
                        <div class="innerColumnTbl">
                            <div class="cstmMinHght groupFormStyle mb-2 p-2">
                                Date of Birth
                            </div>
                            <input type="date" class="form-control-sm form-control mb-2"
                                v-for="(dependent, index) in personalForm.applicationDependent" :key="index"
                                v-model="dependent.dateBirth" />
                        </div>
                        <div class="innerColumnTbl">
                            <div class="cstmMinHght groupFormStyle mb-2 p-2">
                                Nationality / Current citizenship
                            </div>
                            <input type="text" class="form-control-sm form-control mb-2"
                                v-for="(dependent, index) in personalForm.applicationDependent" :key="index"
                                v-model="dependent.citizenshipNat" />
                        </div>
                        <div class="innerColumnTbl">
                            <div class="cstmMinHght groupFormStyle mb-2 p-2">
                                Passport Number
                            </div>
                            <input type="number" class="form-control-sm form-control mb-2"
                                v-for="(dependent, index) in personalForm.applicationDependent" :key="index"
                                v-model="dependent.passportMultiple" />
                        </div>
                        <div class="innerColumnTbl">
                            <div class="cstmMinHght groupFormStyle mb-2 p-2">
                                Dependant’s relationship to main applicant
                            </div>
                            <input type="text" class="form-control-sm form-control mb-2"
                                v-for="(dependent, index) in personalForm.applicationDependent" :key="index"
                                v-model="dependent.relationshipDepend" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="groupFormStyle p-2 mb-3">
                            <h2 class="text-theme font-size-16">Current Address</h2>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A17.</span> Full Address</label>
                                <textarea type="textarea" v-model="personalForm.currentFullAddress"
                                    class="form-control resize-none" rows="3"
                                    :class="{ 'is-invalid': submitted && v$.personalForm.currentFullAddress.$error, }"></textarea>
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.personalForm.currentFullAddress.$error">{{
                                        v$.personalForm.currentFullAddress.required.$message }}</span>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"> Country</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="personalForm.currentCountry" placeholder="Select"
                                        :options="countryOption" @select="countrySelect($event, 1)"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.currentCountry.$error, }" />
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.personalForm.currentCountry.$error">{{
                                            v$.personalForm.currentCountry.required.$message }}</span>
                                </div>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"> State</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="personalForm.currentState" placeholder="Select"
                                        @select="stateSelect($event, 1)" :options="stateOption"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.currentState.$error, }" />
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.personalForm.currentState.$error">{{
                                            v$.personalForm.currentState.required.$message }}</span>
                                </div>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"> City</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="personalForm.currentCity" placeholder="Select" :options="cityOption"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.currentCity.$error, }" />
                                    <span class="invalid-feedback" v-if="submitted && v$.personalForm.currentCity.$error">{{
                                        v$.personalForm.currentCity.required.$message }}</span>
                                </div>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Postal / Zip Code</label>
                                <input type="number" v-model="personalForm.zipCodePstl" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.zipCodePstl.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.zipCodePstl.$error">{{
                                    v$.personalForm.zipCodePstl.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Date Since <sup>(MM/YYYY)</sup></label>
                                <input type="month" v-model="personalForm.monthDate" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.monthDate.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.monthDate.$error">{{
                                    v$.personalForm.monthDate.required.$message }}</span>
                            </div>
                        </div>
                        <div class="groupFormStyle p-2 mb-3">
                            <h2 class="text-theme font-size-16">Permanent Residential Address</h2>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A18.</span> Full Address</label>
                                <textarea type="textarea" v-model="personalForm.permanentFullAddress"
                                    class="form-control resize-none" rows="3"
                                    :class="{ 'is-invalid': submitted && v$.personalForm.permanentFullAddress.$error, }"></textarea>
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.personalForm.permanentFullAddress.$error">{{
                                        v$.personalForm.permanentFullAddress.required.$message }}</span>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"> Country</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="personalForm.permanentCountry" placeholder="Select"
                                        :options="preCountryOption" @select="countrySelect($event, 2)"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.permanentCountry.$error, }" />
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.personalForm.permanentCountry.$error">{{
                                            v$.personalForm.permanentCountry.required.$message }}</span>
                                </div>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"> State</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="personalForm.permanentState" placeholder="Select"
                                        :options="preStateOption" @select="stateSelect($event, 2)"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.permanentState.$error, }" />
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.personalForm.permanentState.$error">{{
                                            v$.personalForm.permanentState.required.$message }}</span>
                                </div>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"> City</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="personalForm.permanentCity" placeholder="Select"
                                        :options="preCityOption"
                                        :class="{ 'is-invalid': submitted && v$.personalForm.permanentCity.$error, }" />
                                    <span class="invalid-feedback"
                                        v-if="submitted && v$.personalForm.permanentCity.$error">{{
                                            v$.personalForm.permanentCity.required.$message }}</span>
                                </div>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Postal / Zip Code</label>
                                <input type="number" v-model="personalForm.permanentZipCodePstl" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.permanentZipCodePstl.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.personalForm.permanentZipCodePstl.$error">{{
                                        v$.personalForm.permanentZipCodePstl.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Date Since <sup>(MM/YYYY)</sup></label>
                                <input type="month" v-model="personalForm.permanentMonthDate" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.permanentMonthDate.$error, }">
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.personalForm.permanentMonthDate.$error">{{
                                        v$.personalForm.permanentMonthDate.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A19.</span> Home Telephone</label>
                                <input type="number" v-model="personalForm.telephoneHome" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.telephoneHome.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.telephoneHome.$error">{{
                                    v$.personalForm.telephoneHome.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A20.</span> Cell phone / Mobile
                                    phone</label>
                                <input type="number" v-model="personalForm.cellPhoneNum" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.cellPhoneNum.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.cellPhoneNum.$error">{{
                                    v$.personalForm.cellPhoneNum.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A21.</span> Email Address</label>
                                <input type="text" v-model="personalForm.emailAddress" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.emailAddress.$error, }">
                                <div v-for="(errorMsg, index) in v$.personalForm.emailAddress.$errors" :key="index"
                                    class="invalid-feedback">
                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="groupFormStyle p-2 mb-3">
                            <h2 class="text-theme font-size-16">Physical Identifying Characteristics</h2>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A22.</span> Colour of Eye</label>
                                <input type="text" v-model="personalForm.colorEye" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.colorEye.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.colorEye.$error">{{
                                    v$.personalForm.colorEye.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A23.</span> Colour of Hair</label>
                                <input type="text" v-model="personalForm.colorHair" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.colorHair.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.colorHair.$error">{{
                                    v$.personalForm.colorHair.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A24.</span> Weight(Kg)</label>
                                <input type="number" v-model="personalForm.weightKg" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.weightKg.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.weightKg.$error">{{
                                    v$.personalForm.weightKg.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A25.</span> Height(cm)</label>
                                <input type="number" v-model="personalForm.heightCm" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && v$.personalForm.heightCm.$error, }">
                                <span class="invalid-feedback" v-if="submitted && v$.personalForm.heightCm.$error">{{
                                    v$.personalForm.heightCm.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A26.</span> Distinguishing
                                    Marks</label>
                                <textarea type="textarea" v-model="personalForm.distinguishingMarks" class="form-control"
                                    rows="7"
                                    :class="{ 'is-invalid': submitted && v$.personalForm.distinguishingMarks.$error, }"></textarea>
                                <span class="invalid-feedback"
                                    v-if="submitted && v$.personalForm.distinguishingMarks.$error">{{
                                        v$.personalForm.distinguishingMarks.required.$message }}</span>
                            </div>
                        </div>
                        <!-- Military Information -->

                        <div class="groupFormStyle p-2 mb-3">
                            <h2 class="text-theme font-size-16">Military Information</h2>
                            <div class="d-block mb-3">
                                <h4 class="font-size-14"><span class="text-theme">A27.</span> Have you ever served in any
                                    armed forces?</h4>
                                <div class="row gx-3">
                                    <div class="col-auto">
                                        <label for="armedYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                                            <input type="radio" v-model="personalForm.armedForce" name="armedForceNm"
                                                class="inputRadioCstm d-none" id="armedYes" :value="true"
                                                :class="{ 'is-invalid': submitted && v$.personalForm.armedForce.$error, }">
                                            <span class="radioCircleIcon">
                                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                <i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                            </span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <label for="armedNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                                            <input type="radio" v-model="personalForm.armedForce" name="armedForceNm"
                                                class="inputRadioCstm d-none" id="armedNo" :value="false"
                                                :class="{ 'is-invalid': submitted && v$.personalForm.armedForce.$error, }">
                                            <span class="radioCircleIcon">
                                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                <i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                            </span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                        </label>
                                    </div>
                                </div>
                                <span class="text-danger" v-if="submitted && v$.personalForm.armedForce.$error">{{
                                    v$.personalForm.armedForce.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A28.</span> Branch</label>
                                <input type="text" v-model="personalForm.branchMdl" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && (v$.personalForm.branchMdl.$error && personalForm.armedForce == true), }">
                                <span class="invalid-feedback"
                                    v-if="submitted && (v$.personalForm.branchMdl.$error && personalForm.armedForce == true)">{{
                                        v$.personalForm.branchMdl.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A29.</span>Date of entry active
                                    service</label>
                                <input type="date" v-model="personalForm.serviceDate" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && (v$.personalForm.serviceDate.$error && personalForm.armedForce == true), }">
                                <span class="invalid-feedback"
                                    v-if="submitted && (v$.personalForm.serviceDate.$error && personalForm.armedForce == true)">{{
                                        v$.personalForm.serviceDate.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A30.</span> Date of separation</label>
                                <input type="date" v-model="personalForm.separationDate" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && (v$.personalForm.separationDate.$error && personalForm.armedForce == true), }">
                                <span class="invalid-feedback"
                                    v-if="submitted && (v$.personalForm.separationDate.$error && personalForm.armedForce == true)">{{
                                        v$.personalForm.separationDate.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A31.</span> Type of Discharge</label>
                                <input type="text" v-model="personalForm.dischargeMdl" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && (v$.personalForm.dischargeMdl.$error && personalForm.armedForce == true), }">
                                <span class="invalid-feedback"
                                    v-if="submitted && (v$.personalForm.dischargeMdl.$error && personalForm.armedForce == true)">{{
                                        v$.personalForm.dischargeMdl.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A32.</span> Ranking at
                                    separation</label>
                                <input type="text" v-model="personalForm.rankSeparate" class="form-control" placeholder=""
                                    :class="{ 'is-invalid': submitted && (v$.personalForm.rankSeparate.$error && personalForm.armedForce == true), }">
                                <span class="invalid-feedback"
                                    v-if="submitted && (v$.personalForm.rankSeparate.$error && personalForm.armedForce == true)">{{
                                        v$.personalForm.rankSeparate.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">A33.</span> Serial Number</label>
                                <input type="number" v-model="personalForm.serialNumberMdl" class="form-control"
                                    placeholder=""
                                    :class="{ 'is-invalid': submitted && (v$.personalForm.serialNumberMdl.$error && personalForm.armedForce == true), }">
                                <span class="invalid-feedback"
                                    v-if="submitted && (v$.personalForm.serialNumberMdl.$error && personalForm.armedForce == true)">{{
                                        v$.personalForm.serialNumberMdl.required.$message }}</span>
                            </div>
                            <div class="d-block mb-3">
                                <h4 class="font-size-14"><span class="text-theme">A34.</span> While in the Military service
                                    were you ever arrested for an offence, which resulted in summary action, a trial, or
                                    special or general court martial?</h4>
                                <div class="row gx-3">
                                    <div class="col-auto">
                                        <label for="militaryYes" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                                            <input type="radio" v-model="personalForm.militaryMdl" name="militaryNm"
                                                class="inputRadioCstm d-none" id="militaryYes" :value="true"
                                                :class="{ 'is-invalid': submitted && v$.personalForm.militaryMdl.$error, }">
                                            <span class="radioCircleIcon">
                                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                <i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                            </span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">Yes</h4>
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <label for="militaryNo" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                                            <input type="radio" v-model="personalForm.militaryMdl" name="militaryNm"
                                                class="inputRadioCstm d-none" id="militaryNo" :value="false"
                                                :class="{ 'is-invalid': submitted && v$.personalForm.militaryMdl.$error, }">
                                            <span class="radioCircleIcon">
                                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                                <i
                                                    class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                            </span>
                                            <h4 class="font-size-14 text-dark-custom mb-0 ms-1">No</h4>
                                        </label>
                                    </div>
                                    <span class="text-danger" v-if="submitted && v$.personalForm.militaryMdl.$error">{{
                                        v$.personalForm.militaryMdl.required.$message }}</span>
                                </div>
                            </div>
                            <div class="d-block mb-3">
                                <h4 class="font-size-14">If yes, please provide details</h4>
                                <input type="text" v-for="(child, index) in personalForm.provideDetailList" :key="index"
                                    v-model="child.provideDetails" class="form-control form-control-sm mb-1" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-block mb-3">
                    <label class="font-size-14 mb-4"><span class="text-theme">A35.</span> Please list all addresses where
                        you have lived for the last ten (10) years, please ensure that there are no gaps in your
                        history.</label>
                    <div class="row">
                        <div class="col-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="">
                                <h4 class="font-size-14">Date from<sup>(MM/YYYY)</sup></h4>
                                <input type="month" class="form-control form-control-sm mb-2" placeholder=""
                                    v-for="(child, index) in personalForm.allAddress" :key="index"
                                    v-model="child.monthFromMdl">
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="">
                                <h4 class="font-size-14">Date to<sup>(MM/YYYY)</sup></h4>
                                <input type="month" class="form-control form-control-sm mb-2" placeholder=""
                                    v-for="(child, index) in personalForm.allAddress" :key="index"
                                    v-model="child.monthToMdl">
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="">
                                <h4 class="font-size-14">Full address<sup>(street address, town, postal code, country)</sup>
                                </h4>
                                <input type="text" class="form-control form-control-sm mb-2" placeholder=""
                                    v-for="(child, index) in personalForm.allAddress" :key="index"
                                    v-model="child.fullAddressMdl">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>